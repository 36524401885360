import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { propTypes, defaultProps } from './props'

// DEPREACATED

export function Resource({ className, children, label, prefill, ...props }) {
  return (
    <div className={c('resource', className)}>
      <a href={prefill} target="_blank" rel="noreferrer">
        {label}
      </a>
    </div>
  )
}
export default Resource

Resource.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  prefill: PropTypes.string,
  label: PropTypes.string,
}

Resource.defaultProps = {
  ...defaultProps,
  className: '',
  prefill: '',
  label: '',
}
