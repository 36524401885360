import isEmpty from 'lodash/isEmpty'

const fillableFieldTypes = (type) => {
  switch (type) {
    case 'REPAIR_COST':
    case 'IMAGES':
    case 'FILES':
      return true
    default:
      return false
  }
}

const isStateDone = (state) => {
  return state === 'DONE' || state === 'ACCEPTED'
}

const isStateRejected = (state) => {
  return state === 'REJECTED'
}

export const taskDefinitions = (devices) => {
  const response = {}
  devices.map((device) => {
    response[device.id] = {
      task_groups: device.task_groups.map((taskGroup) => ({
        ...taskGroup,
        tasks: taskGroup.tasks.map((task) => {
          let completed = false
          task.field_groups.map((fieldGroup) => {
            const fields = fieldGroup.fields.filter((field) =>
              fillableFieldTypes(field.type)
            )
            completed = fields.every((f) => isStateDone(f.field_state))
            return fieldGroup
          })
          return {
            ...task,
            completed,
          }
        }),
      })),
    }
  })
  return response
}

export const claimFulfilledHandler = (state, action) => {
  const { payload } = action
  state.id = payload.id
  const proofLossImages = {}
  const claimDeviceFiles = {}
  const claimDeviceVideos = {}
  const lossTypes = {}
  const rates = {}
  const feedbacks = {}
  const shippings = {}
  const tasks = taskDefinitions(payload.devices)
  let allImagesProcessed = null

  const affectedItems = payload.devices.map((device) => {
    lossTypes[device.id] = {
      typeLoss: [],
      previousDamage: device.previous_damage ? device.previous_damage : '',
      id: device.id,
      deviceId: device.device_id,
      itemOperability: device.item_operability_id
        ? {
            value: device.item_operability_id,
            label: device.item_operability_description,
            name: device.item_operability_name,
          }
        : null,
    }
    const t = []
    device.loss_types.map((lossType) => {
      t.push({
        value: lossType.name,
        label: lossType.name,
        category: lossType.category,
        id: lossType.id,
      })
      return null
    })
    lossTypes[device.id].typeLoss = [...t]

    feedbacks[device.id] = null
    rates[device.id] = null

    if (device.feedback) {
      feedbacks[device.id] = device.feedback.feedback // device.feedbacks
      rates[device.id] = device.feedback.rate // device.rates
    }

    proofLossImages[device.id] = [...device.images]
    claimDeviceFiles[device.id] = [...device.files]
    claimDeviceVideos[device.id] = [...device.videos]
    shippings[device.id] = { ...device.shipping }

    // if any of the images has processing_status = true
    // means that images are still on proccess
    allImagesProcessed = device.images.every(
      (img) => img.processing_status === false
    )

    return {
      id: device.id,
      deviceId: device.device_id,
      kind: device.kind,
      brand: device.brand,
      model: device.model,
      nickname: device.nickname,
      selected: true,
      deviceStatus: device.claim_devices_status,
      repairCost: device.repair_cost,
      repairCostEstimate: device.repair_estimate,
      repairCostUnit: device.repair_cost_unit || 'USD',
      claimDeviceId: device.claim_device_id,
      repair: device.repair,
      replace: device.replace,
      replaceCost: device.replace_cost,
      payout: device.payout,
      verified: device.verified,
      notRepairable: device.not_repairable,
      mailedDevice: device.mailed_device,
      mailedDeviceDate:
        device.mailed_date === null ? new Date() : new Date(device.mailed_date),
      paid: device.paid,
      repairShopAddress: device.repair_shop_address,
      recommendedRepairShop: device.recommended_repair_shop,

      payoutTransactionAmount: device.payout_transaction_amount,
      payoutTransactionDateCreated: device.payout_transaction_date_created,
      payoutDetailReplacementCost: device.payout_detail_replacement_cost,
      payoutDetailTotalReplacementCost:
        device.payout_detail_total_replacement_cost,
      payoutDetailDeductible: device.payout_detail_deductible,
      // name is total_credit_used from payment calculator
      payoutDetailDeductibleCredit: device.payout_detail_deductible_credit,
      payoutDetailTotalCostToAkkoForClaim:
        device.payout_detail_total_cost_to_akko_for_claim,
      payoutDetailTotalPayoutAmount: device.payout_detail_total_payout_amount,
      payoutDetailIsDeductibleWaived: device.payout_detail_is_deductible_waived,
      payoutDetailIsPartnerPayout: device.payout_detail_is_partner_payout,
      payoutDetailTaxes: device.payout_detail_taxes,
      payoutDetailTaxesAmount: device.payout_detail_taxes_amount,
      payoutDetailReplaceCostWithTaxes:
        device.payout_detail_replace_cost_with_taxes,
      payoutDetailHasAppliedClaimLimit:
        device.payout_detail_has_applied_claim_limit,
      payoutDetailClaimLimitValue: device.payout_detail_claim_limit_value,
      paymentServiceFee: device.payment_service_fee,
    }
  })
  state.updating = false
  state.reading = false
  state.affectedItems = affectedItems
  state.typeLoss = lossTypes
  state.proofLossImages = proofLossImages
  state.deviceFiles = claimDeviceFiles
  state.deviceVideos = claimDeviceVideos
  state.tasks = tasks
  state.shippings = shippings
  state.feedbacks = feedbacks
  state.rates = rates
  state.date = new Date(payload.loss_date)
  state.incident = payload.description
  state.paypalAccount = payload.paypal_email || ''
  state.venmoAccount = payload.venmo_username || ''
  state.wireAccount = payload.wire_transfer_email || ''
  // if both are empty, then skip is true
  // ~ a & b ? true : false
  const skip = !!(
    isEmpty(payload.paypal_email) && isEmpty(payload.venmo_username)
  )
  state.skipPayoutMethod = skip
  // state.allImagesProcessed = allImagesProcessed
  // state.allWell = allImagesProcessed
  state.status = payload.status
}
