import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import DeviceItem from '../device-item'
import LossTypeList from '../loss-type-list'
import EvidenceCount from '../evidence-count'
import ActionRequired from '../action-required'
import { IconCircleArrowUpRight } from '../icons/icon-circle-arrow-up-right'
import { getIcon } from '../icons'
import {
  selectClaimItemsAffected,
  selectClaimTypeLossObjects,
  selectClaimProofLossImages,
  selectClaimID,
  selectClaimDeviceTodoTasks,
} from '../../selectors/claim'
import { DEVICE_DETAIL_PATH } from '../../constants'

export function AffectedItemsList({ className, ...props }) {
  const affectedItems = useSelector(selectClaimItemsAffected)
  const lostTypes = useSelector(selectClaimTypeLossObjects)
  const proofLossImages = useSelector(selectClaimProofLossImages)
  const claimID = useSelector(selectClaimID)
  const todoTasks = useSelector(selectClaimDeviceTodoTasks)

  const havePedingTasks = (deviceId) => {
    return !isEmpty(todoTasks[deviceId])
  }

  return (
    <div className={c('affected-items', className)} {...props}>
      <div className="affected-items__items">
        <div className="row">
          {affectedItems.map((item) => (
            <div className="col-12 col-md-6 col-lg-4" key={item.id}>
              <div className="claim-preview shadow card border-light mb-4">
                <div className="card-body position-relative">
                  {havePedingTasks(item.id) ? (
                    <div className="position-absolute top-0 end-0 affected-items__attention-required">
                      Requires Attention
                    </div>
                  ) : null}
                  <Link to={DEVICE_DETAIL_PATH(claimID, item.id)}>
                    <div className="row mt-3">
                      <div className="col-6">
                        <DeviceItem
                          icon={getIcon(item.kind)}
                          main={item.model}
                          second={item.deviceStatus}
                          actionRequired={havePedingTasks(item.id)}
                        />
                      </div>
                      <div className="col-6 text-end">
                        <IconCircleArrowUpRight />
                      </div>
                    </div>
                  </Link>
                  <div>
                    <hr className="border border-1" />
                  </div>
                  <div className="affected-items__detail-title">
                    Type of Loss
                  </div>
                  <LossTypeList
                    className="affected-items__loss-types"
                    list={lostTypes[item.id]}
                    max={2}
                  />
                  <div className="affected-items__detail-title mt-3 d-none-mobile">
                    Evidence
                  </div>
                  <span className="affected-items__detail-group d-none-mobile">
                    <EvidenceCount
                      className="affected-items__count"
                      count={proofLossImages[item.id].length}
                      claimDeviceId={item.id}
                    />
                  </span>
                  {havePedingTasks(item.id) ? (
                    <div className="affected-items__item-col mt-3 d-none-mobile">
                      <div className="affected-items__action">
                        <ActionRequired />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default AffectedItemsList

AffectedItemsList.propTypes = {
  className: PropTypes.string,
}

AffectedItemsList.defaultProps = {
  className: '',
}
