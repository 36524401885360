import { object, string, boolean, number } from 'yup'

export const ItemDetailSchema = object({
  kind: string().required('Required'),
  kind_id: string().nullable(),
  brand: string(),
  brand_id: string().nullable(),
  model: string().required('Required'),
  model_id: string().nullable(),
  purchase_price: number().required('Required'),
  condition_purchased: string().required('Required'),
  condition_purchased_id: string().required('Required'),
  date_purchased: string().required('Required'),
  location_purchased: string().required('Required'),
  serial_number: string().required('Required'),
  nickname: string().required('Required'),
  // conditionals
  carrier: string()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Phone',
      then: (schema) => schema.required('Required'),
    }),
  carrier_id: string()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Phone',
      then: (schema) => schema.required('Required'),
    }),
  storage: number()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Phone' || val === 'Laptop',
      then: (schema) => schema.required('Required'),
    }),
  installments: boolean().when('kind', {
    is: (val) => val === 'Phone',
    then: (schema) => schema.required('Required'),
  }),
  model_number: string()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Laptop',
      then: (schema) => schema.required('Required'),
    }),
  screen_size: number()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Laptop' || val === 'TV',
      then: (schema) => schema.required('Required'),
    }),
  is_touch_screen: boolean().when('kind', {
    is: (val) => val === 'Laptop',
    then: (schema) => schema.required('Required'),
  }),
  imei: string()
    .nullable()
    .when('kind', {
      is: (val) => val === 'Phone',
      then: (schema) => schema.required('Required'),
    }),
  smart_tv: boolean().when('kind', {
    is: (val) => val === 'TV',
    then: (schema) => schema.required('Required'),
  }),
})
