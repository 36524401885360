import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export function RepairProviderLaptops({ className, userAddress, ...props }) {
  return (
    <div className={c('repair-provider-laptops', className)} {...props}>
      <p>
        Please click the button below to find a highly rated computer repair
        shop near you and check to ensure they have the right parts in stock for
        your repair and availability at a time that works for you to drop off
        your device.
      </p>
      <p>
        After receiving an estimate for the cost to repair your device, or a
        diagnosis that the device is not repairable, complete the below fields
        and we&apos;ll proceed with providing you funds for a replacement device
        and have you ship us your damaged one.
      </p>
      <a
        className="btn btn-primary"
        href={`https://www.google.com/maps/search/computer+repair+shops+near+${userAddress.zip}/data=!4m4!2m3!5m1!4e3!6e6`}
        target="_blank"
        rel="noreferrer"
      >
        Open Laptop repair Stores
      </a>
    </div>
  )
}
export default RepairProviderLaptops

RepairProviderLaptops.propTypes = {
  className: PropTypes.string,
  userAddress: PropTypes.shape({}),
}

RepairProviderLaptops.defaultProps = {
  className: '',
  userAddress: {},
}
