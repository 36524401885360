import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'

export function RichText({ className, label, prefill, instruction, ...props }) {
  return (
    <div className={c('rich-text', className)}>
      {!isEmpty(label) && <label className="dc-label">{label}</label>}
      <div dangerouslySetInnerHTML={{ __html: prefill }} />
      {!isEmpty(instruction) && (
        <div className="mb-1 dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default RichText

RichText.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  prefill: PropTypes.string,
  label: PropTypes.string,
}

RichText.defaultProps = {
  ...defaultProps,
  className: '',
  prefill: '',
  label: '',
}
