import React from 'react'

export function IconImage() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.28613"
        y="1.33984"
        width="15.4286"
        height="11.3143"
        fill="white"
      />
      <path
        d="M15.7062 0.3125H1.78089C1.37694 0.3125 0.989544 0.472967 0.703911 0.758599C0.418279 1.04423 0.257813 1.43163 0.257812 1.83558V12.2795C0.257812 12.6835 0.418279 13.0709 0.703911 13.3565C0.989544 13.6421 1.37694 13.8026 1.78089 13.8026H15.7062C16.1101 13.8026 16.4975 13.6421 16.7831 13.3565C17.0688 13.0709 17.2292 12.6835 17.2292 12.2795V1.83558C17.2292 1.43163 17.0688 1.04423 16.7831 0.758599C16.4975 0.472967 16.1101 0.3125 15.7062 0.3125ZM1.78089 1.61799H15.7062C15.7639 1.61799 15.8192 1.64092 15.86 1.68172C15.9008 1.72253 15.9237 1.77787 15.9237 1.83558V10.1037L14.5312 9.05931C14.1164 8.74993 13.6127 8.5828 13.0952 8.5828C12.5777 8.5828 12.074 8.74993 11.6591 9.05931L9.85755 10.4083C9.81731 10.4456 9.76447 10.4664 9.70959 10.4664C9.65472 10.4664 9.60187 10.4456 9.56164 10.4083L7.3597 7.92788C7.1491 7.69372 6.89436 7.50343 6.61008 7.36791C6.32579 7.23238 6.01756 7.1543 5.70304 7.13813C5.38852 7.12196 5.0739 7.16802 4.77722 7.27367C4.48054 7.37932 4.20762 7.54249 3.97412 7.75382L1.56331 9.92964V1.83558C1.56331 1.77787 1.58623 1.72253 1.62704 1.68172C1.66784 1.64092 1.72318 1.61799 1.78089 1.61799ZM15.7062 12.4971H1.78089C1.72318 12.4971 1.66784 12.4742 1.62704 12.4334C1.58623 12.3926 1.56331 12.3372 1.56331 12.2795V11.6964L4.84445 8.71988C4.94839 8.62143 5.07176 8.54579 5.20666 8.49783C5.34155 8.44987 5.48498 8.43064 5.62775 8.44138C5.77136 8.44743 5.91232 8.48212 6.04234 8.5434C6.17237 8.60467 6.28885 8.69131 6.38493 8.79821L8.59557 11.2612C8.84885 11.5457 9.2 11.7243 9.57906 11.7614C9.95811 11.7985 10.3372 11.6914 10.6408 11.4614L12.4424 10.1124C12.6307 9.97118 12.8598 9.89483 13.0952 9.89483C13.3306 9.89483 13.5596 9.97118 13.7479 10.1124L15.9237 11.7399V12.2795C15.9237 12.3372 15.9008 12.3926 15.86 12.4334C15.8192 12.4742 15.7639 12.4971 15.7062 12.4971Z"
        fill="black"
        stroke="#E8E7E5"
        strokeWidth="0.4"
      />
      <path
        d="M12.3968 5.53286C13.1178 5.53286 13.7023 4.94837 13.7023 4.22737C13.7023 3.50636 13.1178 2.92188 12.3968 2.92188C11.6758 2.92188 11.0913 3.50636 11.0913 4.22737C11.0913 4.94837 11.6758 5.53286 12.3968 5.53286Z"
        fill="black"
        stroke="#E8E7E5"
        strokeWidth="0.4"
      />
    </svg>
  )
}
export default IconImage

IconImage.propTypes = {}

IconImage.defaultProps = {}
