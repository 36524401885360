import { createSelector } from '@reduxjs/toolkit'

export const devicesStore = (state) => state.devices

export const selectUserDevices = createSelector(
  devicesStore,
  (devices) => devices.list
)

export const selectUserDeviceById = createSelector(
  [selectUserDevices, (_, deviceId) => deviceId],
  (devices, deviceId) => {
    const found = devices.find((d) => d.id === deviceId)
    if (found) return found
    return {}
  }
)

export const selectProccessingItem = createSelector(
  devicesStore,
  (devices) => devices.proccessingItem
)
