import React from 'react'

export function Tv() {
  return (
    <svg
      width="45"
      height="35"
      viewBox="0 0 45 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6112 33.8331H11.6356L10.8062 33.0038L10.3916 31.7599L20.3428 30.1014V27.8378H1.06836V1.90625H43.9771V27.6136L24.4893 28.0282V30.516L27.3917 30.9306L34.4405 31.7599V33.0038L33.6112 33.8331Z"
        fill="white"
      />
      <path
        d="M16.9159 14.9493L14.5371 17.3281L15.3781 18.1691L17.7569 15.7903L16.9159 14.9493Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M24.0523 11.9887L16.916 19.125L17.757 19.966L24.8933 12.8297L24.0523 11.9887Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M21.6714 10.1977L18.1035 13.7656L18.9445 14.6066L22.5124 11.0387L21.6714 10.1977Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M28.8863 11.8618L25.3184 15.4297L26.1594 16.2707L29.7273 12.7028L28.8863 11.8618Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M43.8527 21.4067C44.2092 21.4067 44.4981 21.1178 44.4981 20.7613V2.1529C44.4981 1.85009 44.3694 1.55975 44.1451 1.35649C43.9301 1.16176 43.6433 1.0628 43.3559 1.0801H1.58191C1.29286 1.06263 1.00751 1.16176 0.792641 1.35649C0.568391 1.55975 0.439657 1.85009 0.439657 2.1529V27.3635C0.427266 27.9667 0.980664 28.486 1.58182 28.4363H19.6292V29.8212L11.1648 30.7281C10.3977 30.8102 9.81928 31.454 9.81928 32.2254V32.743C9.81928 33.5733 10.4949 34.2489 11.3252 34.2489H27.2878C27.6443 34.2489 27.9331 33.96 27.9331 33.6035C27.9331 33.247 27.6443 32.9581 27.2878 32.9581H11.3252C11.2065 32.9581 11.1101 32.8616 11.1101 32.743V32.2254C11.1101 32.1152 11.1927 32.0232 11.3022 32.0115L20.3804 31.0388C21.7948 30.8873 23.229 30.8873 24.6433 31.0388L33.7217 32.0115C33.8311 32.0233 33.9137 32.1152 33.9137 32.2254V32.743C33.9137 32.8616 33.8173 32.9581 33.6986 32.9581H30.2996C29.9431 32.9581 29.6542 33.247 29.6542 33.6035C29.6542 33.96 29.9431 34.2489 30.2996 34.2489H33.6986C34.5289 34.2489 35.2045 33.5733 35.2045 32.743V32.2254C35.2045 31.4539 34.6261 30.8102 33.859 30.7281L25.3946 29.8212V28.4363H43.3559C43.9553 28.4868 44.5113 27.9662 44.4981 27.3635V23.7731C44.4981 23.4166 44.2092 23.1277 43.8527 23.1277C43.4962 23.1277 43.2073 23.4166 43.2073 23.7731V27.1455H1.73043V2.37087H43.2073V20.7613C43.2073 21.1177 43.4963 21.4067 43.8527 21.4067ZM24.1038 29.6951C23.0449 29.6158 21.9789 29.6158 20.9199 29.6951V28.4363H24.1038V29.6951Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Tv
