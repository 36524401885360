import React from 'react'

export function IconMail() {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.707275"
        y="3.56104"
        width="22.2439"
        height="17.561"
        rx="5"
        fill="white"
      />
      <path
        d="M22.1669 0.0488281H3.83308C2.75643 0.0488281 1.87805 0.926246 1.87805 2.00386V15.0374C1.87805 16.1114 2.75252 16.9924 3.83308 16.9924H22.1669C23.2409 16.9924 24.122 16.118 24.122 15.0374V2.00386C24.122 0.929895 23.2475 0.0488281 22.1669 0.0488281ZM21.897 1.35218L13.0414 10.2077L4.10935 1.35218H21.897ZM3.18141 14.7675V2.26753L9.45831 8.49061L3.18141 14.7675ZM4.10301 15.6891L10.3839 9.40821L12.5847 11.5901C12.8394 11.8427 13.2506 11.8419 13.5043 11.5881L15.6502 9.44223L21.897 15.6891H4.10301ZM22.8186 14.7675L16.5718 8.52063L22.8186 2.27374V14.7675Z"
        fill="#060920"
      />
    </svg>
  )
}
export default IconMail

IconMail.propTypes = {}

IconMail.defaultProps = {}
