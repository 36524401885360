import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

export const claimStore = (state) => state.claim

export const selectClaimID = createSelector(claimStore, (claim) => claim.id)

export const selectClaimReadingState = createSelector(
  claimStore,
  (claim) => claim.reading
)

export const selectClaimSkipLoadingScreen = createSelector(
  claimStore,
  (claim) => claim.skipLoadingScreen
)

export const selectClaimStatus = createSelector(
  claimStore,
  (claim) => claim.status
)

export const selectClaimDate = createSelector(claimStore, (claim) => claim.date)

export const selectClaimIncident = createSelector(
  claimStore,
  (claim) => claim.incident
)

export const selectClaimItemsAffected = createSelector(
  claimStore,
  // TODO, items affected selector should be altered by "open: true"
  (claim) => claim.affectedItems.map((d) => ({ ...d, open: true }))
)

export const selectClaimItemAffectedByID = createSelector(
  [selectClaimItemsAffected, (_, claimDeviceId) => claimDeviceId],
  (affectedItems, claimDeviceId) =>
    affectedItems.filter((item) => item.id === claimDeviceId)[0]
)

export const selectClaimDevicePayment = createSelector(
  [selectClaimItemsAffected, (_, claimDeviceId) => claimDeviceId],
  (affectedItems, claimDeviceId) => {
    const item = affectedItems.find((i) => i.id === claimDeviceId)
    return {
      payoutTransactionAmount: item.payoutTransactionAmount,
      payoutTransactionDateCreated: item.payoutTransactionDateCreated,
      payoutDetailReplacementCost: item.payoutDetailReplacementCost,
      payoutDetailTotalReplacementCost: item.payoutDetailTotalReplacementCost,
      payoutDetailDeductible: item.payoutDetailDeductible,
      payoutDetailDeductibleCredit: item.payoutDetailDeductibleCredit,
      payoutDetailTotalCostToAkkoForClaim:
        item.payoutDetailTotalCostToAkkoForClaim,
      payoutDetailTotalPayoutAmount: item.payoutDetailTotalPayoutAmount,
      payoutDetailIsDeductibleWaived: item.payoutDetailIsDeductibleWaived,
      payoutDetailIsPartnerPayout: item.payoutDetailIsPartnerPayout,
      payoutDetailTaxes: item.payoutDetailTaxes,
      payoutDetailTaxesAmount: item.payoutDetailTaxesAmount,
      payoutDetailReplaceCostWithTaxes: item.payoutDetailReplaceCostWithTaxes,
      payoutDetailHasAppliedClaimLimit: item.payoutDetailHasAppliedClaimLimit,
      payoutDetailClaimLimitValue: item.payoutDetailClaimLimitValue,
    }
  }
)

export const selectClaimTypeLoss = createSelector(
  claimStore,
  (claim) => claim.typeLoss
)

export const selectClaimTypeLossNames = createSelector(
  selectClaimTypeLoss,
  (typeLoss) => {
    const list = {}
    Object.values(typeLoss).map((tl) => {
      list[tl.id] = tl.typeLoss.map((t) => t.value)
    })
    return list
  }
)

export const selectClaimTypeLossObjects = createSelector(
  selectClaimTypeLoss,
  (typeLoss) => {
    const list = {}
    Object.values(typeLoss).map((tl) => {
      list[tl.id] = tl.typeLoss.map((t) => t)
    })
    const listGroup = {}
    Object.keys(list).map((key) => {
      listGroup[key] = list[key].reduce((rv, x) => {
        ;(rv[x.category] = rv[x.category] || []).push(x.value)
        return rv
      }, {})
    })
    const listObject = {}
    Object.keys(listGroup).map((key) => {
      listObject[key] = Object.keys(listGroup[key]).map((category) => {
        return {
          cat: category,
          lt: listGroup[key][category],
        }
      })
    })
    return listObject
  }
)

export const selectClaimTypeLossNamesByDeviceId = createSelector(
  [selectClaimTypeLossNames, (_, deviceID) => deviceID],
  (typeLossList, deviceID) => typeLossList[deviceID]
)

export const selectClaimTypeLossObjectsByDeviceId = createSelector(
  [selectClaimTypeLossObjects, (_, deviceID) => deviceID],
  (typeLossList, deviceID) => typeLossList[deviceID]
)

export const selectClaimProofLossImages = createSelector(
  claimStore,
  (claim) => claim.proofLossImages
)

export const selectClaimDeviceEvidences = createSelector(
  [claimStore, (state, data) => data],
  (claim, { claimDeviceId, evidenceType }) => {
    const images = claim.proofLossImages[claimDeviceId]
      .filter((elem) => elem.evidence_type_id === evidenceType)
      .map((img) => ({ ...img, documentType: 'image' }))

    const files = claim.deviceFiles[claimDeviceId]
      .filter((elem) => elem.evidence_type_id === evidenceType)
      .map((file) => ({ ...file, documentType: 'file' }))

    const videos = claim.deviceVideos[claimDeviceId]
      .filter((elem) => elem.evidence_type_id === evidenceType)
      .map((video) => ({ ...video, documentType: 'video' }))

    return [...images, ...files, ...videos]
  }
)

export const selectClaimProofLossImagesByDevice = createSelector(
  [claimStore, (state, deviceId) => deviceId],
  (claim, deviceId) => claim.proofLossImages[deviceId]
)

export const selectDeviceFiles = createSelector(
  claimStore,
  (claim) => claim.deviceFiles
)

export const selectDeviceFileById = createSelector(
  [selectDeviceFiles, (_, deviceID) => deviceID],
  (deviceFiles, deviceID) => deviceFiles[deviceID]
)

export const selectClaimImagesProccessingList = createSelector(
  claimStore,
  (claim) => {
    const imagesAreProcessing = []
    Object.values(claim.proofLossImages).forEach((images) => {
      images.map((i) =>
        imagesAreProcessing.push({
          id: i.id,
          processing_status: i.processing_status,
        })
      )
    })
    return imagesAreProcessing
  }
)

export const selectClaimImagesAreProccessing = createSelector(
  selectClaimImagesProccessingList,
  (imagesProcessingList) => {
    const tmp = imagesProcessingList
      ? imagesProcessingList.some((i) => i.processing_status === true)
      : null
    return tmp
  }
)

export const selectClaimPaypalAccount = createSelector(
  claimStore,
  (claim) => claim.paypalAccount
)

export const selectClaimAcceptedTermsConditions = createSelector(
  claimStore,
  (claim) => claim.acceptedTermsConditions
)

export const selectClaimUpdating = createSelector(
  claimStore,
  (claim) => claim.updating
)

export const selectClaimPayload = createSelector(claimStore, (claim) => {
  return {
    id: claim.id,
    date: claim.date,
    incident: claim.incident,
    affectedItems: claim.affectedItems,
    typeLoss: claim.typeLoss,
    proofLossImages: claim.proofLossImages,
    paypalAccount: claim.paypalAccount,
    acceptedTermsConditions: claim.acceptedTermsConditions,
    venmoAccount: claim.venmoAccount,
    skipPayoutMethod: claim.skipPayoutMethod,
  }
})

export const selectClaimPayloadForValidation = createSelector(
  claimStore,
  (claim) => {
    return {
      date: claim.date,
      incident: claim.incident,
      affectedItems: claim.affectedItems,
      // This fix is to make the validation a lot more easy
      lossTypes: Object.values(claim.typeLoss),
      refPaypalAccount: claim.paypalAccount,
      paypalAccount: claim.paypalAccount,
      refVenmoAccount: claim.venmoAccount,
      venmoAccount: claim.venmoAccount,
      skipPayoutMethod: claim.skipPayoutMethod,
      acceptedTermsConditions: claim.acceptedTermsConditions,
    }
  }
)

export const selectClaimPayloadForSchemaValidation = createSelector(
  selectClaimPayload,
  (claim) => {
    return {
      ...claim,
      typeLoss: Object.values(claim.typeLoss),
    }
  }
)

export const selectImagesStatus = createSelector(claimStore, (claim) => {
  return {
    allImagesProcessed: claim.allImagesProcessed,
    allWell: claim.allWell,
    errors: claim.errors,
  }
})

export const selectTimerId = createSelector(
  claimStore,
  (claim) => claim.timerId
)

export const selectCheckingState = createSelector(
  claimStore,
  (claim) => claim.checking
)

export const selectProcessingImagesValues = createSelector(
  selectClaimImagesProccessingList,
  (imagesProcessing) => {
    const total = imagesProcessing.length
    const ready = imagesProcessing.filter(
      (i) => i.processing_status === false
    ).length
    const current = (100 * ready) / total
    return { total, ready, current }
  }
)

export const selectClaimVenmoAccount = createSelector(
  claimStore,
  (claim) => claim.venmoAccount
)

export const selectClaimSkipPayoutMethod = createSelector(
  claimStore,
  (claim) => claim.skipPayoutMethod
)

export const selectPayoutMethods = createSelector(claimStore, (claim) => {
  return {
    paypal: claim.paypalAccount,
    venmo: claim.venmoAccount,
    nopayout: claim.skipPayoutMethod,
  }
})

export const selectClaimDeviceTasks = createSelector(
  claimStore,
  (claim) => claim.tasks
)

export const selectCustomerTasksByDeviceId = createSelector(
  [selectClaimDeviceTasks, (state, deviceId) => deviceId],
  // (tasks, deviceId) => tasks[deviceId].task_groups[0].tasks.filter((task) => task.type === 1),
  (tasks, deviceId) => {
    const tasksItems = []
    tasks[deviceId].task_groups.map((task_group) =>
      task_group.tasks.map((task) => tasksItems.push(task))
    )
    return tasksItems
  }
)
// TODO, search for a two times nested selector
export const selectCustomerTasksCompletedByDeviceId = createSelector(
  [selectClaimDeviceTasks, (state, claimDeviceId) => claimDeviceId],
  (tasks, claimDeviceId) => {
    const tasksItems = []
    if (isEmpty(tasks[claimDeviceId])) {
      return []
    }
    const notActiveTaskGroups = tasks[claimDeviceId].task_groups.filter(
      (tg) => tg.active === false
    )
    notActiveTaskGroups.map((tg) =>
      tg.tasks.map((task) =>
        tasksItems.push({ ...task, taskGroupName: tg.name })
      )
    )
    return tasksItems.filter((t) => t.task_state_name === 'DONE')
  }
)

export const selectCustomerTasksUncompletedByDeviceId = createSelector(
  [selectClaimDeviceTasks, (state, deviceId) => deviceId],
  // note: for now we olny support one task group
  // (tasks, deviceId) => tasks[deviceId].task_groups[0].tasks.filter((task) => task.type === 1 && task.completed === false),
  (tasks, deviceId) => {
    const tasksItems = []
    if (isEmpty(tasks[deviceId])) {
      return []
    }
    const activeTaskGroups = tasks[deviceId].task_groups.filter(
      (tg) => tg.active === true
    )
    activeTaskGroups.map((tg) =>
      tg.tasks.map((task) =>
        tasksItems.push({ ...task, taskGroupName: tg.name })
      )
    )
    return tasksItems.filter((t) => t.task_state_name === 'TODO')
  }
)

export const selectFieldsByTaskID = createSelector(
  [
    selectClaimDeviceTasks,
    (state, deviceId) => deviceId,
    (state, taskId) => taskId,
  ],
  // note: for now we olny support one task group
  // note for now we only support one field_group by task
  (tasks, deviceId, taskId) => {
    const deviceTasks = tasks[deviceId].task_groups[0].tasks.filter(
      (task) => task.type === 'customer'
    )
    const { fields } = deviceTasks.filter((t) => t.id === taskId)[0]
      .field_groups[0]
    return fields
  }
)

export const selectRates = createSelector(claimStore, (claim) => claim.rates)

export const selectRatesByDeviceId = createSelector(
  [selectRates, (state, deviceId) => deviceId],
  (rates, deviceId) => rates[deviceId]
)

export const selectFeedback = createSelector(
  claimStore,
  (claim) => claim.feedbacks
)

export const selectStoreUrls = createSelector(
  [claimStore, (state, deviceId) => deviceId],
  (claim, deviceId) => claim.storeUrls[deviceId]
)

export const selectFeedbackByDeviceId = createSelector(
  [selectFeedback, (state, deviceId) => deviceId],
  (feedbacks, deviceId) => feedbacks[deviceId]
)

export const selectNotRepairableByDevice = createSelector(
  [selectClaimItemsAffected, (state, deviceId) => deviceId],
  // note: for now we olny support one task group
  (itemsAffected, deviceId) =>
    itemsAffected.find((item) => item.id === deviceId).notRepairable
)

export const selectRepairShopAddressByDevice = createSelector(
  [selectClaimItemsAffected, (state, deviceId) => deviceId],
  (itemsAffected, deviceId) =>
    itemsAffected.find((item) => item.id === deviceId).repairShopAddress
)

export const selectRepairCostByDevice = createSelector(
  [selectClaimItemsAffected, (state, deviceId) => deviceId],
  // note: for now we olny support one task group
  (itemsAffected, deviceId) =>
    itemsAffected.find((item) => item.id === deviceId).repairCost
)

export const selectClaimDeviceTodoTasks = createSelector(
  [selectClaimDeviceTasks],
  (tasks) => {
    const todoTasks = {}
    Object.entries(tasks).map((entry) => {
      const todos = []
      entry[1].task_groups.map((taskGroup) => {
        taskGroup.tasks.map((task) => {
          task.field_groups.map((fieldGroup) => {
            fieldGroup.fields.map((field) => {
              if (field.required === true && field.field_state === 'TODO') {
                todos.push(field)
              }
            })
          })
        })
      })
      todoTasks[entry[0]] = todos
    })
    return todoTasks
  }
)

export const selectClaimPaymentMethod = createSelector(claimStore, (claim) => {
  return {
    paypalAccount: claim.paypalAccount,
    venmoAccount: claim.venmoAccount,
    wireAccount: claim.wireAccount,
  }
})

export const selectDeviceShipping = createSelector(
  [claimStore, (state, deviceId) => deviceId],
  (claim, deviceId) => claim.shippings[deviceId]
)

export const selectEvidenceStatus = createSelector(
  [claimStore, (state, deviceId) => deviceId],
  (claim, deviceId) => {
    // in first time, the evidence.id is null that is why the last filter
    const deviceImages = claim.proofLossImages[deviceId]
    const images = deviceImages
      ? deviceImages
          .filter((img) => img.processing_status === null)
          .map((img) => img.id)
          .filter((id) => id !== null)
      : null

    const deviceFiles = claim.deviceFiles[deviceId]
    const files = deviceFiles
      ? deviceFiles
          .filter((img) => img.processing_status === null)
          .map((img) => img.id)
          .filter((id) => id !== null)
      : null

    const deviceVideos = claim.deviceVideos[deviceId]
    const videos = deviceVideos
      ? deviceVideos
          .filter((img) => img.processing_status === null)
          .map((img) => img.id)
          .filter((id) => id !== null)
      : null

    return {
      images,
      files,
      videos,
    }
  }
)

export const selectClaimLimits = createSelector(
  claimStore,
  (claim) => claim.claimLimits
)
