import React, { useState, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { setDate, setIncident, updateClaim } from '../../reducers/claim'
import { selectClaimDate, selectClaimIncident } from '../../selectors/claim'
import { selectAppTraveled } from '../../selectors/app'
import { DELAY_TIMING, DESCRIBE_INCIDENT_PATH } from '../../constants'
import TextEditor from '../text-editor'
import useValidation from '../../hooks/validations-schema'
import { incidentValidationSchema } from '../../utils/validation'
import { DI_DATE_LOSS_TXT, DI_TEXTAREA_TXT } from '../../i18n'
import Label from '../label'
import 'draft-js/dist/Draft.css'

export function DescribeIncidentForm() {
  const [startDate, setStartDate] = useState(useSelector(selectClaimDate))
  const [incident, setIncidentText] = useState(useSelector(selectClaimIncident))
  const traveled = useSelector(selectAppTraveled)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [errors] = useValidation(incidentValidationSchema, {
    date: startDate,
    incident,
  })

  const update = useCallback(
    debounce(() => {
      dispatch(updateClaim({ section: DESCRIBE_INCIDENT_PATH }))
    }, DELAY_TIMING),
    []
  )

  const onChangeDate = (date) => {
    setStartDate(date)
    dispatch(setDate(date))
    update()
  }

  const onChangeIncident = (value) => {
    // When text editor is empty, it submit by default empty htmls
    if (value === '<p></p>' || value === '<p><br></p>') {
      value = ''
    }
    setIncidentText(value)
    dispatch(setIncident(value))
    update()
  }

  return (
    <div className="describe-incident-form">
      <div>
        <div className="mb-3">
          <Label className="mb-2 d-block">DATE OF INCIDENT</Label>
          <DatePicker
            className="form-control describe-incident-form__date-loss"
            selected={startDate}
            placeholder={t(DI_DATE_LOSS_TXT)}
            maxDate={new Date()}
            onChange={onChangeDate}
          />
          {traveled && !isEmpty(errors.date) && (
            <div className="error-text">{errors.date[0]}</div>
          )}
        </div>
        <div className="mb-3">
          <TextEditor
            placeholder={t(DI_TEXTAREA_TXT)}
            onChange={onChangeIncident}
            value={incident}
          />
          {traveled && !isEmpty(errors.incident) && (
            <div className="error-text">{errors.incident[0]}</div>
          )}
        </div>
      </div>
    </div>
  )
}
export default DescribeIncidentForm
