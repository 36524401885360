import React from 'react'

export function IconCloseV2() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1L1 10"
        stroke="#060920"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 1L10 10"
        stroke="#060920"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default IconCloseV2

IconCloseV2.propTypes = {}

IconCloseV2.defaultProps = {}
