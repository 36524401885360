import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { WHITE_LABEL_BRAND_NAME } from '../../constants'

export function Finished({ className, model }) {
  const onContactUs = () => {
    window.Intercom('show')
  }

  return (
    <div
      className={c('process-finished', className, {
        branded: WHITE_LABEL_BRAND_NAME !== 'AKKO',
      })}
    >
      <img
        src={
          WHITE_LABEL_BRAND_NAME === 'AKKO'
            ? '/images/ic-process-finished.png'
            : '/images/ic-process-finished-branded.png'
        }
        alt="process finished"
      />
      <h5>Claim Process for Your {model} is Complete!</h5>
      <p>All steps have been completed successfully</p>
      <p>
        If you need any further assistance regarding this incident,{' '}
        <a href="#" onClick={onContactUs}>
          contact us.
        </a>
      </p>
    </div>
  )
}
export default Finished

Finished.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string,
}

Finished.defaultProps = {
  className: '',
  model: '',
}
