import React from 'react'

export function IconCheck() {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role='img'
    >
      <path
        d="M3.77772 4.00041C3.76206 4.01668 3.73605 4.01678 3.72027 4.00062L2.03259 2.27263C1.85904 2.09494 1.57291 2.09602 1.40071 2.27502L1.26691 2.41411C1.10231 2.58522 1.10315 2.85605 1.2688 3.02614L3.43392 5.24929C3.60739 5.42741 3.89387 5.42653 4.06623 5.24735L7.73408 1.43451C7.89795 1.26417 7.89795 0.994783 7.73408 0.824436L7.60059 0.685676C7.42749 0.505729 7.1395 0.505729 6.9664 0.685676L7.10932 0.82316L6.9664 0.685676L3.77772 4.00041Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
      />
    </svg>
  )
}
export default IconCheck

IconCheck.propTypes = {}

IconCheck.defaultProps = {}
