import React, { useEffect, useState, useRef } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { Editor, EditorState, RichUtils } from 'draft-js'
import Toolbar from './toolbar'
import 'draft-js/dist/Draft.css'

export function TextEditor({ className, onChange, value, debug, placeholder }) {
  const [html, setHtml] = useState('')
  const [focused, setFocused] = useState(false)
  const refDomEditor = useRef()

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    const content = stateFromHTML(value)
    setEditorState(EditorState.createWithContent(content))
  }, [])

  const onContentChange = (_editorState) => {
    setEditorState(_editorState)
    const h = stateToHTML(_editorState.getCurrentContent())
    setHtml(h)
    onChange(h)
  }

  const handleKeyCommand = (command, _editorState) => {
    const newState = RichUtils.handleKeyCommand(_editorState, command)

    if (newState) {
      onContentChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const onBold = () => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const onItalic = () => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const onUnderline = () => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const onFocus = () => {
    setFocused(true)
    refDomEditor.current.focus()
  }

  const onBlur = () => {
    setFocused(false)
  }

  const setDomEditor = (r) => {
    refDomEditor.current = r
  }

  return (
    <div className={c('text-editor', className, { focus: focused })}>
      <Toolbar onBold={onBold} onItalic={onItalic} onUnderline={onUnderline} />
      <div className={c('form-control')}>
        <Editor
          editorState={editorState}
          onChange={onContentChange}
          handleKeyCommand={handleKeyCommand}
          placeholder={placeholder}
          ref={setDomEditor}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {/* <div contentEditable="true">bl blb dlbd fbd bdfbfd bdlbdfblfdbldfb</div> */}
      {debug && <pre>{html}</pre>}
    </div>
  )
}

export default TextEditor

TextEditor.propTypes = {
  className: PropTypes.string,
  debug: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

TextEditor.defaultProps = {
  className: '',
  debug: false,
  value: '',
  placeholder: '',
  onChange: () => {},
}
