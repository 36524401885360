import React from 'react'

export function Preloading() {
  return (
    <div>
      <div className="p-5">
        <div className="loading-page text-center">
          <div
            className="mx-2 spinner-border spinner-border-sm spinner-bg"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          Loading
        </div>
      </div>
    </div>
  )
}

export default Preloading
