import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { propTypes, defaultProps } from './props'
import { selectRatesByDeviceId } from '../../selectors/claim'
import { updateDynamicField, setRates } from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'

export function Rate({
  className,
  label,
  instruction,
  onChange,
  claimId,
  claimDeviceId,
  deviceId,
  disabled,
  id: fieldId,
}) {
  const storedRate = useSelector((state) =>
    selectRatesByDeviceId(state, claimDeviceId)
  )
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [rate, setRate] = useState(storedRate)

  const update = useCallback(
    debounce(() => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'rate',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        setLoading(false)
        onChange({ fieldId })
      })
    }, DELAY_TIMING),
    []
  )

  const onRate = (r) => {
    setRate(r)
    dispatch(setRates({ claimDeviceId, rate: r }))
    update()
  }
  const rates = [1, 2, 3, 4, 5]
  return (
    <div className={c('amount-text', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div className="mb-1">
        <div className="btn-group" role="group" aria-label="Basic example">
          {rates.map((r) => (
            <button
              key={r}
              type="button"
              disabled={disabled}
              onClick={() => onRate(r)}
              className={c('btn', {
                'btn-primary': rate === r,
                'btn-outline-primary': rate !== r,
              })}
            >
              {r}
            </button>
          ))}
        </div>
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      {/* {loading && <Spinner /> } */}
    </div>
  )
}
export default Rate

Rate.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.bool,
}

Rate.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
