import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Button from '../button'
import { AFFECTED_ITEMS_PATH } from '../../constants'
import IconNotFound from '../icons/icon-not-found'
import IconPlus from '../icons/icon-plus'

export function ClaimsNotFound({ className }) {
  return (
    <div className={c('claims_not_found', className)}>
      <IconNotFound />
      <div className="mt-2">
        <span className="title">No Claims Found</span>
      </div>
      <div className="body-container">
        <span className="body">
          Not to worry. You can start filling your first claim right away!
        </span>
      </div>
      <Button
        className="btn-file-claim mt-3"
        small
        link
        to={`/${AFFECTED_ITEMS_PATH}/`}
      >
        <IconPlus />
        <span className="btn-text">File New Claim</span>
      </Button>
    </div>
  )
}
export default ClaimsNotFound
ClaimsNotFound.propTypes = {
  className: PropTypes.string,
}
ClaimsNotFound.defaultProps = {
  className: '',
}
