import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export function RepairProviderPartner({ className, userAddress, ...props }) {
  return (
    <div className={c('repair-provider-partner', className)} {...props}>
      <p>
        Please contact _PARTNER_PROPERTY_NAME and check to ensure they have the
        right parts in stock for your repair and availability at a time that
        works for you to drop off your device.
      </p>
      <p>
        Your claim is approved for repair as long as the repair cost total does
        not exceed REPLACEMENT COST OF CLAIM DEVICE. If the estimated repair
        cost is more expensive than that amount, indicate so below and we’ll
        proceed with providing you funds for a replacement device instead and
        have you ship us your damaged one.
      </p>
      <p>
        If the repair shop has a replacement device you&apos;re satisfied with
        for sale, they can provide you it for
        REPLACEMENT_COST_OF_THE_CLAIM_DEVICE + 35 and help you set it up. If
        they do not have one available, or the one they have costs more than
        REPLACEMENT_COST_OF_THE_CLAIM.DEVICE + $35, we&apos;ll proceed with
        providing you funds for a replacement device to purchase elsewhere and
        have you ship us your damaged device.
      </p>
      <p>
        The shop will charge you your appropriate deductible for any repair or
        replacement and send AKKO an invoice for the remaining amount due.
        Please do not pay the full price of the repair/replacement at the
        location. A representative at the shop can look up your claim in their
        AKKO Partner Platform account to verify this claim is approved and view
        instructions for them to follow.
      </p>
      <a
        className="btn btn-primary"
        href="https://google.com"
        target="_blank"
        rel="noreferrer"
      >
        Directions
      </a>{' '}
      <a
        className="btn btn-outline-primary"
        href="https://google.com"
        target="_blank"
        rel="noreferrer"
      >
        Call +51 999888777
      </a>{' '}
      <a
        className="btn btn-outline-primary"
        href="https://google.com"
        target="_blank"
        rel="noreferrer"
      >
        Email some@partner.com
      </a>
    </div>
  )
}
export default RepairProviderPartner

RepairProviderPartner.propTypes = {
  className: PropTypes.string,
  userAddress: PropTypes.shape({}),
}

RepairProviderPartner.defaultProps = {
  className: '',
  userAddress: {},
}
