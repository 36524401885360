import React from 'react'

export function IconPlus() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.968504 8C0.968504 8.52184 1.41798 8.94488 1.97244 8.94488L7.99606 8.94488L7.99606 14.6142C7.99606 15.136 8.44554 15.5591 9 15.5591C9.55446 15.5591 10.0039 15.136 10.0039 14.6142L10.0039 8.94488L16.0276 8.94488C16.582 8.94488 17.0315 8.52185 17.0315 8C17.0315 7.47816 16.582 7.05512 16.0276 7.05512L10.0039 7.05512L10.0039 1.38583C10.0039 0.863983 9.55446 0.440946 9 0.440946C8.44554 0.440946 7.99606 0.863983 7.99606 1.38583L7.99606 7.05512L1.97244 7.05512C1.41798 7.05512 0.968504 7.47816 0.968504 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.97244 9.38583C1.15923 9.38583 0.5 8.76537 0.5 8C0.5 7.23463 1.15923 6.61417 1.97244 6.61417L7.52756 6.61417L7.52756 1.38583C7.52756 0.620457 8.18679 1.04534e-06 9 1.05504e-06C9.81321 1.06473e-06 10.4724 0.620457 10.4724 1.38583L10.4724 6.61417L16.0276 6.61418C16.8408 6.61418 17.5 7.23463 17.5 8C17.5 8.76537 16.8408 9.38583 16.0276 9.38583L10.4724 9.38583L10.4724 14.6142C10.4724 15.3795 9.81321 16 9 16C8.18679 16 7.52756 15.3795 7.52756 14.6142L7.52756 9.38583L1.97244 9.38583ZM1.43701 8C1.43701 8.27832 1.67673 8.50394 1.97244 8.50394L8.46457 8.50394L8.46457 14.6142C8.46457 14.8925 8.70429 15.1181 9 15.1181C9.29571 15.1181 9.53543 14.8925 9.53543 14.6142L9.53543 8.50394L16.0276 8.50394C16.3233 8.50394 16.563 8.27832 16.563 8C16.563 7.72168 16.3233 7.49606 16.0276 7.49606L9.53543 7.49606L9.53543 1.38583C9.53543 1.10751 9.29571 0.881891 9 0.881891C8.70429 0.881891 8.46457 1.10751 8.46457 1.38583L8.46457 7.49606L1.97244 7.49606C1.67673 7.49606 1.43701 7.72168 1.43701 8Z"
        fill="white"
      />
    </svg>
  )
}
export default IconPlus

IconPlus.propTypes = {}

IconPlus.defaultProps = {}
