import React from 'react'

export function Piano() {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 34H1L0.5 20.5C0.666667 17.1667 1 10.4 1 10C1 9.6 2.33333 7.16667 3 6C4.5 5 7.5 2.9 7.5 2.5C7.5 2.1 10.8333 1.66667 12.5 1.5L16 3L17.5 6.5L18 11.5L20.5 15L24.5 16L29 16.5L33 18L34.5 20.5L35 23.5V34Z"
        fill="white"
        stroke="#060920"
      />
      <path
        d="M35.0895 19.9077C34.6261 18.729 33.95 17.8383 33.0229 17.1848C31.3526 16.0074 29.1654 15.854 27.0501 15.7056C24.5557 15.5306 22.1996 15.3653 20.5512 13.7169C18.6652 11.8309 18.395 9.50426 18.1337 7.2543C17.7766 4.17948 17.4073 1 12.1813 1C8.92754 1 5.86857 2.26709 3.56785 4.56781C1.26704 6.86853 0 9.92749 0 13.1812V24.6397V34.2041C0 34.6035 0.323689 34.9272 0.723057 34.9272H35.0979C35.4972 34.9272 35.821 34.6035 35.821 34.2041V24.6588C35.8211 24.6524 35.8219 24.6461 35.8219 24.6397C35.8219 24.6269 35.8216 24.6141 35.8209 24.6015C35.8178 22.6867 35.5785 21.151 35.0895 19.9077ZM1.44611 13.1812C1.44611 7.26182 6.26187 2.44611 12.1812 2.44611C16.1194 2.44611 16.3369 4.31874 16.6972 7.42113C16.9599 9.68286 17.2868 12.4977 19.5287 14.7395C21.5593 16.7701 24.2992 16.9623 26.9489 17.1481C30.9492 17.4287 34.139 17.653 34.3624 23.9166H1.44611V13.1812ZM34.3749 33.4811H1.44611V25.3627H3.23067V28.9009C3.23067 29.3002 3.55441 29.624 3.95373 29.624C4.35305 29.624 4.67678 29.3002 4.67678 28.9009V25.3627H6.19607V28.9009C6.19607 29.3002 6.51981 29.624 6.91913 29.624C7.31845 29.624 7.64219 29.3002 7.64219 28.9009V25.3627H11.8691V28.9009C11.8691 29.3002 12.1928 29.624 12.5921 29.624C12.9914 29.624 13.3152 29.3002 13.3152 28.9009V25.3627H17.1874V28.9009C17.1874 29.3002 17.5112 29.624 17.9105 29.624C18.3098 29.624 18.6335 29.3002 18.6335 28.9009V25.3627H22.7639V28.9009C22.7639 29.3002 23.0876 29.624 23.487 29.624C23.8862 29.624 24.21 29.3002 24.21 28.9009V25.3627H25.858V28.9009C25.858 29.3002 26.1818 29.624 26.5811 29.624C26.9805 29.624 27.3041 29.3002 27.3041 28.9009V25.3627H31.1012V28.9009C31.1012 29.3002 31.425 29.624 31.8243 29.624C32.2236 29.624 32.5473 29.3002 32.5473 28.9009V25.3627H34.3749V33.4811H34.3749Z"
        fill="#060920"
      />
      <path
        d="M12.0332 6.28921C10.2248 6.32729 8.52923 7.06196 7.25852 8.35787C5.98599 9.65576 5.28516 11.3693 5.28516 13.1827C5.28516 13.5821 5.60889 13.9058 6.00821 13.9058C6.40753 13.9058 6.73127 13.5821 6.73127 13.1827C6.73127 10.2408 9.12339 7.79698 12.0637 7.73503C12.4629 7.7266 12.7797 7.39616 12.7714 6.99689C12.763 6.59766 12.4333 6.2813 12.0332 6.28921Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Piano
