import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { useNavigate } from 'react-router-dom'
import { selectUserDevices } from '../../selectors/devices'
import {
  selectClaimItemsAffected,
  selectClaimID,
  selectClaimUpdating,
} from '../../selectors/claim'
import {
  updateAffectedItems,
  createClaim,
  updateClaim,
} from '../../reducers/claim'
import {
  AFFECTED_ITEMS_PATH,
  DELAY_TIMING,
  ERROR_PATH,
  CLAIM_STATUS_NEW,
  CLAIM_STATUS_APPROVED,
  DEVICE_STATUS_PENDING,
  DEVICE_STATUS_APPROVED,
} from '../../constants'
import { selectClaims } from '../../selectors/user'
import { addClaim } from '../../reducers/user'
import { selectAppTraveled } from '../../selectors/app'
import DeviceListView from './index.view'
import { affectedItemsValidationSchema } from '../../utils/validation'
import useRunValidationSchema from '../../hooks/validations-schema'
import { selectPartnerPermissionValueByScope } from '../../selectors/partner'
import { ALLOW, PARTNER_CLAIM_ADD_ITEM } from '../../constants/permissions'

export function DeviceList({ className, ...props }) {
  const dispatch = useDispatch()
  const userDevices = useSelector(selectUserDevices)
  const claims = useSelector(selectClaims)
  const [devicesIdsInUse, setDevicesIdsInUse] = useState([])
  const traveled = useSelector(selectAppTraveled)

  const itemsAffected = useSelector(selectClaimItemsAffected)
  const claimUpdating = useSelector(selectClaimUpdating)

  const claimId = useSelector(selectClaimID)
  const navigate = useNavigate()

  const itemsAffectedToDelete = useRef([])
  const [errors] = useRunValidationSchema(affectedItemsValidationSchema, {
    affectedItems: itemsAffected,
  })

  const createNewClaim = useCallback(
    debounce(() => {
      dispatch(createClaim())
        .unwrap()
        .then((claim) => {
          dispatch(addClaim(claim))
          navigate(`/${AFFECTED_ITEMS_PATH}/${claim.id}`)
        })
        .catch((err) => {
          navigate(`/${ERROR_PATH}`)
        })
    }, DELAY_TIMING),
    []
  )

  const updateCurrentClaim = useCallback(
    debounce(() => {
      dispatch(
        updateClaim({
          section: AFFECTED_ITEMS_PATH,
          itemsToDelete: itemsAffectedToDelete.current,
        })
      ).then((claim) => {
        itemsAffectedToDelete.current = []
      })
    }, DELAY_TIMING),
    []
  )

  const onSelectDevice = (selectedDevice) => {
    const index = itemsAffected.findIndex(
      (device) => device.deviceId === selectedDevice.id
    )
    if (index >= 0) {
      // Device is already selected, so lets remove from the list
      itemsAffected.splice(index, 1)
      // We need to post the list of unselected devices
      itemsAffectedToDelete.current.push(selectedDevice.id)
    } else {
      // Devices is not selected, so lets add to the list of selected devices
      itemsAffected.push({ ...selectedDevice })
      // if was in this list, lets remove it
      itemsAffectedToDelete.current = itemsAffectedToDelete.current.filter(
        (i) => i !== selectedDevice.id
      )
    }

    dispatch(updateAffectedItems(itemsAffected))

    if (claimId === null && !claimUpdating) {
      createNewClaim()
    } else {
      updateCurrentClaim()
    }
  }

  const devicesLength = () => {
    const l = userDevices.length
    if (l === 1 || l === 2) {
      return 6
    }
    return 4
  }

  const addItemPermision = useSelector((state) =>
    selectPartnerPermissionValueByScope(state, PARTNER_CLAIM_ADD_ITEM)
  )

  useEffect(() => {
    // take some claims with new or approved status
    const someClaims = claims.filter(
      (claim) =>
        claim.status === CLAIM_STATUS_NEW ||
        claim.status === CLAIM_STATUS_APPROVED
    )

    // take claim's devices
    const someClaimDevices = []
    someClaims.map((claim) =>
      claim.devices.map((cDevice) => someClaimDevices.push(cDevice))
    )
    const someClaimDevicesWithTargetState = someClaimDevices.filter(
      // take some of them
      (claimDevice) =>
        claimDevice.claim_device_status === DEVICE_STATUS_PENDING ||
        claimDevice.claim_device_status === DEVICE_STATUS_APPROVED
    )

    // only their device id
    const deviceIds = someClaimDevicesWithTargetState.map(
      (claimDevice) => claimDevice.device_id
    )
    setDevicesIdsInUse(deviceIds)
  }, [])
  return (
    <DeviceListView
      className={className}
      userDevices={userDevices}
      devicesLength={devicesLength}
      onSelectDevice={onSelectDevice}
      itemsAffected={itemsAffected}
      devicesIdsInUse={devicesIdsInUse}
      addItems={addItemPermision === ALLOW}
      errors={traveled ? errors.affectedItems : null}
      role="list"
    />
  )
}
export default DeviceList

DeviceList.propTypes = {
  className: PropTypes.string,
}

DeviceList.defaultProps = {
  className: '',
}
