import React from 'react'

export function IconCamera() {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.536621"
        y="2.39026"
        width="22.2439"
        height="18.7317"
        rx="5"
        fill="white"
      />
      <path
        d="M22.8252 2.70829H19.7063L19.6096 1.86196C19.5612 1.42684 19.3921 1.03995 19.1261 0.749677C18.7149 0.29042 18.1589 0.0487671 17.5785 0.0487671H10.4218C9.74479 0.0487671 9.14033 0.363136 8.75344 0.919184C8.55999 1.18515 8.46335 1.49951 8.41495 1.838L8.29402 2.70825H5.17511C3.9178 2.70825 2.87805 3.748 2.87805 5.00514V15.16C2.87805 16.4173 3.91763 17.4569 5.17494 17.4569H22.8251C24.0824 17.4569 25.122 16.4173 25.122 15.16V5.00514C25.1221 3.748 24.0824 2.70825 22.8251 2.70825L22.8252 2.70829ZM23.4296 15.1601C23.4296 15.4986 23.1637 15.7645 22.8252 15.7645H5.17507C4.83658 15.7645 4.57061 15.4986 4.57061 15.1601V5.00522C4.57061 4.66673 4.83658 4.40077 5.17507 4.40077H9.04367C9.4788 4.40077 9.84156 4.0864 9.89 3.65128L10.0834 2.03134C10.1076 1.9347 10.1318 1.8863 10.1318 1.8863C10.2044 1.78966 10.3012 1.74125 10.4219 1.74125H17.5787C17.6753 1.74125 17.7721 1.78966 17.8447 1.86218C17.8688 1.8863 17.8931 1.95882 17.9172 2.05563L18.1106 3.65143C18.159 4.08656 18.5216 4.40092 18.957 4.40092H22.8256C23.1641 4.40092 23.43 4.66689 23.43 5.00537L23.4296 15.1601Z"
        fill="#060920"
      />
      <path
        d="M13.9997 4.03729C11.0015 4.03729 8.55957 6.47925 8.55957 9.4774C8.55957 12.4755 11.0015 14.9175 13.9997 14.9175C16.9978 14.9175 19.4398 12.4755 19.4398 9.4774C19.4398 6.47925 16.9978 4.03729 13.9997 4.03729ZM13.9997 13.225C11.9444 13.225 10.252 11.5326 10.252 9.47735C10.252 7.42212 11.9444 5.72968 13.9997 5.72968C16.0549 5.72968 17.7473 7.42212 17.7473 9.47735C17.7473 11.5326 16.0549 13.225 13.9997 13.225Z"
        fill="#060920"
      />
    </svg>
  )
}
export default IconCamera

IconCamera.propTypes = {}

IconCamera.defaultProps = {}
