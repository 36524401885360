import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export function RepairProviderDefault({ className, userAddress, ...props }) {
  return (
    <div className={c('repair-provider-default', className)} {...props}>
      <p>
        Please click the button below to find a highly rated phone repair shop
        near you and check to ensure they have the right parts in stock for your
        repair and availability at a time that works for you to drop off your
        device.
      </p>
      <p>
        Your claim is approved for repair as long as the repair cost total does
        not exceed <b>REPLACEMENT COST OF THE CLAIM DEVICE.</b> If the estimated
        repair cost is more expensive than that amount, indicate so below and
        we’ll proceed with providing you funds for a replacement device instead
        and have you ship us your damaged one.
      </p>
      <a
        className="btn btn-primary"
        href={`https://www.google.com/maps/search/phone+repair+shops+near+${userAddress.zip}/data=!4m4!2m3!5m1!4e3!6e6`}
        target="_blank"
        rel="noreferrer"
      >
        Open Repair Providers
      </a>
    </div>
  )
}
export default RepairProviderDefault

RepairProviderDefault.propTypes = {
  className: PropTypes.string,
  userAddress: PropTypes.shape({}),
}

RepairProviderDefault.defaultProps = {
  className: '',
  userAddress: {},
}
