import React from 'react'

export function Accesories() {
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6446 13.3398C10.4964 13.3404 10.3545 13.3999 10.2501 13.5051C10.1457 13.6103 10.0873 13.7527 10.0879 13.9009V18.3729C10.0879 19.5106 10.6033 20.432 11.3508 21.0134C12.0983 21.5948 13.0519 21.8637 13.9989 21.8637C14.9459 21.8637 15.9006 21.5948 16.6481 21.0134C17.3956 20.432 17.9099 19.5106 17.9099 18.3729V13.9009C17.9104 13.7527 17.8521 13.6103 17.7477 13.5051C17.6433 13.3999 17.5014 13.3404 17.3532 13.3398H10.6446ZM11.2067 14.4576H16.7921V18.3729C16.7921 19.1913 16.4725 19.7337 15.9626 20.1303C15.4526 20.527 14.7285 20.7449 13.9989 20.7449C13.2693 20.7449 12.5452 20.527 12.0352 20.1303C11.5252 19.7337 11.2067 19.1913 11.2067 18.3729V14.4576Z"
        fill="#060920"
      />
      <path
        d="M13.9993 7.33398C11.9301 7.33398 9.85371 7.95668 8.26866 9.23981C6.68362 10.5229 5.61622 12.492 5.61622 15.0206V25.6391C5.61622 25.6391 5.60851 26.4533 6.02446 27.2852C6.4404 28.1171 7.38706 28.9934 8.97053 28.9934H19.028C20.6114 28.9934 21.5581 28.1171 21.9741 27.2852C22.39 26.4533 22.3823 25.6391 22.3823 25.6391V15.0206C22.3823 12.492 21.3149 10.5229 19.7298 9.23981C18.1448 7.95668 16.0684 7.33398 13.9993 7.33398ZM13.9993 8.45172C15.8422 8.45172 17.679 9.0188 19.028 10.1109C20.377 11.2029 21.2645 12.7988 21.2645 15.0206V25.6391C21.2645 25.6391 21.2579 26.221 20.9753 26.7863C20.6926 27.3516 20.2389 27.8757 19.028 27.8757H8.97053C7.75964 27.8757 7.30586 27.3516 7.02322 26.7863C6.74057 26.221 6.73505 25.6391 6.73505 25.6391V15.0206C6.73505 12.7988 7.62151 11.2029 8.97053 10.1109C10.3195 9.0188 12.1563 8.45172 13.9993 8.45172Z"
        fill="#060920"
      />
      <path
        d="M13.9997 0.765625C12.1257 0.765625 10.3092 2.3102 10.1083 5.01718C8.90319 5.32562 7.77028 5.78499 6.78457 6.40562C4.77992 7.6678 3.38115 9.65436 3.38115 12.2268V21.1665H1.14458C0.837639 21.1678 0.58912 21.4163 0.587893 21.7232V26.1953C0.587893 26.1953 0.583193 26.9101 1.02123 27.611C1.42241 28.2529 2.24825 28.8714 3.57107 28.9721C3.62668 29.1483 3.69585 29.3341 3.78938 29.5212C4.20533 30.3531 5.15199 31.224 6.73545 31.224H21.265C22.8484 31.224 23.7951 30.3531 24.211 29.5212C24.3046 29.3341 24.3737 29.1484 24.4293 28.9721C25.7521 28.8714 26.578 28.2529 26.9792 27.611C27.4172 26.9101 27.4114 26.1953 27.4114 26.1953V21.7232C27.4102 21.4163 27.1617 21.1677 26.8547 21.1665H24.6193V12.2268C24.6193 9.65436 23.2205 7.6678 21.2158 6.40562C20.2298 5.78476 19.0966 5.32453 17.891 5.01608C17.6897 2.30977 15.8734 0.765625 13.9997 0.765625ZM13.9997 1.88336C15.2249 1.88336 16.4951 2.74367 16.7449 4.77485C15.8468 4.62045 14.9236 4.54126 13.9997 4.54126C13.0756 4.54126 12.1525 4.62043 11.2544 4.77485C11.5042 2.74367 12.7744 1.88336 13.9997 1.88336ZM13.9997 5.659C15.0296 5.659 16.0555 5.7697 17.0341 5.97555C17.2084 6.01693 17.3208 6.03767 17.4522 6.06614C18.6212 6.35085 19.7072 6.78092 20.6177 7.35416C22.3854 8.46717 23.5015 10.0489 23.5015 12.2268V27.8751C23.5015 27.8751 23.4949 28.456 23.2123 29.0212C22.9296 29.5865 22.4758 30.1062 21.265 30.1062H6.73545C5.52456 30.1062 5.07079 29.5865 4.78814 29.0212C4.5055 28.456 4.49888 27.8751 4.49888 27.8751V21.7232V12.2268C4.49888 10.0489 5.61502 8.46717 7.38273 7.35417C8.29348 6.78073 9.37983 6.35085 10.5493 6.06615C10.7104 6.03278 10.822 6.00957 10.9663 5.97555C11.9447 5.76979 12.97 5.659 13.9997 5.659ZM1.70563 22.2843H3.38115V27.6317C2.77396 27.48 2.15624 27.3144 1.96978 27.0161C1.70923 26.5992 1.70563 26.1953 1.70563 26.1953V22.2843ZM24.6193 22.2843H26.2937V26.1953C26.2937 26.1953 26.2901 26.5992 26.0295 27.0161C25.8431 27.3144 25.2264 27.48 24.6193 27.6317V22.2843Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Accesories

Accesories.propTypes = {}

Accesories.defaultProps = {}
