import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import c from 'classnames'
import Header from '../header'
import Preloading from '../preloading'
import Breadcrumb from '../breadcrumb'
import { selectBrand, selectAddHideCssMobile } from '../../selectors/app'
import useRequestClaim from '../../hooks/request-claim'
import {
  selectClaimReadingState,
  selectClaimSkipLoadingScreen,
} from '../../selectors/claim'

export function DetailLayout({ className }) {
  const params = useParams()
  const { claimId } = params

  useRequestClaim(claimId)

  const claimIsLoading = useSelector(selectClaimReadingState)
  const skipLoadingScreen = useSelector(selectClaimSkipLoadingScreen)
  const brandName = useSelector(selectBrand)
  const addHideCssMobile = useSelector(selectAddHideCssMobile)

  return (
    <div className={c('page', className, brandName)}>
      <Header />
      {claimIsLoading && skipLoadingScreen === false ? (
        <Preloading />
      ) : (
        <div>
          <div
            className={`container-fluid mb-4 ${addHideCssMobile ? 'd-none-mobile' : ''}`}
          >
            <Breadcrumb />
          </div>
          <Outlet />
        </div>
      )}
    </div>
  )
}
export default DetailLayout

DetailLayout.propTypes = {
  className: PropTypes.string,
}

DetailLayout.defaultProps = {
  className: '',
}
