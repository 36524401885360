import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { Button } from '../button'

export function NavigationButtons({
  navigationIndex,
  prevUrl,
  nextUrl,
  prevText,
  nextText,
  onNext,
  onPrev,
  onFileClaim,
  disabled,
  claimDataValid,
  updating,
  isLastSteep,
  ...props
}) {
  const extraClasses = {
    'last-steep': isLastSteep,
    'next-btn': nextUrl,
    'prev-btn': prevUrl,
  }
  return (
    <div className={c('navigation', extraClasses)} {...props}>
      {isLastSteep ? (
        <Button
          className="navigation__btn navigation__btn-file"
          onClick={onFileClaim}
          disabled={!claimDataValid || updating}
        >
          File Claim
        </Button>
      ) : null}
      {nextUrl && (
        <Link
          className={c('btn btn-primary navigation__btn navigation__btn-next', {
            disabled: updating,
          })}
          to={nextUrl}
          onClick={onNext}
          aria-disabled={updating}
        >
          Continue
        </Link>
      )}
      {prevUrl && (
        <Link
          to={prevUrl}
          onClick={onPrev}
          className={c(
            'btn btn-outline-primary navigation__btn navigation__btn-prev',
            { disabled: updating }
          )}
          aria-disabled={updating}
          disabled={updating}
        >
          Previous
        </Link>
      )}
    </div>
  )
}
export default NavigationButtons

NavigationButtons.propTypes = {
  navigationIndex: PropTypes.number,
  prevUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onFileClaim: PropTypes.func,
  disabled: PropTypes.bool,
  claimDataValid: PropTypes.bool,
  updating: PropTypes.bool,
  isLastSteep: PropTypes.bool,
}

NavigationButtons.defaultProps = {
  navigationIndex: 0,
  prevUrl: undefined,
  nextUrl: undefined,
  prevText: 'Previous',
  nextText: 'Continue',
  disabled: false,
  onNext: () => {},
  onPrev: () => {},
  onFileClaim: () => {},
  claimDataValid: false,
  updating: false,
  isLastSteep: false,
}
