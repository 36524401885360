import React from 'react'

export function IconPencil() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.662252 15.9999C0.728161 15.9999 0.794071 15.9999 0.838062 15.978L5.43059 14.6397C5.67231 14.5739 5.91402 14.4422 6.08985 14.2448L15.5606 4.78857C15.8463 4.5033 16 4.10846 16 3.69155C16 3.27465 15.8461 2.90171 15.5606 2.61644L13.3851 0.444319C12.7918 -0.148107 11.803 -0.148107 11.2097 0.444319L1.76092 9.90057C1.58511 10.0761 1.4533 10.3175 1.36547 10.5588L0.0250698 15.1443C-0.0408399 15.3638 0.0250697 15.627 0.20088 15.8026C0.332699 15.9342 0.508505 16 0.662252 16V15.9999ZM9.64955 3.88888L12.1326 6.36817L5.6064 12.8844L3.12348 10.4053L9.64955 3.88888ZM12.1765 1.36568C12.2204 1.32175 12.2864 1.29987 12.3303 1.29987C12.3963 1.29987 12.4402 1.32175 12.4842 1.36568L14.6597 3.5378C14.7037 3.60361 14.7256 3.66942 14.7256 3.69146C14.7256 3.71334 14.7256 3.80119 14.6597 3.84511L13.0774 5.42465L10.5943 2.94537L12.1765 1.36568ZM2.44205 11.5679L4.41962 13.5424L1.6289 14.3542L2.44205 11.5679Z"
        fill="#575757"
      />
    </svg>
  )
}
export default IconPencil

IconPencil.propTypes = {}

IconPencil.defaultProps = {}
