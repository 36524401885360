import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectCategories } from '../../selectors/item-categories'
import ItemCategoryItem from '../item-category-item'
import { AFFECTED_ITEMS_PATH } from '../../constants'
import { selectClaimID } from '../../selectors/claim'

export function ItemCategories({ className, ...props }) {
  const categories = useSelector(selectCategories)
  const claimID = useSelector(selectClaimID)
  return (
    <div className={c('item-categories', className)} {...props}>
      <div className="row">
        {categories.map((category) => (
          <div key={category.id} className="col-md-6 col-12 mb-4">
            <ItemCategoryItem category={category} />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="item-categories_btns">
            <Link
              to={`/${AFFECTED_ITEMS_PATH}/${claimID || ''}`}
              className="btn btn-outline-primary"
            >
              Go Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ItemCategories

ItemCategories.propTypes = {
  className: PropTypes.string,
}

ItemCategories.defaultProps = {
  className: '',
}
