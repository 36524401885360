import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function LossTypeList({ className, max, list, ...props }) {
  if (max === null) {
    max = list.length
  }
  const show = list.slice(0, max)
  const plus = list.slice(max)
  return (
    <div className={c('loss-type-list', className)} {...props}>
      {show.map((ltObj) => (
        <div key={ltObj.cat}>
          <span className="affected-items__detail-group">
            {`${ltObj.cat}:`}
          </span>
          <span className="affected-items__detail-value">
            {ltObj.lt.map((lt, idx) =>
              idx === 0 ? (
                <span key={lt}>{lt}</span>
              ) : (
                <span key={lt}>
                  ,&nbsp;
                  {lt}
                </span>
              )
            )}
          </span>
        </div>
      ))}
      {plus.length > 0 ? (
        <span className="affected-items__detail-group">
          {' '}
          +{plus.length} Other
        </span>
      ) : null}
    </div>
  )
}
export default LossTypeList

LossTypeList.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      cat: PropTypes.string,
      lt: PropTypes.arrayOf(PropTypes.string),
    })
  ),
}

LossTypeList.defaultProps = {
  className: '',
  max: null,
  list: [],
}
