import React from 'react'

export function Jewerly() {
  return (
    <svg
      width="38"
      height="31"
      viewBox="0 0 38 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0009 3.12074C30.4554 2.57528 29.3645 2.02983 28.8191 1.48438L8.0918 2.02983L3.18271 9.12074L2.0918 11.848L19.5463 29.3026C25.0009 23.4844 35.91 11.7389 35.91 11.3026C35.91 10.7571 31.5463 3.66619 31.0009 3.12074Z"
        fill="white"
        stroke="#060920"
      />
      <path
        d="M36.892 11.0649L32.04 3.28567C31.6124 2.5851 31.0113 2.00667 30.2948 1.60628C29.5783 1.2059 28.7707 0.997086 27.9499 1.00003H10.0658C9.24506 0.997086 8.43741 1.2059 7.72093 1.60628C7.00445 2.00667 6.40335 2.5851 5.97573 3.28567L1.12376 11.0649C1.03077 11.2122 0.988301 11.3858 1.00277 11.5595C1.01724 11.7331 1.08787 11.8973 1.20396 12.0272L18.4064 30.2722C18.4813 30.3511 18.5715 30.4139 18.6715 30.4568C18.7714 30.4997 18.8791 30.5219 18.9878 30.5219C19.0966 30.5219 19.2042 30.4997 19.3042 30.4568C19.4042 30.4139 19.4943 30.3511 19.5693 30.2722L36.7717 12.0272C36.8941 11.902 36.9721 11.7401 36.9938 11.5663C37.0155 11.3925 36.9798 11.2164 36.892 11.0649ZM14.2762 2.60399H23.6995L25.4237 10.6639H12.6723L14.2762 2.60399ZM7.3391 4.12775C7.6261 3.66247 8.02725 3.27825 8.50447 3.01157C8.98169 2.74489 9.51914 2.60459 10.0658 2.60399H12.6322L11.0282 10.6639H3.24901L7.3391 4.12775ZM3.69009 12.2678H11.1485L16.6821 26.0618L3.69009 12.2678ZM19.0079 27.5455L12.8727 12.2678H25.1831L19.0079 27.5455ZM21.3336 26.1019L26.9074 12.2678H34.3257L21.3336 26.1019ZM27.0678 10.6639L25.3435 2.60399H27.9499C28.4966 2.60459 29.0341 2.74489 29.5113 3.01157C29.9885 3.27825 30.3897 3.66247 30.6767 4.12775L34.7668 10.6639H27.0678Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default Jewerly
