import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Select from 'react-select'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'

const options = [
  { value: 'a', label: 'screen replacement' },
  { value: 'b', label: 'charging port replacement' },
  { value: 'c', label: 'camera replacement' },
  { value: 'd', label: 'battery replacement' },
  { value: 'e', label: 'back cover replacement' },
  { value: 'f', label: 'camera replacement' },
]

// DEPREACATED
export function MultiSelect({ className, label, instruction, ...props }) {
  return (
    <div className={c('multi-select', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      <Select
        className="multiselect"
        options={options}
        isMulti
        isSearchable={false}
      />
    </div>
  )
}
export default MultiSelect

MultiSelect.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

MultiSelect.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
