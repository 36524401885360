import React from 'react'
import PropTypes from 'prop-types'
import BannerImage from './banner.png'

export function Banner({ className }) {
  return (
    <div className={`banner ${className}`}>
      <img alt="akko" src={BannerImage} />
    </div>
  )
}
export default Banner

Banner.propTypes = {
  className: PropTypes.string,
}

Banner.defaultProps = {
  className: '',
}
