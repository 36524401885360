export const HOST = process.env.REACT_APP_API
export const PUBLIC_IMAGE_API = process.env.REACT_APP_PUBLIC_IMAGE_API
export const API_IMAGES_KEY = process.env.REACT_APP_API_IMAGES_KEY
export const FILE_PIPELINE_ENV = process.env.REACT_APP_APP_ENV
export const WHITE_LABEL_TITLE = process.env.REACT_APP_WHITE_LABEL_TITLE
export const WHITE_LABEL_FAVICON_LINK =
  process.env.REACT_APP_WHITE_LABEL_FAVICON_LINK
export const WHITE_LABEL_BRAND_NAME =
  process.env.REACT_APP_WHITE_LABEL_BRAND_NAME
export const WHITE_LABEL_BRAND_IMAGE_URL =
  process.env.REACT_APP_WHITE_LABEL_BRAND_IMAGE_URL
export const WHITE_LABEL_COLOR = process.env.REACT_APP_WHITE_LABEL_COLOR
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

export const HOME_PATH = '/'
export const DESCRIBE_INCIDENT_PATH = 'describe-incident'
export const AFFECTED_ITEMS_PATH = 'affected-items'
export const ADD_ITEMS_PATH = 'add-items'
export const ADD_ITEM_DETAILS_PATH = 'item-details'
export const TYPE_LOSS_PATH = 'type-loss'
export const PROOF_LOSS_PATH = 'proof-loss'
export const PAYOUT_PATH = 'payout'
export const REVIEW_CLAIM_PATH = 'review-claim'
export const CONFIRM_ADDRESS_PATH = 'confirm-address'
export const CLAIM_DETAIL_PATH = 'claim-detail'
export const NAVIGATION_PATH = 'navigation'
export const FILE_CLAIM = 'file_claim'
export const LOGIN_PATH = 'login'
export const ERROR_PATH = 'error'
export const DEVICE_DETAIL_PATH = (claimId, deviceId) =>
  `/${CLAIM_DETAIL_PATH}/${claimId}/devices/${deviceId}`
export const RESET_PASSWORD_PATH = 'reset-password'

export const PAGE_AFFECTED_ITEMS = 'affected_items'
export const PAGE_DESCRIBE_INCIDENT = 'describe_incident'
export const PAGE_LOSS_TYPES = 'loss_types'
export const PAGE_PAYOUT = 'payout'
export const PAGE_REVIEW = 'review'
export const PAGE_CONFIRM_ADDRESS = 'confirm_address'

export const NAVIGATION_SEQUENCE = [
  AFFECTED_ITEMS_PATH,
  TYPE_LOSS_PATH,
  DESCRIBE_INCIDENT_PATH,
  CONFIRM_ADDRESS_PATH,
  REVIEW_CLAIM_PATH,
]

export const NAVIGATION_SEQUENCE_MOBILE_TEXTS = [
  'Proceed to Affected Items',
  'Proceed to Type of Loss',
  'Proceed to Describe Incident',
  'Proceed to Confirmation Address',
  'Proceed to Claim Review',
]

export const NAVIGATION_SEQUENCE_MOBILE_TITLES = [
  'Select Affected Items',
  'Select Type of Loss',
  'Describe The Incident',
  'Confirm Your Address',
  'Review Claim',
]

export const NAVIGATION_SEQUENCE_MOBILE_STEP_CLASS = [
  'divider-step-1',
  'divider-step-2',
  'divider-step-3',
  'divider-step-4',
  'divider-step-5',
]

export const CREDIT_CARD_NAMES = [
  'alipay',
  'american-express',
  'code-front',
  'code',
  'diners-club',
  'discover',
  'elo',
  'hiper',
  'hipercard',
  'jcb',
  'maestro',
  'mastercard',
  'mir',
  'unionpay',
  'paypal',
  'visa',
]

export const LOGIN_API = `${HOST}/login/`
export const RESET_PASSWORD_API = `${HOST}/api/v1/users/reset-password/`
export const USER_AUTH = `${HOST}/api/v1/claims/users/auth/`
export const GET_PARTNER = `${HOST}/api/v1/claims/partner/`
export const GET_DEVICES = `${HOST}/api/v1/claims/users/devices/`
export const DEVICE_ITEM_ADD = `${HOST}/api/v2/devices/`
export const DEVICE_ITEM_UPDATE = (deviceId) =>
  `${HOST}/api/v2/devices/${deviceId}/`
export const CREATE_CLAIM = `${HOST}/api/v1/claims/`
export const READ_CLAIM = `${HOST}/api/v1/claims/`
export const LIST_CLAIM = `${HOST}/api/v1/claims/`
export const UPDATE_CLAIM = `${HOST}/api/v1/claims/`
export const GET_LOSS_TYPES = `${HOST}/api/v1/claims/loss-type-categories/`
export const IMAGES_STATUS_PATH = `${HOST}/api/v1/claims/`
export const EVIDENCE_STATUS_PATH = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/claim-devices/${claimDeviceId}/evidence-status/`
export const DYNAMIC_COMPONENT_HANDLER = `${HOST}/api/v1/dynamic-components/fields/handler/`
export const DEVICE_STORE_URLS = `${HOST}/api/v1/claims/product-store-detail/`
export const DELETE_IMAGE_CLAIM_API = (claimId, imageId) =>
  `${HOST}/api/v1/claims/${claimId}/images/${imageId}`
export const DELETE_CLAIM_EVIDENCES = (claimId) =>
  `${HOST}/api/v1/claims/${claimId}/delete-evidence/`
export const UPDATE_USER_PAYOUT_METHOD = (claimId) =>
  `${HOST}/api/v1/claims/${claimId}/update-payout-method/`
export const UPDATE_USER_ADDRES = (claimId) =>
  `${HOST}/api/v1/claims/${claimId}/update-address/`
export const UPDATE_DEVICE_REPAIR_COST = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/repair-cost/`
export const UPLOAD_DEVICE_REPAIR_RECEIP = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/file/`
export const UPDATE_REPAIR_INVOICE = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/file/`
export const DELETE_DEVICE_FILE = (claimId, claimDeviceId, fileId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/file/${fileId}/`
export const CLAIM_TASK_CONTINUE = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/claim-devices/${claimDeviceId}/continue/`
export const SUBMIT_PAYMENT = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/submit-payment/`
export const CALCULATE_PAYMENT = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/calculate-payment/`
export const GENERATE_SHIPPING_LABEL = `${HOST}/api/v1/shippings/easyship/`
export const GET_CLAIM_LIMITS = (userId, contractId) =>
  `${HOST}/users/${userId}/contracts/${contractId}/contract-line-items/claim-restrictions/`
export const GET_ACTIVE_CONTRACT = (userId) =>
  `${HOST}/users/${userId}/contracts/`
export const GET_PAYMENT_SERVICE_FEE = (claimId, claimDeviceId) =>
  `${HOST}/api/v1/claims/${claimId}/devices/${claimDeviceId}/replacement-deductible/`

export const GET_CURRENT_APP_THEME = (domain) =>
  `${HOST}/api/v1/configurations/themes/?domain=${domain}`
export const GET_APP_THEMES = `${HOST}/api/v1/configurations/themes/branding/`
export const GET_COUNTRIES = `${HOST}/api/v1/countries/`
export const GET_FEATURE_FLAGS = `${HOST}/feature_flags/`
export const GET_CLAIM_DEVICES_TASKS = `${HOST}/c/rule-sets/claim/`
export const GET_CURRENCIES = `${HOST}/api/v1/currencies/`
export const ITEM_OPERABILITIES = `${HOST}/devices/item-operabilities/`
export const ITEM_CATEGORIES = `${HOST}/devices/v2/item_categories/`
export const MOBILE_OPERATORS = `${HOST}/mobile_operators/`
export const PURCHASE_CONDITIONS = `${HOST}/api/v1/devices/item-purchase-conditions/`

export const TASK_GRAPHS = `${HOST}/internal/v3/task-graphs/`
export const GRAPH = `${HOST}/api/v1/graphs/tree/`
export const GRAPH_UPDATE = `${HOST}/api/v1/graphs/tree/`

export const CRACKED_CAMERA = 'Cracked Camera'
export const CRACKED_BACK = 'Cracked Back'
export const CRACKED_SCREEN = 'Cracked Screen'
export const LIQUID_DAMAGE = 'Liquid Damage'
export const OTHER = 'Accidental Damage - Other'

export const DELAY_TIMING = 750
export const CHECK_IMAGES_STATUS_TIMER =
  process.env.REACT_APP_CHECK_IMAGES_STATUS_TIMER

export const PAYPAL_PAYOUT_METHOD = 'PAYPAL'
export const VENMO_PAYOUT_METHOD = 'VENMO'
export const SKIP_PAYOUT_METHOD = 'SKIP'

export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const STAGING = 'staging'

export const CLAIM_STATUS_NEW = 'NEW'
export const CLAIM_STATUS_APPROVED = 'APPROVED'
export const DEVICE_STATUS_PENDING = 'pending'
export const DEVICE_STATUS_APPROVED = 'approved'

export const GOOGLE_API_GEOCODE_DEBOUNCE_TIMER = 500
export const GOOGLE_API_PLACES_DEBOUNCE_TIMER = 500

export const CLEAR = 'CLEAR'
export const CLEAR_STAGING = 'https://claims.clear.staging.x.getakko.com'
export const CLEAR_PRODUCTION = 'https://claims.clear.x.getakko.com'
