import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { PaymentSchema } from './schemas'
import {
  setPaypalAccount,
  setVenmoAccount,
  setWireAccount,
} from '../../reducers/claim'
import PaymentMethodView from './index.view'

const PAYPAL = 'paypal'
const VENMO = 'venmo'
const WIRE = 'wire'

export function PaymentMethod({
  className,
  onSubmit,
  onChange,
  loading,
  savingPayoutMethod,
  claimPaymentMethod,
  disabled,
}) {
  const dispatch = useDispatch()
  const onChangeFields = async (data) => {
    const { method, email, phone, wire } = data
    try {
      await PaymentSchema.validate(data)
      if (method === PAYPAL) {
        dispatch(setPaypalAccount(email))
      }
      if (method === VENMO) {
        dispatch(setVenmoAccount(phone))
      }
      if (method === WIRE) {
        dispatch(setWireAccount(wire))
      }
      onChange(data)
    } catch (err) {
      // do nothing since we only need to prevent the dispatch
    }
  }
  const onSubmitForm = (data) => {
    onSubmit(data)
  }

  const initialValues = {
    method: 'paypal',
    email: '',
    emailConfirm: '',
    phone: '',
    phoneConfirm: '',
    wire: '',
    wireConfirm: '',
  }
  if (!isEmpty(claimPaymentMethod.paypalAccount)) {
    initialValues.method = 'paypal'
    initialValues.email = claimPaymentMethod.paypalAccount
    initialValues.emailConfirm = claimPaymentMethod.paypalAccount
  }
  if (!isEmpty(claimPaymentMethod.venmoAccount)) {
    initialValues.method = 'venmo'
    initialValues.phone = claimPaymentMethod.venmoAccount
    initialValues.phoneConfirm = claimPaymentMethod.venmoAccount
  }
  if (!isEmpty(claimPaymentMethod.wireAccount)) {
    initialValues.method = 'wire'
    initialValues.wire = claimPaymentMethod.wireAccount
    initialValues.wireConfirm = claimPaymentMethod.wireAccount
  }

  return (
    <PaymentMethodView
      className={className}
      loading={loading}
      savingPayoutMethod={savingPayoutMethod}
      initialValues={initialValues}
      onChangeFields={onChangeFields}
      onSubmitForm={onSubmitForm}
      disabled={disabled}
    />
  )
}
export default PaymentMethod

PaymentMethod.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  savingPayoutMethod: PropTypes.bool,
  claimPaymentMethod: PropTypes.object,
  disabled: PropTypes.bool,
}

PaymentMethod.defaultProps = {
  className: '',
  onSubmit: () => {},
  onChange: () => {},
  loading: false,
  savingPayoutMethod: false,
  claimPaymentMethod: null,
  disabled: false,
}
