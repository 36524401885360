import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import { CLEAR_PRODUCTION, CLEAR_STAGING } from '../../constants'
import { propTypes, defaultProps } from './props'

export function Hyperlink({
  className,
  prefill,
  instruction,
  label,
  taskGroupName,
  ...props
}) {
  const hostUrl = window.location.origin
  if (
    taskGroupName === 'ON_UPLOAD_EVIDENCE' &&
    (hostUrl === CLEAR_STAGING || hostUrl === CLEAR_PRODUCTION)
  ) {
    return <div />
  }
  return (
    <div className={c('hyperlink p-1 mb-3', className)}>
      {!isEmpty(instruction) && <span>{instruction}</span>}
      &nbsp;&nbsp;
      <a target="_blank" href={prefill} rel="noreferrer">
        {label}
      </a>
    </div>
  )
}
export default Hyperlink

Hyperlink.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

Hyperlink.defaultProps = {
  ...defaultProps,
  className: '',
}
