import React from 'react'
import PageHeader from '../components/page-header'
import ConfirmAddressField from '../components/confirm-address-v2'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import { PAGE_CONFIRM_ADDRESS } from '../constants'

export function ConfirmAddress() {
  useStoreCurrentSteep(PAGE_CONFIRM_ADDRESS)

  return (
    <div>
      <PageHeader title="Confirm Your Address">
        <div className="page-header__subtitle">
          Confirm your current address so we can ensure there is a repair
          provider nearby or send you a replacement item
        </div>
      </PageHeader>
      <ConfirmAddressField />
    </div>
  )
}
export default ConfirmAddress
