import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import { propTypes, defaultProps } from './props'
import { CLEAR_PRODUCTION, CLEAR_STAGING } from '../../constants'

export function Text({
  className,
  prefill,
  label,
  instruction,
  taskGroupName,
  ...props
}) {
  const hostUrl = window.location.origin
  if (
    taskGroupName === 'ON_UPLOAD_EVIDENCE' &&
    (hostUrl === CLEAR_STAGING || hostUrl === CLEAR_PRODUCTION)
  ) {
    return <div />
  }
  return (
    <div className={c('text p-1 mb-3', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div>{prefill}</div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default Text

Text.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

Text.defaultProps = {
  ...defaultProps,
  className: '',
}
