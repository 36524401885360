import React from 'react'

export function Entertaiments() {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.197 23.5874H8.68482L8.09935 23.002L7.80666 22.1239L14.8311 20.9532V19.3554H1.22559V1.05078H31.5141V19.1971L17.758 19.4898V21.2459L19.8068 21.5386L24.7824 22.1239V23.002L24.197 23.5874Z"
        fill="white"
      />
      <path
        d="M12.4116 10.2584L10.7324 11.9375L11.3261 12.5312L13.0052 10.852L12.4116 10.2584Z"
        fill="#060920"
      />
      <path
        d="M17.4495 8.1677L12.4121 13.2051L13.0058 13.7987L18.0431 8.76135L17.4495 8.1677Z"
        fill="#060920"
      />
      <path
        d="M15.7685 6.90335L13.25 9.42188L13.8437 10.0155L16.3622 7.497L15.7685 6.90335Z"
        fill="#060920"
      />
      <path
        d="M20.8613 8.07718L18.3428 10.5957L18.9364 11.1894L21.4549 8.67083L20.8613 8.07718Z"
        fill="#060920"
      />
      <path
        d="M31.4259 14.8164C31.6775 14.8164 31.8815 14.6125 31.8815 14.3608V1.22546C31.8815 1.01171 31.7906 0.806766 31.6323 0.663293C31.4806 0.525833 31.2781 0.455979 31.0752 0.468189H1.58769C1.38365 0.455858 1.18223 0.525833 1.03056 0.663293C0.872265 0.806766 0.781394 1.01171 0.781394 1.22546V19.0212C0.772647 19.4469 1.16328 19.8135 1.58763 19.7785H14.3269V20.7561L8.35207 21.3962C7.81062 21.4542 7.40231 21.9086 7.40231 22.4531V22.8184C7.40231 23.4045 7.87919 23.8814 8.4653 23.8814H19.733C19.9846 23.8814 20.1886 23.6775 20.1886 23.4259C20.1886 23.1742 19.9846 22.9703 19.733 22.9703H8.4653C8.38153 22.9703 8.31344 22.9022 8.31344 22.8184V22.4531C8.31344 22.3753 8.37175 22.3104 8.44908 22.3021L14.8572 21.6155C15.8556 21.5086 16.868 21.5086 17.8663 21.6155L24.2746 22.3021C24.3518 22.3105 24.4102 22.3753 24.4102 22.4531V22.8184C24.4102 22.9022 24.3421 22.9703 24.2583 22.9703H21.859C21.6073 22.9703 21.4034 23.1742 21.4034 23.4259C21.4034 23.6775 21.6073 23.8814 21.859 23.8814H24.2583C24.8444 23.8814 25.3213 23.4045 25.3213 22.8184V22.4531C25.3213 21.9085 24.913 21.4542 24.3715 21.3962L18.3967 20.7561V19.7785H31.0752C31.4983 19.8141 31.8908 19.4466 31.8815 19.0212V16.4868C31.8815 16.2351 31.6775 16.0312 31.4259 16.0312C31.1742 16.0312 30.9703 16.2351 30.9703 16.4868V18.8673H1.69253V1.37932H30.9703V14.3608C30.9703 14.6124 31.1743 14.8164 31.4259 14.8164V14.8164ZM17.4855 20.667C16.738 20.6111 15.9856 20.6111 15.2381 20.667V19.7785H17.4855V20.667Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Entertaiments

Entertaiments.propTypes = {}

Entertaiments.defaultProps = {}
