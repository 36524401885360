import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import DevicePicker from '../device-picker'
import { getIcon } from '../icons'
import NoItems from '../no-items'
import { ADD_ITEMS_PATH } from '../../constants'

export function DeviceListView({
  className,
  userDevices,
  devicesLength,
  onSelectDevice,
  itemsAffected,
  devicesIdsInUse,
  addItems,
  errors,
  ...props
}) {
  return (
    <div className={c('device-list', className)} {...props}>
      <div className="row">
        {userDevices.length > 0
          ? userDevices.map((device, index) => (
              <div
                key={device.id}
                className={`col-xl-${devicesLength()} col-lg-6 col-md-12 mb-3`}
              >
                <DevicePicker
                  onSelect={onSelectDevice}
                  className="affected-device"
                  icon={getIcon(device.kind)}
                  data={device}
                  checked={
                    !!itemsAffected.find((i) => i.deviceId === device.id)
                  }
                  disabled={devicesIdsInUse.includes(device.id)}
                />
              </div>
            ))
          : null}
        {userDevices.length > 0 && addItems ? (
          <div className="device__new-item-mobile col-12 mb-3">
            <div className="device affected-device justify-content-center">
              <Link className="uline-none link-text" to={`/${ADD_ITEMS_PATH}/`}>
                Add New Item +
              </Link>
            </div>
          </div>
        ) : null}
        {userDevices.length <= 0 && addItems ? <NoItems /> : null}
      </div>
      {errors && <div className="error-text" role="alert">{errors[0]}</div>}
    </div>
  )
}
export default DeviceListView

DeviceListView.propTypes = {
  className: PropTypes.string,
  userDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      kind: PropTypes.string.isRequired,
    })
  ).isRequired,
  devicesLength: PropTypes.func.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
  itemsAffected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  devicesIdsInUse: PropTypes.arrayOf(PropTypes.number).isRequired,
  addItems: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
}

DeviceListView.defaultProps = {
  className: '',
  errors: [],
  addItems: false,
}
