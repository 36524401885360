import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { selectCurrencies } from '../../selectors/currencies'

export function Amount({
  className,
  onChange,
  initialValue,
  disabled,
  showUnit,
  defaultUnit,
  ...props
}) {
  const [unitValue, selectUnitValue] = useState(defaultUnit)
  const [amountValue, setAmountValue] = useState(initialValue)
  const currencies = useSelector(selectCurrencies)

  const onChangeNumber = (e) => {
    const newAmountValue = e.floatValue
    setAmountValue(newAmountValue)
    onChange({
      value: newAmountValue,
      unit: unitValue,
    })
  }

  const onSelectUnit = (e) => {
    const newUnitValue = e.target.value
    selectUnitValue(newUnitValue)
    onChange({
      value: amountValue,
      unit: newUnitValue,
    })
  }

  return (
    <div className={c('repair-cost d-flex', className)}>
      <div className="input-group me-1">
        {showUnit && (
          <span
            className="input-group-text repair-cost__unit"
            id="basic-addon1"
          >
            $
          </span>
        )}
        <NumberFormat
          {...props}
          disabled={disabled}
          className={c('form-control', 'repair-cost__amount')}
          thousandSeparator=","
          decimalScale={2}
          decimalSeparator="."
          onValueChange={onChangeNumber}
          value={amountValue}
        />
      </div>
      <select
        onChange={onSelectUnit}
        className="form-select repair-cost__unit-selector"
        value={unitValue}
        disabled={disabled}
      >
        {currencies.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.code}
          </option>
        ))}
      </select>
    </div>
  )
}
export default Amount

Amount.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showUnit: PropTypes.bool,
  defaultUnit: PropTypes.string,
  initialValue: PropTypes.number,
}

Amount.defaultProps = {
  className: '',
  initialValue: 0,
  disabled: false,
  showUnit: true,
  onChange: () => {},
  defaultUnit: 'usd',
}
