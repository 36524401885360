import { createSelector } from '@reduxjs/toolkit'

export const itemOperabilitiesStore = (state) => state.itemOperabilities

export const selectItemOperabilities = createSelector(
  itemOperabilitiesStore,
  (itemOperabilities) => itemOperabilities.itemOperabilities
)

export const selectItemOperabilitiesForSelector = createSelector(
  itemOperabilitiesStore,
  (itemOperabilities) =>
    itemOperabilities.itemOperabilities.map((i) => ({
      value: i.id,
      label: i.description,
    }))
)
