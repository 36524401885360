import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toggleLoading, setAppError } from './app'
import { GET_LOSS_TYPES } from '../constants'

export const getLossTypes = createAsyncThunk(
  'losstypes/getLossTypes',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(GET_LOSS_TYPES)
      return response.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error loading loss types' })
      )
    }
  }
)

export const initialState = {
  list: [],
}

export const lossTypesStore = createSlice({
  name: 'losstypes',
  initialState,
  reducers: {
    addLossTypes: (state, action) => {
      const { payload } = action
      state.devices = payload.loss_types
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLossTypes.pending, (state, action) => {})
    builder.addCase(getLossTypes.fulfilled, (state, action) => {
      const { payload } = action
      state.list = payload
    })
    builder.addCase(getLossTypes.rejected, (state, action) => {})
  },
})

export const { addLossTypes } = lossTypesStore.actions

export default lossTypesStore.reducer
