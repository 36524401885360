import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function StepContent({ className, step, title, content, image }) {
  return (
    <div className={c('step-content', className)}>
      <div className="step-content_banner">
        <div className="step-content_banner-fix">
          <img src={image} alt="" />
        </div>
      </div>
      <div className="step-content_body">
        <div className="step-content_heading">
          {step && <div className="step-content_step-count">Step {step}</div>}
          <div className="step-content_title">{title}</div>
        </div>
        <div className="">{content}</div>
      </div>
    </div>
  )
}
export default StepContent

StepContent.propTypes = {
  className: PropTypes.string,
  step: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
}

StepContent.defaultProps = {
  className: '',
  step: null,
  title: '',
  content: '',
  image: '',
}
