import React from 'react'

export function Skis() {
  return (
    <svg
      width="31"
      height="38"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17V20L2.5 26.5L2 31.5L4 34.5L6.5 36C7.33333 35.8333 9.1 35.5 9.5 35.5C9.9 35.5 11 34.8333 11.5 34.5L13 33L13.5 30V25L13 19.5V14.5L13.5 10V6L12.5 3.5L10.5 1.5L8.5 1L6 1.5L4 3L2.5 5L2 9L3 17Z"
        fill="white"
      />
      <path
        d="M19 17V20L18.5 26.5L18 31.5L20 34.5L22.5 36C23.3333 35.8333 25.1 35.5 25.5 35.5C25.9 35.5 27 34.8333 27.5 34.5L29 33L29.5 30V25L29 19.5V14.5L29.5 10V6L28.5 3.5L26.5 1.5L24.5 1L22 1.5L20 3L18.5 5L18 9L19 17Z"
        fill="white"
      />
      <path
        d="M13.1414 16.7357L13.7745 7.86607C13.7882 7.69973 13.7894 7.54704 13.7894 7.38069C13.7894 3.86262 10.9206 1 7.39633 1C5.6224 1 3.91302 1.74607 2.7064 3.04455C1.48612 4.36042 0.885294 6.07104 1.01812 7.85863L1.65247 16.7444C1.70957 17.3986 1.73688 18.0702 1.73688 18.8002C1.73688 19.6704 1.70833 20.4984 1.65123 21.2655L1.01812 30.1364C0.886536 31.9265 1.48612 33.6371 2.7064 34.953C3.91426 36.2527 5.62364 36.9975 7.39633 36.9975C9.17151 36.9975 10.8809 36.2515 12.0875 34.953C13.3078 33.6371 13.9086 31.9265 13.7758 30.1389L13.1402 21.2556C13.093 20.6895 13.0682 20.1235 13.0682 19.5413C13.0558 19.0745 13.0558 18.6053 13.0682 18.136C13.0806 17.6668 13.1042 17.2013 13.1414 16.7357ZM11.8268 18.8362C11.8268 18.9243 11.8169 19.0112 11.8181 19.0981L2.86157 21.7298L2.88764 21.3562C2.93109 20.7715 2.95219 20.1421 2.96461 19.4953C2.96585 19.4159 2.9733 19.3476 2.97454 19.2682L11.9025 16.6464C11.9013 16.6675 11.9013 16.6898 11.9001 16.7121C11.8467 17.4173 11.8281 18.1286 11.8268 18.8362ZM3.61509 3.89117C4.60199 2.82855 5.94392 2.24262 7.39509 2.24262C10.2354 2.24262 12.5468 4.54786 12.5468 7.38194C12.5468 7.51352 12.5481 7.63518 12.5356 7.77173L11.9969 15.323L2.95964 17.9771C2.94599 17.5203 2.92488 17.0746 2.88764 16.6476L2.25454 7.77049C2.14654 6.32925 2.63067 4.95131 3.61509 3.89117ZM11.1776 34.1113C10.1894 35.174 8.84627 35.7599 7.39509 35.7599C5.94392 35.7599 4.60199 35.174 3.61509 34.1113C2.63067 33.0512 2.14654 31.6733 2.25454 30.2295L2.76599 23.0506L11.8603 20.3779C11.8728 20.7032 11.8752 21.0309 11.9013 21.3537L12.5356 30.2308C12.6436 31.6733 12.1608 33.0512 11.1776 34.1113Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M9.25768 23.3438H5.53354C4.1643 23.3438 3.05078 24.4573 3.05078 25.8265C3.05078 27.1958 4.1643 28.3093 5.53354 28.3093H9.25768C10.6269 28.3093 11.7404 27.1958 11.7404 25.8265C11.7404 24.4573 10.6257 23.3438 9.25768 23.3438ZM9.25768 27.0679H5.53354C4.8483 27.0679 4.29216 26.5105 4.29216 25.8265C4.29216 25.1425 4.8483 24.5851 5.53354 24.5851H9.25768C9.94292 24.5851 10.4991 25.1425 10.4991 25.8265C10.4991 26.5105 9.94168 27.0679 9.25768 27.0679Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M5.53354 14.653H9.25768C10.6269 14.653 11.7404 13.5395 11.7404 12.1703C11.7404 10.801 10.6269 9.6875 9.25768 9.6875H5.53354C4.1643 9.6875 3.05078 10.801 3.05078 12.1703C3.05078 13.5395 4.1643 14.653 5.53354 14.653ZM5.53354 10.9289H9.25768C9.94292 10.9289 10.4991 11.4863 10.4991 12.1703C10.4991 12.8543 9.94292 13.4116 9.25768 13.4116H5.53354C4.8483 13.4116 4.29216 12.8543 4.29216 12.1703C4.29216 11.4863 4.8483 10.9289 5.53354 10.9289Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M29.5858 19.5437C29.5609 18.6065 29.587 17.663 29.6603 16.7345L30.2921 7.86607C30.3058 7.69973 30.307 7.54704 30.307 7.38069C30.307 3.86262 27.4394 1 23.9152 1C22.14 1 20.4306 1.74607 19.224 3.04455C18.0037 4.36042 17.4029 6.07104 17.5357 7.85863L18.1713 16.7444C18.2271 17.3887 18.2545 18.0615 18.2545 18.8001C18.2545 19.6803 18.2271 20.5083 18.17 21.2655L17.5357 30.1377C17.4041 31.9277 18.0037 33.6384 19.224 34.9542C20.4318 36.254 22.1412 36.9988 23.9152 36.9988C25.6891 36.9988 27.3985 36.2527 28.6051 34.9542C29.8254 33.6384 30.4262 31.9277 30.2934 30.1402L29.659 21.2556C29.6094 20.6908 29.5858 20.1247 29.5858 19.5437ZM28.3444 18.8362C28.3444 18.923 28.3345 19.0087 28.3357 19.0968L19.3791 21.7286L19.4052 21.3549C19.4313 20.9962 19.4511 20.6175 19.4648 20.229C19.4698 20.0974 19.4723 19.9596 19.4747 19.8255C19.4797 19.6393 19.4871 19.4581 19.4896 19.2669L28.4189 16.6439C28.4176 16.665 28.4189 16.6873 28.4164 16.7084C28.3643 17.4135 28.3444 18.1261 28.3444 18.8362ZM20.1302 3.88993C21.1183 2.82731 22.4615 2.24138 23.9127 2.24138C26.7529 2.24138 29.0632 4.54662 29.0632 7.38069C29.0632 7.51228 29.0644 7.63394 29.052 7.77049L28.5145 15.3218L19.476 17.9759C19.4623 17.5153 19.4412 17.0697 19.4052 16.6464L18.7709 7.76925C18.6641 6.328 19.147 4.95007 20.1302 3.88993ZM27.6927 34.1101C26.7058 35.1727 25.3638 35.7586 23.9127 35.7586C22.4615 35.7586 21.1183 35.1727 20.1302 34.1101C19.147 33.05 18.6629 31.6733 18.7709 30.2295L19.2836 23.0506L28.3779 20.3792C28.3903 20.7044 28.3928 21.0322 28.4189 21.3549L29.052 30.232C29.1612 31.672 28.6771 33.05 27.6927 34.1101Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M25.7753 23.3438H22.0511C20.6819 23.3438 19.5684 24.4573 19.5684 25.8265C19.5684 27.1958 20.6819 28.3093 22.0511 28.3093H25.7753C27.1445 28.3093 28.258 27.1958 28.258 25.8265C28.258 24.4573 27.1445 23.3438 25.7753 23.3438ZM25.7753 27.0679H22.0511C21.3659 27.0679 20.8097 26.5105 20.8097 25.8265C20.8097 25.1425 21.3659 24.5851 22.0511 24.5851H25.7753C26.4605 24.5851 27.0166 25.1425 27.0166 25.8265C27.0166 26.5105 26.4605 27.0679 25.7753 27.0679Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M22.0511 14.653H25.7753C27.1445 14.653 28.258 13.5395 28.258 12.1703C28.258 10.801 27.1445 9.6875 25.7753 9.6875H22.0511C20.6819 9.6875 19.5684 10.801 19.5684 12.1703C19.5684 13.5395 20.6831 14.653 22.0511 14.653ZM22.0511 10.9289H25.7753C26.4605 10.9289 27.0166 11.4863 27.0166 12.1703C27.0166 12.8543 26.4605 13.4116 25.7753 13.4116H22.0511C21.3659 13.4116 20.8097 12.8543 20.8097 12.1703C20.8097 11.4863 21.3671 10.9289 22.0511 10.9289Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default Skis
