import { createSelector } from '@reduxjs/toolkit'

export const itemCategoriesStore = (state) => state.itemCategories

export const electItemCategoryList = createSelector(
  itemCategoriesStore,
  (itemCategories) => itemCategories.list
)

export const selectCategories = createSelector(
  electItemCategoryList,
  (itemCategories) =>
    itemCategories.map((i) => {
      return { id: i.id, name: i.name, description: i.description }
    })
)

export const selectCategoryByCategoryName = createSelector(
  [electItemCategoryList, (_, categoryName) => categoryName],
  (itemCategories, categoryName) => {
    const cat = itemCategories.find(
      (ic) => ic.name.toLowerCase() === categoryName.toLowerCase()
    )
    return {
      name: cat.name,
      id: cat.id,
    }
  }
)

export const selectItemTypeByCategoryName = createSelector(
  [electItemCategoryList, (_, categoryName) => categoryName],
  (itemCategories, categoryName) => {
    return itemCategories.find(
      (ic) => ic.name.toLowerCase() === categoryName.toLowerCase()
    ).item_types
  }
)
