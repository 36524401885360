import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import c from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Header from '../components/header'
import { selectBrand } from '../selectors/app'
import {
  HOME_PATH,
  RESET_PASSWORD_PATH,
  WHITE_LABEL_BRAND_NAME,
} from '../constants'
import { LoginValidation } from '../utils/validation'
import Button from '../components/button'
import { loginUser } from '../reducers/user'
import Autosaving from '../components/autosaving'
import Message from '../components/message'

export function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const brandName = useSelector(selectBrand)

  const onSubmit = (values) => {
    const { phone, password } = values
    setLoading(true)
    dispatch(loginUser({ phone, password }))
      .then(() => {
        setLoading(false)
        navigate(HOME_PATH)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  const onCloseErrorMessage = () => setError(false)

  return (
    <div className={c('page', brandName)}>
      <Header />
      <div className="container-fluid">
        <div className="login">
          <div className="title">
            Welcome to {WHITE_LABEL_BRAND_NAME} Claims
          </div>
          <div className="card">
            <div className="card-body p-4">
              <Formik
                initialValues={{
                  phone: '',
                  password: '',
                }}
                validationSchema={LoginValidation}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="mb-3">
                      <label className="form-label">Cell Phone Number</label>
                      <NumberFormat
                        className="form-control"
                        onValueChange={({ value }) =>
                          setFieldValue('phone', value)
                        }
                        format="(###) ###-####"
                        mask="_"
                        allowEmptyFormatting
                      />
                      <ErrorMessage
                        component="div"
                        className="error-text"
                        name="phone"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                      />
                      <ErrorMessage
                        component="div"
                        className="error-text"
                        name="password"
                      />
                    </div>
                    <div className="mb-3">
                      <Link
                        className="primary tdn"
                        to={`/${RESET_PASSWORD_PATH}`}
                      >
                        Click here to get a new password.
                      </Link>
                    </div>
                    <div className="mb-3">
                      {error && (
                        <Message onClose={onCloseErrorMessage} closable error>
                          Authentication credentials are wrong
                        </Message>
                      )}
                      {loading ? (
                        <Autosaving label="Login..." />
                      ) : (
                        <Button type="submit">Login</Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
