import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { IconArrowDown } from '../icons/icon-arrow-down'

export function Collapsable({ className, children, header, open, ...props }) {
  const [isopen, setOpen] = useState(open)
  const onToggle = () => setOpen(!isopen)
  return (
    <div className={c('collapsable', className, { open: isopen })} {...props}>
      <div className="collapsable__header" onClick={onToggle}>
        <div className="collapsable__header-content">{header}</div>
        <div className="collapsable__action">
          <div className={c('collapsable__button', { open: isopen })}>
            <IconArrowDown />
          </div>
        </div>
      </div>
      {isopen ? <div className="collapsable__body">{children}</div> : null}
    </div>
  )
}
export default Collapsable

Collapsable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  open: PropTypes.bool,
}

Collapsable.defaultProps = {
  className: '',
  children: '',
  header: '',
  open: false,
}
