import React from 'react'

export function Cameralens() {
  return (
    <svg
      width="25"
      height="31"
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 29.5H4H3.5V26H0.5V6.5H3V3H0.5V0.5H24.5V3H22V6.5H24.5V26H21.5V29.5Z"
        fill="white"
      />
      <path
        d="M4.57377 12.0938C4.25687 12.0938 4 12.3506 4 12.6675V17.5036C4 17.8205 4.25687 18.0774 4.57377 18.0774C4.89067 18.0774 5.14754 17.8205 5.14754 17.5036V12.6675C5.14754 12.3506 4.89067 12.0938 4.57377 12.0938Z"
        fill="#060920"
      />
      <path
        d="M25 2.50868V1.17984C25 0.529265 24.4707 0 23.8201 0H1.17988C0.529265 0 0 0.529265 0 1.17984V2.50868C0 3.15926 0.529265 3.68852 1.17988 3.68852H2.46066C2.46036 3.69936 2.45902 3.70992 2.45902 3.72082V5.86934C2.45902 5.88024 2.46036 5.89081 2.46066 5.90164H1.17988C0.529265 5.90164 0 6.4309 0 7.08148V25.6234C0 26.274 0.529265 26.8033 1.17988 26.8033H2.87049C2.87019 26.8141 2.86885 26.8247 2.86885 26.8356V28.9841C2.86885 29.6347 3.39812 30.1639 4.04873 30.1639H20.9513C21.6019 30.1639 22.1311 29.6347 22.1311 28.9841V26.8356C22.1311 26.8247 22.1298 26.8141 22.1295 26.8033H23.8201C24.4707 26.8033 25 26.274 25 25.6234V7.08148C25 6.4309 24.4707 5.90164 23.8201 5.90164H22.5393C22.5396 5.89081 22.541 5.88024 22.541 5.86934V3.72082C22.541 3.70992 22.5396 3.69936 22.5393 3.68852H23.8201C24.4707 3.68852 25 3.15926 25 2.50868ZM1.14754 2.50868V1.17984C1.14754 1.16323 1.16323 1.14754 1.17988 1.14754H23.8201C23.8368 1.14754 23.8525 1.16323 23.8525 1.17984V2.50868C23.8525 2.52529 23.8368 2.54098 23.8201 2.54098H21.3611H3.6389H1.17988C1.16323 2.54098 1.14754 2.52529 1.14754 2.50868ZM20.9836 26.8356V28.9841C20.9836 29.0007 20.9679 29.0164 20.9513 29.0164H4.04873C4.03208 29.0164 4.01639 29.0007 4.01639 28.9841V26.8356C4.01639 26.819 4.03208 26.8033 4.04873 26.8033H20.9513C20.9679 26.8033 20.9836 26.819 20.9836 26.8356ZM23.8525 7.08148V25.6234C23.8525 25.64 23.8368 25.6557 23.8201 25.6557H20.9513H4.04873H1.17988C1.16323 25.6557 1.14754 25.64 1.14754 25.6234V7.08148C1.14754 7.06487 1.16323 7.04918 1.17988 7.04918H3.6389H21.3611H23.8201C23.8368 7.04918 23.8525 7.06487 23.8525 7.08148ZM21.3934 3.72082V5.86934C21.3934 5.88595 21.3778 5.90164 21.3611 5.90164H3.6389C3.62225 5.90164 3.60656 5.88595 3.60656 5.86934V3.72082C3.60656 3.70421 3.62225 3.68852 3.6389 3.68852H21.3611C21.3778 3.68852 21.3934 3.70421 21.3934 3.72082Z"
        fill="#060920"
      />
      <path
        d="M9.81596 12.0938C9.49906 12.0938 9.24219 12.3506 9.24219 12.6675V17.5036C9.24219 17.8205 9.49906 18.0774 9.81596 18.0774C10.1329 18.0774 10.3897 17.8205 10.3897 17.5036V12.6675C10.3897 12.3506 10.1329 12.0938 9.81596 12.0938Z"
        fill="#060920"
      />
      <path
        d="M15.0562 12.0938C14.7393 12.0938 14.4824 12.3506 14.4824 12.6675V17.5036C14.4824 17.8205 14.7393 18.0774 15.0562 18.0774C15.3731 18.0774 15.63 17.8205 15.63 17.5036V12.6675C15.63 12.3506 15.3731 12.0938 15.0562 12.0938Z"
        fill="#060920"
      />
      <path
        d="M20.2984 12.0938C19.9815 12.0938 19.7246 12.3506 19.7246 12.6675V17.5036C19.7246 17.8205 19.9815 18.0774 20.2984 18.0774C20.6153 18.0774 20.8722 17.8205 20.8722 17.5036V12.6675C20.8722 12.3506 20.6153 12.0938 20.2984 12.0938Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Cameralens
