import React from 'react'

export function Speaker() {
  return (
    <svg
      width="27"
      height="38"
      viewBox="0 0 27 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="24" height="34" fill="white" />
      <path
        d="M24.55 1H3.25C1.975 1 1 1.975 1 3.25V34.75C1 36.025 1.975 37 3.25 37H24.55C25.825 37 26.8 36.025 26.8 34.75V3.25C26.8 1.975 25.825 1 24.55 1ZM25.3 34.75C25.3 35.2 25 35.5 24.55 35.5H3.25C2.8 35.5 2.5 35.2 2.5 34.75V3.25C2.5 2.8 2.8 2.5 3.25 2.5H24.55C25 2.5 25.3 2.8 25.3 3.25V34.75Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
      <path
        d="M13.9004 9.70312C11.8004 9.70312 10.1504 11.3531 10.1504 13.4531C10.1504 15.5531 11.8004 17.2031 13.9004 17.2031C16.0004 17.2031 17.6504 15.5531 17.6504 13.4531C17.6504 11.3531 16.0004 9.70312 13.9004 9.70312ZM13.9004 15.7031C12.6254 15.7031 11.6504 14.7281 11.6504 13.4531C11.6504 12.1781 12.7004 11.2031 13.9004 11.2031C15.1004 11.2031 16.1504 12.1781 16.1504 13.4531C16.1504 14.7281 15.1754 15.7031 13.9004 15.7031Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M13.9008 23.7969C11.2758 23.7969 9.17578 25.8969 9.17578 28.5219C9.17578 31.1469 11.2758 33.2469 13.9008 33.2469C16.5258 33.2469 18.6258 31.1469 18.6258 28.5219C18.6258 25.8969 16.5258 23.7969 13.9008 23.7969ZM13.9008 31.6719C12.1008 31.6719 10.6758 30.2469 10.6758 28.4469C10.6758 26.7219 12.1008 25.2219 13.9008 25.2219C15.6258 25.2219 17.1258 26.6469 17.1258 28.4469C17.1258 30.2469 15.6258 31.6719 13.9008 31.6719Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M13.7504 12.6971C13.4504 12.7721 13.1504 13.0721 13.1504 13.4471C13.1504 13.8971 13.4504 14.1971 13.9004 14.1971C14.3504 14.1971 14.6504 13.8971 14.6504 13.4471C14.6504 12.9971 14.2004 12.6221 13.7504 12.6971Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M14.2004 27.7755C13.6754 27.6255 13.1504 28.0005 13.1504 28.5255C13.1504 28.9755 13.4504 29.2755 13.9004 29.2755C14.1254 29.2755 14.2754 29.2005 14.4254 29.0505C14.8004 28.6755 14.6504 28.0005 14.2004 27.7755Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M13.9008 5.72656C9.62578 5.72656 6.17578 9.17656 6.17578 13.4516C6.17578 17.7266 9.62578 21.1016 13.9008 21.1016C18.1758 21.1016 21.6258 17.6516 21.6258 13.3766C21.6258 9.10156 18.1758 5.72656 13.9008 5.72656ZM13.9008 19.6016C10.5258 19.6016 7.67578 16.8266 7.67578 13.3766C7.67578 9.92656 10.4508 7.15156 13.9008 7.15156C17.3508 7.15156 20.1258 9.92656 20.1258 13.3766C20.1258 16.8266 17.2758 19.6016 13.9008 19.6016Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Speaker
