import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { GET_CURRENCIES } from '../constants'

export const getCurrencies = createAsyncThunk(
  'currencies/getCurrencies',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(`${GET_CURRENCIES}`)
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error on list currencies' })
      )
    }
  }
)

export const initialState = {
  currencies: [],
}
export const currenciesStore = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    addCurrencies: (state, action) => {
      const { payload } = action
      state.currencies = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrencies.fulfilled, (state, action) => {
      const { payload } = action
      state.currencies = payload
    })
  },
})

export const { addCurrencies } = currenciesStore.actions

export default currenciesStore.reducer
