import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { registerPageVisited, setCurrentSteep } from '../reducers/app'

export function useStoreCurrentSteep(pageName) {
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(setCurrentSteep(location.pathname))
    dispatch(registerPageVisited(pageName))
  }, [])
  return null
}
