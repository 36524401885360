import React from 'react'
import PropTypes from 'prop-types'

export function Container({ className, children }) {
  return <div className={`container-fluid ${className}`}>{children}</div>
}
export default Container

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Container.defaultProps = {
  className: '',
  children: undefined,
}
