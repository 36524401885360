import { object, string, ref } from 'yup'

const PAYPAL = 'paypal'
const VENMO = 'venmo'
const WIRE = 'wire'

export const PaymentSchema = object({
  method: string(),
  email: string()
    .email()
    .when('method', {
      is: (val) => val === PAYPAL,
      then: (schema) => schema.required('Email is required'),
    }),
  emailConfirm: string()
    .email()
    .when('method', {
      is: (val) => val === PAYPAL,
      then: (schema) => {
        return schema
          .required('Email confirmation is required')
          .oneOf([ref('email')], 'Email should be the same')
      },
    }),
  phone: string().when('method', {
    is: (val) => val === VENMO,
    then: (schema) => schema.required('Phone is required'),
  }),
  phoneConfirm: string().when('method', {
    is: (val) => val === VENMO,
    then: (schema) => {
      return schema
        .required('Phone confirmation is required')
        .oneOf([ref('phone')], 'Venmo Phone should be the same')
    },
  }),
  wire: string().when('method', {
    is: (val) => val === WIRE,
    then: (schema) => schema.required('Email is required'),
  }),
  wireConfirm: string().when('method', {
    is: (val) => val === WIRE,
    then: (schema) => {
      return schema
        .required('Email confirmation is required')
        .oneOf([ref('wire')], 'Email should be the same')
    },
  }),
})
