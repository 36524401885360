import React from 'react'

export function Smartwatch() {
  return (
    <svg
      width="22"
      height="34"
      viewBox="0 0 22 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6024 32.9049H6.70463L5.38834 28.0784L2.75575 26.7621L1.43945 24.5683V9.21154L3.19451 6.57895L5.38834 6.14018L6.70463 0.875H15.0412L15.9187 6.14018H17.235L19.4288 8.33401V10.0891L21.1839 10.9666V14.4767L19.4288 14.9155V17.5481C19.4288 17.8991 20.5989 18.2793 21.1839 18.4256V23.6908L19.4288 24.1295L18.1125 26.7621L16.3575 27.2009L14.6024 32.9049Z"
        fill="white"
        stroke="#060920"
      />
      <path
        d="M5.06937 6.11152L5.0688 6.11152C3.21258 6.1132 1.70824 7.61754 1.70656 9.47376V9.47421L1.70656 23.9814L1.70657 23.9819C1.70824 25.8381 3.21258 27.3424 5.0688 27.3441H5.06925L15.9464 27.3441L15.948 27.3441C17.8001 27.3382 19.2993 25.837 19.3026 23.9849V23.984V9.47679V9.47634C19.301 7.62012 17.7966 6.11578 15.9404 6.11411L15.9401 6.11411L5.06937 6.11152ZM15.9459 27.5558H5.07118C3.09954 27.5533 1.502 25.9554 1.5 23.9838V9.47713C1.50266 7.50609 3.09984 5.90891 5.07088 5.90625H15.9474C17.9185 5.90891 19.5158 7.50625 19.5183 9.47743V23.9835C19.5164 25.9557 17.9181 27.554 15.9459 27.5558Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M19.5177 14.2433V14.7433H20.0177L20.2451 14.7433L20.2463 14.7433C20.8223 14.742 21.2889 14.2754 21.2903 13.6994V13.6982L21.2903 11.5512L21.2903 11.55C21.2889 10.974 20.8223 10.5074 20.2463 10.506V10.506H20.2451H20.0177H19.5177V11.006V14.2433ZM20.2447 14.9525H19.4132C19.3554 14.9525 19.3086 14.9057 19.3086 14.8479V10.4014C19.3086 10.3437 19.3554 10.2969 19.4132 10.2969H20.2449C20.9374 10.2975 21.4987 10.8587 21.4994 11.5512C21.4994 11.5512 21.4994 11.5512 21.4994 11.5513C21.4994 11.5514 21.4994 11.5514 21.4994 11.5515L21.4994 13.7002C21.4976 14.3919 20.9366 14.952 20.2447 14.9525Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M19.5177 23.5404V24.0404H20.0177L20.0345 24.0404L20.0354 24.0404C20.7279 24.0392 21.2891 23.4781 21.2903 22.7855V22.7847L21.2903 19.2774L21.2903 19.2765C21.2891 18.584 20.7279 18.0228 20.0354 18.0216H20.0345H20.0177H19.5177V18.5216V23.5404ZM20.0339 24.2495L19.4132 24.2495C19.3554 24.2495 19.3086 24.2027 19.3086 24.145V17.9171C19.3086 17.8593 19.3554 17.8125 19.4132 17.8125H20.0342C20.8429 17.8137 21.4982 18.469 21.4994 19.2777V22.7844C21.4982 23.593 20.8429 24.2483 20.0342 24.2495C20.0341 24.2495 20.034 24.2495 20.0339 24.2495Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M7.06868 32.7339L7.06868 32.7339H7.07206H14.2895C14.6059 32.7339 14.8772 32.5087 14.9357 32.1981L15.8581 27.4249C15.8707 27.3702 15.9244 27.3351 15.9798 27.3457C16.0351 27.3564 16.0719 27.4089 16.0634 27.4643L15.1409 32.2377C15.1409 32.2378 15.1409 32.238 15.1408 32.2382C15.0611 32.6456 14.7043 32.9398 14.2892 32.9405C14.2891 32.9405 14.2889 32.9405 14.2888 32.9405H7.07271C7.0726 32.9405 7.0725 32.9405 7.07239 32.9405C6.65716 32.9398 6.3004 32.6456 6.22068 32.2381C6.22065 32.2379 6.22063 32.2378 6.2206 32.2377L5.29818 27.4643C5.28965 27.4089 5.32644 27.3564 5.38177 27.3457C5.43715 27.3351 5.49087 27.3702 5.50345 27.425L6.42586 32.1982L6.91704 32.1048L6.42612 32.1996L6.42603 32.1991C6.48522 32.5078 6.75427 32.7318 7.06868 32.7339Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M16.0646 5.9837L16.0644 5.98301L15.1409 1.20367L16.0646 5.9837ZM16.0646 5.9837C16.0698 6.01066 16.0641 6.0386 16.0487 6.06134C16.0333 6.08408 16.0095 6.09975 15.9825 6.10489L15.969 6.10746M16.0646 5.9837L15.969 6.10746M15.969 6.10746L15.9557 6.11077M15.969 6.10746L15.9557 6.11077M15.9557 6.11077C15.9536 6.1113 15.9514 6.11175 15.9493 6.11212M15.9557 6.11077L15.9493 6.11212M15.9493 6.11212C15.9047 6.10682 15.8679 6.07333 15.8591 6.02839L15.9493 6.11212ZM15.859 6.02817L14.9357 1.25016C14.8772 0.939554 14.6059 0.714316 14.2895 0.714316H7.07229C7.07221 0.714316 7.07214 0.714316 7.07206 0.714316C6.75592 0.714206 6.48426 0.938666 6.42474 1.24913L6.42483 1.24864L6.91575 1.3435L6.42457 1.25L15.859 6.02817ZM5.50345 6.01684C5.49087 6.07155 5.43715 6.10672 5.38177 6.09605C5.32644 6.08539 5.28965 6.0329 5.29818 5.97749L6.22069 1.20367C6.2207 1.2036 6.22072 1.20353 6.22073 1.20346C6.30002 0.795537 6.65692 0.500777 7.07247 0.5H14.2891C14.7046 0.500777 15.0614 0.795438 15.1408 1.20325L5.50345 6.01684Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M9.5016 8.68571H1.94442C1.88666 8.68571 1.83984 8.63889 1.83984 8.58114C1.83984 8.52338 1.88666 8.47656 1.94442 8.47656H9.5016C9.55935 8.47656 9.60617 8.52338 9.60617 8.58114C9.60617 8.63889 9.55935 8.68571 9.5016 8.68571Z"
        fill="#060920"
        stroke="#060920"
      />
      <path
        d="M13.9299 8.68571H11.8526C11.7949 8.68571 11.748 8.63889 11.748 8.58114C11.748 8.52338 11.7949 8.47656 11.8526 8.47656H13.9299C13.9876 8.47656 14.0345 8.52338 14.0345 8.58114C14.0345 8.63889 13.9876 8.68571 13.9299 8.68571Z"
        fill="#060920"
        stroke="#060920"
      />
    </svg>
  )
}
export default Smartwatch
