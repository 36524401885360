import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { toggleLoading, setAppError, addNotification } from '../app'
import {
  READ_CLAIM,
  CREATE_CLAIM,
  UPDATE_CLAIM,
  AFFECTED_ITEMS_PATH,
  TYPE_LOSS_PATH,
  PROOF_LOSS_PATH,
  DESCRIBE_INCIDENT_PATH,
  FILE_CLAIM,
  PAYOUT_PATH,
  PUBLIC_IMAGE_API,
  API_IMAGES_KEY,
  FILE_PIPELINE_ENV,
  IMAGES_STATUS_PATH,
  EVIDENCE_STATUS_PATH,
  DELETE_IMAGE_CLAIM_API,
  DELETE_CLAIM_EVIDENCES,
  UPDATE_USER_PAYOUT_METHOD,
  DELETE_DEVICE_FILE,
  UPDATE_DEVICE_REPAIR_COST,
  UPLOAD_DEVICE_REPAIR_RECEIP,
  DYNAMIC_COMPONENT_HANDLER,
  CLAIM_TASK_CONTINUE,
  DEVICE_STORE_URLS,
  SUBMIT_PAYMENT,
  CALCULATE_PAYMENT,
  GENERATE_SHIPPING_LABEL,
  CONFIRM_ADDRESS_PATH,
  GET_CLAIM_LIMITS,
  GET_PAYMENT_SERVICE_FEE,
} from '../../constants'

// NOTE: dont import anything from this file on components
// import from index.js that re-export those thunks

export const getClaim = createAsyncThunk(
  'claim/getClaim',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    const { claimId } = payload
    try {
      const response = await axios.get(`${READ_CLAIM}${claimId}/`)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on reading claim' }))
    }
  },
  {
    condition: (payload, { getState, extra }) => {
      // Avoid query again the same claim
      const { claim } = getState()
      const { claimId, force } = payload || {}
      if (force) return true
      if (Number(claimId) !== claim.id) {
        return true
      }
      return false
    },
  }
)

export const createClaim = createAsyncThunk(
  'claim/createClaim',
  async (userId, thunkAPI) => {
    const state = thunkAPI.getState()
    const { dispatch } = thunkAPI
    const { claim } = state
    try {
      const response = await axios.post(CREATE_CLAIM, {
        affected_items: claim.affectedItems.map((item) => item.id),
      })
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error on creating new claim' })
      )
    }
  }
)

export const updateClaim = createAsyncThunk(
  'claim/updateClaim',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { section, itemsToDelete, preventPopupError } = payload
    const { dispatch, rejectWithValue } = thunkAPI
    const { claim, user } = state
    try {
      let data = {}
      if (section === AFFECTED_ITEMS_PATH) {
        data = {
          affected_items: claim.affectedItems.map((item) => item.deviceId),
          affected_items_removed: itemsToDelete,
        }
      }
      if (section === TYPE_LOSS_PATH) {
        data = { type_loss: claim.typeLoss }
      }
      if (section === PROOF_LOSS_PATH) {
        data = {
          proof_loss_images: claim.proofLossImages,
        }
      }
      if (section === DESCRIBE_INCIDENT_PATH) {
        data = {
          loss_date: `${claim.date.getFullYear()}-${claim.date.getMonth() + 1}-${claim.date.getDate()} 00:00:00`,
          incident: claim.incident,
        }
      }
      if (section === CONFIRM_ADDRESS_PATH) {
        data = {
          address: {
            country: user.country,
            city: user.city,
            state: user.state,
            street: user.street,
            zip: user.zip,
          },
        }
      }
      // deprecated, delete soon
      if (section === PAYOUT_PATH) {
        if (claim.skipPayoutMethod) {
          data = {
            skip_payout_method: claim.skipPayoutMethod,
          }
        }
        if (!isEmpty(claim.paypalAccount)) {
          data = {
            paypal_account: claim.paypalAccount,
          }
        }
        if (!isEmpty(claim.venmoAccount)) {
          data = {
            venmo_account: claim.venmoAccount,
          }
        }
      }
      if (section === FILE_CLAIM) {
        data = {
          finish: true,
          accepted_terms_conditions: claim.acceptedTermsConditions,
        }
      }
      const response = await axios.patch(`${UPDATE_CLAIM}${claim.id}/`, data)
      return response.data.data
    } catch (error) {
      if (preventPopupError === undefined) {
        dispatch(
          addNotification({
            type: 'error',
            message: JSON.stringify(error.response.data.message),
          })
        )
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getSignedURL = createAsyncThunk(
  'image/getSignedURL',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { claimId, type, filename } = payload
    try {
      const filePipelineUrl = new URL(
        `https://api-gateway.${FILE_PIPELINE_ENV}.cloud.getakko.com/upload`
      )
      filePipelineUrl.search = new URLSearchParams({
        api_key: API_IMAGES_KEY,
        id: claimId,
        type,
        filename,
      })
      const response = await axios.get(filePipelineUrl)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getPaymentServiceFee = createAsyncThunk(
  'claim/getPaymentServiceFee',
  async (payload, thunkAPI) => {
    const { claimId, claimDeviceId } = payload
    const { rejectWithValue } = thunkAPI
    try {
      const response = await axios.get(
        GET_PAYMENT_SERVICE_FEE(claimId, claimDeviceId)
      )
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const submitImage = createAsyncThunk(
  'image/submitImage',
  async (payload, thunkAPI) => {
    const { headers, url } = payload
    const { rejectWithValue } = thunkAPI
    try {
      const gcpPipeLine = axios.create()
      const response = await gcpPipeLine.put(url, payload.file, { headers })
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const checkImagesStatus = createAsyncThunk(
  'claim/checkImagesStatus',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    const { claimId } = payload
    try {
      const response = await axios.get(
        `${IMAGES_STATUS_PATH}${claimId}/images_status/`
      )
      return response.data.data
    } catch (error) {
      //
    }
  }
)

export const checkEvidenceStatus = createAsyncThunk(
  'claim/checkEvidenceStatus',
  async (payload, thunkAPI) => {
    const { claimId, claimDeviceId, images, files, videos } = payload
    try {
      let url = ''
      if (!isEmpty(images)) {
        url += `&images=${images.join('&images=')}`
      }
      if (!isEmpty(files)) {
        url += `&files=${files.join('&files=')}`
      }
      if (!isEmpty(videos)) {
        url += `&videos=${videos.join('&videos=')}`
      }
      const response = await axios.get(
        `${EVIDENCE_STATUS_PATH(claimId, claimDeviceId)}?${url}`
      )
      return response.data.data
    } catch (error) {
      //
    }
  }
)

export const deleteClaimImage = createAsyncThunk(
  'claim/deleteClaimImage',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { claim } = state
    const { dispatch } = thunkAPI
    const { deviceId, imageId } = payload
    dispatch(toggleLoading(true))
    try {
      const response = await axios.delete(
        DELETE_IMAGE_CLAIM_API(claim.id, imageId)
      )
      dispatch(toggleLoading(false))
      return {
        ...response.data.data,
        deviceId,
      }
    } catch (error) {
      dispatch(toggleLoading(false))
      dispatch(setAppError({ error: true, message: 'Error on delete image' }))
    }
  }
)

export const deleteEvidence = createAsyncThunk(
  'claim/deleteEvidence',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { claim } = state
    const { claimDeviceId, id, filename, documentType } = payload
    try {
      const data = {
        id,
        filename,
        document_type: documentType,
      }
      const response = await axios.post(DELETE_CLAIM_EVIDENCES(claim.id), data)
      return {
        ...response.data.data,
        documentType,
        claimDeviceId,
      }
    } catch (error) {
      //
    }
  }
)

export const getClaimLimits = createAsyncThunk(
  'claims/getClaimLimits',
  async (payload, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI
    try {
      const state = getState()
      const { user } = state
      // NOTE:
      // Some users do not have contract, therefore `contract` will
      // be empty array it will `user.contract[0].id` fail
      /// but it is inside try catch
      const response = await axios.get(
        GET_CLAIM_LIMITS(user.user_id, user.contract[0].id)
      )
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateUserPayoutMethod = createAsyncThunk(
  'user/updateUserPayoutMethod',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        paypal: payload.paypal,
        venmo: payload.venmo,
        skip: payload.skip,
      }
      const response = await axios.post(
        UPDATE_USER_PAYOUT_METHOD(payload.claimID),
        data
      )
      return response.data.data
    } catch (error) {
      console.log(error)
      dispatch(
        setAppError({ error: true, message: 'Error on update payout method' })
      )
    }
  }
)

export const submitPayment = createAsyncThunk(
  'user/submitPayment',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { claimId, claimDeviceId, fieldId } = payload
    try {
      const response = await axios.post(
        SUBMIT_PAYMENT(claimId, claimDeviceId),
        { field_id: fieldId }
      )
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const calculatePayment = createAsyncThunk(
  'user/calculatePayment',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { claimId, claimDeviceId } = payload
    try {
      const response = await axios.get(
        CALCULATE_PAYMENT(claimId, claimDeviceId)
      )
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const generateShippingLabel = createAsyncThunk(
  'user/generateShippingLabel',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { claimId, claimDeviceId } = payload
    try {
      const response = await axios.post(GENERATE_SHIPPING_LABEL, {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
      })
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateDeviceRepairCost = createAsyncThunk(
  'user/updateDeviceRepairCost',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        repair_cost: payload.repairCost,
        agreement: payload.agreement,
        // file_name: payload.filename,
        // file_size: payload.size,
        // file_type: payload.type,
      }
      const path = UPDATE_DEVICE_REPAIR_COST(payload.claimID, payload.deviceId)
      const response = await axios.post(path, data)
      return response.data.data
    } catch (error) {
      console.log(error)
      dispatch(
        setAppError({ error: true, message: 'Error on update repair cost' })
      )
    }
  }
)

// not used, delete soon
export const uploadDeviceRepairReceip = createAsyncThunk(
  'user/uploadDeviceRepairReceip',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        file_name: payload.filename,
        file_size: payload.size,
        file_type: payload.type,
      }
      const path = UPLOAD_DEVICE_REPAIR_RECEIP(
        payload.claimID,
        payload.deviceID
      )
      const response = await axios.post(path, data)
      return response.data.data
    } catch (error) {
      console.log(error)
      dispatch(
        setAppError({ error: true, message: 'Error on update repair cost' })
      )
    }
  }
)

export const deleteClaimDeviceFile = createAsyncThunk(
  'user/deleteClaimDeviceFile',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const { claimId, deviceId, fileId } = payload
      const response = await axios.delete(
        DELETE_DEVICE_FILE(claimId, deviceId, fileId)
      )
      return {
        ...response.data.data,
        deviceId,
      }
    } catch (error) {
      console.log(error)
      dispatch(
        setAppError({ error: true, message: 'Error on update repair cost' })
      )
    }
  }
)

export const updateDynamicFieldContinue = createAsyncThunk(
  'image/updateDynamicFieldContinue',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { claim } = state
    const { claimId, claimDeviceId } = payload
    try {
      const response = await axios.post(
        CLAIM_TASK_CONTINUE(claimId, claimDeviceId)
      )
      return response.data.data
    } catch (error) {
      console.log('error on submit image')
    }
  }
)

export const getDeviceStoreUrls = createAsyncThunk(
  'image/getDeviceStoreUrls',
  async (payload, thunkAPI) => {
    const { claimId, claimDeviceId } = payload
    try {
      const response = await axios.post(DEVICE_STORE_URLS, {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
      })
      return response.data.data
    } catch (error) {
      console.log('error on submit image')
    }
  }
)

// Dynamic Components
// NOT USED, delete soon
export const updateDynamicFieldImages = createAsyncThunk(
  'image/updateDynamicFieldImages',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { claim } = state
    try {
      const data = {
        ...payload,
        proof_loss_images: claim.proofLossImages,
      }
      const response = await axios.post(DYNAMIC_COMPONENT_HANDLER, data)
      return response.data.data
    } catch (error) {
      console.log('error on submit image')
    }
  }
)

// TODO, use updateDynamicField instead
export const updateDynamicFieldFile = createAsyncThunk(
  'image/updateDynamicFieldFile',
  async (payload) => {
    try {
      const response = await axios.post(DYNAMIC_COMPONENT_HANDLER, payload)
      return response.data.data
    } catch (error) {
      console.log('error on submit image')
    }
  }
)

const requiredParams = ({
  claim_device_id,
  claim_id,
  device_id,
  field_id,
}) => ({
  claim_device_id,
  claim_id,
  device_id,
  field_id,
})

const payloadBuilder = {
  repairable: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )
    return {
      ...requiredParams(payload),
      handler: 'repairable',
      not_repairable: itemAffected.notRepairable,
    }
  },
  repair_cost: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )
    return {
      ...requiredParams(payload),
      handler: 'repair_cost',
      repair_cost: itemAffected.repairCost,
      repair_cost_estimate: itemAffected.repairCostEstimate,
      repair_cost_unit: itemAffected.repairCostUnit,
      type: payload.type,
    }
  },
  repair_replace: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )
    return {
      ...requiredParams(payload),
      handler: 'repair_replace',
      repair: itemAffected.repair,
      replace: itemAffected.replace,
    }
  },
  images: (claimState, payload) => {
    return {
      ...requiredParams(payload),
      handler: 'images',
      evidence_type_id: payload.evidence_type_id,
      proof_loss_images: claimState.proofLossImages,
    }
  },
  files: (claimState, payload) => {
    return {
      ...requiredParams(payload),
      handler: 'files',
      files: claimState.deviceFiles,
    }
  },
  rate: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    return {
      ...requiredParams(payload),
      handler: 'rate',
      rate: claimState.rates[claimDeviceId],
      identifier: String(claimDeviceId),
      entity: 'claim_devices',
    }
  },
  feedback: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    return {
      ...requiredParams(payload),
      handler: 'feedback',
      feedback: claimState.feedbacks[claimDeviceId],
      identifier: String(claimDeviceId),
      entity: 'claim_devices',
    }
  },
  verification: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )
    return {
      ...requiredParams(payload),
      handler: 'verification',
      verified: itemAffected.verified,
    }
  },
  mailed_device: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )

    const d = itemAffected.mailedDeviceDate
    const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} 00:00:00`
    return {
      ...requiredParams(payload),
      handler: 'mailed_device',
      mailed_device: itemAffected.mailedDevice,
      mailed_device_date: date,
    }
  },
  payout: (claimState, payload) => {
    return {
      ...requiredParams(payload),
      handler: 'payout',
      paypal: claimState.paypalAccount,
      venmo: claimState.venmoAccount,
      wire: claimState.wireAccount,
      no_payment: payload.no_payment,
      confirm_no_payment: payload.confirm_no_payment,
    }
  },
  evidences: (claimState, payload) => {
    const {
      file_name: fileName,
      file_type: fileType,
      file_size: fileSize,
      evidenceId,
      toDelete,
      evidence_type_id: evidenceTypeId,
    } = payload
    return {
      ...requiredParams(payload),
      handler: 'evidences',
      evidence_type_id: evidenceTypeId,
      to_delete: toDelete,
      evidence_id: evidenceId,
      evidences: [
        {
          file_name: fileName,
          file_size: fileSize,
          file_type: fileType,
        },
      ],
    }
  },
  checkbox: (claimState, payload) => {
    return {
      ...requiredParams(payload),
      handler: 'checkbox',
      checked: payload.checked,
    }
  },
  shop_address: (claimState, payload) => {
    const { claim_device_id: claimDeviceId } = payload
    const itemAffected = claimState.affectedItems.find(
      (item) => item.id === claimDeviceId
    )
    return {
      ...requiredParams(payload),
      handler: 'shop_address',
      address: itemAffected.repairShopAddress,
      recommended_repair_shop: itemAffected.recommendedRepairShop,
    }
  },
  shipment_address: (claimState, payload) => {
    const { street, city, state, zip } = payload
    return {
      ...requiredParams(payload),
      handler: 'shipment_address',
      street,
      city,
      state,
      zip,
    }
  },
  payment_service_fee: (claimState, payload) => {
    return {
      ...requiredParams(payload),
      handler: 'payment_service_fee',
      method: 'card',
      stripe_token: payload.token,
      amount: 1,
    }
  },
}

export const updateDynamicField = createAsyncThunk(
  'image/updateDynamicField',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const state = thunkAPI.getState()
    const { claim } = state
    try {
      const { handler } = payload
      const data = payloadBuilder[handler](claim, payload)
      const response = await axios.post(DYNAMIC_COMPONENT_HANDLER, data)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
