import React from 'react'

export function IconShield() {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 1L1 2L0.5 5.5L1.5 9L4 12.5L6.5 14.5L9.5 12L11 9.5L12 6L12.5 2.5L9.5 1L6.5 0.5L3.5 1Z"
        fill="white"
      />
      <path
        d="M0.385179 1.65062C1.42601 1.14685 2.43563 0.750258 3.4348 0.471645C4.46527 0.193031 5.48525 0.0322611 6.48442 0H6.51563C7.52525 0.0321325 8.54524 0.192902 9.56525 0.471645C10.5852 0.750258 11.6157 1.15765 12.6669 1.67208C12.8751 1.77926 13 1.99362 13 2.21865C13 5.35901 12.6045 7.78128 11.6261 9.79626C10.6477 11.8327 9.0969 13.4404 6.81747 14.9087C6.6093 15.0374 6.35945 15.0266 6.17213 14.8981C3.90315 13.4297 2.3627 11.8327 1.37392 9.7963C0.405974 7.78135 0 5.35906 0 2.21869C0 1.96142 0.166538 1.73634 0.385179 1.65062ZM4.32991 6.9453C4.10091 6.70949 4.10091 6.32369 4.32991 6.08786C4.55891 5.85205 4.93356 5.85205 5.16257 6.08786L6.29712 7.25618L8.4725 5.02687C8.70151 4.79105 9.0658 4.79105 9.30517 5.02687C9.53417 5.26268 9.53417 5.64848 9.30517 5.8843L6.71346 8.55313C6.48445 8.78895 6.1098 8.78895 5.88079 8.54246L4.32991 6.9453ZM3.7471 1.65062C2.90408 1.87566 2.05058 2.19719 1.18672 2.60458C1.21792 5.36981 1.58221 7.50269 2.43571 9.26044C3.26837 10.986 4.57976 12.3794 6.50534 13.6762C8.42052 12.3794 9.7319 10.986 10.5646 9.26044C11.4181 7.50264 11.7927 5.36981 11.8239 2.60458C10.9601 2.19732 10.1066 1.87578 9.26353 1.65062C8.31643 1.38267 7.4004 1.24337 6.50534 1.21124C5.5998 1.24337 4.68384 1.38268 3.7471 1.65062Z"
        fill="#060920"
      />
    </svg>
  )
}

export default IconShield

IconShield.propTypes = {}

IconShield.defaultProps = {}
