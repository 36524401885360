import { createSelector } from '@reduxjs/toolkit'

export const appStore = (state) => state.app

export const selectAppLoadingState = createSelector(
  appStore,
  (app) => app.loading
)

export const selectAppNotification = createSelector(
  appStore,
  (app) => app.notification
)

export const selectAppNotifications = createSelector(
  appStore,
  (app) => app.notifications
)

export const selectTimerId = createSelector(appStore, (app) => app.timerId)

export const selectAppCurrentSteep = createSelector(
  appStore,
  (app) => app.currentSteep
)

export const selectAppTraveled = createSelector(appStore, (app) => app.traveled)

export const selectAppError = createSelector(appStore, (app) => ({
  error: app.error,
  errorMessage: app.errorMessage,
}))

export const selectLogo = createSelector(appStore, (app) => app.logoPath)

export const selectBrand = createSelector(appStore, (app) => app.brand)

export const selectBranding = createSelector(appStore, (app) => app.branding)

export const selectThemes = createSelector(appStore, (app) => app.themes)

export const selectPagesVisited = createSelector(
  appStore,
  (app) => app.pagesVisited
)

export const selectPageVisitedByName = createSelector(
  [appStore, (_, pageName) => pageName],
  (app, pageName) => app.pagesVisited[pageName]
)

export const selectAddHideCssMobile = createSelector(
  appStore,
  (app) => app.addHideCssMobile
)
