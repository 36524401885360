import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../components/page-header'
import TypeLossForm from '../components/type-loss-form'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import { DL_TXT, DL_DESC_TXT } from '../i18n'
import { PAGE_LOSS_TYPES } from '../constants'

export function TypeLossPage() {
  const { t } = useTranslation()
  useStoreCurrentSteep(PAGE_LOSS_TYPES)

  return (
    <div>
      <PageHeader title={t(DL_TXT)} subtitle={t(DL_DESC_TXT)} />
      <TypeLossForm />
    </div>
  )
}
export default TypeLossPage
