import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import c from 'classnames'
import { selectClaimID } from '../../selectors/claim'
import IconCopy from '../icons/icon-copy'

export function ClaimStatus({ className, ...props }) {
  const claimID = useSelector(selectClaimID)
  const onCopyClaim = () => {
    navigator.clipboard.writeText(claimID)
  }

  return (
    <div className={c('claim-status', className)} {...props}>
      <span>
        Claim&nbsp; #{claimID}
        &nbsp;
        <button
          type="button"
          onClick={onCopyClaim}
          className="btn btn-sm btn-link"
        >
          <IconCopy />
        </button>
      </span>
    </div>
  )
}
export default ClaimStatus

ClaimStatus.propTypes = {
  className: PropTypes.string,
}

ClaimStatus.defaultProps = {
  className: '',
}
