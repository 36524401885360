import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'
import IconNoItem from '../icons/icon-no-items'
import { ADD_ITEMS_PATH } from '../../constants'
import IconPlus from '../icons/icon-plus'

export function NoItems({ className, ...props }) {
  return (
    <div className={c('no-items', className)} {...props}>
      <div className="no-items_icon">
        <IconNoItem />
      </div>
      <div className="no-items_title">No Items Found</div>
      <div className="no-items_desc">
        Add an item to start filing your claim
      </div>
      <div>
        <Link
          to={`/${ADD_ITEMS_PATH}`}
          className="btn btn-primary no-items_btn"
        >
          <IconPlus />
          <span className="btn-text">Add Item</span>
        </Link>
      </div>
    </div>
  )
}
export default NoItems

NoItems.propTypes = {
  className: PropTypes.string,
}

NoItems.defaultProps = {
  className: '',
}
