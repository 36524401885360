import React from 'react'

export function IconCalendar() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89963 2.92532H2.59961V2.27522H1.30078V4.2301H11.7009V2.27522H10.4006V2.92532H9.10059V2.27522H3.89963V2.92532ZM11.7009 5.5365H1.30078V12.0733H11.7009V5.5365ZM9.10059 0.96805H3.89963V0.3125H2.59961V0.96805H1.30002C0.582039 0.96805 0 1.55295 0 2.27446V12.0725C0 12.794 0.582039 13.3789 1.30002 13.3789H11.7002C12.4182 13.3789 13.0002 12.794 13.0002 12.0725V2.27446C13.0002 1.55295 12.4182 0.96805 11.7002 0.96805H10.4006V0.3125H9.10059V0.96805ZM4.55007 8.15095H3.25005V6.84454H4.55007V8.15095ZM5.85059 8.15085H7.15061V6.84444H5.85059V8.15085ZM9.7493 8.15095H8.44928V6.84454H9.7493V8.15095ZM3.25 10.7652H4.55002V9.45879H3.25V10.7652ZM7.15061 10.7652H5.85059V9.45879H7.15061V10.7652Z"
        fill="#757D8A"
      />
    </svg>
  )
}
export default IconCalendar

IconCalendar.propTypes = {}

IconCalendar.defaultProps = {}
