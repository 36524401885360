import React from 'react'

export function Watch() {
  return (
    <svg
      width="25"
      height="38"
      viewBox="0 0 25 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 36.5H7.5L6.5 35V28L3.5 25L2 21.5L1.5 17L3 14L5 11.5L6.5 10V3L7 1.5H16L17 2.5V10L18 11.5L20.5 14L21.5 16.5H22.5L24 18V20L23 21.5L21.5 22L19.5 25.5L17 27.6805V35.5L16 36.5Z"
        fill="white"
      />
      <path
        d="M10.162 18.1047C9.9564 18.3748 9.79586 18.6749 9.74185 19.0199C9.65333 19.5721 9.78536 20.1242 10.1124 20.5743C10.5055 21.116 11.1387 21.4401 11.8064 21.4401C12.249 21.4401 12.6721 21.302 13.0307 21.041C13.4838 20.7139 13.7809 20.2308 13.8694 19.6786C13.9249 19.335 13.8649 18.9989 13.7539 18.6779L15.8919 17.1295C16.1605 16.9344 16.2205 16.5593 16.0269 16.2908C15.8319 16.0192 15.4523 15.9622 15.1882 16.1557L13.0412 17.7101C12.4935 17.3155 11.7854 17.2015 11.1402 17.407L8.363 13.5766C8.16645 13.3035 7.78836 13.251 7.52429 13.4416C7.25423 13.6366 7.19571 14.0117 7.38926 14.2803L10.162 18.1047ZM12.5235 18.8234L12.525 18.8249C12.525 18.8264 12.5265 18.8264 12.5265 18.8264C12.6676 19.0199 12.7231 19.2555 12.6856 19.4881C12.6481 19.7236 12.5205 19.9292 12.327 20.0687C11.9444 20.3493 11.3638 20.2533 11.0847 19.8692C10.9466 19.6786 10.8896 19.4431 10.9286 19.209C10.9647 18.9734 11.0922 18.7664 11.2812 18.6284C11.4373 18.5188 11.6173 18.4603 11.8064 18.4603C12.0884 18.4603 12.3555 18.5953 12.5235 18.8234Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M5.7802 27.8792V35.108C5.7802 36.1508 6.55589 37 7.50563 37H15.8417C16.7929 37 17.5671 36.1523 17.5671 35.108V27.8792C19.5746 26.5439 21.1065 24.5514 21.8492 22.2078C21.9752 22.2333 22.1028 22.2558 22.2318 22.2558C23.5941 22.2558 24.6624 20.826 24.6624 19C24.6624 17.174 23.5941 15.7442 22.2318 15.7442C22.0983 15.7442 21.9692 15.7667 21.8402 15.7922C21.1185 13.4936 19.6392 11.4846 17.5671 10.1058V2.89197C17.5671 1.84921 16.7914 1 15.8417 1H7.50713C6.55589 1 5.7817 1.84771 5.7817 2.89197V10.0983C2.90398 12.0128 1 15.2821 1 18.9895C1 22.6969 2.90248 25.9647 5.7802 27.8792ZM16.3668 35.108C16.3668 35.4831 16.1268 35.7982 15.8417 35.7982H7.50713C7.22356 35.7982 6.982 35.4831 6.982 35.108V28.5604C8.40135 29.2581 9.99025 29.6617 11.6752 29.6617C13.3601 29.6617 14.949 29.2581 16.3668 28.5604V35.108ZM23.4621 19C23.4621 20.1793 22.8155 21.1125 22.1448 21.045C22.2753 20.3788 22.3473 19.6917 22.3473 18.988C22.3473 18.2963 22.2618 17.6227 22.1328 16.9595C22.808 16.8845 23.4621 17.8162 23.4621 19ZM6.982 2.89197C6.982 2.51688 7.22206 2.2018 7.50713 2.2018H15.8432C16.1268 2.2018 16.3683 2.51688 16.3683 2.89197V9.42461C14.9115 8.70743 13.3151 8.31433 11.6767 8.31433C9.99175 8.31433 8.40285 8.71793 6.9835 9.4156V2.89197H6.982ZM11.6752 9.51613C13.4786 9.51613 15.2341 10.0263 16.7524 10.9925C19.5056 12.7494 21.1485 15.7382 21.1485 18.988C21.1485 24.2108 16.8995 28.4599 11.6767 28.4599C6.45236 28.4599 2.2033 24.2108 2.2033 18.988C2.2033 13.7652 6.45086 9.51613 11.6752 9.51613Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.2"
      />
      <path
        d="M11.6744 26.3594C11.3428 26.3594 11.0742 26.6294 11.0742 26.9595V27.5222C11.0742 27.8538 11.3443 28.1223 11.6744 28.1223C12.0045 28.1223 12.2745 27.8523 12.2745 27.5222V26.9595C12.2745 26.6294 12.006 26.3594 11.6744 26.3594Z"
        fill="#060920"
      />
      <path
        d="M11.6744 11.6223C12.006 11.6223 12.2745 11.3522 12.2745 11.0222V10.4595C12.2745 10.1279 12.0045 9.85938 11.6744 9.85938C11.3443 9.85938 11.0742 10.1294 11.0742 10.4595V11.0222C11.0742 11.3522 11.3428 11.6223 11.6744 11.6223Z"
        fill="#060920"
      />
      <path
        d="M3.70381 18.3906H3.14117C2.80958 18.3906 2.54102 18.6607 2.54102 18.9908C2.54102 19.3209 2.81108 19.5909 3.14117 19.5909H3.70381C4.03539 19.5909 4.30396 19.3209 4.30396 18.9908C4.30396 18.6607 4.03389 18.3906 3.70381 18.3906Z"
        fill="#060920"
      />
      <path
        d="M19.6451 19.5909H20.2077C20.5393 19.5909 20.8079 19.3209 20.8079 18.9908C20.8079 18.6607 20.5378 18.3906 20.2077 18.3906H19.6451C19.3135 18.3906 19.0449 18.6607 19.0449 18.9908C19.0449 19.3209 19.3135 19.5909 19.6451 19.5909Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Watch
