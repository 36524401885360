import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import c from 'classnames'
import { setUserAddress } from '../../reducers/user'
import { updateClaim } from '../../reducers/claim'
import { CONFIRM_ADDRESS_PATH } from '../../constants'
import { selectFormattedUserAddress } from '../../selectors/user'
import GoogleAddress from '../google-address'

const handleAddressError = (err) => {
  const { message, type } = err
  if (type === 'validation') {
    const { address } = message
    const fields = Object.keys(address)
    return `Please complete a full address '${fields.join(', ')}' ${fields.length > 1 ? 'are' : 'is'} missing`
  }
  return message
}

export function ConfirmAddress({ className }) {
  const address = useSelector(selectFormattedUserAddress)
  const [addressError, setAddressError] = useState('')
  const dispatch = useDispatch()

  const onSelectAddress = (addressPayload) => {
    dispatch(setUserAddress(addressPayload))
    dispatch(
      updateClaim({ section: CONFIRM_ADDRESS_PATH, preventPopupError: true })
    )
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        const message = handleAddressError(err)
        setAddressError(message)
      })
  }

  return (
    <div className={c('confirm-address', className)}>
      <label className="form-label small soft">STREET ADDRESS</label>
      <GoogleAddress value={address} onSelectAddress={onSelectAddress} />
      {!isEmpty(addressError) && (
        <div className="error-text">{addressError}</div>
      )}
    </div>
  )
}
export default ConfirmAddress

ConfirmAddress.propTypes = {
  className: PropTypes.string,
}

ConfirmAddress.defaultProps = {
  className: '',
}
