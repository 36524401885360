import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { ITEM_OPERABILITIES } from '../constants'

export const getItemOperabilities = createAsyncThunk(
  'itemOperabilities/getItemOperabilities',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(`${ITEM_OPERABILITIES}`)
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error on list currencies' })
      )
    }
  }
)

export const initialState = {
  itemOperabilities: [],
}
export const itemOperabilitiesStore = createSlice({
  name: 'itemOperabilities',
  initialState,
  reducers: {
    addItemOperabilities: (state, action) => {
      const { payload } = action
      state.itemOperabilities = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItemOperabilities.fulfilled, (state, action) => {
      const { payload } = action
      state.itemOperabilities = payload
    })
  },
})

export const { addItemOperabilities } = itemOperabilitiesStore.actions

export default itemOperabilitiesStore.reducer
