import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import StepContent from './step'
import IconCloseV2 from '../icons/icon-close-v2'
import {
  WHITE_LABEL_BRAND_IMAGE_URL,
  WHITE_LABEL_BRAND_NAME,
} from '../../constants'

export function Walkthrough({ className, onClose, claimId, model }) {
  const [current, setCurrent] = useState(0)

  const onNext = () => {
    setCurrent(current + 1)
  }
  const onPrev = () => {
    setCurrent(current - 1)
  }
  const onSkip = () => {
    onClose()
  }

  return (
    <div className={c('walkthrough', className)}>
      <button onClick={onSkip} type="button" className="walkthrough_close">
        <IconCloseV2 />
      </button>
      <div className="walkthrough_heading">
        <div className="walkthrough_title">
          Claim #{claimId} for your {model} has just been submitted to our
          system!
        </div>
        <div className="walkthrough_desc">
          The following guide will now walk you through each step to help you
          get a general overview of how the claim process works
        </div>
      </div>
      <div className="walkthrough_steps">
        <div
          className={c('walkthrough_step step-0', { active: current === 0 })}
        >
          <StepContent
            image="/images/steps/step0.png"
            title={`Claim #${claimId} for your ${model} has just been submitted to our system!`}
            content="The following guide will now walk you through each step to help you get a general overview of how the claim process works"
          />
        </div>
        <div
          className={c('walkthrough_step step-1', { active: current === 1 })}
        >
          <StepContent
            step="1"
            image="/images/steps/step1.png"
            title="Complete Your Pending Tasks"
            content="You will be prompted to provide additional information and materials for your claim in a series of “Tasks”. The tasks that you complete will be automatically moved to the completed section towards the bottom."
          />
        </div>
        <div
          className={c('walkthrough_step step-2', { active: current === 2 })}
        >
          <StepContent
            step="2"
            image="/images/steps/step2.png"
            title="Wait, While We Review Your Completed Tasks"
            content={`As you complete each task, our team will review your submitted information so that we can properly resolve a repair or resolve for your ${model}. If you need assistance at all along the way, don’t hesitate to reach out to our support!`}
          />
        </div>
        <div
          className={c('walkthrough_step step-3', { active: current === 3 })}
        >
          <StepContent
            step="3"
            image="/images/steps/step3.png"
            title="Get Your Device Repaired or Replaced"
            content="Once done with the review process, our team will give you further instructions on how to get your device be repaired or replaced."
          />
        </div>
      </div>
      <div className="walkthrough_footer">
        <div className="walkthrough_akko">
          <img
            className="walkthrough_akko-logo"
            src={WHITE_LABEL_BRAND_IMAGE_URL}
            alt=""
          />
          <div className="walkthrough_akko-message">
            <div className="walkthrough_akko-message-title">Have No Fear!</div>
            <div className="walkthrough_akko-message-desc">
              {WHITE_LABEL_BRAND_NAME} will get you back on track ASAP.
            </div>
          </div>
        </div>
        <div className="walkthrough_nav">
          <button onClick={onSkip} type="button" className="btn btn-primary">
            Continue to Claims
          </button>
        </div>
        <div className="walkthrough_nav-mobile">
          <button
            onClick={() => (current < 3 ? onNext() : onSkip())}
            type="button"
            className="btn btn-primary btn--fw"
          >
            {current < 3 ? 'Continue' : 'Continue to Claims'}
          </button>
          {current > 0 && (
            <button
              onClick={onPrev}
              type="button"
              className="btn btn-outline-primary btn--fw"
            >
              Previous
            </button>
          )}
          {current === 0 && (
            <button
              onClick={onSkip}
              type="button"
              className="btn btn-outline-primary btn--fw"
            >
              Skip for Now
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default Walkthrough

Walkthrough.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  claimId: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
}

Walkthrough.defaultProps = {
  className: '',
  onClose: () => {},
}
