import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { IconBold } from '../icons/icon-bold'
import { IconUnderline } from '../icons/icon-underline'
import { IconItalic } from '../icons/icon-italic'

export function Toolbar({ className, onBold, onItalic, onUnderline }) {
  return (
    <div className={c('toolbar', className)}>
      <button type="button" title="bold" onClick={onBold}>
        <IconBold />
      </button>
      <button type="button" title="italic" onClick={onItalic}>
        <IconItalic />
      </button>
      <button type="button" title="underline" onClick={onUnderline}>
        <IconUnderline />
      </button>
    </div>
  )
}

export default Toolbar

Toolbar.propTypes = {
  className: PropTypes.string,
  onBold: PropTypes.func,
  onItalic: PropTypes.func,
  onUnderline: PropTypes.func,
}

Toolbar.defaultProps = {
  className: '',
  onBold: () => {},
  onItalic: () => {},
  onUnderline: () => {},
}
