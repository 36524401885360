import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Walkthrough from '.'

export function WalkthroughModal({ className, onClose, claimId, model }) {
  return (
    <div className={c('evidence', className)}>
      <div className="modal show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <Walkthrough claimId={claimId} model={model} onClose={onClose} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  )
}
export default WalkthroughModal

WalkthroughModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  claimId: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
}
WalkthroughModal.defaultProps = {
  className: '',
  onClose: () => {},
}
