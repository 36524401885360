import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_FEATURE_FLAGS } from '../constants'

export const getFeatureFlags = createAsyncThunk(
  'featureflags/getFeatureFlags',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const response = await axios.get(
        `${GET_FEATURE_FLAGS}?features=quipt,claim_limits`
      )
      return response.data.data
    } catch (error) {
      rejectWithValue(error)
    }
  }
)

export const initialState = {
  featureFlags: [],
}
export const featureFlagStore = createSlice({
  name: 'featureflags',
  initialState,
  reducers: {
    addFeatureFlags: (state, action) => {
      state.featureFlags = action.payload.featureFlags
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatureFlags.pending, (state, action) => {})
    builder.addCase(getFeatureFlags.fulfilled, (state, action) => {
      const { payload } = action
      state.featureFlags = payload
    })
    builder.addCase(getFeatureFlags.rejected, (state, action) => {})
  },
})

export const { addFeatureFlags } = featureFlagStore.actions

export default featureFlagStore.reducer
