import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AkkoLogo from '../logo/index'
import IconArrowLeft from '../icons/icon-arrow-left'
import { selectAppCurrentSteep } from '../../selectors/app'
import { NEED_HELP_TXT, CONTACT_US_TXT } from '../../i18n'
import { selectUserToken } from '../../selectors/user'

export function Header() {
  const currentPage = useSelector(selectAppCurrentSteep)
  const token = useSelector(selectUserToken)
  const { t } = useTranslation()
  const onLogOut = () => {
    localStorage.removeItem('token')
    location.href = '/'
  }
  const onContactUs = () => {
    window.Intercom('show')
  }

  return (
    <div className="container-fluid d-flex header">
      <div className="header__icon-back d-lg-none me-3">
        <Link to={currentPage || '/'}>
          <IconArrowLeft />
        </Link>
      </div>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between">
          <div className="header__logo">
            <Link to="/" title="go to home">
              <AkkoLogo />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-none d-lg-block mx-4">
              {token && (
                <Link className="primary tdn" onClick={onLogOut} to="#">
                  Logout
                </Link>
              )}
            </div>
            <div className="d-none d-lg-block mx-4">{t(NEED_HELP_TXT)}</div>
            <div>
              <button
                type="button"
                onClick={onContactUs}
                className="btn btn-outline-primary btn-sm"
              >
                <span className="d-none d-lg-block">{t(CONTACT_US_TXT)}</span>
                <span className="d-lg-none">{t(NEED_HELP_TXT)}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header

Header.propTypes = {}

Header.defaultProps = {}
