import React, { useState } from 'react'
import c from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'
import { updateDynamicField } from '../../reducers/claim'
import Spinner from '../spinner'
import GoogleAddress from '../google-address'
import { selectUserAddressString } from '../../selectors/user'

export function ShipmentAddress({
  className,
  label,
  instruction,
  claimId,
  disabled,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
}) {
  const [loading] = useState(false)
  const userAddress = useSelector(selectUserAddressString)
  const dispatch = useDispatch()

  const onSelectAddress = (address) => {
    onChange({ fieldId })
    const fieldData = {
      claim_id: claimId,
      claim_device_id: claimDeviceId,
      device_id: deviceId,
      field_id: fieldId,
      handler: 'shipment_address',
      street: `${address.street_number} ${address.street}`,
      city: address.city,
      state: address.state,
      zip: address.zip,
    }
    dispatch(updateDynamicField(fieldData))
  }

  return (
    <div className={c('shipping-label', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      {loading && <Spinner />}
      <GoogleAddress
        disabled={disabled}
        value={userAddress}
        onSelectAddress={onSelectAddress}
      />
    </div>
  )
}
export default ShipmentAddress

ShipmentAddress.propTypes = {
  ...propTypes,
}

ShipmentAddress.defaultProps = {
  ...defaultProps,
}
