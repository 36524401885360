import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { ITEM_CATEGORIES } from '../constants'

export const getItemCategories = createAsyncThunk(
  'itemCategories/getItemCategories',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(ITEM_CATEGORIES)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on user Devices' }))
    }
  }
)

export const initialState = {
  list: [],
}
export const itemCategoriesStore = createSlice({
  name: 'itemCategories',
  initialState,
  reducers: {
    addItemCategory: (state, action) => {
      state.list.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItemCategories.pending, (state, action) => {})
    builder.addCase(getItemCategories.fulfilled, (state, action) => {
      const { payload } = action
      state.list = payload
    })
    builder.addCase(getItemCategories.rejected, (state, action) => {})
  },
})

export const { addItemCategory } = itemCategoriesStore.actions

export default itemCategoriesStore.reducer
