import React from 'react'

export function IconCopy() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22222 8.77778H2.11111C1.49746 8.77778 1 8.28032 1 7.66667V2.11111C1 1.49746 1.49746 1 2.11111 1H7.66667C8.28032 1 8.77778 1.49746 8.77778 2.11111V3.22222M4.33333 11H9.88889C10.5025 11 11 10.5025 11 9.88889V4.33333C11 3.71968 10.5025 3.22222 9.88889 3.22222H4.33333C3.71968 3.22222 3.22222 3.71968 3.22222 4.33333V9.88889C3.22222 10.5025 3.71968 11 4.33333 11Z"
        stroke="#757D8A"
      />
    </svg>
  )
}
export default IconCopy

IconCopy.propTypes = {}

IconCopy.defaultProps = {}
