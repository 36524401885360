import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import { propTypes, defaultProps } from './props'
import { updateDynamicField } from '../../reducers/claim'

export function Checkbox({
  className,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  label,
  prefill,
  instruction,
  onChange,
  field_state: fieldState,
  ...props
}) {
  const [checked, setChecked] = useState(fieldState === 'DONE')
  const dispatch = useDispatch()
  const onCheck = () => {
    const newCheckedState = !checked
    setChecked(newCheckedState)
    onChange({ fieldId, newState: newCheckedState ? 'DONE' : 'TODO' })
    const fieldData = {
      claim_id: claimId,
      claim_device_id: claimDeviceId,
      device_id: deviceId,
      field_id: fieldId,
      handler: 'checkbox',
      checked: newCheckedState,
    }
    dispatch(updateDynamicField(fieldData))
  }
  return (
    <div className={c('checkbox', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div className="form-check">
        <input
          className="form-check-input"
          name="agreement"
          type="checkbox"
          id="agreement"
          checked={checked}
          onChange={onCheck}
        />
        <label className="form-check-label" htmlFor="agreement">
          {prefill}
        </label>
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default Checkbox

Checkbox.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

Checkbox.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
