import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import Select from 'react-select'

export function ItemMake({
  className,
  itemId,
  options,
  onChange,
  disabled,
  current,
  ...props
}) {
  const [itenMakeValue, setItemMakeValue] = useState(current.label)
  const [currentOption, setCurrentOption] = useState(
    isEmpty(current.value) ? null : current
  )

  const onChangeInput = (e) => {
    setItemMakeValue(e.target.value)
    onChange({ value: null, label: itenMakeValue })
  }

  const onChangeSelector = ({ value, label }) => {
    setCurrentOption({ value, label })
    onChange({ value, label })
  }
  return (
    <div className={c('item-make-input', className)} {...props}>
      {options.length <= 0 ? (
        <input
          disabled={disabled}
          type="text"
          className="form-control"
          onChange={onChangeInput}
          value={itenMakeValue}
        />
      ) : (
        <Select
          isDisabled={disabled}
          className="multiselect"
          placeholder="Select Item Brand"
          options={options.map((o) => ({ value: o.id, label: o.name }))}
          isSearchable={false}
          classNamePrefix="multiselect"
          onChange={onChangeSelector}
          value={currentOption}
        />
      )}
    </div>
  )
}
export default ItemMake

ItemMake.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
  current: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

ItemMake.defaultProps = {
  className: '',
  itemId: Date.now(),
  current: {},
  options: [],
  onChange: () => {},
  disabled: false,
}
