import React from 'react'

export function IconChevronDown() {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4 4L7 1"
        stroke="#8990A2"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default IconChevronDown

IconChevronDown.propTypes = {}

IconChevronDown.defaultProps = {}
