import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function StatusLabel({
  className,
  children,
  new_,
  open,
  closed,
  draft,
  success,
  progress,
  pending,
  ...props
}) {
  return (
    <span
      className={c('status-label', className, {
        new: new_,
        open,
        closed,
        draft,
        success,
        inprogress: progress,
        pending,
      })}
      {...props}
    >
      {children.toLowerCase()}
    </span>
  )
}
export default StatusLabel

StatusLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  new_: PropTypes.bool,
  closed: PropTypes.bool,
  draft: PropTypes.bool,
  success: PropTypes.bool,
  progress: PropTypes.bool,
  pending: PropTypes.bool,
}

StatusLabel.defaultProps = {
  className: '',
  children: null,
  open: false,
  new_: false,
  closed: false,
  draft: false,
  success: false,
  progress: false,
  pending: false,
}
