import React from 'react'

export function Exs() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.304026 0.305349C-0.0620905 0.671465 -0.0620905 1.26506 0.304026 1.63117L4.28223 5.60938L0.304026 9.58758C-0.0620905 9.95369 -0.0620905 10.5473 0.304026 10.9134C0.670142 11.2795 1.26374 11.2795 1.62985 10.9134L5.60805 6.9352L9.5848 10.912C9.95092 11.2781 10.5445 11.2781 10.9106 10.912C11.2767 10.5458 11.2767 9.95224 10.9106 9.58612L6.93388 5.60938L10.9106 1.63263C11.2767 1.26651 11.2767 0.672916 10.9106 0.306799C10.5445 -0.059317 9.95092 -0.059317 9.5848 0.306799L5.60805 4.28355L1.62985 0.305349C1.26374 -0.0607679 0.670142 -0.0607672 0.304026 0.305349Z"
        fill="#7E7E7E"
      />
    </svg>
  )
}
export default Exs

Exs.propTypes = {}

Exs.defaultProps = {}
