import React from 'react'
import PropTypes from 'prop-types'
import { Link, useMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import c from 'classnames'
import IconArrowBreadcrumb from '../icons/icon-arrow-breadcrumb'
import {
  selectClaimID,
  selectClaimItemAffectedByID,
} from '../../selectors/claim'
import {
  HOME_PATH,
  CLAIM_DETAIL_PATH,
  DEVICE_DETAIL_PATH,
  WHITE_LABEL_BRAND_NAME,
} from '../../constants'

export function Breadcrumb({ className, ...props }) {
  const claimID = useSelector(selectClaimID)
  const isDeviceView = useMatch(
    DEVICE_DETAIL_PATH(':claimId', ':claimDeviceId')
  )
  const params = useParams()
  const { claimDeviceId } = params
  const deviceDetail = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )
  return (
    <div className={c('breadcrumbs', className)} {...props}>
      <Link to={HOME_PATH}>
        <span>{WHITE_LABEL_BRAND_NAME}</span>
      </Link>
      &nbsp;&nbsp;
      <IconArrowBreadcrumb />
      &nbsp;&nbsp;
      <Link
        to={`/${CLAIM_DETAIL_PATH}/${claimID}`}
        className={c('', { active: isDeviceView === null })}
      >
        <span>Claim #{claimID}</span>
      </Link>
      {isDeviceView && deviceDetail ? (
        <>
          &nbsp;&nbsp;
          <IconArrowBreadcrumb />
          &nbsp;&nbsp;
          <span className="active">{deviceDetail.model}</span>
        </>
      ) : null}
    </div>
  )
}
export default Breadcrumb

Breadcrumb.propTypes = {
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  className: '',
}
