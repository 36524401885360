import React from 'react'

export function IconChevronRedRight() {
  return (
    <svg
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L5 4.5L1 1"
        stroke="#F3103D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default IconChevronRedRight

IconChevronRedRight.propTypes = {}

IconChevronRedRight.defaultProps = {}
