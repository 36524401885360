import React from 'react'

export function IconPhone() {
  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.73975"
        y="1.14062"
        width="8.81633"
        height="16.751"
        rx="1"
        fill="white"
      />
      <path
        d="M3.0615 18.7721H9.23293C10.4487 18.7721 11.437 17.7829 11.437 16.568V2.46189C11.437 1.247 10.4487 0.257812 9.23293 0.257812H3.0615C1.84573 0.257812 0.857422 1.247 0.857422 2.46189V16.568C0.857422 17.7829 1.84573 18.7721 3.0615 18.7721ZM1.73905 2.46189C1.73905 1.73278 2.33239 1.13945 3.0615 1.13945H9.23293C9.96204 1.13945 10.5554 1.73278 10.5554 2.46189V16.568C10.5554 17.2971 9.96204 17.8905 9.23293 17.8905H3.0615C2.33239 17.8905 1.73905 17.2971 1.73905 16.568V2.46189Z"
        fill="black"
      />
      <path
        d="M7.02879 16.1289H5.26552C5.02219 16.1289 4.82471 16.3264 4.82471 16.5697C4.82471 16.8131 5.02219 17.0105 5.26552 17.0105H7.02879C7.27212 17.0105 7.4696 16.8131 7.4696 16.5697C7.4696 16.3264 7.27212 16.1289 7.02879 16.1289Z"
        fill="black"
      />
      <path
        d="M3.50234 2.90116H8.79214C9.03547 2.90116 9.23295 2.70368 9.23295 2.46035C9.23295 2.21702 9.03547 2.01953 8.79214 2.01953H3.50234C3.25901 2.01953 3.06152 2.21702 3.06152 2.46035C3.06152 2.70368 3.25901 2.90116 3.50234 2.90116Z"
        fill="black"
      />
    </svg>
  )
}
export default IconPhone

IconPhone.propTypes = {}

IconPhone.defaultProps = {}
