import React, { useState, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { propTypes, defaultProps } from './props'
import { selectClaimItemAffectedByID } from '../../selectors/claim'
import {
  updateDynamicField,
  setMailedDevice,
  setMailedDateDevice,
} from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'

export function MailedDevice({
  className,
  label,
  instruction,
  deviceId,
  claimId,
  claimDeviceId,
  id: fieldId,
  onChange,
  prefill,
  disabled,
}) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )

  const [startDate, setStartDate] = useState(device.mailedDeviceDate)
  const [mailedDevice, setDeviceHaveMailed] = useState(device.mailedDevice)

  const update = useCallback(
    debounce((newState) => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'mailed_device',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        setLoading(false)
        onChange({ fieldId, newState: newState ? 'DONE' : 'TODO' })
      })
    }, DELAY_TIMING),
    []
  )

  const onCheck = () => {
    const newMailedState = !mailedDevice
    const data = {
      claimDeviceId,
      mailed: newMailedState,
    }
    dispatch(setMailedDevice(data))
    setDeviceHaveMailed(newMailedState)
    update(newMailedState)
  }
  const onChangeDate = (date) => {
    setStartDate(date)
    dispatch(setMailedDateDevice({ date, claimDeviceId }))
    update(true)
  }

  return (
    <div className={c('mailed-device', className)}>
      <div className="form-check mb-3 mt-2">
        <input
          checked={mailedDevice}
          onChange={onCheck}
          className="form-check-input"
          type="checkbox"
          id="mailed-check-box"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="mailed-check-box">
          {prefill}
        </label>
      </div>
      <div className="mb-1">
        {!isEmpty(label) && (
          <div className="form-label xsmall soft">{label}</div>
        )}
        <DatePicker
          className="form-control describe-incident-form__date-loss"
          selected={startDate}
          maxDate={new Date()}
          onChange={onChangeDate}
        />
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default MailedDevice

MailedDevice.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.bool,
}

MailedDevice.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
