import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { useSelector, useDispatch } from 'react-redux'
import c from 'classnames'
import Label from '../label'
import { DELAY_TIMING } from '../../constants'
import { propTypes, defaultProps } from './props'
import { updateDynamicField, setNotRepairable } from '../../reducers/claim'
import { selectNotRepairableByDevice } from '../../selectors/claim'
import Spinner from '../spinner'

export function Repairable({
  className,
  onChange,
  prefill,
  instruction,
  label,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  disabled,
  ...props
}) {
  const [loading, setLoading] = useState(false)
  const isNotRepairable = useSelector((state) =>
    selectNotRepairableByDevice(state, claimDeviceId)
  )
  const [cboxRepairable, setCboxRepairable] = useState(isNotRepairable)
  const dispatch = useDispatch()

  const update = useCallback(
    debounce(() => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'repairable',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        onChange({ fieldId })
        setLoading(false)
      })
    }, DELAY_TIMING),
    []
  )

  const onChangeRepairable = () => {
    const localCboxState = !cboxRepairable
    setCboxRepairable(localCboxState)
    dispatch(
      setNotRepairable({
        claimDeviceId,
        notRepairable: localCboxState,
      })
    )
    update()
  }

  return (
    <div className={c('repairable', className)}>
      <div className="form-check mb-1">
        <input
          checked={cboxRepairable}
          onChange={onChangeRepairable}
          className="form-check-input"
          type="checkbox"
          id="repairable-check-box"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="repairable-check-box">
          {label}
        </label>
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      {/* { loading && <Spinner />} */}
    </div>
  )
}
export default Repairable

Repairable.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

Repairable.defaultProps = {
  ...defaultProps,
  className: '',
  disabled: false,
}
