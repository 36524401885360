import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppNotifications } from '../../selectors/app'
import { removeNotification } from '../../reducers/app'

export function Notification({ className, ...props }) {
  const dispatch = useDispatch()
  const container = document.getElementById('modals')
  const notifications = useSelector(selectAppNotifications)
  const onClose = () => {
    dispatch(removeNotification())
  }
  return (
    <div className={c('notification', className)} {...props}>
      {notifications.length > 0 &&
        createPortal(
          <div className={c(`toast-container ${notifications[0].type}`)}>
            <div className="toast align-items-center show">
              <div className="d-flex">
                <div className="toast-body">{notifications[0].message}</div>
                <button
                  type="button"
                  onClick={onClose}
                  className="btn-close me-2 m-auto"
                />
              </div>
            </div>
          </div>,
          container
        )}
    </div>
  )
}
export default Notification

Notification.propTypes = {
  className: PropTypes.string,
}

Notification.defaultProps = {
  className: '',
}
