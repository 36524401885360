import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectBranding } from '../../selectors/app'

export function LogoAkko({ className, ...props }) {
  const branding = useSelector(selectBranding)
  const logoPath = branding.logoUrl
  return (
    <img
      alt="akko"
      className={`logo-akko ${className}`}
      {...props}
      src={logoPath}
    />
  )
}
export default LogoAkko

LogoAkko.propTypes = {
  className: PropTypes.string,
}

LogoAkko.defaultProps = {
  className: '',
}
