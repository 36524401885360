import React from 'react'

export function IconChat() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32822 4.33984H5.67195C5.38163 4.33984 5.11343 4.49547 4.96819 4.74805C4.8231 5.00065 4.8231 5.31191 4.96819 5.56448C5.11342 5.81707 5.38163 5.97269 5.67195 5.97269H9.32822C9.61854 5.97269 9.88674 5.81706 10.032 5.56448C10.1771 5.31189 10.1771 5.00062 10.032 4.74805C9.88674 4.49546 9.61854 4.33984 9.32822 4.33984Z"
        fill="#757D8A"
        stroke="#E8E8E8"
        strokeWidth="0.4"
      />
      <path
        d="M9.32822 7.60547H5.67195C5.38163 7.60547 5.11343 7.76109 4.96819 8.01368C4.8231 8.26641 4.8231 8.57768 4.96819 8.83025C5.11342 9.08284 5.38163 9.23846 5.67195 9.23846H9.32822C9.61854 9.23846 9.88674 9.08283 10.032 8.83025C10.1771 8.57766 10.1771 8.26639 10.032 8.01368C9.88674 7.76109 9.61854 7.60547 9.32822 7.60547Z"
        fill="#757D8A"
        stroke="#E8E8E8"
        strokeWidth="0.4"
      />
      <path
        d="M7.50041 0.664077C3.91723 0.664077 1.00038 3.41155 1.00038 6.7878C1.00293 7.93443 1.34213 9.055 1.97535 10.0088C1.85208 10.276 1.70883 10.5331 1.54675 10.7784L1.27457 11.1866C1.05523 11.516 0.962423 11.9144 1.01386 12.3073C1.06515 12.7003 1.25685 13.0611 1.55341 13.3226C1.84997 13.584 2.23098 13.7281 2.62544 13.7279C3.78007 13.7292 4.9072 13.3728 5.85311 12.7073C6.39194 12.8423 6.9451 12.9108 7.50039 12.9115C11.0836 12.9115 14.0004 10.164 14.0004 6.78778C14.0004 3.41154 11.0836 0.664062 7.50039 0.664062L7.50041 0.664077ZM7.50041 11.2785C7.08157 11.279 6.66429 11.229 6.25724 11.1294C5.7857 11.0191 5.28963 11.1148 4.89234 11.3929C4.22442 11.8513 3.43423 12.0961 2.62546 12.095L2.89764 11.6868C3.14702 11.309 3.36182 10.9093 3.53948 10.4927C3.71575 10.0649 3.65879 9.57591 3.38915 9.20057C2.89309 8.49463 2.62631 7.652 2.62544 6.78786C2.62544 4.30779 4.809 2.29714 7.50046 2.29714C10.1919 2.29714 12.3755 4.30776 12.3755 6.78786C12.3755 9.26793 10.1919 11.2786 7.50046 11.2786L7.50041 11.2785Z"
        fill="#757D8A"
        stroke="#E8E8E8"
        strokeWidth="0.4"
      />
    </svg>
  )
}
export default IconChat

IconChat.propTypes = {}

IconChat.defaultProps = {}
