import React from 'react'

export function IconWarning() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.52331" cy="5.52331" r="5.52331" fill="#2C90E7" />
      <path
        d="M5.5671 6.39509C6.03853 6.39509 6.42425 6.00938 6.42425 5.53795V2.96652C6.42425 2.49509 6.03853 2.10938 5.5671 2.10938C5.09568 2.10938 4.70996 2.49509 4.70996 2.96652V5.53795C4.70996 6.00938 5.09568 6.39509 5.5671 6.39509Z"
        fill="white"
      />
      <path
        d="M5.99568 8.96652C6.23139 8.96652 6.42425 8.77366 6.42425 8.53795V7.6808C6.42425 7.44509 6.23139 7.25223 5.99568 7.25223H5.13853C4.90282 7.25223 4.70996 7.44509 4.70996 7.6808V8.53795C4.70996 8.77366 4.90282 8.96652 5.13853 8.96652H5.99568Z"
        fill="white"
      />
    </svg>
  )
}
export default IconWarning

IconWarning.propTypes = {}

IconWarning.defaultProps = {}
