import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconArrowLeft from '../icons/icon-arrow-left'
import {
  NAVIGATION_PATH,
  NAVIGATION_SEQUENCE,
  NAVIGATION_SEQUENCE_MOBILE_TITLES,
  NAVIGATION_SEQUENCE_MOBILE_STEP_CLASS,
} from '../../constants'
import { selectClaimID } from '../../selectors/claim'

export function NavigationHeaderMobile({ className, children }) {
  const location = useLocation()
  const claimID = useSelector(selectClaimID)
  const navigationIndex = NAVIGATION_SEQUENCE.findIndex(
    (nav) =>
      `/${nav}/${claimID}` === location.pathname ||
      `/${nav}/` === location.pathname
  )
  const pageClaimStep = navigationIndex >= 0 ? navigationIndex + 1 : 0
  const pageClaimTitle =
    navigationIndex >= 0
      ? NAVIGATION_SEQUENCE_MOBILE_TITLES[navigationIndex]
      : ''
  const pageClaimDividerClass =
    navigationIndex >= 0
      ? NAVIGATION_SEQUENCE_MOBILE_STEP_CLASS[navigationIndex]
      : ''

  return (
    <div className={c('navigation-header-mobile', className)}>
      <div className="container-fluid ">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="navigation-header-mobile__title">
              {pageClaimTitle}
            </div>
            {children && children}
          </div>
          <div className="navigation-header-mobile__step_number">
            Step {pageClaimStep} of 5
          </div>
          <Link
            to={`/${NAVIGATION_PATH}`}
            className="navigation-header-mobile__arrow_back me-1"
          >
            <IconArrowLeft />
          </Link>
        </div>
      </div>
      <div>
        <hr className={pageClaimDividerClass} />
      </div>
    </div>
  )
}
export default NavigationHeaderMobile

NavigationHeaderMobile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

NavigationHeaderMobile.defaultProps = {
  children: undefined,
  className: '',
}
