import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function IconArrowSmRight({ className }) {
  return (
    <svg
      className={c('icon', className)}
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L4.5 4.5L1 8" stroke="#A3A3A3" strokeWidth="1.5" />
    </svg>
  )
}
export default IconArrowSmRight

IconArrowSmRight.propTypes = {
  className: PropTypes.string,
}

IconArrowSmRight.defaultProps = {
  className: '',
}
