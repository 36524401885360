import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import Header from '../components/header'
import Banner from '../components/banner'
import SideBarMenu from '../components/side-bar-menu'
import { selectAppCurrentSteep, selectBrand } from '../selectors/app'
import { AFFECTED_ITEMS_PATH } from '../constants'
import {
  COVERED_TXT,
  GET_STARTED_TXT,
  START_FILING_TXT,
  CONTINUE_FILING_TXT,
} from '../i18n'

export function NavigationMobile() {
  const { t } = useTranslation()
  const currentPage = useSelector(selectAppCurrentSteep)
  const brandName = useSelector(selectBrand)
  return (
    <div className={c('page', brandName)}>
      <Header />
      <Banner className="mb-3" />
      <div className="container-fluid">
        <div className="main main--mobile">
          <div className="titular">
            <div className="titular__main">{t(COVERED_TXT)}</div>
            <div className="titular__secondary">{t(GET_STARTED_TXT)}</div>
          </div>
          <SideBarMenu />
          {isEmpty(currentPage) ? (
            <Link
              to={`/${AFFECTED_ITEMS_PATH}`}
              className="btn btn-primary btn--fw mb-4"
            >
              {t(START_FILING_TXT)}
            </Link>
          ) : (
            <Link
              to={`${currentPage}`}
              className="btn btn-primary btn--fw mb-4"
            >
              {t(CONTINUE_FILING_TXT)}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
export default NavigationMobile
