import React from 'react'

export function Tablet() {
  return (
    <svg
      width="33"
      height="45"
      viewBox="0 0 33 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58434 12.4665C4.77067 12.4665 4.94984 12.3948 5.09317 12.2587L7.95984 9.392C8.23934 9.1125 8.23934 8.661 7.95984 8.3815C7.68034 8.102 7.22884 8.102 6.94934 8.3815L4.08267 11.2482C3.80317 11.5277 3.80317 11.9792 4.08267 12.2587C4.21884 12.3948 4.39801 12.4665 4.58434 12.4665Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M4.58434 16.0488C4.77067 16.0488 4.94984 15.9771 5.09317 15.841L6.5265 14.4076C6.80601 14.1281 6.80601 13.6766 6.5265 13.3971C6.247 13.1176 5.79551 13.1176 5.51601 13.3971L4.08267 14.8305C3.80317 15.11 3.80317 15.5615 4.08267 15.841C4.21884 15.9771 4.39801 16.0488 4.58434 16.0488Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M6.94026 11.9567C6.81126 12.0929 6.73242 12.2792 6.73242 12.4655C6.73242 12.6519 6.81126 12.8382 6.94026 12.9744C7.07642 13.1034 7.26276 13.1822 7.44909 13.1822C7.63542 13.1822 7.82176 13.1034 7.95792 12.9744C8.08692 12.8382 8.16576 12.6519 8.16576 12.4655C8.16576 12.2792 8.08692 12.0929 7.95792 11.9567C7.69276 11.6915 7.21259 11.6915 6.94026 11.9567Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M8.37475 11.542C8.51809 11.6782 8.69725 11.7498 8.88359 11.7498C9.06992 11.7498 9.24909 11.6782 9.39242 11.542L11.5424 9.392C11.8219 9.1125 11.8219 8.661 11.5424 8.3815C11.2629 8.102 10.8114 8.102 10.5319 8.3815L8.38192 10.5315C8.09525 10.8038 8.09525 11.2625 8.37475 11.542Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M10.5249 11.9561L4.07486 18.4061C3.79536 18.6856 3.79536 19.1371 4.07486 19.4166C4.21819 19.5599 4.39736 19.6316 4.58369 19.6316C4.77003 19.6316 4.94919 19.5599 5.09253 19.4237L11.5425 12.9737C11.822 12.6942 11.822 12.2427 11.5425 11.9632C11.263 11.6837 10.8044 11.6766 10.5249 11.9561Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M11.9578 10.527C11.8288 10.6632 11.75 10.8495 11.75 11.0358C11.75 11.2222 11.8288 11.4085 11.9578 11.5447C12.094 11.6737 12.2803 11.7525 12.4667 11.7525C12.653 11.7525 12.8393 11.6737 12.9755 11.5447C13.1045 11.4085 13.1833 11.2222 13.1833 11.0358C13.1833 10.8495 13.1045 10.6632 12.9755 10.527C12.7032 10.2618 12.223 10.2618 11.9578 10.527Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M14.1079 8.37795L13.3913 9.09462C13.1118 9.37412 13.1118 9.82562 13.3913 10.1051C13.5346 10.2485 13.7138 10.3201 13.9001 10.3201C14.0864 10.3201 14.2656 10.2485 14.4089 10.1123L15.1256 9.39562C15.4051 9.11612 15.4051 8.66462 15.1256 8.38512C14.8461 8.10562 14.3874 8.09845 14.1079 8.37795Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M18.2009 3.14844H17.4842C17.0901 3.14844 16.7676 3.47094 16.7676 3.8651C16.7676 4.25927 17.0901 4.58177 17.4842 4.58177H18.2009C18.5951 4.58177 18.9176 4.25927 18.9176 3.8651C18.9176 3.47094 18.5951 3.14844 18.2009 3.14844Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
      <path
        d="M29.7383 1H3.795C2.25417 1 1 2.27567 1 3.838V5.3V38.2667V41.162C1 42.7243 2.25417 44 3.795 44H29.7383C31.2792 44 32.5333 42.7243 32.5333 41.162V38.2667V5.3V3.838C32.5333 2.27567 31.2792 1 29.7383 1ZM17.4833 41.85C16.695 41.85 16.05 41.205 16.05 40.4167C16.05 39.6283 16.695 38.9833 17.4833 38.9833C18.2717 38.9833 18.9167 39.6283 18.9167 40.4167C18.9167 41.205 18.2717 41.85 17.4833 41.85ZM2.43333 6.73333H31.1V36.8333H2.43333V6.73333ZM2.43333 41.162V38.2667H15.6057C15.0037 38.7898 14.6167 39.5567 14.6167 40.4167C14.6167 41.2767 15.0037 42.0435 15.6057 42.5667H3.795C3.0425 42.5667 2.43333 41.936 2.43333 41.162ZM31.1 41.162C31.1 41.936 30.4908 42.5667 29.7383 42.5667H19.361C19.963 42.0435 20.35 41.2767 20.35 40.4167C20.35 39.5567 19.963 38.7898 19.361 38.2667H31.1V41.162ZM2.43333 5.3V3.838C2.43333 3.064 3.0425 2.43333 3.795 2.43333H29.7383C30.4908 2.43333 31.1 3.064 31.1 3.838V5.3H2.43333Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Tablet
