import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import c from 'classnames'
import PageHeader from '../components/page-header'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import IconArrowBreadcrumb from '../components/icons/icon-arrow-breadcrumb'
import { ADD_ITEMS_PATH } from '../constants'

export function AddItem() {
  useStoreCurrentSteep()
  const location = useLocation()

  return (
    <div>
      <PageHeader title="Add New Item">
        <div className="breadcrumbs">
          <Link
            className={c('', {
              active: location.pathname === `/${ADD_ITEMS_PATH}/`,
            })}
            to={`/${ADD_ITEMS_PATH}/`}
          >
            <span>Select Item Category</span>
          </Link>
          &nbsp;&nbsp;
          <IconArrowBreadcrumb />
          &nbsp;&nbsp;
          <span
            className={c('', {
              active: /item-details/.test(location.pathname),
            })}
          >
            <span>Add Item Details</span>
          </span>
        </div>
      </PageHeader>
      <Outlet />
    </div>
  )
}
export default AddItem
