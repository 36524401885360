import { createSelector } from '@reduxjs/toolkit'

export const featureFlagStore = (state) => state.featureFlag

export const selectFeatureFlags = createSelector(
  featureFlagStore,
  (featureFlag) => featureFlag.featureFlags
)

export const selectFeatureFlagByName = createSelector(
  [selectFeatureFlags, (_, name) => name],
  (featureFlags, name) => {
    const feature = featureFlags.find((ff) => ff.feature === name)
    return feature ? feature.state : false
  }
)
