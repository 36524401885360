import React from 'react'

export function IconClip() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95323 5.29097L8.21213 6.00442L8.95323 5.29097ZM8.76826 6.8967L8.30484 7.34287L9.04637 8.05675L9.97367 7.16406L6.26489 10.7343C4.83185 12.1138 2.50846 12.1139 1.07465 10.7337C-0.358812 9.35388 -0.357419 7.11544 1.07404 5.73744L4.78282 2.16719L4.78265 2.16736L5.52243 1.45525C6.54717 0.46876 8.20777 0.46783 9.23189 1.45373C10.2558 2.43929 10.2569 4.03624 9.23022 5.02442L5.15332 8.9488C4.53899 9.54024 3.54173 9.53905 2.92863 8.94888C2.3143 8.35753 2.31448 7.3986 2.92872 6.80734L7.00719 2.88123L6.26566 3.59502L7.00719 4.30889L3.67034 7.52113C3.46562 7.71819 3.46562 8.03804 3.67026 8.23501C3.87436 8.43147 4.20768 8.43164 4.41188 8.23501L8.48878 4.31062C9.10531 3.71707 9.1054 2.75941 8.49036 2.16748C7.87611 1.57613 6.87955 1.57665 6.2641 2.16908L1.81571 6.45127C0.793173 7.43555 0.793083 9.03486 1.81633 10.0198C2.84044 11.0057 4.49988 11.0058 5.52354 10.0205L8.76826 6.8967ZM6.26563 3.59512L5.15328 4.66594L5.89482 5.37973L7.00716 4.309L6.26563 3.59512ZM5.33869 4.48747L6.08022 5.20126L5.89482 5.37973L5.15328 4.66594L5.33869 4.48747ZM5.89394 3.95291C6.09918 3.75534 6.43013 3.75357 6.63635 3.95206C6.84116 4.14921 6.84142 4.46854 6.63547 4.66678L6.08022 5.20126L5.33869 4.48747L5.89394 3.95291ZM8.8602 6.80842C9.06545 6.61077 9.3964 6.60899 9.60261 6.80749C9.80742 7.00463 9.80768 7.32397 9.60173 7.52221L9.04648 8.05677L8.30495 7.3429L8.8602 6.80842Z"
        fill="#060920"
      />
    </svg>
  )
}
export default IconClip

IconClip.propTypes = {}

IconClip.defaultProps = {}
