import React from 'react'

export function Microphone() {
  return (
    <svg
      width="25"
      height="37"
      viewBox="0 0 25 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 33.5L7 35.5H18C18.4 35.5 18.1667 34.8333 18 34.5L17 33H14V28L17 27L20 25L22 22L23 18.5L23.5 15H20V7.5L18.5 5L16.5 3L14 2H11.5L8.5 3L6.5 4.5L5 7V15H2L1.5 18L3 22.5L5.5 25.5L8.5 27L11 28V33L9.5 32.5L7.5 33.5Z"
        fill="white"
      />
      <path
        d="M23.2945 14.02H20.7546V9.19C20.7546 8.49 20.684 7.86 20.4724 7.23C19.6258 3.66 16.3804 1 12.5 1C7.98466 1 4.2454 4.64 4.2454 9.19V14.02H1.70552C1.28221 14.02 1 14.3 1 14.72V17.17C1 22.77 5.09202 27.39 10.3834 28.37V32.01H9.53681C7.70245 32.01 6.22086 33.48 6.22086 35.3C6.22086 35.72 6.50307 36 6.92638 36H18.0031C18.4264 36 18.7086 35.72 18.7086 35.3C18.7086 33.48 17.227 32.01 15.3926 32.01H14.6166V28.44C19.9785 27.46 24 22.84 24 17.24V14.79C24 14.37 23.6472 14.02 23.2945 14.02ZM12.5 2.4C15.3926 2.4 17.9325 4.22 18.8497 6.81H14.1933C13.7699 6.81 13.4877 7.09 13.4877 7.51C13.4877 7.93 13.7699 8.21 14.1933 8.21H19.273C19.3436 8.56 19.3436 8.84 19.3436 9.19V10.45H14.1933C13.7699 10.45 13.4877 10.73 13.4877 11.15C13.4877 11.57 13.7699 11.85 14.1933 11.85H19.3436V14.09H14.1933C13.7699 14.09 13.4877 14.37 13.4877 14.79C13.4877 15.21 13.7699 15.49 14.1933 15.49H19.3436V17.24C19.3436 20.95 16.3098 24.03 12.5 24.03C9.60736 24.03 7.06749 22.21 6.15031 19.62H10.8067C11.2301 19.62 11.5123 19.34 11.5123 18.92C11.5123 18.5 11.2301 18.22 10.8067 18.22H5.72699C5.65644 17.87 5.65644 17.59 5.65644 17.24V15.98H10.8067C11.2301 15.98 11.5123 15.7 11.5123 15.28C11.5123 14.86 11.2301 14.58 10.8067 14.58H5.65644V12.34H10.8067C11.2301 12.34 11.5123 12.06 11.5123 11.64C11.5123 11.22 11.2301 10.94 10.8067 10.94H5.65644V9.19C5.65644 5.41 8.76074 2.4 12.5 2.4ZM17.227 34.6H7.77301C8.05521 33.9 8.76074 33.41 9.53681 33.41H15.3926C16.2393 33.41 16.9448 33.9 17.227 34.6ZM13.2055 32.01H11.7945V28.58C12.0061 28.58 12.2178 28.58 12.5 28.58C12.7117 28.58 12.9233 28.58 13.2055 28.58V32.01ZM22.589 17.24C22.589 22.77 18.0736 27.25 12.5 27.25C6.92638 27.25 2.41104 22.77 2.41104 17.24V15.49H4.2454V17.24C4.2454 21.72 7.91411 25.43 12.5 25.43C17.0153 25.43 20.7546 21.79 20.7546 17.24V15.49H22.589V17.24Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Microphone
