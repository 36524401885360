import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Button({
  className,
  children,
  link,
  to,
  onClick,
  off,
  primary,
  secondary,
  outline,
  fw,
  dotted,
  sm,
  small,
  lg,
  disabled,
  ...props
}) {
  let Tag = 'button'
  let customProps = {
    type: 'button',
    onClick,
  }
  if (link) {
    Tag = Link
    customProps = {
      to,
    }
  }
  return (
    <Tag
      className={c('btn', className, {
        'btn-primary': primary && !secondary && !off,
        'btn-outline-primary': primary && off,
        'btn-secondary': secondary && !off,
        'btn-outline-secondary': secondary && off,
        'btn-sm': sm || small,
        'btn-lg': lg,
        'btn--fw': fw,
        'btn--dotted': dotted,
      })}
      disabled={disabled}
      {...customProps}
      {...props}
    >
      {children}
    </Tag>
  )
}
export default Button

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  off: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  fw: PropTypes.bool,
  dotted: PropTypes.bool,
  sm: PropTypes.bool,
  small: PropTypes.bool,
  lg: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.bool,
  to: PropTypes.string,
}

Button.defaultProps = {
  className: '',
  children: '',
  onClick: undefined,
  off: false,
  primary: true,
  secondary: false,
  outline: false,
  fw: false,
  dotted: false,
  sm: false,
  small: false,
  lg: false,
  disabled: false,
  link: false,
  to: '/',
}
