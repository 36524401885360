import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { FieldsFactory } from './fields-factory'
import { updateDynamicFieldContinue, setFieldState } from '../../reducers/claim'
import Spinner from '../spinner'
import { selectClaimDeviceEvidences } from '../../selectors/claim'
import { CLEAR } from '../../constants'

export function DynamicComponent({
  className,
  claimId,
  claimDeviceId,
  deviceId,
  fields,
  disabled,
  completed,
  taskGroupName,
  ...props
}) {
  const [loading, setLoading] = useState(false)
  /** This flag is to avoid showing button `continue` on success */
  const [success, setSuccess] = useState(null)
  const [errorContinue, setErrorContinue] = useState(true)
  const dispatch = useDispatch()

  const onChange = ({ fieldId, newState }) => {
    dispatch(setFieldState({ fieldId, newState: newState || 'DONE' }))
  }

  const onContinue = () => {
    setLoading(true)
    dispatch(updateDynamicFieldContinue({ claimId, claimDeviceId }))
      .unwrap()
      .then((response) => {
        setLoading(false)
        setErrorContinue(response.continue)
        if (response.continue) {
          setSuccess(true)
        }
      })
  }

  const fillablesFields = fields.filter((f) => f.fillable)
  const someRequiredFieldAreInTodoState = () => {
    return fillablesFields.some(
      (f) => f.required === true && f.field_state === 'TODO'
    )
  }

  // When an item in evidences is been processing, the button `continue` should be disabled
  const evidenceFieldType = fields.find((f) => f.type === 'EVIDENCES')
  let evidenceIsProcessing = false
  if (evidenceFieldType) {
    const evidences = useSelector((state) =>
      selectClaimDeviceEvidences(state, {
        claimDeviceId,
        evidenceType: evidenceFieldType.evidence_type_id,
      })
    )
    evidenceIsProcessing = evidences.some((i) => isEmpty(i.processing_status))
  }

  return (
    <div className={c('dynamic-component', className)} {...props}>
      {fields.map((field) => (
        <div
          key={field.id}
          className={c(
            field.type === CLEAR ||
              field.type === 'TEXT' ||
              field.type === 'HYPERLINK'
              ? ''
              : 'p-1 mb-3',
            `field-type-${field.type}`
          )}
        >
          {FieldsFactory[field.type]({
            ...field,
            claimId,
            claimDeviceId,
            deviceId,
            onChange,
            disabled,
            completed,
            taskGroupName,
          })}
        </div>
      ))}
      {!disabled && fillablesFields.length > 0 ? (
        <div className="p-1">
          <div className="mb-1 btn-continue">
            {loading ? (
              <Spinner />
            ) : (
              !success && (
                <button
                  className={c('btn btn-primary', {
                    disabled:
                      someRequiredFieldAreInTodoState() || evidenceIsProcessing,
                  })}
                  disabled={
                    someRequiredFieldAreInTodoState() || evidenceIsProcessing
                  }
                  type="button"
                  onClick={onContinue}
                >
                  Continue
                </button>
              )
            )}
          </div>
        </div>
      ) : null}
      {errorContinue === false ? (
        <div className="text-warning">
          There are fields that need to be completed
        </div>
      ) : null}
    </div>
  )
}
export default DynamicComponent

DynamicComponent.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  claimId: PropTypes.number.isRequired,
  claimDeviceId: PropTypes.number.isRequired,
  deviceId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  completed: PropTypes.bool,
  taskGroupName: PropTypes.string,
}

DynamicComponent.defaultProps = {
  className: '',
  fields: [],
  disabled: false,
  completed: false,
  taskGroupName: '',
}
