import React from 'react'
import IconSmarthphone from './icon-smartphone'
import Image from './icon-image'
import Receip from './icon-receip'
import IconPhoto from './icon-photo'
import IconCamera from './icon-camera'
import IconLocation from './icon-location'
import IconReceipt from './icon-receipt'
import IconReport from './icon-report'
import IconUpload from './icon-upload-v2'
import IconInformation from './icon-information'
import IconMail from './icon-mail'
import IconQR from './icon-qr'

import AmazonFireStick from '../icon-devices/amazon-firesitick'
import AppleControl from '../icon-devices/apple-control'
import Backpack from '../icon-devices/backpack'
import Bicycle from '../icon-devices/bicycle'
import Blender from '../icon-devices/blender'
import Camera from '../icon-devices/camera'
import Cameralens from '../icon-devices/cameralens'
import Coffemaker from '../icon-devices/coffemaker'
import DjEquipment from '../icon-devices/djequipment'
import Golf from '../icon-devices/golf'
import Guitar from '../icon-devices/guitar'
import Headphones from '../icon-devices/headphone'
import Imac from '../icon-devices/imac'
import Jewerly from '../icon-devices/jewerly'
import Laptop from '../icon-devices/laptop'
import Microphone from '../icon-devices/microphone'
import Microwave from '../icon-devices/microwave'
import Piano from '../icon-devices/piano'
import PsControl from '../icon-devices/pscontrol'
import Rollerblade from '../icon-devices/rollerblade'
import Rollerskate from '../icon-devices/rollerskate'
import Scooter from '../icon-devices/scooter'
import Skateboard from '../icon-devices/skateboard'
import Skis from '../icon-devices/skis'
import Smartphone from '../icon-devices/smartphone'
import Smartwatch from '../icon-devices/smartwatch'
import Snowboard from '../icon-devices/snowboard'
import Speaker from '../icon-devices/speaker'
import Surfboard from '../icon-devices/surfboard'
import Tablet from '../icon-devices/tablet'
import Tv from '../icon-devices/tv'
import Unicycle from '../icon-devices/unicycle'
import Watch from '../icon-devices/watch'

export const ICONS = {
  phone: () => <IconSmarthphone />,
  tv: () => <Tv />,
  laptop: () => <Laptop />,
  amazonfirestick: () => <AmazonFireStick />,
  applecontrol: () => <AppleControl />,
  backpack: () => <Backpack />,
  bicycle: () => <Bicycle />,
  blender: () => <Blender />,
  camera: () => <Camera />,
  cameralens: () => <Cameralens />,
  coffemaker: () => <Coffemaker />,
  djequipment: () => <DjEquipment />,
  golf: () => <Golf />,
  guitar: () => <Guitar />,
  headphones: () => <Headphones />,
  imac: () => <Imac />,
  jewerly: () => <Jewerly />,
  microphone: () => <Microphone />,
  Microwave: () => <Microwave />,
  piano: () => <Piano />,
  psControl: () => <PsControl />,
  rollerblade: () => <Rollerblade />,
  rollerskate: () => <Rollerskate />,
  scooter: () => <Scooter />,
  skateboard: () => <Skateboard />,
  skis: () => <Skis />,
  smartphone: () => <Smartphone />,
  smartwatch: () => <Smartwatch />,
  snowboard: () => <Snowboard />,
  speaker: () => <Speaker />,
  surfboard: () => <Surfboard />,
  tablet: () => <Tablet />,
  unicycle: () => <Unicycle />,
  watch: () => <Watch />,
}

export const getIcon = (kind) => {
  return ICONS[kind.toLowerCase()] ? ICONS[kind.toLowerCase()]() : null
}

export const DYNAMIC_COMPONENTS_ICONS = {
  CAMERA: () => <IconCamera />,
  LOCATION: () => <IconLocation />,
  RECEIPT: () => <IconReceipt />,
  REPORT: () => <IconReport />,
  UPLOAD: () => <IconUpload />,
  INFORMATION: () => <IconInformation />,
  MAIL: () => <IconMail />,
  QR: () => <IconQR />,
  IMAGE: () => <Image />,
  VIDEO: () => <IconPhoto />,
  FILE: () => <Receip />,
  PAYMENT: () => <Receip />,
  DEFAULT: () => <IconInformation />,
}
export const getDynamicComponentIcon = (kind) => {
  return DYNAMIC_COMPONENTS_ICONS[kind]
    ? DYNAMIC_COMPONENTS_ICONS[kind]()
    : DYNAMIC_COMPONENTS_ICONS.DEFAULT()
}
