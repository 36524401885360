import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import { propTypes, defaultProps } from './props'
import { CLEAR_PRODUCTION, CLEAR_STAGING } from '../../constants'

// DEPREACATED
export function TextClear({
  className,
  prefill,
  label,
  instruction,
  taskGroupName,
  ...props
}) {
  const hostUrl = window.location.origin
  if (
    taskGroupName === 'ON_UPLOAD_EVIDENCE' &&
    (hostUrl === CLEAR_STAGING || hostUrl === CLEAR_PRODUCTION)
  ) {
    return (
      <div className={c('text p-1 mb-3', className)}>
        {!isEmpty(label) && <div className="dc-label">{label}</div>}
        <div>{prefill}</div>
        {!isEmpty(instruction) && (
          <div className="dc-instruction">{instruction}</div>
        )}
      </div>
    )
  }
  return <div />
}
export default TextClear

TextClear.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

TextClear.defaultProps = {
  ...defaultProps,
  className: '',
}
