import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useParams } from 'react-router-dom'
import { propTypes, defaultProps } from './props'
import {
  selectClaimItemAffectedByID,
  selectDeviceFileById,
} from '../../selectors/claim'
import {
  addDeviceFiles,
  deleteClaimDeviceFile,
  updateDynamicField,
} from '../../reducers/claim'
import ProofLossListView from '../proof-loss-list/view'

// DEPREACATED
export function Files({
  className,
  label,
  instruction,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
  disabled,
  modifyable,
  type,
  ...props
}) {
  const dispatch = useDispatch()
  let deviceFiles = useSelector((state) =>
    selectDeviceFileById(state, claimDeviceId)
  )
  // temporal solution to doucment type
  if (type === 'FILES_ESTIMATES') {
    deviceFiles = deviceFiles.filter(
      (file) => file.document_type === 'FILES_ESTIMATES'
    )
  }
  if (type === 'FILES_RECEIPTS') {
    deviceFiles = deviceFiles.filter(
      (file) => file.document_type === 'FILES_RECEIPTS'
    )
  }
  claimDeviceId = parseInt(claimDeviceId, 10)
  const [tmpOriginalImageNames, setTmpOriginalImageNames] = useState({})
  const [fileFormat, setFileFormat] = useState()

  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )

  const onChangeFieldReceip = (id, event) => {
    const currentFile = event.nativeEvent.target.files[0]
    const hash = uuidv4()
    const filename = `CLAIM/${claimId}/${hash.substring(0, 4)}_${currentFile.name}`
    setTmpOriginalImageNames({
      ...tmpOriginalImageNames,
      [filename]: currentFile.name,
    })
    // setFileFormat(currentFile.type)
    const data = {
      claim_id: claimId,
      device_id: deviceId,
      field_id: fieldId,
      claim_device_id: claimDeviceId,
      handler: 'files',
      name: filename,
      processing_status: true,
      public_url: '/files/demo-file.pdf',
      file_name: filename,
      file_size: currentFile.size,
      file_type: currentFile.type,
      document_type: type,
    }
    dispatch(addDeviceFiles(data))
    dispatch(updateDynamicField(data)).then(() => {
      onChange({ fieldId })
    })
  }

  const onDeleteFile = (fileID) => {
    dispatch(
      deleteClaimDeviceFile({ claimId, claimDeviceId, fileId: fileID })
    ).then(() => {})
  }

  return (
    <div className={c('images mt-2', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div>
        <ProofLossListView
          {...props}
          className={c(className, { modifyable })}
          images={deviceFiles}
          tmpOriginalImageNames={tmpOriginalImageNames}
          onChangeImage={onChangeFieldReceip}
          onDelete={onDeleteFile}
          device={device}
          modifyable={modifyable}
          disabled={disabled}
          fieldId={fieldId}
          file
        />
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction mb-1">{instruction}</div>
      )}
    </div>
  )
}
export default Files

Files.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.bool,
}

Files.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
