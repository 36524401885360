import React from 'react'

export function IconArrowLeft() {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1L2 6.5L8 12" stroke="#9BA1AB" strokeWidth="2" />
    </svg>
  )
}
export default IconArrowLeft

IconArrowLeft.propTypes = {}

IconArrowLeft.defaultProps = {}
