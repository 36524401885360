import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'
import { selectRepairStore } from '../repair-providers'
import { selectClaimItemAffectedByID } from '../../selectors/claim'
import Spinner from '../spinner'
import { updateDynamicField, setDeviceVerified } from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'

export function Verification({
  className,
  label,
  instruction,
  deviceId,
  claimId,
  claimDeviceId,
  id: fieldId,
  onChange,
  prefill,
  disabled,
}) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )
  const [verified, setVerified] = useState(device.verified)

  const update = useCallback(
    debounce((newCheckState) => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'verification',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        setLoading(false)
        onChange({ fieldId, newState: newCheckState ? 'DONE' : 'TODO' })
      })
    }, DELAY_TIMING),
    []
  )

  const onCheck = () => {
    const newCheckState = !verified
    const data = {
      claimDeviceId,
      verified: newCheckState,
    }
    dispatch(setDeviceVerified(data))
    setVerified(newCheckState)
    update(newCheckState)
  }

  return (
    <div className={c('amount-text', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      {!isEmpty(instruction) && (
        <div className="mb-1 dc-instruction">{instruction}</div>
      )}
      {/* <div>
        <button
          type="button"
          onClick={onVerify}
          className={c('btn', {
            'btn-primary': verified,
            'btn-outline-secondary': !verified,
          })}
          disabled={disabled}
        >
          { prefill || 'I confirm my device'}
        </button>
      </div> */}
      <div className="form-check mb-1">
        <input
          checked={verified}
          onChange={onCheck}
          className="form-check-input"
          type="checkbox"
          id="verified-cbox"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="verified-cbox">
          {prefill || 'I confirm my device'}
        </label>
      </div>
      {/* {loading && <Spinner /> } */}
    </div>
  )
}
export default Verification

Verification.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.bool,
}

Verification.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
