import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Formik, Form, ErrorMessage } from 'formik'
import c from 'classnames'
import Message from '../components/message'
import Header from '../components/header'
import Autosaving from '../components/autosaving'
import Button from '../components/button'
import { selectBrand } from '../selectors/app'
import { ResetPasswordValidation } from '../utils/validation'
import { resetUserPassword } from '../reducers/user'
import { LOGIN_PATH } from '../constants'

export function ResetPassword() {
  const brandName = useSelector(selectBrand)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  const onSubmit = ({ phone }) => {
    setLoading(true)
    setPasswordReset(false)
    dispatch(resetUserPassword({ phone }))
      .then(() => {
        setLoading(false)
        setPasswordReset(true)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  const onCloseErrorMessage = () => setError(false)

  const onClosePasswordResetMessage = () => setPasswordReset(false)

  return (
    <div className={c('page', brandName)}>
      <Header />
      <div className="container-fluid">
        <div className="login">
          <div className="title">Get a New Passcode</div>
          <div className="card">
            <div className="card-body p-4">
              <Formik
                initialValues={{
                  phone: '',
                }}
                validationSchema={ResetPasswordValidation}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="mb-3">
                      <label className="form-label">
                        Enter the phone number associated with your account to
                        receive a new passcode via SMS and email.
                      </label>
                      {!passwordReset && (
                        <NumberFormat
                          className="form-control"
                          onValueChange={({ value }) =>
                            setFieldValue('phone', value)
                          }
                          format="(###) ###-####"
                          mask="_"
                          allowEmptyFormatting
                        />
                      )}
                      <ErrorMessage
                        component="div"
                        className="error-text"
                        name="phone"
                      />
                    </div>
                    <div className="mb-3">
                      {passwordReset && (
                        <Message
                          onClose={onClosePasswordResetMessage}
                          closable
                          error
                        >
                          Password successfully reset. Please check your email
                          or text messages.
                        </Message>
                      )}
                      {passwordReset && (
                        <Link className="primary tdn" to={`/${LOGIN_PATH}`}>
                          Click here to login.
                        </Link>
                      )}
                    </div>
                    <div className="mb-3">
                      {error && (
                        <Message onClose={onCloseErrorMessage} closable error>
                          Account&apos;s cell phone number does not exist
                        </Message>
                      )}
                      {!passwordReset &&
                        (loading ? (
                          <Autosaving label="Resetting password..." />
                        ) : (
                          <Button type="submit">Send me a New Passcode</Button>
                        ))}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword
