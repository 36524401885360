import React from 'react'

export function IconReport() {
  return (
    <svg
      width="23"
      height="27"
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.878052"
        y="7.39026"
        width="19.9024"
        height="18.7317"
        rx="5"
        fill="white"
      />
      <path
        d="M18.3173 4.0075H17.2271V2.88929C17.2271 2.66701 17.1388 2.45369 16.9816 2.29634C16.8243 2.13918 16.611 2.05085 16.3887 2.05085H14.6555C14.3376 1.37544 13.7743 0.84663 13.0804 0.571894C12.3862 0.297162 11.6137 0.297162 10.9196 0.571894C10.2256 0.846625 9.66228 1.37544 9.34447 2.05085H7.58339C7.36091 2.05085 7.14779 2.13918 6.99044 2.29634C6.83309 2.45369 6.74476 2.667 6.74476 2.88929V4.0073L5.6827 4.0075C4.71887 4.0075 3.79468 4.39025 3.11303 5.0717C2.43158 5.75335 2.04883 6.67759 2.04883 7.64137V23.0993C2.04883 24.0631 2.43158 24.9873 3.11303 25.6688C3.79468 26.3503 4.71892 26.7332 5.6827 26.7332H18.3174C19.2812 26.7332 20.2054 26.3503 20.8871 25.6688C21.5685 24.9874 21.9513 24.0631 21.9513 23.0993V7.64137C21.9513 6.67754 21.5685 5.75335 20.8871 5.0717C20.2054 4.39025 19.2812 4.0075 18.3174 4.0075L18.3173 4.0075ZM8.44993 3.72789H9.95948C10.1508 3.72536 10.3356 3.6575 10.483 3.53544C10.6306 3.41338 10.7318 3.24472 10.77 3.05715C10.8303 2.65665 11.0796 2.30956 11.44 2.12472C11.8003 1.94007 12.2275 1.94007 12.5881 2.12472C12.9484 2.30956 13.1976 2.65662 13.2578 3.05715C13.296 3.24472 13.3974 3.41338 13.5448 3.53544C13.6922 3.6575 13.8771 3.72536 14.0686 3.72789H15.5779V5.82436H8.42198L8.44993 3.72789ZM20.274 23.0992C20.274 23.6181 20.0677 24.1159 19.7008 24.4828C19.3338 24.8498 18.8362 25.0559 18.3171 25.0559H5.68245C5.16342 25.0559 4.66581 24.8498 4.29883 24.4828C3.93187 24.1159 3.72559 23.6181 3.72559 23.0992V7.64129C3.72559 7.12227 3.93189 6.62466 4.29883 6.25768C4.66579 5.89071 5.16337 5.68444 5.68245 5.68444H6.7447V6.66289H6.74451C6.74451 6.88537 6.83284 7.09868 6.99019 7.25584C7.14754 7.41319 7.36066 7.50152 7.58314 7.50152H16.3883C16.6106 7.50152 16.8239 7.4132 16.9812 7.25584C17.1384 7.09869 17.2267 6.88537 17.2267 6.66289V5.68444H18.289C18.808 5.68444 19.3056 5.89073 19.6726 6.25768C20.0396 6.62462 20.2456 7.12222 20.2456 7.64129L20.274 23.0992Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
      <path
        d="M7.47157 12.0853H10.9376C11.2373 12.0853 11.5142 11.9255 11.664 11.6661C11.8137 11.4066 11.8137 11.0869 11.664 10.8275C11.5142 10.568 11.2373 10.4081 10.9376 10.4081H7.47157C7.17188 10.4081 6.89499 10.568 6.74525 10.8275C6.5955 11.0868 6.5955 11.4066 6.74525 11.6661C6.89499 11.9255 7.17187 12.0853 7.47157 12.0853Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
      <path
        d="M16.5283 13.7633H7.47157C7.17188 13.7633 6.89499 13.923 6.74525 14.1825C6.5955 14.442 6.5955 14.7616 6.74525 15.0212C6.89499 15.2805 7.17187 15.4404 7.47157 15.4404H16.5283C16.828 15.4404 17.1049 15.2805 17.2546 15.0212C17.4044 14.7616 17.4044 14.442 17.2546 14.1825C17.1049 13.923 16.828 13.7633 16.5283 13.7633Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
      <path
        d="M16.5283 17.1171H7.47157C7.17188 17.1171 6.89499 17.2768 6.74525 17.5363C6.5955 17.7958 6.5955 18.1154 6.74525 18.3749C6.89499 18.6344 7.17187 18.7941 7.47157 18.7941H16.5283C16.828 18.7941 17.1049 18.6344 17.2546 18.3749C17.4044 18.1154 17.4044 17.7958 17.2546 17.5363C17.1049 17.2768 16.828 17.1171 16.5283 17.1171Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
      <path
        d="M16.5283 20.4723H7.47157C7.17188 20.4723 6.89499 20.6322 6.74525 20.8915C6.5955 21.151 6.5955 21.4706 6.74525 21.7301C6.89499 21.9897 7.17187 22.1495 7.47157 22.1495H16.5283C16.828 22.1495 17.1049 21.9897 17.2546 21.7301C17.4044 21.4706 17.4044 21.151 17.2546 20.8915C17.1049 20.6322 16.828 20.4723 16.5283 20.4723Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
    </svg>
  )
}
export default IconReport

IconReport.propTypes = {}

IconReport.defaultProps = {}
