import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../components/page-header'
import DescribeIncidentForm from '../components/describe-incident-form'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import { DI_TXT, DI_DESCRIPTION_TXT } from '../i18n'
import { PAGE_DESCRIBE_INCIDENT } from '../constants'

export function DescribeIncidentPage() {
  const { t } = useTranslation()
  useStoreCurrentSteep(PAGE_DESCRIBE_INCIDENT)

  return (
    <div>
      <PageHeader title={t(DI_TXT)} subtitle={t(DI_DESCRIPTION_TXT)} />
      <DescribeIncidentForm />
    </div>
  )
}
export default DescribeIncidentPage
