import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { IconCheck } from '../icons/icon-check'
import { PaymentSchema } from './schemas'
import Spinner from '../spinner'

const PAYPAL = 'paypal'
const VENMO = 'venmo'
const WIRE = 'wire'

export function PaymentMethodView({
  className,
  loading,
  savingPayoutMethod,
  initialValues,
  onChangeFields,
  onSubmitForm,
  disabled,
}) {
  return (
    <div className={c('payment-payme', className)}>
      <div className="title">Select Payout Method</div>
      <Formik
        initialValues={initialValues}
        validationSchema={PaymentSchema}
        validate={onChangeFields}
        onSubmit={onSubmitForm}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="options">
              <button
                type="button"
                className={c('option-btn', {
                  checked: values.method === PAYPAL,
                })}
                onClick={() => {
                  setFieldValue('method', 'paypal')
                }}
              >
                <img
                  className="option-logo paypal"
                  alt="payment logo"
                  src="/images/paypal-logo.png"
                />
                <span className="check">
                  <IconCheck />
                </span>
              </button>
              <button
                type="button"
                className={c('option-btn', {
                  checked: values.method === VENMO,
                })}
                onClick={() => {
                  setFieldValue('method', 'venmo')
                }}
              >
                <img
                  className="option-logo venmo"
                  alt="payment logo"
                  src="/images/venmo-logo.png"
                />
                <span className="check">
                  <IconCheck />
                </span>
              </button>
              <button
                type="button"
                className={c('option-btn', {
                  checked: values.method === WIRE,
                })}
                onClick={() => {
                  setFieldValue('method', 'wire')
                }}
              >
                Other Methods
                <span className="check">
                  <IconCheck />
                </span>
              </button>
            </div>
            {values.method === PAYPAL && (
              <div className="payment-info paypal">
                <div className="mb-4">
                  <label className="form-label">PAYPAL EMAIL</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-text"
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">CONFIRM PAYPAL EMAIL</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="emailConfirm"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="emailConfirm"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
            )}
            {values.method === VENMO && (
              <div className="payment-info venmo">
                <div className="mb-4">
                  <label className="form-label">VENMO PHONE</label>
                  <Field
                    className="form-control mb-3"
                    type="text"
                    name="phone"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error-text"
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">CONFIRM VENMO PHONE</label>
                  <Field
                    className="form-control mb-3"
                    type="text"
                    name="phoneConfirm"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="phoneConfirm"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
            )}
            {values.method === WIRE && (
              <div className="payment-info wire">
                <div className="wire_title">Prepaid Card</div>
                <div className="wire_desc">
                  A reward link containing the prepaid card will be sent to your
                  email
                </div>
                <div className="mb-4">
                  <label className="form-label">EMAIL ADDRESS</label>
                  <Field
                    className="form-control mb-3"
                    type="text"
                    name="wire"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="wire"
                    component="div"
                    className="error-text"
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">CONFIRM EMAIL ADDRESS</label>
                  <Field
                    className="form-control mb-3"
                    type="text"
                    name="wireConfirm"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="wireConfirm"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <button
                type="submit"
                disabled={disabled || savingPayoutMethod}
                className={c('btn btn-outline-primary btn-sm payment-btn', {
                  disabled: disabled || savingPayoutMethod,
                })}
              >
                {values.method === WIRE ? 'Send Reward Link' : 'Send payment'}
              </button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default PaymentMethodView

PaymentMethodView.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  savingPayoutMethod: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onChangeFields: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

PaymentMethodView.defaultProps = {
  className: '',
  disabled: false,
  savingPayoutMethod: false,
}
