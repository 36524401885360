import React from 'react'

export function IconCircleArrowUpRight() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F1F1F1" />
      <path
        d="M22.3966 13.7162C22.3966 13.4401 22.1727 13.2162 21.8966 13.2162L17.3966 13.2162C17.1204 13.2162 16.8966 13.4401 16.8966 13.7162C16.8966 13.9923 17.1204 14.2162 17.3966 14.2162L21.3966 14.2162L21.3966 18.2162C21.3966 18.4923 21.6204 18.7162 21.8966 18.7162C22.1727 18.7162 22.3966 18.4923 22.3966 18.2162L22.3966 13.7162ZM14.0679 22.252L22.2501 14.0698L21.543 13.3626L13.3608 21.5449L14.0679 22.252Z"
        fill="black"
      />
    </svg>
  )
}
export default IconCircleArrowUpRight

IconCircleArrowUpRight.propTypes = {}

IconCircleArrowUpRight.defaultProps = {}
