import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PageHeader from '../components/page-header'
import DeviceList from '../components/device-list'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import { SAI_TXT } from '../i18n'
import { ADD_ITEMS_PATH, PAGE_AFFECTED_ITEMS } from '../constants'
import { selectPartnerPermissionValueByScope } from '../selectors/partner'
import { ALLOW, PARTNER_CLAIM_ADD_ITEM } from '../constants/permissions'

export function AffectedItems() {
  useStoreCurrentSteep(PAGE_AFFECTED_ITEMS)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addItemPermision = useSelector((state) =>
    selectPartnerPermissionValueByScope(state, PARTNER_CLAIM_ADD_ITEM)
  )

  return (
    <div>
      <PageHeader title={t(SAI_TXT)} stepNumber={1}>
        <div className="page-header__subtitle">
          {addItemPermision === ALLOW ? (
            <>
              If an item was affected which was not added to your account,
              please&nbsp;
              <Link
                className="color-primary uline-none"
                to={`/${ADD_ITEMS_PATH}/`}
              >
                add it here
              </Link>
            </>
          ) : null}
        </div>
      </PageHeader>
      <DeviceList />
    </div>
  )
}
export default AffectedItems
