import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconAlert from '../icons/icon-alert'
import IconSuccess from '../icons/icon-success'

export function InfoLabel({
  className,
  label,
  success,
  warning,
  count,
  ...props
}) {
  return (
    <div className={c('info-label', className)} {...props}>
      {warning ? (
        <>
          <IconAlert />
          &nbsp;
        </>
      ) : null}
      {success ? (
        <>
          <IconSuccess />
          &nbsp;
        </>
      ) : null}
      <div>{label}</div>
      &nbsp;&nbsp;
      {count !== null ? (
        <span className="info-label__count">{count}</span>
      ) : null}
    </div>
  )
}
export default InfoLabel

InfoLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  warning: PropTypes.bool,
  success: PropTypes.bool,
}

InfoLabel.defaultProps = {
  className: '',
  label: '',
  count: 0,
  warning: false,
  success: false,
}
