import React, { useState } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import { updateDynamicField, submitImage } from '../../reducers/claim'
import {
  selectClaimDeviceEvidences,
  selectClaimItemAffectedByID,
} from '../../selectors/claim'
import { propTypes, defaultProps } from './props'
import ProofLossListView from '../proof-loss-list/view'
import { getSignedURL } from '../../reducers/claim/thunks'

export function Evidences({
  className,
  label,
  instruction,
  images,
  modifyable,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
  disabled,
  evidence_type_id,
  ...props
}) {
  const dispatch = useDispatch()
  const evidences = useSelector((state) =>
    selectClaimDeviceEvidences(state, {
      claimDeviceId,
      evidenceType: evidence_type_id,
    })
  )
  const [error, setError] = useState(null)

  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )

  // This solution is to keep the original name files
  const [tmpOriginalImageNames, setTmpOriginalImageNames] = useState({})

  const onChangeImage = (id, event) => {
    setError(false)
    const file = event.nativeEvent.target.files[0]
    const hash = uuidv4()
    const newFileName = `CLAIM/${claimId}/${hash.substring(0, 4)}_${file.name}`
    setTmpOriginalImageNames({
      ...tmpOriginalImageNames,
      [newFileName]: { name: file.name, public_url: URL.createObjectURL(file) },
    })

    const fieldData = {
      claim_id: claimId,
      claim_device_id: claimDeviceId,
      device_id: deviceId,
      field_id: fieldId,
      handler: 'evidences',
      evidence_type_id,
      file_name: newFileName,
      file_size: file.size,
      file_type: file.type,
    }

    dispatch(updateDynamicField(fieldData)).then(() => {
      dispatch(getSignedURL({ claimId, type: 'CLAIM', filename: newFileName }))
        .unwrap()
        .then(({ headers, url }) => {
          const d = { headers, url, file }
          dispatch(submitImage(d))
            .unwrap()
            .catch((e) => {
              setError(true)
            })
        })
        .catch((e) => {
          setError(true)
        })
    })
  }

  const onDelete = (id, filename, documentType, type) => {
    const fieldData = {
      claim_id: claimId,
      claim_device_id: claimDeviceId,
      device_id: deviceId,
      field_id: fieldId,
      handler: 'evidences',
      file_name: filename,
      file_type: type,
      evidenceId: id,
      toDelete: true,
    }
    dispatch(updateDynamicField(fieldData))
  }

  const evidenceIsProcessing = evidences.some((i) =>
    isEmpty(i.processing_status)
  )

  return (
    <div className={c('images mt-2', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <ProofLossListView
        {...props}
        className={c(className, { modifyable })}
        images={evidences}
        tmpOriginalImageNames={tmpOriginalImageNames}
        onDelete={onDelete}
        onChangeImage={onChangeImage}
        device={device}
        modifyable={modifyable}
        disabled={disabled}
        fieldId={fieldId}
      />
      {error && (
        <div className="error-box2">
          Something went wrong, please contact support
        </div>
      )}
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      {evidenceIsProcessing && (
        <div className="dc-warning">
          <ul>
            <li>Do not close this page until the upload finishes</li>
            <li>Maximum file size allowed 30 MB</li>
          </ul>
        </div>
      )}
    </div>
  )
}
export default Evidences

Evidences.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  device: PropTypes.shape({
    id: PropTypes.number,
  }),
  modifyable: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),
}

Evidences.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  device: undefined,
  images: [],
  modifyable: true,
}
