import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { propTypes, defaultProps } from './props'
import { GOOGLE_API_PLACES_DEBOUNCE_TIMER } from '../../constants'
import {
  setFieldOptions,
  setRepairShopAddress,
  updateDynamicField,
} from '../../reducers/claim'
import { selectPartnerPropertyRepairInfo } from '../../selectors/partner'
import {
  selectClaimItemAffectedByID,
  selectRepairShopAddressByDevice,
} from '../../selectors/claim'

export function StoreAddress({
  className,
  prefill,
  label,
  instruction,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
  disabled,
  ...props
}) {
  const [location, setLocation] = useState(
    useSelector((state) =>
      selectRepairShopAddressByDevice(state, claimDeviceId)
    )
  )
  const propertyRepairInfo = useSelector(selectPartnerPropertyRepairInfo)
  const affectedItem = useSelector((state) =>
    selectClaimItemAffectedByID(state, Number(claimDeviceId))
  )
  const [recommendedRepairShop, setRecommendedRepairshop] = useState(
    affectedItem.recommendedRepairShop
  )

  const dispatch = useDispatch()

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GM_KEY,
  })

  const update = useCallback(
    debounce((locationName) => {
      getPlacePredictions({ input: locationName })
    }, GOOGLE_API_PLACES_DEBOUNCE_TIMER),
    []
  )

  const onChangeAddress = (e) => {
    const locationName = e.target.value
    setLocation(locationName)
    update(locationName)
  }

  const onSelectAddress = (placeId) => {
    placesService.getDetails({ placeId }, (placeDetails) => {
      const selectedLocation = `${placeDetails.name}, ${placeDetails.formatted_address}`
      setLocation(selectedLocation)
      dispatch(
        setRepairShopAddress({
          address: selectedLocation,
          claimDeviceId,
          recommendedRepairShop: false,
        })
      )
      getPlacePredictions({})
      onChange({ fieldId })
      const fieldData = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'shop_address',
      }
      dispatch(updateDynamicField(fieldData))
    })
  }

  const onRecommendedRepairShop = (e) => {
    const newState = e.target.value === 'true'
    setRecommendedRepairshop(newState)
    if (newState) {
      dispatch(
        setRepairShopAddress({
          address: '',
          recommendedRepairShop: newState,
          claimDeviceId,
        })
      )
      const fieldData = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'shop_address',
      }
      dispatch(updateDynamicField(fieldData))
      setLocation('')
    }
    if (newState) {
      dispatch(
        setFieldOptions({
          fieldId,
          newRequiredState: false,
          newFieldState: 'DONE',
        })
      )
    } else {
      dispatch(
        setFieldOptions({
          fieldId,
          newRequiredState: true,
          newFieldState: 'TODO',
        })
      )
    }
  }

  return (
    <div className={c('store-address', className)}>
      <div>
        {!isEmpty(label) && (
          <label className="form-label small soft">{label}</label>
        )}
        {propertyRepairInfo.isRepair && (
          <div>
            <div className="form-check form-check-inline">
              <input
                checked={recommendedRepairShop}
                className="form-check-input"
                name="repair-shop"
                type="radio"
                id="repair-shop"
                value="true"
                onChange={onRecommendedRepairShop}
              />
              <label className="form-check-label" htmlFor="repair-shop">
                I went to the recommended repair shop
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                checked={recommendedRepairShop === false}
                className="form-check-input"
                name="repair-shop"
                type="radio"
                id="other-repair-shop"
                value="false"
                onChange={onRecommendedRepairShop}
              />
              <label className="form-check-label" htmlFor="other-repair-shop">
                I went to a different repair shop
              </label>
            </div>
          </div>
        )}
        {(recommendedRepairShop === false || !propertyRepairInfo.isRepair) && (
          <div>
            <label className="form-label small soft">
              Please complete the address of the repair shop you went
            </label>
            <input
              type="text"
              onChange={onChangeAddress}
              className="form-control store-address_input"
              name="store-address"
              placeholder="Enter your Address"
              value={location}
              disabled={isPlacePredictionsLoading}
            />
            <div>
              {!isEmpty(placePredictions) && (
                <div className="store-address_opt-container">
                  <div className="store-address_options">
                    {placePredictions.map((r) => (
                      <button
                        key={r.place_id}
                        type="button"
                        className="store-address_option"
                        onClick={() => onSelectAddress(r.place_id)}
                      >
                        {r.description}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default StoreAddress

StoreAddress.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

StoreAddress.defaultProps = {
  ...defaultProps,
  className: '',
}
