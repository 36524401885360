import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/header'
import Container from '../components/layouts/container'
import { selectAppError } from '../selectors/app'

export function ErrorPage() {
  const appErrorState = useSelector(selectAppError)

  return (
    <div>
      <Header />
      <Container className="">
        <div className="card error-box">
          <div className="card-body">{appErrorState.errorMessage}</div>
        </div>
      </Container>
    </div>
  )
}
export default ErrorPage
