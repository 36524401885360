import React from 'react'

export function useRunValidationSchema(schema, values) {
  const errors = {}
  try {
    schema.validateSync(values, { abortEarly: false })
  } catch (err) {
    err.inner.map((inr) => {
      errors[inr.path] = [...inr.errors]
    })
  }
  return [errors]
}
export default useRunValidationSchema
