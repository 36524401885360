import React from 'react'

export function IconSuccess() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#2F7D22" />
      <path
        d="M5.45387 7.08518C5.43827 7.10106 5.4127 7.10116 5.39699 7.08539L4.04017 5.72395C3.8673 5.55049 3.58607 5.55156 3.41451 5.72633L3.36072 5.78112L3.50345 5.92123L3.36072 5.78112C3.19199 5.95302 3.19284 6.22865 3.36263 6.3995L5.11347 8.16131C5.28626 8.33519 5.56784 8.33432 5.73956 8.15938L8.74662 5.09597C8.91462 4.92483 8.91462 4.65067 8.74662 4.47952L8.69311 4.42501C8.52064 4.24931 8.23757 4.24931 8.06511 4.42501L5.45387 7.08518Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
      />
    </svg>
  )
}
export default IconSuccess

IconSuccess.propTypes = {}

IconSuccess.defaultProps = {}
