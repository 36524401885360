import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export function RepairProviderSamsung({ className, userAddress, ...props }) {
  return (
    <div className={c('repair-provider-samsung', className)} {...props}>
      <p>
        Please click the button below to start your repair request through{' '}
        ubreakifix, Samsung’s Authorized Repair Partner.
      </p>
      <p>
        Your claim is approved for repair. You&apos;ll complete your{' '}
        repair/replacement at your nearest ubreakifix and pay the store{' '}
        directly. AKKO will then reimburse you for the total cost of the repair{' '}
        less your applicable repair deductible.
      </p>
      <a
        className="btn btn-primary"
        href={`https://new.ubreakifix.com/?device=73&postal_code=${userAddress.zip}&service=CC`}
        target="_blank"
        rel="noreferrer"
      >
        Open Samsung repair Stores
      </a>
    </div>
  )
}
export default RepairProviderSamsung

RepairProviderSamsung.propTypes = {
  className: PropTypes.string,
  userAddress: PropTypes.shape({}),
}

RepairProviderSamsung.defaultProps = {
  className: '',
  userAddress: {},
}
