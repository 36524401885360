import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const NEED_HELP_TXT = 'NEED_HELP'
export const CONTACT_US_TXT = 'CONTACT_US'

export const COVERED_TXT = 'COVERED'
export const GET_STARTED_TXT = 'GET_STARTED'

export const AFFECTED_ITEMS_TXT = 'AFFECTED_ITEMS'
export const TYPE_LOSS_TXT = 'TYPE_LOSS'
export const INCIDENT_TXT = 'INCIDENT'
export const PROFF_LOSS_TXT = 'PROFF_LOSS'
export const PAYOUT_TXT = 'PAYOUT'
export const REVIEW_TXT = 'REVIEW'

export const CLAIM_STATUS = 'CLAIM_STATUS'

export const PREVIOUS_BTN_TXT = 'PREVIOUS_BTN_TXT'
export const CONTINUE_BTN_TXT = 'CONTINUE_BTN_TXT'
export const FILE_CLAIM_BTN_TXT = 'FILE_CLAIM_BTN_TXT'

export const UPLOAD_FILE_TXT = 'UPLOAD_FILE'

export const START_FILING_TXT = 'START_FILING_TXT'
export const CONTINUE_FILING_TXT = 'CONTINUE_FILING_TXT'

export const REQUIRED_FIELDS_TXT = 'REQUIRED_FIELDS_TXT'
export const IMAGES_PROCESSING_TXT = 'IMAGES_PROCESSING'
export const IMAGES_WORD_TXT = 'IMAGES_PROCESSING'

export const DATE_LOSS_TXT = 'DATE_LOSS'
export const DESCRIPTION_INCIDENT_TXT = 'DESCRIPTION_INCIDENT'

export const SAI_TXT = 'SELECT_AFFECTED_ITEMS_TXT'
export const SAI_DESCRIPTION_TXT = 'SELECT_AFFECTED_ITEMS_DESCRIPTION_TXT'
export const SAI_DESCRIPTION_LINK_TXT =
  'SELECT_AFFECTED_ITEMS_DESCRIPTION_LINK_TXT'

export const DL_TXT = 'DESCRIBE_LOSS_TXT'
export const DL_DESC_TXT = 'DESCRIBE_LOSS_DESC_TXT'
export const DL_SELECTOR_TXT = 'DECRIBE_SELECTOR_TXT'
export const DL_TEXTAREA_TXT = 'DECRIBE_TEXTAREA_TXT'

export const DI_TXT = 'DESCRIBE_INCIDENT_TXT'
export const DI_DESCRIPTION_TXT = 'DESCRIBE_INCIDENT_DESC_TXT'
export const DI_DATE_LOSS_TXT = 'DESCRIBE_INCIDENT_DATE_LOSS_TXT'
export const DI_TEXTAREA_TXT = 'DESCRIBE_INCIDENT_TEXTAREA_TXT'
export const DI_REQUIRED_TXT = 'DESCRIBE_INCIDENT_REQUIRED_TXT'

export const UPL_TXT = 'UPLOAD_PROOF_LOSS_TXT'
export const UPL_DESCRIPTION_TXT = 'UPLOAD_PROOF_LOSS_DESC_TXT'

export const SPM_TXT = 'SELECT_PAYMENT_METHOD_TXT'
export const SPM_HAVE_PAYPAL_TXT = 'SELECT_PAYMENT_METHOD_HAVE_PAYPAL_TXT'
export const SPM_DONT_HAVE_PAYPAL_TXT = 'SELECT_PAYMENT_METHOD_NO_PAYPAL_TXT'
export const SPM_ENTER_PAYPAL_TXT = 'SELECT_PAYMENT_METHOD_ENTER_PAYPAL_TXT'

export const RC_TXT = 'REVIEW_TXT'
export const RC_DATE_LOSS_TXT = 'REVIEW_DATE_LOSS_TXT'
export const RC_INCIDENT_TXT = 'REVIEW_INCIDENT_TXT'
export const RC_TYPELOSS_TXT = 'REVIEW_TYPELOSS_TXT'
export const RC_PREV_DAMAGE_TXT = 'REVIEW_PREV_DAMAGE_TXT'
export const RC_IMAGES_TXT = 'REVIEW_IMAGES_TXT'
export const RC_DISCLAIMER_TXT = 'REVIEW_DISCLAIMER_TXT'
export const RC_DISCLAIMER_CONTENT_TXT = 'REVIEW_DISCLAIMER_CONTENT_TXT'
export const RC_TERMS_TXT = 'REVIEW_TERMS_TXT'
export const RC_REQUIRED_TXT = 'REVIEW_REQUIRED_TXT'
export const RC_NOIMAGES_TXT = 'REVIEW_NOIMAGES_TXT'

export const SPM_HAVE_VENMO_TXT = 'SELECT_PAYMENT_METHOD_HAVE_VENMO_TXT'
export const SPM_DONT_HAVE_VENMO_TXT = 'SELECT_PAYMENT_METHOD_NO_VENMO_TXT'
export const SPM_ENTER_VENMO_TXT = 'SELECT_PAYMENT_METHOD_ENTER_VENMO_TXT'

export const PM_PAYPAL_TXT = 'PAYOUT_METHOD_PAYPAL_TXT'
export const PM_VENMO_TXT = 'PAYOUT_METHOD_VENMO_TXT'
export const PM_SKIP_TXT = 'PAYOUT_SKIP_TXT'
export const SPM_SUB_TITLE_TXT = 'SELECT_PAYMENT_METHOD_SUB_TITLE_TXT'
export const SPM_MESSAGE_WITHOUT_PAYOUT_TXT =
  'SELECT_PAYMENT_METHOD_MESSAGE_WITHOUT_PAYOUT_TXT'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      [NEED_HELP_TXT]: 'Need help?',
      [CONTACT_US_TXT]: 'Contact Us',
      [COVERED_TXT]: "We've Got You Covered!",
      [GET_STARTED_TXT]: 'Get started by completing the following sections',

      [AFFECTED_ITEMS_TXT]: 'Select Affected Items',
      [TYPE_LOSS_TXT]: 'Select Type of Loss',
      [INCIDENT_TXT]: 'Describe the Incident',
      [PROFF_LOSS_TXT]: 'Upload Proof of Loss',
      [PAYOUT_TXT]: 'Select Payout Method',
      [REVIEW_TXT]: 'Review & Submit',
      [START_FILING_TXT]: 'Start Filing Claim',
      [CONTINUE_FILING_TXT]: 'Continue Filing Claim',

      [CLAIM_STATUS]: 'Claim Status',

      [REQUIRED_FIELDS_TXT]: 'Some fields are required',
      [IMAGES_PROCESSING_TXT]: 'are still uploading',
      [IMAGES_WORD_TXT]: 'Images',

      [UPLOAD_FILE_TXT]: 'Upload Files',

      [DATE_LOSS_TXT]: 'DATE OF LOSS',
      [DESCRIPTION_INCIDENT_TXT]: 'DESCRIPTION OF THE INCIDENT',

      [PREVIOUS_BTN_TXT]: 'Previous',
      [CONTINUE_BTN_TXT]: 'Continue',
      [FILE_CLAIM_BTN_TXT]: 'File Claim',

      [SAI_TXT]: 'Select Affected Items',
      [SAI_DESCRIPTION_TXT]:
        'If an item was affected which was not added to your account, please',
      [SAI_DESCRIPTION_LINK_TXT]: 'add it here',

      [DL_TXT]: 'Select Type of Loss',
      [DL_DESC_TXT]:
        'Select all applicable losses and indicate any known prior damage for each item',
      [DL_SELECTOR_TXT]: 'Type of Loss',
      [DL_TEXTAREA_TXT]: 'Describe any known prior damage',

      [DI_TXT]: 'Describe the Incident',
      [DI_DESCRIPTION_TXT]:
        'Please provide as much information as you can describing the incident',
      [DI_DATE_LOSS_TXT]: 'Type of loss',
      [DI_TEXTAREA_TXT]: 'Describe the incident',
      [DI_REQUIRED_TXT]: 'Incident description is required',

      [UPL_TXT]: 'Upload Proof of Loss',
      [UPL_DESCRIPTION_TXT]:
        'Provide clear images of any damage/evidence and a copy of a police report if applicable',

      [SPM_TXT]: 'Select Payout Method',
      [SPM_HAVE_PAYPAL_TXT]: 'I have a Paypal account',
      [SPM_DONT_HAVE_PAYPAL_TXT]: "I don't have a Paypal account",
      [SPM_ENTER_PAYPAL_TXT]: 'Enter Paypal Email',

      [RC_TXT]: 'Review Your Claim',
      [RC_DATE_LOSS_TXT]: 'DATE OF LOSS',
      [RC_INCIDENT_TXT]: 'DESCRIPTION OF THE INCIDENT',
      [RC_TYPELOSS_TXT]: 'TYPE OF LOSS',
      [RC_PREV_DAMAGE_TXT]: 'PREVIOUS DAMAGE',
      [RC_IMAGES_TXT]: 'IMAGES',
      [RC_DISCLAIMER_TXT]: 'Disclaimer',
      [RC_DISCLAIMER_CONTENT_TXT]:
        'We must advise you that any person who knowingly, and with intent to commit fraud, files a statement of claim containing any materially false information, or conceals for the purpose of misleading information concerning any fact thereto, commits a fraudulent act, which is a crime and may be prosecuted to the full extent of the law. By signing, I agree that the statements above are true and correct to the best of my knowledge and cannot be changed once submitted to the company.',
      [RC_TERMS_TXT]:
        'I agree and am filing this claim with full knowledge of my coverage benefits as outlined in the user agreement and terms and conditions.',
      [RC_REQUIRED_TXT]: 'You must agree to the above to file your claim',
      [RC_NOIMAGES_TXT]: 'No images yet',

      [SPM_HAVE_VENMO_TXT]: 'I have a Venmo account',
      [SPM_DONT_HAVE_VENMO_TXT]: "I don't have a Venmo account",
      [SPM_ENTER_VENMO_TXT]: 'Enter Venmo Phone',

      [PM_PAYPAL_TXT]: 'PayPal',
      [PM_VENMO_TXT]: 'Venmo',
      [PM_SKIP_TXT]: 'Other',

      [SPM_SUB_TITLE_TXT]:
        'Select your preferred payout method below to ensure fastest reimbursement',
      [SPM_MESSAGE_WITHOUT_PAYOUT_TXT]:
        'Our support team will coordinate with you to gather your bank wiring details upon claim resolution',
    },
  },
  es: {
    translation: {
      [NEED_HELP_TXT]: 'Ayuda?',
      [CONTACT_US_TXT]: 'Contactanos',
      [COVERED_TXT]: 'Te tenemos cubierto',
      [GET_STARTED_TXT]: 'Inicia completando las siguientes secciones',

      [AFFECTED_ITEMS_TXT]: 'Dispositivos Afectados',
      [TYPE_LOSS_TXT]: 'Tipo de perdida',
      [INCIDENT_TXT]: 'Describe el Incidente',
      [PROFF_LOSS_TXT]: 'Pruebas de Pérdida',
      [PAYOUT_TXT]: 'Método de Pago',
      [REVIEW_TXT]: 'Verificar y enviar',

      [PREVIOUS_BTN_TXT]: 'Anterior',
      [CONTINUE_BTN_TXT]: 'Continuar',
      [FILE_CLAIM_BTN_TXT]: 'File Claim',

      [START_FILING_TXT]: 'Iniciar el reclamo',
      [CONTINUE_FILING_TXT]: 'Continuar reclamo',

      [CLAIM_STATUS]: 'Estado del Reclamo',

      [REQUIRED_FIELDS_TXT]: 'Some fields are required',
      [IMAGES_PROCESSING_TXT]: 'are still uploading',
      [IMAGES_WORD_TXT]: 'Images',

      [UPLOAD_FILE_TXT]: 'Cargar Archivos',

      [DATE_LOSS_TXT]: 'FECHA DE PERDIDA',
      [DESCRIPTION_INCIDENT_TXT]: 'DESCRIPCION DEL INCIDENTE',

      [SAI_TXT]: 'Selecciona Dispositivos afectados',
      [SAI_DESCRIPTION_TXT]:
        'If an item was affected which was not added to your account, please add it here',
      [SAI_DESCRIPTION_LINK_TXT]: 'add it here',

      [DL_TXT]: 'Describe el Tipo de Pérdida',
      [DL_DESC_TXT]:
        'Select all applicable losses and indicate any known prior damage for each item',
      [DL_SELECTOR_TXT]: 'Typo de pérdida',
      [DL_TEXTAREA_TXT]: 'Describe any known prior damage',

      [DI_TXT]: 'Describe el Incidente',
      [DI_DESCRIPTION_TXT]:
        'Please provide as much information as you can describing the incident',
      [DI_DATE_LOSS_TXT]: 'Type of loss',
      [DI_TEXTAREA_TXT]: 'Describe el incidente',
      [DI_REQUIRED_TXT]: 'Incident description is required',

      [UPL_TXT]: 'Subir pruebas de pérdida',
      [UPL_DESCRIPTION_TXT]:
        'Provea claras imagenes del daño/evidencia o la copia de su reporte policial',

      [SPM_TXT]: 'Selecciona método de pago',
      [SPM_HAVE_PAYPAL_TXT]: 'Tengo una cuenta paypal',
      [SPM_DONT_HAVE_PAYPAL_TXT]: 'No tengo cuenta paypal',
      [SPM_ENTER_PAYPAL_TXT]: 'Escribir correo de paypal',

      [RC_TXT]: 'Revisa tu Claim',
      [RC_DATE_LOSS_TXT]: 'FECHA DE PERDIDA',
      [RC_INCIDENT_TXT]: 'DESCRIPCION DEL INCIDENTE',
      [RC_TYPELOSS_TXT]: 'TIPO DE PERDIDA',
      [RC_PREV_DAMAGE_TXT]: 'DANO ANTERIOR',
      [RC_IMAGES_TXT]: 'IMAGENES',
      [RC_DISCLAIMER_TXT]: 'Descargo',
      [RC_DISCLAIMER_CONTENT_TXT]:
        'We must advise you that any person who knowingly, and with intent to commit fraud, files a statement of claim containing any materially false information, or conceals for the purpose of misleading information concerning any fact thereto, commits a fraudulent act, which is a crime and may be prosecuted to the full extent of the law. By signing, I agree that the statements above are true and correct to the best of my knowledge and cannot be changed once submitted to the company.',
      [RC_TERMS_TXT]:
        'I agree and am filing this claim with full knowledge of my coverage benefits as outlined in the user agreement and terms and conditions.',
      [RC_REQUIRED_TXT]: 'You must agree to the above to file your claim',
      [RC_NOIMAGES_TXT]: 'No hay imagenes',

      [SPM_HAVE_VENMO_TXT]: 'Tengo una cuenta venmo',
      [SPM_DONT_HAVE_VENMO_TXT]: 'No tengo cuenta venmo',
      [SPM_ENTER_VENMO_TXT]: 'Escribir teléfono de tu cuenta venmo',

      [PM_PAYPAL_TXT]: 'PayPal',
      [PM_VENMO_TXT]: 'Venmo',
      [PM_SKIP_TXT]: 'Skip',

      [SPM_SUB_TITLE_TXT]:
        'Selecting a payout method now ensures the fastest possible transfer once your claim is approved for repair or replacement',
      [SPM_MESSAGE_WITHOUT_PAYOUT_TXT]:
        'Our support team will coordinate with you to gather your bank wiring details upon claim resolution',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
