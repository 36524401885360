import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import CheckStatus from '../check-status'
import IconContainer from '../icon-container'
import DropdownBtn from '../dropdown-button'

export function Device({
  className,
  done,
  open,
  children,
  icon,
  onSelect,
  data,
  ...props
}) {
  return (
    <div
      className={c('device', className, { 'device--open': open })}
      {...props}
    >
      <div className="device__head" onClick={() => onSelect(data)}>
        <div className="d-flex align-items-center">
          <IconContainer className="me-3" md>
            {icon}
          </IconContainer>
          <div className="flex-grow-1">
            <div className="d-lg-flex align-items-center">
              <div className="device__model">{data.model}</div>
              <div className="device__code">{data.nickname}</div>
            </div>
          </div>
          <DropdownBtn up={open} />
        </div>
      </div>
      {open && <div className="device__body">{children}</div>}
    </div>
  )
}
export default Device

Device.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onSelect: PropTypes.func,
  icon: PropTypes.node,
  done: PropTypes.bool,
  open: PropTypes.bool,
  data: PropTypes.shape({
    model: PropTypes.string,
    code: PropTypes.string,
  }),
}

Device.defaultProps = {
  className: '',
  children: undefined,
  onSelect: () => {},
  icon: undefined,
  done: false,
  open: false,
  data: {
    model: '',
    code: '',
  },
}
