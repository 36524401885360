import React from 'react'

export function AppleControl() {
  return (
    <svg
      width="45"
      height="37"
      viewBox="0 0 45 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="6" width="25" height="25" rx="5" fill="white" />
      <rect x="31" y="1" width="13" height="35" rx="2" fill="white" />
      <path
        d="M4.30645 30.7568H22.1613C23.9798 30.7568 25.4677 29.2689 25.4677 27.4503V9.59551C25.4677 7.77697 23.9798 6.28906 22.1613 6.28906H4.30645C2.4879 6.28906 1 7.77697 1 9.59551V27.4503C1 29.2689 2.4879 30.7568 4.30645 30.7568Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M33.4186 36.0484H41.3541C42.8089 36.0484 43.9992 34.8581 43.9992 33.4032V3.64516C43.9992 2.19032 42.8089 1 41.3541 1H33.4186C31.9638 1 30.7734 2.19032 30.7734 3.64516V33.4032C30.7734 34.8581 31.9638 36.0484 33.4186 36.0484Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M31.1055 15.875H43.67"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.0651 19.846C36.0651 20.5764 35.4729 21.1686 34.7425 21.1686C34.0121 21.1686 33.4199 20.5764 33.4199 19.846C33.4199 19.1157 34.0121 18.5234 34.7425 18.5234C35.4729 18.5234 36.0651 19.1157 36.0651 19.846Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.0651 11.9163C36.0651 12.6467 35.4729 13.2389 34.7425 13.2389C34.0121 13.2389 33.4199 12.6467 33.4199 11.9163C33.4199 11.186 34.0121 10.5938 34.7425 10.5938C35.4729 10.5938 36.0651 11.186 36.0651 11.9163Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M41.3541 11.9163C41.3541 12.6467 40.7619 13.2389 40.0316 13.2389C39.3012 13.2389 38.709 12.6467 38.709 11.9163C38.709 11.186 39.3012 10.5938 40.0316 10.5938C40.7619 10.5938 41.3541 11.186 41.3541 11.9163Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.0651 25.1351C36.0651 25.8654 35.4729 26.4577 34.7425 26.4577C34.0121 26.4577 33.4199 25.8654 33.4199 25.1351C33.4199 24.4047 34.0121 23.8125 34.7425 23.8125C35.4729 23.8125 36.0651 24.4047 36.0651 25.1351Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M40.0316 26.4589C39.3041 26.4589 38.709 25.8638 38.709 25.1363V19.846C38.709 19.1186 39.3041 18.5234 40.0316 18.5234C40.759 18.5234 41.3541 19.1186 41.3541 19.846V25.1363C41.3541 25.8638 40.759 26.4589 40.0316 26.4589Z"
        stroke="#060920"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
export default AppleControl
