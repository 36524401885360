import React from 'react'

export function IconPhoto() {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="2" width="19" height="16" rx="5" fill="white" />
      <path
        d="M19.0382 2.27167H16.3741L16.2916 1.54877C16.2502 1.17711 16.1057 0.846632 15.8786 0.598694C15.5273 0.206412 15.0524 0 14.5567 0H8.4436C7.86534 0 7.34903 0.268524 7.01856 0.743481C6.85332 0.970663 6.77077 1.23917 6.72943 1.5283L6.62614 2.27164H3.96207C2.88812 2.27164 2 3.15976 2 4.23356V12.9075C2 13.9815 2.88797 14.8694 3.96193 14.8694H19.0381C20.112 14.8694 21 13.9815 21 12.9075V4.23356C21.0001 3.15976 20.112 2.27164 19.0381 2.27164L19.0382 2.27167ZM19.5545 12.9076C19.5545 13.1967 19.3273 13.4239 19.0382 13.4239H3.96203C3.67291 13.4239 3.44573 13.1967 3.44573 12.9076V4.23364C3.44573 3.94451 3.67291 3.71733 3.96203 3.71733H7.26647C7.63813 3.71733 7.94799 3.44881 7.98937 3.07715L8.15461 1.69345C8.17521 1.6109 8.19595 1.56956 8.19595 1.56956C8.2579 1.48701 8.34059 1.44567 8.44373 1.44567H14.5568C14.6393 1.44567 14.722 1.48701 14.784 1.54896C14.8046 1.56956 14.8253 1.6315 14.8459 1.71419L15.0112 3.07728C15.0525 3.44894 15.3622 3.71746 15.7341 3.71746H19.0385C19.3276 3.71746 19.5548 3.94464 19.5548 4.23377L19.5545 12.9076Z"
        fill="#060920"
      />
      <path
        d="M11.4983 3.40625C8.9374 3.40625 6.85156 5.49209 6.85156 8.05301C6.85156 10.6139 8.9374 12.6998 11.4983 12.6998C14.0592 12.6998 16.1451 10.6139 16.1451 8.05301C16.1451 5.49209 14.0592 3.40625 11.4983 3.40625ZM11.4983 11.2541C9.7428 11.2541 8.29718 9.80849 8.29718 8.05297C8.29718 6.29745 9.7428 4.85183 11.4983 4.85183C13.2538 4.85183 14.6995 6.29745 14.6995 8.05297C14.6995 9.80849 13.2538 11.2541 11.4983 11.2541Z"
        fill="#060920"
      />
    </svg>
  )
}
export default IconPhoto

IconPhoto.propTypes = {}

IconPhoto.defaultProps = {}
