import React from 'react'

export function IconUploadv2() {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.04871"
        y="2.04883"
        width="18.7317"
        height="18.7317"
        rx="5"
        fill="white"
      />
      <path
        d="M8.14415 20.746H10.9826C11.4671 20.746 11.8479 20.3652 11.8479 19.8807V8.35449L14.617 11.1236C14.9632 11.4698 15.5169 11.4698 15.8286 11.1236C16.1748 10.7774 16.1748 10.2237 15.8286 9.91202L11.6055 5.65467C11.4324 5.48155 11.2248 5.41226 10.9826 5.41226C10.7402 5.41226 10.5326 5.48155 10.3597 5.65467L6.1369 9.91227C5.79067 10.2585 5.79067 10.8122 6.1369 11.1239C6.48313 11.4701 7.03678 11.4701 7.34848 11.1239L10.1176 8.35474V19.0156H8.14453C5.20228 19.0156 2.77943 16.5927 2.77943 13.6505V7.97369C2.77943 5.03144 5.20228 2.60859 8.14453 2.60859H13.8556C16.7978 2.60859 19.2207 5.03144 19.2207 7.97369V13.6847C19.2207 16.7308 17.0747 19.0498 14.2018 19.0498C13.7173 19.0498 13.3365 19.4306 13.3365 19.9152C13.3365 20.3997 13.7173 20.7805 14.2018 20.7805C17.9746 20.7805 20.9514 17.6654 20.9514 13.6848V7.97375C20.9514 4.0624 17.767 0.878052 13.8557 0.878052L8.1444 0.878293C4.23306 0.878293 1.04871 4.0627 1.04871 7.97399V13.685C1.04895 17.5616 4.2333 20.7461 8.1444 20.7461L8.14415 20.746Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default IconUploadv2

IconUploadv2.propTypes = {}

IconUploadv2.defaultProps = {}
