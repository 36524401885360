import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../components/page-header'
import ProofLossForm from '../components/proof-loss-form'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import { UPL_TXT, UPL_DESCRIPTION_TXT } from '../i18n'

export function ProofLossPage() {
  useStoreCurrentSteep()
  const { t } = useTranslation()
  return (
    <div>
      <PageHeader title={t(UPL_TXT)} subtitle={t(UPL_DESCRIPTION_TXT)} />
      <ProofLossForm />
    </div>
  )
}
export default ProofLossPage
