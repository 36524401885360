import React, { useState } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import {
  addProofLossImages,
  updateDynamicField,
  setTimerId,
  checkImagesStatus,
  submitImage,
  deleteClaimImage,
} from '../../reducers/claim'
import {
  selectClaimItemAffectedByID,
  selectClaimProofLossImages,
  selectTimerId,
} from '../../selectors/claim'
import { CHECK_IMAGES_STATUS_TIMER } from '../../constants'
import { propTypes, defaultProps } from './props'
import ProofLossListView from '../proof-loss-list/view'

// DEPREACATED
export function Images({
  className,
  label,
  instruction,
  images,
  modifyable,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
  disabled,
  evidence_type_id,
  ...props
}) {
  const dispatch = useDispatch()
  const timerId = useSelector(selectTimerId)
  const proofLossImages = useSelector(selectClaimProofLossImages)

  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )

  // This solution is to keep the original name files
  const [tmpOriginalImageNames, setTmpOriginalImageNames] = useState({})

  const onChangeImage = (id, event) => {
    const file = event.nativeEvent.target.files[0]
    const hash = uuidv4()
    const filename = `CLAIM/${claimId}/${hash.substring(0, 4)}_${file.name}`
    setTmpOriginalImageNames({
      ...tmpOriginalImageNames,
      [filename]: file.name,
    })
    const data = {
      id: null,
      claimId,
      device_id: deviceId,
      file_name: filename,
      file_size: file.size,
      file_type: file.type,
      processing_status: true,
      file,
      filename,
      public_url: URL.createObjectURL(file),
      evidence_type_id,
    }
    dispatch(addProofLossImages(data))
    dispatch(submitImage(data))
    dispatch(
      updateDynamicField({
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'images',
        evidence_type_id,
      })
    ).then(() => {
      onChange({ fieldId })
    })
    // execute only one time
    // TODO, move to hooks
    if (timerId === null) {
      const newTimerId = setInterval(() => {
        dispatch(checkImagesStatus({ claimId }))
      }, CHECK_IMAGES_STATUS_TIMER)
      dispatch(setTimerId(newTimerId))
    }
  }

  const onDelete = (id) => {
    dispatch(deleteClaimImage({ deviceId: device.id, imageId: id }))
  }
  return (
    <div className={c('images mt-2', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      <ProofLossListView
        {...props}
        className={c(className, { modifyable })}
        images={proofLossImages[device.id]}
        tmpOriginalImageNames={tmpOriginalImageNames}
        onDelete={onDelete}
        onChangeImage={onChangeImage}
        device={device}
        modifyable={modifyable}
        disabled={disabled}
        fieldId={fieldId}
      />
    </div>
  )
}
export default Images

Images.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  device: PropTypes.shape({
    id: PropTypes.number,
  }),
  modifyable: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),
}

Images.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  device: undefined,
  images: [],
  modifyable: true,
}
