export const generateInitialValues = (itemId, currentDevice) => {
  let initialValues = {}
  if (itemId) {
    initialValues = {
      kind: currentDevice.item_type,
      kind_id: currentDevice.item_type_id,
      brand: currentDevice.brand,
      brand_id: currentDevice.item_make_id,
      model: currentDevice.model,
      model_id: currentDevice.item_model_id,
      purchase_price: currentDevice.price,
      condition_purchased: currentDevice.item_purchase_condition_description,
      condition_purchased_id: currentDevice.item_purchase_condition_id,
      date_purchased: new Date(currentDevice.purchase_date),
      location_purchased: currentDevice.purchased_at,
      serial_number: currentDevice.serial_number,
      nickname: currentDevice.nickname,
      // conditionals
      carrier: currentDevice.mobile_operator,
      carrier_id: currentDevice.mobile_operator_id,
      imei: currentDevice.imei,
      is_touch_screen: currentDevice.touchscreen,
      installments: currentDevice.installments,
      model_number: currentDevice.device_identifier,
      screen_size: currentDevice.screen_size || '',
      storage: currentDevice.storage || '',
      smart_tv: currentDevice.smart_tv || false,
    }
  } else {
    initialValues = {
      kind: '',
      kind_id: '',
      brand: '',
      brand_id: '',
      model: '',
      model_id: '',
      purchase_price: '',
      condition_purchased: '',
      condition_purchased_id: '',
      date_purchased: '',
      location_purchased: '',
      serial_number: '',
      nickname: '',
      // conditionals
      carrier: '',
      carrier_id: '',
      imei: '',
      is_touch_screen: false,
      installments: false,
      model_number: '',
      screen_size: '',
      storage: '',
      smart_tv: false,
    }
  }
  return initialValues
}
