import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import StatusLabel from '../status-label'
import { getIcon } from '../icons'
import { CLAIM_DETAIL_PATH, AFFECTED_ITEMS_PATH } from '../../constants'
import IconChevronRedRight from '../icons/icon-chevron-red-right'
import ClaimsListMobileDevice from '../claims-list-mobile-device'

export function ClaimsListMobile({ className, claims, ...props }) {
  const onSelectClaim = (status, id) => {
    const path = status === 'DRAFT' ? AFFECTED_ITEMS_PATH : CLAIM_DETAIL_PATH
    return `${path}/${id}`
  }

  return (
    <div className={c('claims-list-mobile', className)} {...props}>
      <div className="claims-list__items-card row">
        {claims.map((item) => (
          <div className="col-12" key={item.id}>
            <div className="shadow card border-light mb-4">
              <div className="card-body position-relative">
                <Link to={onSelectClaim(item.status, item.id)}>
                  <div className="row mt-3">
                    <div className="col-6">
                      <div className="flex-grow-1">
                        <div className="claim-list-item__main">
                          Claim #{item.id}
                        </div>
                        {item.status !== 'DRAFT' ? (
                          <div className="claim-list-item__main-subtitle">
                            View Claim Details&nbsp;
                            <IconChevronRedRight />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6 text-end">
                      <StatusLabel className={item.status.toLowerCase()}>
                        {item.status}
                      </StatusLabel>
                    </div>
                  </div>
                </Link>
                {item.status !== 'DRAFT' ? (
                  <div>
                    <hr className="border border-1" />
                  </div>
                ) : null}
                {item.status !== 'DRAFT' ? (
                  <div className="claims-list-items__devices">
                    {item.devices.map((itemDevice) => (
                      <div className="col-12" key={itemDevice.id}>
                        <ClaimsListMobileDevice
                          icon={getIcon(itemDevice.kind)}
                          main={itemDevice.model}
                          lossTypes={itemDevice.loss_types}
                          claimId={item.id}
                          deviceId={itemDevice.device_id}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ClaimsListMobile

ClaimsListMobile.propTypes = {
  className: PropTypes.string,
  claims: PropTypes.array,
  main: PropTypes.string,
  second: PropTypes.string,
}

ClaimsListMobile.defaultProps = {
  className: '',
  claims: [],
  main: '',
  second: '',
}
