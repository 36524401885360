import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { IconCheckWhite } from '../icons/icon-check-white'

const PAYPAL = 'Paypal'
const VENMO = 'Venmo'
const WIRE = 'Wire'

export function Confirmation({ className, payDate, paymentMethod }) {
  let method = null
  let target = null
  let message = ''
  if (!isEmpty(paymentMethod.paypalAccount)) {
    method = PAYPAL
    target = paymentMethod.paypalAccount
    message = `Payout completed to ${method} account ${target}`
  } else if (!isEmpty(paymentMethod.venmoAccount)) {
    method = VENMO
    target = paymentMethod.venmoAccount
    message = `Payout completed to ${method} account ${target}`
  } else if (!isEmpty(paymentMethod.wireAccount)) {
    method = WIRE
    target = paymentMethod.wireAccount
    message = `A reward link containing the prepaid card was sent to ${target}`
  }
  return (
    <div className={c('payment-confirmation', className)}>
      <div className="message">
        <div className="check">
          <IconCheckWhite />
        </div>
        <div className="detail">
          <div className="detail_txt">{message}</div>
          <div className="detail_date">
            {moment(payDate).format('DD MMM YYYY')}
          </div>
        </div>
      </div>
      <div>
        {method === PAYPAL && (
          <img
            className="payment-logo"
            alt="payment logo"
            src="/images/paypal-logo.png"
          />
        )}
        {method === VENMO && (
          <img
            className="payment-logo"
            alt="payment logo"
            src="/images/venmo-logo.png"
          />
        )}
      </div>
    </div>
  )
}
export default Confirmation

Confirmation.propTypes = {
  className: PropTypes.string,
  payDate: PropTypes.string,
  paymentMethod: PropTypes.shape({
    paypalAccount: PropTypes.string,
    venmoAccount: PropTypes.string,
    wireAccount: PropTypes.string,
  }),
}

Confirmation.defaultProps = {
  className: '',
  payDate: '',
  paymentMethod: null,
}
