import React from 'react'

export function IconEdit() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4988 1.74599H1.28706H0.800781V14.3891H12.9576V3.69108L14.4165 2.71853L12.4714 0.773438L11.4988 1.74599Z"
        fill="white"
      />
      <path
        d="M14.7319 2.20515L12.9813 0.454568C12.7555 0.228686 12.3978 0.228686 12.1907 0.454568L4.56724 8.05926C4.52959 8.0969 4.49194 8.15337 4.4543 8.20984L2.91077 11.504C2.81665 11.711 2.8543 11.9745 3.02371 12.144C3.13665 12.2569 3.26842 12.3134 3.41901 12.3134C3.4943 12.3134 3.58842 12.2945 3.66371 12.2569L6.95782 10.7134C7.01429 10.6945 7.07076 10.6569 7.10841 10.6004L14.7319 2.97691C14.8449 2.86397 14.9013 2.73221 14.9013 2.58162C14.9013 2.43103 14.8449 2.29927 14.7319 2.20515ZM6.37429 9.75337L4.58606 10.6004L5.43312 8.81219L10.8731 3.37221L11.8331 4.33221L6.37429 9.75337ZM12.6049 3.5228L11.6449 2.5628L12.5672 1.64045L13.5272 2.60045L12.6049 3.5228Z"
        fill="black"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M13.1516 6.76067C12.8316 6.76067 12.5869 7.00537 12.5869 7.32537V7.62655V13.5559H1.44337V2.35597H2.64808H3.17513H8.33277C8.65277 2.35597 8.89747 2.11127 8.89747 1.79127C8.89747 1.47127 8.65277 1.22656 8.33277 1.22656H3.17513H2.64808H1.44337C0.822199 1.22656 0.313965 1.7348 0.313965 2.35597V13.5559C0.313965 14.1771 0.822199 14.6854 1.44337 14.6854H12.5869C13.2081 14.6854 13.7163 14.1771 13.7163 13.5559V11.316V7.62655V7.32537C13.7163 7.0242 13.4528 6.76067 13.1516 6.76067Z"
        fill="black"
        stroke="#E8E7E5"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default IconEdit

IconEdit.propTypes = {}

IconEdit.defaultProps = {}
