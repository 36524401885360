import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import c from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import PaymentMethod from '../payment'
import Confirmation from '../payment/confirmation'
import Breakdown from '../payment/breakdown'
import Agreement from '../payment/agreement'
import Amount from '../payment/amount'
import Label from '../label'
import Spinner from '../spinner'
import { selectFeatureFlagByName } from '../../selectors/feature-flags'
import { propTypes, defaultProps } from './props'
import {
  selectClaimDevicePayment,
  selectClaimItemAffectedByID,
  selectClaimLimits,
  selectClaimPaymentMethod,
} from '../../selectors/claim'
import { updateDynamicField, submitPayment } from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'
import { calculatePayment } from '../../reducers/claim/thunks'

export function Payment({
  className,
  onChange,
  label,
  instruction,
  children,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  field_state: fieldState,
  disabled,
  completed,
  ...props
}) {
  const [loading, setLoading] = useState(false)
  const [loadingPaymentCalc, setLoadingPaymentCalc] = useState(false)
  const [openBreakdown, setOpenBreakdown] = useState(false)
  const [paymentDoing, setPaymentDoing] = useState(false)
  const [savingPayoutMethod, setSavingPayoutMethod] = useState(false)
  const [error, setError] = useState(false)
  const [showPaymentForm, setPaymentForm] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState(
    useSelector((state) =>
      selectClaimDevicePayment(state, parseInt(claimDeviceId, 10))
    )
  )
  const noPayment = paymentInfo.payoutDetailTotalPayoutAmount <= 0
  const [noPaymentConfirmation, setNoPaymentConfirmation] = useState(
    fieldState === 'DONE'
  )

  const dispatch = useDispatch()

  const onShowPaymentForm = () => {
    setPaymentForm(!showPaymentForm)
  }

  const claimPaymentMethod = useSelector(selectClaimPaymentMethod)

  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )

  const featureFlagClaimLimit = useSelector((_) =>
    selectFeatureFlagByName(_, 'claim_limits')
  )
  const claimLimits = useSelector(selectClaimLimits)

  const update = useCallback(
    debounce((extraPayload) => {
      setLoading(true)
      setSavingPayoutMethod(true)
      dispatch(
        updateDynamicField({
          claim_id: claimId,
          claim_device_id: claimDeviceId,
          device_id: deviceId,
          field_id: fieldId,
          handler: 'payout',
          no_payment: extraPayload.noPayment,
          confirm_no_payment: extraPayload.confirmNoPayment,
        })
      ).then(() => {
        /**
         * usually this method is dispatched here but
         * now is dispatched below when the payment success
         * leave commented as reference
         * */
        // onChange({ fieldId })
        setLoading(false)
        setSavingPayoutMethod(false)
      })
    }, DELAY_TIMING),
    []
  )

  const onPayme = (data) => {
    setError(false)
    setPaymentDoing(true)
    dispatch(
      submitPayment({
        claimId,
        claimDeviceId,
        fieldId,
      })
    )
      .unwrap()
      .then((response) => {
        setPaymentDoing(false)
        // Once payment is completed, mark this field as completed
        onChange({ fieldId })
      })
      .catch((err) => {
        setError(true)
        setPaymentDoing(false)
      })
  }

  const onToggleBreakDown = () => setOpenBreakdown(!openBreakdown)

  const onNoPaymentConfirmation = () => {
    const newState = !noPaymentConfirmation
    setNoPaymentConfirmation(newState)
    update({ noPayment: true, confirmNoPayment: newState })
    // we need to update the payment field as completed
    // since is required but should not be completed
    onChange({ fieldId, newState: newState ? 'DONE' : 'TODO' })
  }

  const onChangePaymentFields = () => {
    update({})
  }

  const onContactUs = () => {
    window.Intercom('show')
  }

  useEffect(() => {
    if (!device.paid) {
      setLoadingPaymentCalc(true)
      dispatch(calculatePayment({ claimId, claimDeviceId }))
        .unwrap()
        .then((response) => {
          setLoadingPaymentCalc(false)
          setPaymentInfo({
            payoutDetailReplacementCost: response.replacement_cost,
            payoutDetailTotalReplaceCost: response.total_replacement_cost,
            payoutDetailTaxes: response.taxes,
            payoutDetailTaxesAmount: response.taxes_amount,
            payoutDetailReplaceCostWithTaxes: response.replace_cost_with_taxes,
            payoutDetailTotalPayoutAmount: response.total_payout_amount,
            payoutDetailDeductible: response.deductible,
            payoutDetailDeductibleCredit: response.total_credit_used,
            payoutDetailHasAppliedClaimLimit: response.has_applied_claim_limit,
            payoutDetailClaimLimitValue: response.claim_limit_value,
          })
        })
    }
  }, [])
  if (loadingPaymentCalc) return <Spinner />
  if (featureFlagClaimLimit === true && isEmpty(claimLimits))
    return <div>Please contact support</div>
  return (
    <div className={c('payment', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      <div>
        <Amount
          loading={loadingPaymentCalc}
          totalPayoutAmount={paymentInfo.payoutDetailTotalPayoutAmount}
          onToggleBreakDown={onToggleBreakDown}
          openBreakdown={openBreakdown}
          limitApplied={paymentInfo.payoutDetailHasAppliedClaimLimit}
        />
        {openBreakdown && (
          <Breakdown
            repair={device.repair}
            replace={device.replace}
            openBreakdown={openBreakdown}
            cost={paymentInfo.payoutDetailReplacementCost}
            costWithTaxes={paymentInfo.payoutDetailReplaceCostWithTaxes}
            taxes={paymentInfo.payoutDetailTaxes}
            taxesAmount={paymentInfo.payoutDetailTaxesAmount}
            finalCost={paymentInfo.payoutDetailTotalReplaceCost}
            credit={paymentInfo.payoutDetailDeductibleCredit}
            deductible={paymentInfo.payoutDetailDeductible}
            totalPayoutAmount={paymentInfo.payoutDetailTotalPayoutAmount}
            limitApplied={paymentInfo.payoutDetailHasAppliedClaimLimit}
            claimLimitValue={paymentInfo.payoutDetailClaimLimitValue}
          />
        )}
        {paymentInfo.payoutDetailTotalPayoutAmount <= 0 && (
          <div className="payment-zero">
            Based on the claim cost and your deductible, the cost of this claim
            is $0 or less. No payout is required for this claim
          </div>
        )}
        <div className="payment-divider" />
        {noPayment ? (
          <Agreement
            onChecked={onNoPaymentConfirmation}
            checked={noPaymentConfirmation}
            disabled={disabled}
            label="Agree that the above mentioned amount is correct"
            id="no-payment-confirmation"
          />
        ) : null}
        {!device.paid && !noPayment ? (
          <Agreement
            onChecked={onShowPaymentForm}
            checked={showPaymentForm}
            disabled={disabled}
            label="Agree that the above mentioned amount is correct"
            id="show-payment-form"
          />
        ) : null}
        {!device.paid && showPaymentForm && (
          <PaymentMethod
            claimPaymentMethod={claimPaymentMethod}
            onSubmit={onPayme}
            onChange={onChangePaymentFields}
            loading={paymentDoing}
            savingPayoutMethod={savingPayoutMethod}
            disabled={disabled}
          />
        )}
        {device.paid && (
          <Confirmation
            payDate={device.payoutTransactionDateCreated}
            paymentMethod={claimPaymentMethod}
          />
        )}
      </div>
      {error && (
        <div className="bg-warning bg-opacity-10 p-3 mt-2 rounded">
          Something went wrong please{' '}
          <button
            type="button"
            onClick={onContactUs}
            className="btn btn-sm btn-link"
          >
            contact support
          </button>
        </div>
      )}
    </div>
  )
}
export default Payment

Payment.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

Payment.defaultProps = {
  ...defaultProps,
  className: '',
  children: null,
  disabled: false,
}
