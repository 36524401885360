import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import IconPhone from '../icons/icon-phone'
import IconLossType from '../icons/icon-loss-type'
import IconEdit from '../icons/icon-edit'
import IconShield from '../icons/icon-shield'
import IconSearch from '../icons/icon-search'
import IconImage from '../icons/icon-image'
import MenuItem from '../menu-item'
import {
  DESCRIBE_INCIDENT_PATH,
  AFFECTED_ITEMS_PATH,
  TYPE_LOSS_PATH,
  REVIEW_CLAIM_PATH,
  PROOF_LOSS_PATH,
  CONFIRM_ADDRESS_PATH,
} from '../../constants'
import {
  claimStore,
  selectClaimID,
  selectClaimItemsAffected,
} from '../../selectors/claim'
import {
  AFFECTED_ITEMS_TXT,
  TYPE_LOSS_TXT,
  INCIDENT_TXT,
  REVIEW_TXT,
  PROFF_LOSS_TXT,
} from '../../i18n'
import useValidation from '../../hooks/validations-schema'
import {
  affectedItemsValidationSchema,
  lossTypesSchemaValidation,
  incidentValidationSchema,
  reviewValidationSchema,
  addressValidationSchema,
} from '../../utils/validation'
import { selectUserAddress } from '../../selectors/user'

export function SideBarMenu({ className }) {
  const claim = useSelector(claimStore)
  let claimID = useSelector(selectClaimID)
  const { t } = useTranslation()
  const devices = useSelector(selectClaimItemsAffected)
  claimID = claimID || ''
  const address = useSelector(selectUserAddress)

  const everyImagesIsProcessed = () => {
    if (isEmpty(claim.proofLossImages)) {
      return false
    }
    const values = Object.values(claim.proofLossImages)
    for (let i = 0; i < values.length; i += 1) {
      if (values[i].length <= 0) {
        return false
      }
      if (values[i].some((image) => image.processing_status === true)) {
        return false
      }
    }
    return true
  }
  return (
    <div className={`navigation-menu ${className}`}>
      <div className="navigation-menu__item">
        <MenuItem
          to={`/${AFFECTED_ITEMS_PATH}/${claimID}`}
          icon={<IconPhone />}
          label={t(AFFECTED_ITEMS_TXT)}
          done={isEmpty(
            useValidation(affectedItemsValidationSchema, {
              affectedItems: claim.affectedItems,
            })[0]
          )}
        />
      </div>
      <div className="navigation-menu__item">
        <MenuItem
          disabled={devices.length <= 0}
          to={`/${TYPE_LOSS_PATH}/${claimID}`}
          icon={<IconLossType />}
          label={t(TYPE_LOSS_TXT)}
          done={isEmpty(
            useValidation(
              lossTypesSchemaValidation,
              Object.values(claim.typeLoss)[0]
            )[0]
          )}
        />
      </div>
      <div className="navigation-menu__item">
        <MenuItem
          disabled={devices.length <= 0}
          to={`/${DESCRIBE_INCIDENT_PATH}/${claimID}`}
          icon={<IconEdit />}
          label={t(INCIDENT_TXT)}
          done={isEmpty(
            useValidation(incidentValidationSchema, {
              date: claim.date,
              incident: claim.incident,
            })[0]
          )}
        />
      </div>
      <div className="navigation-menu__item d-none">
        <MenuItem
          disabled={devices.length <= 0}
          to={`/${PROOF_LOSS_PATH}/${claimID}`}
          icon={<IconImage />}
          label={t(PROFF_LOSS_TXT)}
          done={everyImagesIsProcessed()}
        />
      </div>
      <div className="navigation-menu__item navigation-menu--item-confirm">
        <MenuItem
          disabled={devices.length <= 0}
          to={`/${CONFIRM_ADDRESS_PATH}/${claimID}`}
          icon={<IconShield />}
          label="Confirm Address"
          done={isEmpty(
            useValidation(addressValidationSchema, {
              address,
            })[0]
          )}
        />
      </div>
      <div className="navigation-menu__item navigation-menu--item-confirm">
        <MenuItem
          disabled={devices.length <= 0}
          to={`/${REVIEW_CLAIM_PATH}/${claimID}`}
          icon={<IconSearch />}
          label={t(REVIEW_TXT)}
          done={isEmpty(
            useValidation(reviewValidationSchema, {
              acceptedTermsConditions: claim.acceptedTermsConditions,
            })[0]
          )}
        />
      </div>
    </div>
  )
}
export default SideBarMenu

SideBarMenu.propTypes = {
  className: PropTypes.string,
}

SideBarMenu.defaultProps = {
  className: '',
}
