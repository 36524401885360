import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Exs from '../icons/exs'
import ProcessingMessage from './proccessing-message'

export function Evidence({ className, onClose, evidences }) {
  const [currentImage, setCurrentImage] = useState(evidences[0])

  const onSelectThumb = (id) => {
    const found = evidences.find((img) => img.id === id)
    setCurrentImage(found)
  }

  const getSrc = (type, src) => {
    if (/image/.test(type)) return src
    if (/application/.test(type)) return '/images/file.png'
    if (/video/.test(type)) return '/images/video.png'
    return '/images/image.png'
  }

  return (
    <div className={c('evidence', className)}>
      <div className="modal show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="evidence__content">
                <button
                  onClick={onClose}
                  type="button"
                  className="evidence__close"
                >
                  <Exs />
                </button>
                <div className="evidence__heading">
                  <h2 className="evidence__title">Upload Evidence</h2>
                  <div className="evidence__desc">
                    View uploaded media evidence for the claim
                  </div>
                </div>
                <div>
                  <div className="image">
                    <div className="image__sidebar">
                      {evidences.map((img) => (
                        <button
                          onClick={() => onSelectThumb(img.id)}
                          key={img.id}
                          type="button"
                          className="image__thumb"
                        >
                          <img
                            alt=""
                            src={getSrc(img.file_type, img.public_url)}
                          />
                        </button>
                      ))}
                    </div>
                    <div
                      className={c('image__view', {
                        'image-processing':
                          currentImage.processing_status === null,
                      })}
                    >
                      {currentImage.processing_status === null ? (
                        <div>
                          <ProcessingMessage />
                          <img
                            src={getSrc(
                              currentImage.file_type,
                              currentImage.public_url
                            )}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div>
                          {/image/.test(currentImage.file_type) ? (
                            <img src={currentImage.public_url} alt="" />
                          ) : null}
                          {/application/.test(currentImage.file_type) ? (
                            <embed
                              src={`${currentImage.public_url}#&toolbar=0&navpanes=0`}
                              width="100%"
                              height="800px"
                              type="application/pdf"
                              frameBorder="0"
                            />
                          ) : null}
                          {/video/.test(currentImage.file_type) ? (
                            <video width="100%" controls>
                              <source
                                src={currentImage.public_url}
                                type="video/mp4"
                              />
                            </video>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  )
}
export default Evidence

Evidence.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  evidences: PropTypes.array,
}
Evidence.defaultProps = {
  className: '',
  onClose: () => {},
  evidences: [],
}
