import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserData, selectUserToken } from '../../selectors/user'
import useInitialRequest from '../../hooks/initial-request'
import Preloading from '../preloading'
import { selectAppLoadingState } from '../../selectors/app'
import { LOGIN_PATH } from '../../constants'

export function Auth() {
  useInitialRequest()
  const navigate = useNavigate()
  const userData = useSelector(selectUserData)
  const appIsLoading = useSelector(selectAppLoadingState)
  const token = useSelector(selectUserToken)
  useEffect(() => {
    if (window.Intercom && userData.user_id) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: userData.email,
        name: `${userData.firstname}  ${userData.lastname}`,
        user_id: userData.user_id.toString(),
        user_hash: userData.intercom_hashes.web_hash,
      })
    } else if (!token) {
      navigate(LOGIN_PATH)
    }
  }, [userData.user_id])
  return <div>{appIsLoading ? <Preloading /> : <Outlet />}</div>
}
export default Auth

Auth.propTypes = {}

Auth.defaultProps = {}
