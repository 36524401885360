import React from 'react'

export function IconNoItem() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3874_6043"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="44"
        height="45"
      >
        <path
          d="M0.119141 0.631748H43.87V44.5488H0.119141V0.631748Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3874_6043)">
        <path
          d="M42.5906 12.9488H1.40332V37.755C1.40332 40.7993 3.86217 43.2676 6.89499 43.2676H37.099C40.1318 43.2676 42.5906 40.7993 42.5906 37.755V12.9488Z"
          fill="white"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.5906 7.42887C42.5906 4.38392 40.1318 1.91633 37.099 1.91633H6.89499C3.86217 1.91633 1.40332 4.38392 1.40332 7.42887V12.9414H42.5906V7.42887Z"
          fill="white"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9375 27.1582C21.0743 26.0178 22.9194 26.0178 24.0561 27.1582"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.6064 22.5957V21.2175"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.89453 7.4375H8.26739"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0723 7.4375H14.4452"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.252 7.4375H20.6249"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3877 22.5957V21.2175"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.5906 7.43473C42.5906 4.38978 40.1318 1.92219 37.099 1.92219H6.89499C3.86217 1.92219 1.40332 4.38978 1.40332 7.43473V12.9473H42.5906V7.43473Z"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.5906 12.9488H1.40332V37.755C1.40332 40.7993 3.86217 43.2676 6.89499 43.2676H37.099C40.1318 43.2676 42.5906 40.7993 42.5906 37.755V12.9488Z"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export default IconNoItem

IconNoItem.propTypes = {}

IconNoItem.defaultProps = {}
