import React from 'react'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import c from 'classnames'
import Header from '../header'
import { selectBrand } from '../../selectors/app'

export function HomeLayout({ className }) {
  const brandName = useSelector(selectBrand)

  return (
    <div className={c('page', className, brandName)}>
      <Header />
      <div className={c('container-fluid mb-5')}>
        <Outlet />
      </div>
    </div>
  )
}
export default HomeLayout

HomeLayout.propTypes = {
  className: PropTypes.string,
}

HomeLayout.defaultProps = {
  className: '',
}
