import { createSelector } from '@reduxjs/toolkit'

export const losstypesStore = (state) => state.losstypes

export const selectLossTypesList = createSelector(
  losstypesStore,
  (losstypes) => losstypes.list
)

export const selectLossTypesListForSelect = createSelector(
  selectLossTypesList,
  // this filter is temporal since claims-v2 only support Accidental Damage
  // (list) => list.filter((l) => l.name === 'Accidental Damage').map((l) => {
  (list) =>
    list.map((l) => {
      return {
        label: l.name,
        category_id: l.id,
        options: l.loss_types.map((opt) => {
          return {
            value: opt.name,
            label: opt.name,
            category: l.name,
            id: opt.id,
          }
        }),
      }
    })
)

export const selectLossTypeIdsItemOperabilities = createSelector(
  losstypesStore,
  (losstypes) => {
    const validItemOperabilities = []
    losstypes.list.map((parent) =>
      parent.loss_types.map((child) => {
        if (
          child.name !== 'Theft' &&
          child.name !== 'Theft from Unattended Vehicle' &&
          child.name !== 'Vandalism'
        ) {
          validItemOperabilities.push(child.id)
        }
      })
    )
    return validItemOperabilities
  }
)
