import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { propTypes, defaultProps } from './props'
import { selectUserAddress } from '../../selectors/user'
import { selectPartnerPropertyRepairInfo } from '../../selectors/partner'
import { selectUserDeviceById } from '../../selectors/devices'

export function RepairShop({
  className,
  label,
  instruction,
  prefill,
  claimDeviceId,
  deviceId,
}) {
  const userAddress = useSelector(selectUserAddress)
  const propertyRepairInfo = useSelector(selectPartnerPropertyRepairInfo)
  const currentDevice = useSelector((state) =>
    selectUserDeviceById(state, Number(deviceId))
  )
  let offerRepairDeviceService = null
  if (propertyRepairInfo.isRepair) {
    offerRepairDeviceService = propertyRepairInfo.repairDevices.find(
      (d) => d === currentDevice.kind
    )
  }

  return (
    <div className={c('repair-shops', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div className="mb-1">
        {propertyRepairInfo.isRepair && !!offerRepairDeviceService ? (
          <div className="repair-shops__property-info">
            <div className="repair-shops__property-name">
              {propertyRepairInfo.name}
            </div>
            <div className="repair-shops__property-address">
              {propertyRepairInfo.address}
            </div>
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/${propertyRepairInfo.address}/data=!4m4!2m3!5m1!4e3!6e6`}
              className="btn btn-outline-primary"
              rel="noreferrer"
            >
              View in Map
            </a>
          </div>
        ) : (
          <a
            target="_blank"
            href={`https://www.google.com/maps/search/${currentDevice.kind}+repair+shops+near+${userAddress.zip}/data=!4m4!2m3!5m1!4e3!6e6`}
            className="btn btn-primary"
            rel="noreferrer"
          >
            {prefill || 'Nearest repair shops'}
          </a>
        )}
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default RepairShop

RepairShop.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

RepairShop.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
