import React from 'react'

export function IconClose() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.595281 0.594172C0.14001 1.04944 0.14001 1.78758 0.595281 2.24285L5.54188 7.18945L0.595281 12.1361C0.14001 12.5913 0.14001 13.3295 0.595281 13.7847C1.05055 14.24 1.78869 14.24 2.24396 13.7847L7.19056 8.83813L12.1361 13.7836C12.5913 14.2389 13.3295 14.2389 13.7847 13.7836C14.24 13.3284 14.24 12.5902 13.7847 12.1349L8.83924 7.18945L13.7847 2.24396C14.24 1.78869 14.24 1.05055 13.7847 0.595281C13.3295 0.14001 12.5913 0.14001 12.1361 0.595281L7.19056 5.54077L2.24396 0.594173C1.78869 0.138902 1.05055 0.138901 0.595281 0.594172Z"
        fill="#7E7E7E"
      />
    </svg>
  )
}
export default IconClose

IconClose.propTypes = {}

IconClose.defaultProps = {}
