import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReviewBox from '../components/review'
import { selectClaimItemsAffected } from '../selectors/claim'
import ClaimStatus from '../components/claim-status'
import InfoLabel from '../components/info-label'
import AffectedItemsList from '../components/affected-items'
import { setAddHideCssMobile } from '../reducers/app'

export function ClaimDetailPage() {
  const affectedItems = useSelector(selectClaimItemsAffected)
  const dispatch = useDispatch()

  dispatch(setAddHideCssMobile(true))

  return (
    <div className="container-fluid mb-5">
      <ClaimStatus />
      <ReviewBox className="mb-5" />
      <InfoLabel
        className="mb-3"
        label="Affected Items"
        count={affectedItems.length}
      />
      <AffectedItemsList />
    </div>
  )
}
export default ClaimDetailPage
