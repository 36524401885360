import React from 'react'

export function IconDelete() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="1.44531"
        width="2.04275"
        height="16.342"
        rx="1.02138"
        transform="rotate(-45 0 1.44531)"
        fill="#757D8A"
      />
      <rect
        y="11.5664"
        width="16.342"
        height="2.04275"
        rx="1.02138"
        transform="rotate(-45 0 11.5664)"
        fill="#757D8A"
      />
    </svg>
  )
}
export default IconDelete

IconDelete.propTypes = {}

IconDelete.defaultProps = {}
