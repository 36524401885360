import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NavigationButtons from '../navigation-buttons'
import Autosaving from '../autosaving'
import {
  NAVIGATION_SEQUENCE,
  NAVIGATION_SEQUENCE_MOBILE_TEXTS,
  REVIEW_CLAIM_PATH,
  CLAIM_DETAIL_PATH,
  DEVICE_DETAIL_PATH,
  FILE_CLAIM,
} from '../../constants'
import { updateClaim } from '../../reducers/claim'
import { updateClaimListItem } from '../../reducers/user'
import {
  selectClaimUpdating,
  selectClaimPayload,
  selectClaimItemsAffected,
} from '../../selectors/claim'
import { claimDataIsValid } from '../../utils/validation'
import { REQUIRED_FIELDS_TXT } from '../../i18n'

export function NavigationBar({ prev, next, ...props }) {
  const [error, setError] = useState(false)
  const [finishingClaim, setFinishingClaim] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updating = useSelector(selectClaimUpdating)
  const params = useParams()
  const { claimId: claimID } = params
  const affectedItems = useSelector(selectClaimItemsAffected)
  const navigationIndex = NAVIGATION_SEQUENCE.findIndex(
    (nav) =>
      `/${nav}/${claimID}` === location.pathname ||
      `/${nav}/${claimID}/` === location.pathname
  )
  const { t } = useTranslation()
  const claimDataValid = claimDataIsValid(useSelector(selectClaimPayload))

  const onFileClaim = () => {
    setFinishingClaim(true)
    dispatch(updateClaim({ section: FILE_CLAIM }))
      .unwrap()
      .then((claim) => {
        let to = `/${CLAIM_DETAIL_PATH}/${claimID}`
        if (affectedItems.length === 1) {
          to = DEVICE_DETAIL_PATH(claimID, affectedItems[0].id)
        }
        dispatch(updateClaimListItem(claim))
        // TODO, temporal fix for the device issue
        // navigate(`${to}?wt=true`)
        window.location.href = `${to}?wt=true`
        setFinishingClaim(false)
      })
      .catch((err) => {
        setError(true)
        setFinishingClaim(false)
      })
  }

  const onPrev = () => {}

  const onNext = () => {
    dispatch(updateClaim({ section: NAVIGATION_SEQUENCE[navigationIndex] }))
  }

  const isLastSteep = useMatch(`${REVIEW_CLAIM_PATH}/:claimId`)

  const getPrevUrl = (index) => {
    if (NAVIGATION_SEQUENCE[index - 1]) {
      return `/${NAVIGATION_SEQUENCE[index - 1]}/${claimID}`
    }
  }

  const getNextUrl = (index) => {
    if (NAVIGATION_SEQUENCE[index + 1]) {
      return `/${NAVIGATION_SEQUENCE[index + 1]}/${claimID}`
    }
  }

  return (
    <div className="navigation-bar" {...props}>
      {isLastSteep && !claimDataValid && (
        <div className="error-text">
          <ul>
            <li>{t(REQUIRED_FIELDS_TXT)}</li>
          </ul>
        </div>
      )}
      {error && (
        <div className="error-text">
          <ul>
            <li>Something went wrong</li>
          </ul>
        </div>
      )}
      <div className="navigation-bar__autosaving">
        {updating && <Autosaving />}
      </div>
      <div className="navigation-bar__btns">
        {claimID && !finishingClaim && affectedItems.length > 0 ? (
          <NavigationButtons
            navigationIndex={navigationIndex}
            prevUrl={getPrevUrl(navigationIndex)}
            nextUrl={getNextUrl(navigationIndex)}
            prevText={NAVIGATION_SEQUENCE_MOBILE_TEXTS[navigationIndex - 1]}
            nextText={NAVIGATION_SEQUENCE_MOBILE_TEXTS[navigationIndex + 1]}
            onPrev={onPrev}
            onNext={onNext}
            onFileClaim={onFileClaim}
            claimDataValid={claimDataValid}
            updating={updating}
            isLastSteep={!!isLastSteep}
          />
        ) : null}
      </div>
    </div>
  )
}
export default NavigationBar

NavigationBar.propTypes = {
  prev: PropTypes.string,
  next: PropTypes.string,
}

NavigationBar.defaultProps = {
  prev: 'Previous',
  next: 'Continue',
}
