import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconContainer from '../icon-container'
import { IconCircleArrowUpRight } from '../icons/icon-circle-arrow-up-right'
import { DEVICE_DETAIL_PATH } from '../../constants'

export function ClaimsListMobileDevice({
  className,
  icon,
  main,
  lossTypes,
  claimId,
  deviceId,
  ...props
}) {
  return (
    <div className={c('claims-list-mobile-device', className)} {...props}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <Link to={DEVICE_DETAIL_PATH(claimId, deviceId)}>
            <div className="row mt-3">
              <div className="col-2">
                <IconContainer className="me-3" md>
                  {icon}
                </IconContainer>
              </div>
              <div className="col-7">
                <div className="claims-list-mobile-device__main">{main}</div>
                <div className="claims-list-mobile-device__secondary">
                  {lossTypes && lossTypes.length > 0 ? lossTypes[0].name : null}
                </div>
              </div>
              <div className="col-3 text-end">
                <IconCircleArrowUpRight />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default ClaimsListMobileDevice

ClaimsListMobileDevice.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  main: PropTypes.string,
  lossTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  claimId: PropTypes.number,
  deviceId: PropTypes.number,
}

ClaimsListMobileDevice.defaultProps = {
  className: '',
  icon: undefined,
  main: '',
  lossTypes: [],
  claimId: 0,
  deviceId: 0,
}
