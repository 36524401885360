import React from 'react'

export function Skateboard() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5138 28.8625L17.1566 37.7768L13.8138 38.8911H9.35664L6.01378 37.2196L2.67093 33.8768L1.55664 29.9768L3.22807 23.8482L22.1709 4.90536L26.6281 2.11964L29.9709 1.5625L33.3138 2.67679L37.2138 5.4625L38.8852 8.80536V11.0339V14.3768L36.6566 17.7196L32.1995 22.1768L25.5138 28.8625Z"
        fill="white"
      />
      <path
        d="M4.44947 37.0779C8.4102 41.0386 14.8786 40.9579 18.9089 36.9277L36.9276 18.9089C40.9578 14.8787 41.0387 8.4102 37.0779 4.44943L36.5505 3.92202C32.5898 -0.0386696 26.1214 0.0421902 22.0911 4.07243L4.07243 22.0912C0.0421593 26.1214 -0.0386577 32.5898 3.92208 36.5506L4.44947 37.0779ZM5.5985 35.9289L5.07111 35.4015C1.74119 32.0716 1.79407 26.6676 5.22147 23.2402L23.2402 5.22146C26.6676 1.79409 32.0716 1.74118 35.4015 5.07105L35.9289 5.59845C39.2588 8.92848 39.2059 14.3324 35.7785 17.7598L17.7598 35.7785C14.3324 39.2059 8.92841 39.2588 5.5985 35.9289Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M19.4488 29.765C19.6011 29.9174 19.6867 30.1241 19.6867 30.3396C19.6867 30.555 19.6011 30.7616 19.4488 30.914L16.2889 34.0739C16.1366 34.2262 15.9299 34.3118 15.7144 34.3118C15.499 34.3118 15.2923 34.2262 15.1399 34.0739L12.6129 31.5469C12.4606 31.3945 12.375 31.1878 12.375 30.9723C12.375 30.7569 12.4606 30.5503 12.6129 30.3979L15.7728 27.2379C15.9252 27.0856 16.1318 27 16.3473 27C16.5628 27 16.7694 27.0856 16.9218 27.2379L19.4488 29.765ZM17.7252 30.3396L16.3473 28.9615L14.3365 30.9723L15.7144 32.3503L17.7252 30.3396Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M13.7624 24.0786C13.9148 24.231 14.0004 24.4377 14.0004 24.6532C14.0004 24.8687 13.9148 25.0752 13.7624 25.2276L10.6026 28.3874C10.4502 28.5397 10.2435 28.6253 10.0281 28.6253C9.81257 28.6253 9.60592 28.5397 9.45354 28.3874L6.92544 25.8594C6.77309 25.707 6.6875 25.5003 6.6875 25.2848C6.6875 25.0694 6.77309 24.8628 6.92544 24.7104L10.0853 21.5504C10.2377 21.3981 10.4443 21.3125 10.6598 21.3125C10.8753 21.3125 11.0819 21.3981 11.2343 21.5504L13.7624 24.0786ZM12.0389 24.6531L10.6598 23.274L8.64899 25.2848L10.0281 26.6639L12.0389 24.6531Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M21.5505 11.2343C21.3981 11.0819 21.3125 10.8752 21.3125 10.6597C21.3125 10.4442 21.3981 10.2376 21.5505 10.0852L24.7103 6.92544C24.8627 6.77309 25.0694 6.6875 25.2848 6.6875C25.5003 6.6875 25.707 6.77309 25.8594 6.92544L28.3874 9.45344C28.5398 9.60582 28.6254 9.81247 28.6254 10.028C28.6254 10.2434 28.5398 10.4501 28.3874 10.6025L25.2276 13.7624C25.0752 13.9147 24.8686 14.0003 24.6531 14.0003C24.4376 14.0003 24.231 13.9147 24.0786 13.7624L21.5505 11.2343ZM23.274 10.6597L24.6531 12.0388L26.6639 10.028L25.2848 8.64898L23.274 10.6597Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M27.2379 16.9218C27.0856 16.7694 27 16.5628 27 16.3473C27 16.1318 27.0856 15.9252 27.2379 15.7728L30.3978 12.6129C30.5502 12.4606 30.7568 12.375 30.9723 12.375C31.1878 12.375 31.3944 12.4606 31.5468 12.6129L34.0738 15.1399C34.2261 15.2923 34.3117 15.4989 34.3117 15.7144C34.3117 15.9299 34.2261 16.1365 34.0738 16.2889L30.9139 19.4489C30.7616 19.6012 30.5549 19.6868 30.3394 19.6868C30.124 19.6868 29.9173 19.6012 29.7649 19.4489L27.2379 16.9218ZM28.9615 16.3473L30.3394 17.7252L32.3502 15.7144L30.9723 14.3365L28.9615 16.3473Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4764 14.8129C20.2654 14.8191 20.065 14.9073 19.9179 15.0587L15.0588 19.9162C14.9807 19.9911 14.9184 20.0808 14.8754 20.18C14.8325 20.2793 14.8098 20.3861 14.8086 20.4943C14.8075 20.6024 14.828 20.7096 14.8688 20.8097C14.9097 20.9099 14.9701 21.0009 15.0466 21.0773C15.1231 21.1538 15.214 21.2142 15.3142 21.2551C15.4143 21.2959 15.5216 21.3164 15.6297 21.3153C15.7379 21.3141 15.8447 21.2914 15.9439 21.2484C16.0432 21.2055 16.1329 21.1432 16.2077 21.0651L21.0668 16.2076C21.1841 16.0935 21.2643 15.9466 21.2968 15.7861C21.3292 15.6257 21.3124 15.4591 21.2486 15.3084C21.1848 15.1576 21.077 15.0296 20.9392 14.9412C20.8014 14.8528 20.6401 14.808 20.4764 14.8129Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9129 17.2504C22.7019 17.2566 22.5015 17.3448 22.3543 17.4962L17.4953 22.3537C17.4174 22.4286 17.3553 22.5183 17.3125 22.6175C17.2697 22.7167 17.2472 22.8234 17.2461 22.9314C17.2451 23.0394 17.2656 23.1466 17.3065 23.2466C17.3473 23.3466 17.4077 23.4374 17.4841 23.5138C17.5605 23.5902 17.6513 23.6506 17.7513 23.6914C17.8513 23.7323 17.9585 23.7529 18.0665 23.7518C18.1745 23.7508 18.2812 23.7282 18.3804 23.6854C18.4796 23.6426 18.5693 23.5805 18.6442 23.5027L23.5033 18.6453C23.6207 18.5311 23.7009 18.3842 23.7333 18.2237C23.7658 18.0632 23.749 17.8967 23.6852 17.7459C23.6214 17.5951 23.5135 17.467 23.3757 17.3786C23.2379 17.2902 23.0766 17.2455 22.9129 17.2504Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3504 19.6879C25.1394 19.6941 24.939 19.7823 24.7919 19.9337L19.9328 24.7912C19.8549 24.8661 19.7928 24.9558 19.75 25.055C19.7073 25.1542 19.6847 25.2609 19.6836 25.3689C19.6826 25.4769 19.7031 25.5841 19.7439 25.6841C19.7848 25.7841 19.8452 25.8749 19.9216 25.9513C19.998 26.0277 20.0888 26.0881 20.1888 26.1289C20.2888 26.1698 20.396 26.1904 20.504 26.1893C20.612 26.1883 20.7187 26.1657 20.8179 26.1229C20.9171 26.0801 21.0068 26.018 21.0817 25.9402L25.9408 21.0826C26.0582 20.9685 26.1383 20.8216 26.1708 20.6611C26.2032 20.5007 26.1865 20.3341 26.1226 20.1834C26.0588 20.0326 25.951 19.9046 25.8132 19.8162C25.6754 19.7278 25.5141 19.683 25.3504 19.6879Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Skateboard
