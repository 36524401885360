import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export function RepairProviderPartner({ className, userAddress, ...props }) {
  return (
    <div className={c('repair-provider-apple', className)} {...props}>
      <p>
        Please click the button below to start your repair request through
        Apple.{' '}
      </p>
      <p>
        Your claim is approved for repair/replacement. You’ll complete your
        repair/replacement with Apple and pay Apple directly. AKKO will then
        reimburse you for the total cost of the repair/replacement less your
        applicable repair/replacement deductible.
      </p>
      <a
        className="btn btn-primary"
        href="https://support.apple.com/repair"
        target="_blank"
        rel="noreferrer"
      >
        Start Your Apple Repair Request
      </a>
    </div>
  )
}
export default RepairProviderPartner

RepairProviderPartner.propTypes = {
  className: PropTypes.string,
  userAddress: PropTypes.shape({}),
}

RepairProviderPartner.defaultProps = {
  className: '',
  userAddress: {},
}
