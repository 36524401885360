import PropTypes from 'prop-types'

export const propTypes = {
  claimId: PropTypes.number.isRequired,
  claimDeviceId: PropTypes.number.isRequired,
  deviceId: PropTypes.number.isRequired,
  // props from field payload
  display_order: PropTypes.number.isRequired,
  field_group_id: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  completed: PropTypes.bool,
  label: PropTypes.string,
  note: PropTypes.string,
  placeholder: PropTypes.string,
  instruction: PropTypes.string,
  prefill: PropTypes.string,
  meta_data: PropTypes.shape({}),
  handler: PropTypes.string,
  evidence_type_id: PropTypes.string,
  content: PropTypes.string,
  taskGroupName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export const defaultProps = {
  handler: '',
  instruction: '',
  prefill: '',
  placeholder: '',
  note: '',
  label: '',
  required: false,
  completed: false,
  meta_data: {},
  evidence_type_id: '',
  content: '',
  taskGroupName: '',
  className: '',
  disabled: false,
}
