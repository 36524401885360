import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconAlert from '../icons/icon-alert'

export function ActionRequired({ className, ...props }) {
  return (
    <span className={c('action-required', className)} {...props}>
      <IconAlert />
      &nbsp; Action required
    </span>
  )
}
export default ActionRequired

ActionRequired.propTypes = {
  className: PropTypes.string,
}

ActionRequired.defaultProps = {
  className: '',
}
