import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconArrowLeft from '../icons/icon-arrow-left'
import { NAVIGATION_PATH } from '../../constants'

export function PageHeader({ className, children, title, subtitle }) {
  return (
    <div className={c('page-header', className)}>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="page-header__title">{title}</div>
          {subtitle && <div className="page-header__subtitle">{subtitle}</div>}
          {children && children}
        </div>
        <Link
          to={`/${NAVIGATION_PATH}`}
          className="page-header__arrow_back me-3"
        >
          <IconArrowLeft />
        </Link>
      </div>
    </div>
  )
}
export default PageHeader

PageHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

PageHeader.defaultProps = {
  children: undefined,
  className: '',
  title: '',
  subtitle: undefined,
}
