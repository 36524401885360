import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import c from 'classnames'
import Header from '../header'
import SideBarMenu from '../side-bar-menu'
import NavigationBar from '../navigation-bar'
import Preloading from '../preloading'
import { selectBrand } from '../../selectors/app'
import useRequestClaim from '../../hooks/request-claim'
import { selectClaimReadingState } from '../../selectors/claim'
import { reset, toggleReadingClaim } from '../../reducers/claim'
import NavigationHeaderMobile from '../navigation-header-mobile'

export function Internal({ className }) {
  const params = useParams()
  const { claimId } = params
  const dispatch = useDispatch()

  useRequestClaim(claimId)

  const claimIsReading = useSelector(selectClaimReadingState)
  const brandName = useSelector(selectBrand)
  useEffect(() => {
    if (claimId === undefined) {
      dispatch(reset())
      dispatch(toggleReadingClaim())
    }
  }, [])

  return (
    <div className={c('page', className, brandName)}>
      <Header />
      <NavigationHeaderMobile />
      {claimIsReading ? (
        <Preloading />
      ) : (
        <div>
          <div className="container-fluid">
            <div className={c('main')}>
              <div className="main__navigation-container">
                <div className="main__navigation">
                  <SideBarMenu />
                </div>
              </div>
              <div className="main__content">
                <div className="main__content-container">
                  <div>
                    <Outlet />
                  </div>
                  <div>
                    <NavigationBar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Internal

Internal.propTypes = {
  className: PropTypes.string,
}

Internal.defaultProps = {
  className: '',
}
