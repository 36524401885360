import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { setAppError } from './app'
import {
  USER_AUTH,
  UPDATE_USER_ADDRES,
  LOGIN_API,
  LIST_CLAIM,
  RESET_PASSWORD_API,
  GET_ACTIVE_CONTRACT,
} from '../constants'
import { identifyUser } from '../utils/posthog'

export const listClaims = createAsyncThunk(
  'user/listClaims',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(`${LIST_CLAIM}`)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on list claim' }))
    }
  },
  {
    condition: (payload, { getState, extra }) => {
      const { user } = getState()
      const { force } = payload || {}
      if (force) return true
      if (isEmpty(user.claims)) {
        return true
      }
      return false
    },
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.post(USER_AUTH)

      // Track authenticated user
      identifyUser(response.data.data.user_id)

      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on Authentication' }))
    }
  }
)

export const updateUserAddress = createAsyncThunk(
  'user/updateUserAddress',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        city: payload.city,
        state: payload.state,
        zip: payload.zip,
        country_id: payload.country,
        street: payload.street,
      }
      const response = await axios.post(
        UPDATE_USER_ADDRES(payload.claimID),
        data
      )
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on update address' }))
    }
  }
)

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        email: payload.phone,
        password: payload.password,
      }
      const response = await axios.post(LOGIN_API, data)
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on update address' }))
    }
  }
)

export const resetUserPassword = createAsyncThunk(
  'user/resetUserPassword',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const data = {
        phone_no: payload.phone,
      }
      const response = await axios.post(RESET_PASSWORD_API, data)
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on reset password' }))
    }
  }
)

export const getContract = createAsyncThunk(
  'user/getContract',
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    try {
      const state = getState()
      const { user } = state
      const response = await axios.get(GET_ACTIVE_CONTRACT(user.user_id))
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on read contract' }))
    }
  }
)

export const initialState = {
  token: localStorage.getItem('token'),
  user_id: null,
  firstname: null,
  lastname: null,
  phone: null,
  email: null,
  authed: false,
  error: false,
  errorMessage: '',
  country: '',
  country_id: '',
  city: '',
  state: '',
  street: '',
  zip: '',
  claims: [],
  contract: null,
}
export const userStore = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { payload } = action
      state.id = payload.id
    },
    setToken: (state, action) => {
      const { payload } = action
      state.token = payload.token
    },
    addClaim: (state, action) => {
      const { payload } = action
      state.claims = [payload, ...state.claims]
    },
    setUserAddress: (state, action) => {
      const { payload } = action
      state.street = `${payload.street_number} ${payload.street}`
      state.city = payload.city
      state.state = payload.state
      state.country = payload.country
      state.zip = payload.zip
    },
    updateClaimListItem: (state, action) => {
      const { payload } = action
      const index = state.claims.findIndex((claim) => claim.id === payload.id)
      if (index >= 0) {
        state.claims[index] = { ...payload }
      }
    },
  },
  extraReducers: (builder) => {
    // get user
    builder.addCase(getUserData.fulfilled, (state, action) => {
      const { payload } = action
      state.user_id = payload.user_id
      state.firstname = payload.firstname
      state.lastname = payload.lastname
      state.phone = payload.phone
      state.email = payload.email
      state.authed = true
      state.country = payload.country
      state.country_id = payload.country_id
      state.city = payload.city
      state.state = payload.state
      state.street = payload.street
      state.zip = payload.zip
      state.intercom_hashes = payload.intercom_hashes
    })
    builder.addCase(getUserData.rejected, (state, action) => {
      state.error = true
      state.errorMessage = 'Auth fail'
    })

    // update address
    builder.addCase(updateUserAddress.pending, (state, action) => {})
    builder.addCase(updateUserAddress.fulfilled, (state, action) => {
      const { payload } = action
      state.country = payload.country
      state.country_id = payload.country_id
      state.city = payload.city
      state.state = payload.state
      state.street = payload.street
      state.zip = payload.zip
    })
    builder.addCase(updateUserAddress.rejected, (state, action) => {})

    // login user
    builder.addCase(loginUser.pending, (state, action) => {})
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { payload } = action
      state.token = payload.access_token
      localStorage.setItem('token', payload.access_token)
    })
    builder.addCase(loginUser.rejected, (state, action) => {})

    // list claims
    builder.addCase(listClaims.pending, (state, action) => {})
    builder.addCase(listClaims.fulfilled, (state, action) => {
      const { payload } = action
      const claims = {
        open: payload.filter((claim) => claim.status === 'NEW'),
        draft: payload.filter((claim) => claim.status === 'DRAFT'),
        closed: payload.filter((claim) => claim.status === 'CLOSED'),
      }
      state.claims = payload
    })
    builder.addCase(listClaims.rejected, (state, action) => {})

    // reset user password
    builder.addCase(resetUserPassword.pending, (state, action) => {})
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      const { payload } = action
      state.message = payload.success_message
    })
    builder.addCase(resetUserPassword.rejected, (state, action) => {})

    // get contract
    builder.addCase(getContract.pending, (state, action) => {})
    builder.addCase(getContract.fulfilled, (state, action) => {
      const { payload } = action
      state.contract = payload
    })
    builder.addCase(getContract.rejected, (state, action) => {})
  },
})

export const {
  setUser,
  setToken,
  addClaim,
  updateClaimListItem,
  setUserAddress,
} = userStore.actions

export default userStore.reducer
