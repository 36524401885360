import React from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Button from '../components/button'
import ClaimsList from '../components/claims-list'
import { AFFECTED_ITEMS_PATH } from '../constants'
import IconPlus from '../components/icons/icon-plus'
import { selectClaims } from '../selectors/user'
import ClaimsNotFound from '../components/no-claims'

export function Home() {
  const claims = useSelector(selectClaims)

  return (
    <div className="home">
      <div className="home__heading mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 cera">My Claims</div>
          {!isEmpty(claims) && (
            <Button
              className="btn-file-claim"
              small
              link
              to={`/${AFFECTED_ITEMS_PATH}/`}
            >
              <IconPlus />
              &nbsp; File New Claim
            </Button>
          )}
        </div>
      </div>
      {isEmpty(claims) ? <ClaimsNotFound /> : <ClaimsList />}
    </div>
  )
}
export default Home
