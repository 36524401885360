import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { propTypes, defaultProps } from './props'
import { getClaim } from '../../reducers/claim/thunks'
import { setSkipLoadingScreen } from '../../reducers/claim'
import { selectClaimID } from '../../selectors/claim'
import { selectTimerId } from '../../selectors/app'
import { setTimerId } from '../../reducers/app'

export function Autorefresh({ className, prefill, ...props }) {
  const dispatch = useDispatch()
  const claimId = useSelector(selectClaimID)
  const timerId = useSelector(selectTimerId)

  let timerValue = parseInt(prefill, 10)
  if (isNaN(timerValue) || timerValue < 1000) {
    timerValue = 5000
  }

  if (timerId === null) {
    dispatch(setSkipLoadingScreen(true))
    const newTimerId = setTimeout(() => {
      dispatch(getClaim({ claimId, force: true })).then(() => {
        dispatch(setTimerId(null))
      })
    }, timerValue)
    dispatch(setTimerId(newTimerId))
  }

  useEffect(() => {
    return () => {
      dispatch(setSkipLoadingScreen(false))
      clearTimeout(timerId)
      dispatch(setTimerId(null))
    }
  }, [])

  return <div className={c('auto-refresh', className)} />
}
export default Autorefresh

Autorefresh.propTypes = {
  ...propTypes,
  className: PropTypes.string,
}

Autorefresh.defaultProps = {
  ...defaultProps,
  className: '',
}
