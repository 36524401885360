import React from 'react'

export function IconNotFound() {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="42" cy="42" r="42" fill="#F6F5F5" />
      <mask
        id="mask0_4885_7761"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="20"
        width="44"
        height="45"
      >
        <path
          d="M20.1187 20.6298H63.8695V64.5469H20.1187V20.6298Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4885_7761)">
        <path
          d="M62.5897 32.9468H21.4023V57.7531C21.4023 60.7973 23.8612 63.2656 26.894 63.2656H57.098C60.1308 63.2656 62.5897 60.7973 62.5897 57.7531V32.9468Z"
          fill="white"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5897 27.425C62.5897 24.38 60.1308 21.9124 57.098 21.9124H26.894C23.8612 21.9124 21.4023 24.38 21.4023 27.425V32.9375H62.5897V27.425Z"
          fill="white"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9368 47.1562C41.0735 46.0159 42.9187 46.0159 44.0554 47.1562"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.6057 42.5938V41.2156"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8938 27.4375H28.2667"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.0708 27.4375H34.4437"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.2507 27.4375H40.6237"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.387 42.5938V41.2156"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5899 27.4328C62.5899 24.3878 60.1311 21.9202 57.0982 21.9202H26.8943C23.8614 21.9202 21.4026 24.3878 21.4026 27.4328V32.9453H62.5899V27.4328Z"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5899 32.9468H21.4026V57.7531C21.4026 60.7973 23.8614 63.2656 26.8943 63.2656H57.0982C60.1311 63.2656 62.5899 60.7973 62.5899 57.7531V32.9468Z"
          stroke="#060920"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default IconNotFound

IconNotFound.propTypes = {}

IconNotFound.defaultProps = {}
