import { configureStore, combineReducers } from '@reduxjs/toolkit'
import app from './app'
import user from './user'
import claim from './claim'
import devices from './devices'
import losstypes from './losstypes'
import countries from './countries'
import tasks from './tasks'
import graph from './graph'
import currencies from './currencies'
import itemOperabilities from './item-operabilities'
import itemCategories from './item-categories'
import mobileOperators from './mobile-operators'
import purchaseConditions from './purchase-conditions'
import partner from './partner'
import featureFlag from './feature-flags'

const rootReducer = combineReducers({
  app,
  user,
  claim,
  devices,
  losstypes,
  countries,
  tasks,
  graph,
  currencies,
  itemOperabilities,
  itemCategories,
  mobileOperators,
  purchaseConditions,
  partner,
  featureFlag,
})

export function setupStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
  return store
}
