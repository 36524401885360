import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import ImagePreview from '../image-preview'
import BtnUploadProof from '../btn-upload-proof'

export function ProofLossListView({
  className,
  tmpOriginalImageNames,
  onDelete,
  onChangeImage,
  updating,
  onAddImage,
  images,
  device,
  modifyable,
  disabled,
  fieldId,
  file,
  ...props
}) {
  return (
    <div className={c('proof-loss-list', className, { modifyable })}>
      {images.map((i) => {
        return (
          <ImagePreview
            className="proof-loss-list__item"
            id={i.id}
            key={i.file_name + i.id}
            name={tmpOriginalImageNames[i.file_name]?.name || i.file_name}
            type={i.file_type}
            size={i.file_size}
            loading={isEmpty(i.processing_status)}
            src={tmpOriginalImageNames[i.file_name]?.public_url || i.public_url}
            onDelete={onDelete}
            deletable={modifyable}
            disabled={disabled}
            file={file}
            approved={i.approved}
            documentType={i.documentType}
          />
        )
      })}
      {modifyable ? (
        <BtnUploadProof
          onChangeImage={onChangeImage}
          id={fieldId}
          className="proof-loss-list__btn-upload"
          disabled={updating || disabled}
        />
      ) : null}
    </div>
  )
}
export default ProofLossListView

ProofLossListView.propTypes = {
  className: PropTypes.string,
  device: PropTypes.shape({
    id: PropTypes.number,
  }),
  modifyable: PropTypes.bool,
  file: PropTypes.bool,
  onAddImage: PropTypes.func,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),

  tmpOriginalImageNames: PropTypes.shape({}),
  onDelete: PropTypes.func,
  onChangeImage: PropTypes.func,
  updating: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
}

ProofLossListView.defaultProps = {
  className: '',
  onAddImage: () => {},
  device: undefined,
  images: [],
  modifyable: true,
  file: false,
  tmpOriginalImageNames: {},
  onDelete: () => {},
  onChangeImage: () => {},
  updating: false,
  disabled: false,
}
