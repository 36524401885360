import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'
import IconArrowV3 from '../icons/icon-arrow-v3'
import { ADD_ITEMS_PATH, ADD_ITEM_DETAILS_PATH } from '../../constants'
import IconAppliances from '../icon-devices/appliances'
import IconElectronics from '../icon-devices/electronics'
import IconTransport from '../icon-devices/transports'
import IconEntertainment from '../icon-devices/entertaiments'
import IconSports from '../icon-devices/sports'
import IconPhotography from '../icon-devices/photography'
import IconAudios from '../icon-devices/audios'
import IconAccesories from '../icon-devices/accesories'

const icons = {
  Electronics: <IconElectronics />,
  Transport: <IconTransport />,
  Entertainment: <IconEntertainment />,
  Sports: <IconSports />,
  Small_Appliances: <IconAppliances />,
  Audio: <IconAudios />,
  Photography: <IconPhotography />,
  Accessories: <IconAccesories />,
}

export function ItemCategoryItem({ className, category, ...props }) {
  return (
    <Link
      className={c('item-category', className)}
      to={`/${ADD_ITEMS_PATH}/${category.name.replace(' ', '-')}/${ADD_ITEM_DETAILS_PATH}/`}
      {...props}
    >
      <span className="item-category_content">
        <span className="item-category_icon">
          {icons[category.name.replace(' ', '_')]}
        </span>
        <span className="item-category_detail">
          <span className="item-category_name">{category.name}</span>
          <span className="item-category_examples">{category.description}</span>
        </span>
        <span className="item-category_arrow">
          <IconArrowV3 />
        </span>
      </span>
    </Link>
  )
}
export default ItemCategoryItem

ItemCategoryItem.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
}

ItemCategoryItem.defaultProps = {
  className: '',
  path: '',
  category: {},
}
