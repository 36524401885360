import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Device from '../device'
import { getIcon } from '../icons'
import {
  selectClaimItemsAffected,
  selectClaimTypeLoss,
  selectClaimID,
} from '../../selectors/claim'
import SelectAffectedItems from '../select-items'
import { RC_TYPELOSS_TXT, RC_PREV_DAMAGE_TXT } from '../../i18n'
import { TYPE_LOSS_PATH } from '../../constants'

export function ReviewList({ className, errors, editable }) {
  const [devices, setDevices] = useState(useSelector(selectClaimItemsAffected))
  const typeLoss = useSelector(selectClaimTypeLoss)
  const claimId = useSelector(selectClaimID)
  const { t } = useTranslation()
  const onSelectDevice = (d) => {
    const updatedDevices = devices.map((device) => {
      if (device.id === d.id) {
        return { ...device, open: !device.open }
      }
      return device
    })
    setDevices(updatedDevices)
  }
  return (
    <div className={c('review-list', className)}>
      <div className="section-title">Affected Items</div>
      <SelectAffectedItems devices={devices} />
      {devices.map((device) => (
        <Device
          key={device.id}
          className="mb-3"
          onSelect={onSelectDevice}
          icon={getIcon(device.kind)}
          data={device}
          open={device.open}
        >
          <div className="mb-3 review-device">
            <div className="mb-3">
              <div className="review-device__title">{t(RC_TYPELOSS_TXT)}</div>
              <div className="review-device__body">
                {typeLoss[device.id].typeLoss.map((tl) => (
                  <span className="badge bg-secondary me-1" key={tl.id}>
                    {tl.value}
                  </span>
                ))}
              </div>
              {!isEmpty(errors.typeLoss) && (
                <div className="error-box2">{errors.typeLoss[0]}</div>
              )}
            </div>
            {typeLoss[device.id].itemOperability && (
              <div className="mb-3">
                <div className="review-device__title">ITEM OPERABILITY</div>
                <div className="review-device__body">
                  <div>{typeLoss[device.id].itemOperability.label}</div>
                  <small>
                    {typeLoss[device.id].itemOperability.description}
                  </small>
                </div>
                {!isEmpty(errors.itemOperability) && (
                  <div className="error-box2">{errors.itemOperability[0]}</div>
                )}
              </div>
            )}
            <div className="mb-3">
              <div className="review-device__title">
                {t(RC_PREV_DAMAGE_TXT)}
              </div>
              <div className="review-device__body">
                {typeLoss[device.id].previousDamage}
              </div>
              {!isEmpty(errors.previousDamage) && (
                <div className="error-box2">{errors.previousDamage[0]}</div>
              )}
            </div>
            {editable && (
              <div className="text-end">
                <Link
                  className="primary small unone"
                  to={`/${TYPE_LOSS_PATH}/${claimId}`}
                >
                  Edit
                </Link>
              </div>
            )}
          </div>
        </Device>
      ))}
    </div>
  )
}
export default ReviewList

ReviewList.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  errors: PropTypes.shape({
    itemOperability: PropTypes.arrayOf(PropTypes.string),
    previousDamage: PropTypes.arrayOf(PropTypes.string),
    typeLoss: PropTypes.arrayOf(PropTypes.string),
  }),
}
ReviewList.defaultProps = {
  className: '',
  editable: false,
  errors: {},
}
