import React from 'react'

export function IconUpload() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.98556C1 1.72417 1.10384 1.47349 1.28866 1.28866C1.47349 1.10384 1.72417 1 1.98556 1H7.8989L10.3628 3.95667H18.74C19.0014 3.95667 19.2521 4.06051 19.4369 4.24533C19.6218 4.43016 19.7256 4.68084 19.7256 4.94223V17.7545C19.7256 18.0159 19.6218 18.2665 19.4369 18.4514C19.2521 18.6362 19.0014 18.74 18.74 18.74H1.98556C1.72417 18.74 1.47349 18.6362 1.28866 18.4514C1.10384 18.2665 1 18.0159 1 17.7545V1.98556Z"
        stroke="#F3103D"
        strokeLinejoin="round"
      />
      <path
        d="M13.3191 10.8487L10.3624 7.89844L7.40576 10.8551"
        stroke="#F3103D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3623 7.89844V14.7973"
        stroke="#F3103D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default IconUpload

IconUpload.propTypes = {}

IconUpload.defaultProps = {}
