import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Autosaving({ className, label, ...props }) {
  return (
    <div className={c('autosaving', className)} {...props} role="progressbar">
      <div className="spinner-border spinner-border-sm spinner-bg">
        <span className="visually-hidden">Loading...</span>
      </div>
      <span className="ms-2">{label}</span>
    </div>
  )
}
export default Autosaving

Autosaving.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
}

Autosaving.defaultProps = {
  className: '',
  label: 'Autosave in progress...',
}
