import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconCheck from '../icons/icon-check'

export function CheckStatus({ className, done, sm, checked, ...props }) {
  return (
    <div
      className={c('check-status', className, {
        checked,
        'check-status--done': done,
        'check-status--sm': sm,
      })}
      {...props}
      role="checkbox"
      aria-checked="false"
    >
      {checked && <IconCheck />}
    </div>
  )
}
export default CheckStatus

CheckStatus.propTypes = {
  className: PropTypes.string,
  done: PropTypes.bool,
  sm: PropTypes.bool,
  checked: PropTypes.bool,
}

CheckStatus.defaultProps = {
  className: '',
  done: false,
  sm: false,
  checked: true,
}
