import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CheckStatus from '../check-status'
import IconContainer from '../icon-container'
import IconPencil from '../icons/icon-pencil'
import IconDelete from '../icons/icon-delete'
import { ADD_ITEMS_PATH, ADD_ITEM_DETAILS_PATH } from '../../constants'
import { selectPartnerPermissionValueByScope } from '../../selectors/partner'
import { ALLOW, PARTNER_CLAIM_ADD_ITEM } from '../../constants/permissions'

export function DevicePicker({
  className,
  icon,
  children,
  onSelect,
  checked,
  data,
  disabled,
  ...props
}) {
  const addItemPermision = useSelector((state) =>
    selectPartnerPermissionValueByScope(state, PARTNER_CLAIM_ADD_ITEM)
  )
  const onSelectDevice = () => {
    if (!disabled) {
      onSelect(data)
    }
  }
  const onStop = (e) => {
    e.stopPropagation()
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={onSelectDevice}
      className={c('device', className, { disabled, checked })}
      {...props}
      role="button"
      tabIndex={0}
    >
      <div className="device__head">
        <div className="d-flex align-items-center">
          <div className="device__check-status">
            <CheckStatus checked={checked} />
          </div>
          <IconContainer className="me-3" md>
            {icon}
          </IconContainer>
          <div className="flex-grow-1">
            <div className="device__model">{data.model}</div>
            <div className="device__code">{data.brand}</div>
          </div>
          {addItemPermision === ALLOW && (
            <div className="device__actions">
              <Link
                className="device__actions-btn"
                to={`/${ADD_ITEMS_PATH}/${data.category}/${ADD_ITEM_DETAILS_PATH}/${data.id}`}
                type="button"
                onClick={onStop}
              >
                <IconPencil />
              </Link>
              <Link
                to="/"
                className="device__actions-btn d-none"
                type="button"
                onClick={onStop}
              >
                <IconDelete />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default DevicePicker

DevicePicker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  onSelect: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    model: PropTypes.string,
    brand: PropTypes.string,
    category: PropTypes.string,
  }),
}

DevicePicker.defaultProps = {
  className: '',
  children: undefined,
  icon: undefined,
  onSelect: () => {},
  checked: false,
  disabled: false,
  data: {},
}
