import React from 'react'

export function IconArrowDown() {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4 4L7 1"
        stroke="#757D8A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default IconArrowDown

IconArrowDown.propTypes = {}

IconArrowDown.defaultProps = {}
