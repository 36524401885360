import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageHeader from '../components/page-header'
import ReviewBox from '../components/review'
import ReviewList from '../components/review-list'
import { useStoreCurrentSteep } from '../hooks/current-steep'
import Disclaimer from '../components/disclaimer'
import { setTraveled } from '../reducers/app'
import { RC_TXT } from '../i18n'
import { PAGE_REVIEW } from '../constants'
import useRunValidationSchema from '../hooks/validations-schema'
import { claimValidationSchemaV2 } from '../utils/validation'
import { selectClaimPayloadForValidation } from '../selectors/claim'

export function ReviewClaimPage() {
  const dispatch = useDispatch()
  useStoreCurrentSteep(PAGE_REVIEW)
  const { t } = useTranslation()
  const claim = useSelector(selectClaimPayloadForValidation)
  const [errors] = useRunValidationSchema(claimValidationSchemaV2, { ...claim })

  useEffect(() => {
    dispatch(setTraveled(true))
  }, [])
  return (
    <div className="review-claim-page">
      <PageHeader
        title={t(RC_TXT)}
        subtitle="Please review your claim information before submitting"
      />
      <ReviewBox
        className="mb-4"
        editable
        errors={{ date: errors.date, incident: errors.incident }}
      />
      <ReviewList
        className="mb-5"
        modifyable={false}
        editable
        errors={{
          itemOperability: errors['lossTypes[0].itemOperability'],
          previousDamage: errors['lossTypes[0].previousDamage'],
          typeLoss: errors['lossTypes[0].typeLoss'],
        }}
      />
      <Disclaimer
        errors={{ acceptedTermsConditions: errors.acceptedTermsConditions }}
      />
    </div>
  )
}
export default ReviewClaimPage
