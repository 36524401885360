import React from 'react'

export function IconSearch() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37398 14.6285C0.848821 14.1033 0.881026 13.2428 1.44363 12.7581L5.58819 9.18945C5.75184 9.42418 5.94013 9.64509 6.14753 9.85245C6.35762 10.0625 6.57867 10.2508 6.81326 10.4145L3.24424 14.5589C2.75969 15.1217 1.89895 15.1537 1.37394 14.6286L1.37398 14.6285Z"
        fill="white"
        stroke="#060920"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4827 9.85212C11.4571 11.8776 8.17218 11.8776 6.14658 9.85212C4.12103 7.82663 4.12401 4.54467 6.14957 2.51912C8.17511 0.493626 11.4542 0.493626 13.4795 2.51912C15.505 4.54462 15.508 7.82657 13.4825 9.85212H13.4827Z"
        fill="white"
        stroke="#060920"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 3.33301C12.9208 4.75375 12.9221 7.06412 11.503 8.48323"
        stroke="#060920"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconSearch

IconSearch.propTypes = {}

IconSearch.defaultProps = {}
