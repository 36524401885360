import React from 'react'

export function IconLocation() {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75986 16.8062L8.38592 21.0729C8.4937 21.3576 8.76673 21.5455 9.07122 21.5455C9.37571 21.5455 9.64872 21.3576 9.75652 21.0729L11.3826 16.8062C14.7117 15.8118 17.1429 12.7234 17.1429 9.07143C17.1429 4.61684 13.5261 1 9.07143 1C4.61676 1 1 4.61675 1 9.07143C1 12.7234 3.43053 15.8116 6.75986 16.8062Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75986 16.8062L8.38592 21.0729C8.4937 21.3576 8.76673 21.5455 9.07122 21.5455C9.37571 21.5455 9.64872 21.3576 9.75652 21.0729L11.3826 16.8062C14.7117 15.8118 17.1429 12.7234 17.1429 9.07143C17.1429 4.61684 13.5261 1 9.07143 1C4.61676 1 1 4.61675 1 9.07143C1 12.7234 3.43095 15.8116 6.76028 16.8062H6.75986ZM7.99925 15.9358C7.91342 15.7106 7.72261 15.5419 7.48855 15.4845C4.60703 14.775 2.46749 12.171 2.46749 9.07143C2.46749 5.42683 5.42679 2.46753 9.07139 2.46753C12.716 2.46753 15.6753 5.42683 15.6753 9.07143C15.6753 12.1709 13.5356 14.7751 10.6542 15.4845C10.4202 15.5419 10.2294 15.7106 10.1435 15.9358L9.07156 18.7506L7.99959 15.9358H7.99925Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07149 3.9375C6.23668 3.9375 3.93555 6.23922 3.93555 9.07386C3.93555 11.9085 6.23676 14.2098 9.07149 14.2098C11.9062 14.2098 14.2079 11.9086 14.2079 9.07386C14.2079 6.23914 11.9061 3.9375 9.07149 3.9375ZM9.07149 5.4052C11.0966 5.4052 12.7403 7.04896 12.7403 9.07403C12.7403 11.0986 11.0966 12.7429 9.07149 12.7429C7.04693 12.7429 5.40266 11.0986 5.40266 9.07403C5.40266 7.04896 7.04693 5.4052 9.07149 5.4052Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default IconLocation

IconLocation.propTypes = {}

IconLocation.defaultProps = {}
