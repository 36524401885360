import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { propTypes, defaultProps } from './props'
import { selectStoreUrls } from '../../selectors/claim'
import { getDeviceStoreUrls } from '../../reducers/claim/thunks'
import Spinner from '../spinner'

export function ShopStores({
  className,
  label,
  instruction,
  prefill,
  claimId,
  claimDeviceId,
}) {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const urls = useSelector((state) => selectStoreUrls(state, claimDeviceId))
  useEffect(() => {
    if (isEmpty(urls)) {
      dispatch(
        getDeviceStoreUrls({
          claimId,
          claimDeviceId,
        })
      ).then(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])
  return (
    <div className={c('shop-stores', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      {!isEmpty(instruction) && (
        <div className="mb-1 dc-instruction">{instruction}</div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        urls.map((url) => (
          <a
            className="btn btn-primary"
            key={url}
            target="_blank"
            href={url}
            rel="noreferrer"
          >
            {prefill || 'Go to suggested replacement'}
          </a>
        ))
      )}
    </div>
  )
}
export default ShopStores

ShopStores.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

ShopStores.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
