import React from 'react'

export function IconSmartphone() {
  return (
    <svg
      width="17"
      height="31"
      viewBox="0 0 17 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.89502 3.15623L2.0161 2.03516H14.3479L16.0295 3.71677V28.941L14.3479 30.0621H2.57663L0.89502 28.941V3.15623Z"
        fill="white"
      />
      <path
        d="M6.28759 15.0957L4.71924 16.6641L5.27372 17.2185L6.84208 15.6502L6.28759 15.0957Z"
        fill="#060606"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M10.9912 13.1348L6.28613 17.8398L6.84062 18.3943L11.5456 13.6893L10.9912 13.1348Z"
        fill="#060606"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M9.43097 11.9523L7.07861 14.3047L7.6331 14.8592L9.98545 12.5068L9.43097 11.9523Z"
        fill="#060606"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M5.51453 27.2656H2.69376C2.56908 27.2656 2.4495 27.3152 2.36133 27.4033C2.27316 27.4915 2.22363 27.6111 2.22363 27.7358C2.22363 27.8604 2.27316 27.98 2.36133 28.0682C2.4495 28.1564 2.56908 28.2059 2.69376 28.2059H5.51453C5.63922 28.2059 5.7588 28.1564 5.84697 28.0682C5.93513 27.98 5.98466 27.8604 5.98466 27.7358C5.98466 27.6111 5.93513 27.4915 5.84697 27.4033C5.7588 27.3152 5.63922 27.2656 5.51453 27.2656Z"
        fill="#060606"
      />
      <path
        d="M7.39875 27.2656H6.92862C6.80394 27.2656 6.68436 27.3152 6.59619 27.4033C6.50803 27.4915 6.4585 27.6111 6.4585 27.7358C6.4585 27.8604 6.50803 27.98 6.59619 28.0682C6.68436 28.1564 6.80394 28.2059 6.92862 28.2059H7.39875C7.52344 28.2059 7.64302 28.1564 7.73119 28.0682C7.81935 27.98 7.86888 27.8604 7.86888 27.7358C7.86888 27.6111 7.81935 27.4915 7.73119 27.4033C7.64302 27.3152 7.52344 27.2656 7.39875 27.2656Z"
        fill="#060606"
      />
      <path
        d="M13.9799 0.941406H2.69683C2.07362 0.942106 1.47613 1.18999 1.03545 1.63067C0.594772 2.07135 0.346891 2.66884 0.346191 3.29205V27.7387C0.346891 28.362 0.594772 28.9594 1.03545 29.4001C1.47613 29.8408 2.07362 30.0887 2.69683 30.0894H13.9799C14.6031 30.0887 15.2006 29.8408 15.6413 29.4001C16.082 28.9594 16.3299 28.362 16.3306 27.7387V3.29205C16.3299 2.66884 16.082 2.07135 15.6413 1.63067C15.2006 1.18999 14.6031 0.942106 13.9799 0.941406ZM11.526 1.88166L11.2439 3.29205H5.43292L5.15085 1.88166H11.526ZM15.3903 27.7387C15.3899 28.1127 15.2412 28.4712 14.9768 28.7356C14.7123 29 14.3539 29.1487 13.9799 29.1491H2.69683C2.32291 29.1487 1.96441 29 1.7 28.7356C1.4356 28.4712 1.28687 28.1127 1.28645 27.7387V3.29205C1.28687 2.91812 1.4356 2.55963 1.7 2.29522C1.96441 2.03081 2.32291 1.88208 2.69683 1.88166H4.1919L4.58646 3.85438C4.60777 3.96096 4.66536 4.05685 4.74941 4.12576C4.83346 4.19466 4.93879 4.23231 5.04748 4.23231H11.6293C11.738 4.23231 11.8433 4.19466 11.9273 4.12576C12.0114 4.05685 12.069 3.96096 12.0903 3.85438L12.4849 1.88166H13.9799C14.3539 1.88208 14.7123 2.03081 14.9768 2.29522C15.2412 2.55963 15.3899 2.91812 15.3903 3.29205V27.7387Z"
        fill="#060606"
        stroke="#E8E7E5"
        strokeWidth="0.5"
      />
    </svg>
  )
}
export default IconSmartphone

IconSmartphone.propTypes = {}

IconSmartphone.defaultProps = {}
