import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { GET_DEVICES, DEVICE_ITEM_ADD, DEVICE_ITEM_UPDATE } from '../constants'

export const getUserDevices = createAsyncThunk(
  'devices/getUserDevices',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(GET_DEVICES)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on user Devices' }))
    }
  }
)

export const addItemDevice = createAsyncThunk(
  'devices/addItemDevice',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.post(DEVICE_ITEM_ADD, payload)
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on user Devices' }))
    }
  }
)
export const updateItemDevice = createAsyncThunk(
  'devices/updateItemDevice',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.patch(
        `${DEVICE_ITEM_UPDATE(payload.id)}`,
        payload
      )
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on user Devices' }))
    }
  }
)

export const initialState = {
  proccessingItem: false,
  list: [],
}
export const devicesStore = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    addDevices: (state, action) => {
      state.devices = action.payload.devices
    },
    toggleProccessingItem: (state, action) => {
      const { payload } = action
      state.proccessingItem = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDevices.pending, (state, action) => {})
    builder.addCase(getUserDevices.fulfilled, (state, action) => {
      const { payload } = action
      state.list = payload.map((p) => ({ ...p, deviceId: p.id }))
    })
    builder.addCase(getUserDevices.rejected, (state, action) => {})
    // add item
    builder.addCase(addItemDevice.pending, (state, action) => {
      state.proccessingItem = true
    })
    builder.addCase(addItemDevice.fulfilled, (state, action) => {
      const { payload } = action
      state.list.push(payload)
      state.proccessingItem = false
    })
    builder.addCase(addItemDevice.rejected, (state, action) => {
      state.proccessingItem = false
    })
    // update item
    builder.addCase(updateItemDevice.pending, (state, action) => {
      state.proccessingItem = true
    })
    builder.addCase(updateItemDevice.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.list.findIndex((i) => i.id === payload.id)
      state.list[index] = { ...payload }
      state.proccessingItem = false
    })
    builder.addCase(updateItemDevice.rejected, (state, action) => {
      state.proccessingItem = false
    })
  },
})

export const { addDevices, toggleProccessingItem } = devicesStore.actions

export default devicesStore.reducer
