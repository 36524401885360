import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'
import { generateShippingLabel } from '../../reducers/claim'
import Spinner from '../spinner'
import { selectDeviceShipping } from '../../selectors/claim'

export function ShippingLabel({
  className,
  label,
  instruction,
  claimId,
  disabled,
  claimDeviceId,
}) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const shipping = useSelector((state) =>
    selectDeviceShipping(state, claimDeviceId)
  )

  const generate = () => {
    setLoading(true)
    dispatch(generateShippingLabel({ claimId, claimDeviceId })).then(() =>
      setLoading(false)
    )
  }

  return (
    <div className={c('shipping-label', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      {!loading && isEmpty(shipping) && (
        <button
          type="button"
          disabled={disabled}
          onClick={generate}
          className={c('btn btn-outline-primary', { disabled })}
        >
          Generate Shipping Label
        </button>
      )}
      {loading && <Spinner />}
      {!loading && !isEmpty(shipping) && (
        <a
          href={shipping.file}
          className="btn btn-primary"
          rel="noreferrer"
          target="_blank"
        >
          Download Shipping Label
        </a>
      )}
    </div>
  )
}
export default ShippingLabel

ShippingLabel.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.string,
}

ShippingLabel.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
