import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import c from 'classnames'
import DeviceItem from '../device-item'
import StatusLabel from '../status-label'
import LossTypeList from '../loss-type-list'
import EvidenceCount from '../evidence-count'
import IconArrowBack from '../icons/icon-arrow-back'
import { getIcon } from '../icons'
import { CLAIM_DETAIL_PATH } from '../../constants'
import IconInfo from '../icons/icon-info'

export function DeviceItemHeader({
  className,
  device,
  typeLost,
  onInfo,
  claimID,
}) {
  return (
    <div className={c('device-item-header', className)}>
      <div className="ddcol-col ddcol-1">
        <Link to={`/${CLAIM_DETAIL_PATH}/${claimID}`}>
          <IconArrowBack />
        </Link>
      </div>
      <div className="ddcol-col ddcol-2">
        <DeviceItem
          icon={getIcon(device.kind)}
          main={device.brand}
          second={device.model}
        />
      </div>
      <div className="ddcol-col ddcol-3">
        <div className="label">TYPE OF LOSS</div>
        <LossTypeList
          className="device-item-header__loss-types"
          list={typeLost}
          max={2}
        />
      </div>
      <div className="device-item-header__divider" />
      <div className="ddcol-col ddcol-4">
        <div className="label">EVIDENCE</div>
        <EvidenceCount
          className="device-item-header__evidence"
          claimDeviceId={device.id}
        />
      </div>
      <div className="device-item-header__divider" />
      <div className="ddcol-col ddcol-5">
        <div className="label">CURRENT STATUS</div>
        <div>
          <StatusLabel
            className={c({
              inprogress: device.deviceStatus === 'pending',
              success: device.deviceStatus === 'approved',
              rejected: device.deviceStatus === 'rejected',
              finished: device.deviceStatus === 'finished',
            })}
          >
            {device.deviceStatus}
          </StatusLabel>
        </div>
      </div>
      <div className="ddcol-col ddcol-6">
        <div className="label">&nbsp;</div>
        <button
          type="button"
          title="info"
          className="walkthrough-info"
          onClick={onInfo}
        >
          <IconInfo />
        </button>
      </div>
    </div>
  )
}
export default DeviceItemHeader

DeviceItemHeader.propTypes = {
  className: PropTypes.string,
  device: PropTypes.shape({
    brand: PropTypes.string,
    model: PropTypes.string,
    deviceStatus: PropTypes.string,
  }),
  typeLost: PropTypes.arrayOf(
    PropTypes.shape({
      cat: PropTypes.string,
      lt: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  claimID: PropTypes.number,
  onInfo: PropTypes.func,
}

DeviceItemHeader.defaultProps = {
  className: '',
  device: {},
  typeLost: [],
  claimID: 1,
  onInfo: () => {},
}
