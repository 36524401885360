import { createSlice } from '@reduxjs/toolkit'
import {
  WHITE_LABEL_COLOR,
  WHITE_LABEL_BRAND_IMAGE_URL,
  WHITE_LABEL_FAVICON_LINK,
  WHITE_LABEL_BRAND_NAME,
  WHITE_LABEL_TITLE,
} from '../constants'

export const initialState = {
  loading: true,
  currentSteep: null,
  traveled: false,
  navigation: [],
  error: false,
  errorMessage: '',
  timerId: null,
  notification: {
    show: false,
    type: 'error',
    message: 'some error',
  },
  notifications: [], // [{ type: 'error', message: 'some message' }]
  brand: localStorage.getItem('brand') || 'akko',
  logoPath: localStorage.getItem('logopath') || '/brands/akko.png',
  brandColor: null,
  branding: {
    name: WHITE_LABEL_TITLE,
    logoUrl: WHITE_LABEL_BRAND_IMAGE_URL,
    color: WHITE_LABEL_COLOR,
    brandName: WHITE_LABEL_BRAND_NAME,
    tabIconUrl: WHITE_LABEL_FAVICON_LINK,
  },
  themes: [],
  pagesVisited: {},
  addHideCssMobile: false,
}
export const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {
    registerPageVisited: (state, action) => {
      const { payload } = action
      state.pagesVisited[payload] = true
    },
    toggleLoading: (state, action) => {
      const { payload } = action
      state.loading = payload
    },
    setTimerId: (state, action) => {
      const { payload } = action
      state.timerId = payload
    },
    setCurrentSteep: (state, action) => {
      const { payload } = action
      state.currentSteep = payload
    },
    setTraveled: (state, action) => {
      const { payload } = action
      state.traveled = payload
    },
    setAppError: (state, action) => {
      const { payload } = action
      const { error, message } = payload
      state.error = error
      state.errorMessage = message
    },
    setoLogoPath: (state, action) => {
      const { payload } = action
      state.logoPath = payload
    },
    setBrand: (state, action) => {
      const { payload } = action
      state.brand = payload
    },
    setBranding: (state, action) => {
      const { payload } = action
      const { name, color, logoUrl } = payload
      state.branding = {
        name,
        color,
        logoUrl,
      }
    },
    setNotification: (state, action) => {
      const { payload } = action
      state.notification = {
        ...payload,
      }
    },
    closeNotification: (state, action) => {
      const { payload } = action
      state.notification = {
        show: false,
        message: '',
        type: '',
      }
    },
    addNotification: (state, action) => {
      const { payload } = action
      state.notifications = [
        ...state.notifications,
        { ...payload, id: Date.now() },
      ]
    },
    removeNotification: (state, action) => {
      const tmp = [...state.notifications]
      tmp.shift()
      state.notifications = [...tmp]
    },
    setAddHideCssMobile: (state, action) => {
      const { payload } = action
      state.addHideCssMobile = payload
    },
  },
})

export const {
  registerPageVisited,
  toggleLoading,
  setCurrentSteep,
  setTraveled,
  setAppError,
  setoLogoPath,
  setBrand,
  setBranding,
  setNotification,
  closeNotification,
  addNotification,
  removeNotification,
  setTimerId,
  setAddHideCssMobile,
} = appStore.actions

export default appStore.reducer
