import React from 'react'

export function IconInformation() {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.571411"
        y="3"
        width="19.4286"
        height="18.2857"
        rx="5"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2856 4.60001C22.2856 2.45416 20.5458 0.714233 18.4 0.714233H6.74283C4.59601 0.714233 2.85718 2.45416 2.85718 4.60001V19.1712C2.85718 19.5646 3.09315 19.9182 3.45648 20.0688C3.81979 20.2194 4.23759 20.1358 4.51536 19.858C4.51536 19.858 6.40282 17.9706 7.54722 16.8262C7.9115 16.4619 8.40589 16.257 8.92076 16.257H18.4001C20.546 16.257 22.2857 14.518 22.2857 12.3712L22.2856 4.60001ZM20.3429 4.60001C20.3429 3.52748 19.4725 2.65712 18.4 2.65712H6.74283C5.66934 2.65712 4.79992 3.52748 4.79992 4.60001V16.8261L6.17363 15.4526C6.9022 14.724 7.89002 14.314 8.92072 14.314H18.4001C19.4726 14.314 20.343 13.4446 20.343 12.3711L20.3429 4.60001ZM13.5426 11.3998V8.48553C13.5426 7.94935 13.1064 7.51417 12.5712 7.51417C12.035 7.51417 11.5999 7.94935 11.5999 8.48553V11.3998C11.5999 11.936 12.035 12.3711 12.5712 12.3711C13.1064 12.3711 13.5426 11.936 13.5426 11.3998ZM12.5712 4.60001C13.1064 4.60001 13.5426 5.03519 13.5426 5.57137C13.5426 6.10755 13.1064 6.54273 12.5712 6.54273C12.035 6.54273 11.5999 6.10755 11.5999 5.57137C11.5999 5.03519 12.035 4.60001 12.5712 4.60001Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.25"
      />
    </svg>
  )
}
export default IconInformation

IconInformation.propTypes = {}

IconInformation.defaultProps = {}
