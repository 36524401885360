import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import Device from '../device'
import {
  selectClaimItemsAffected,
  selectClaimTypeLoss,
} from '../../selectors/claim'
import LossFields from './loss-fields'
import { DELAY_TIMING, TYPE_LOSS_PATH } from '../../constants'
import { getIcon } from '../icons'
import { updateClaim } from '../../reducers/claim'

export function TypeLossForm() {
  const [devices, setDevices] = useState(useSelector(selectClaimItemsAffected))
  const typeLoss = useSelector(selectClaimTypeLoss)
  const dispatch = useDispatch()

  const onToggleDropDownDevice = (d) => {
    const updatedDevices = devices.map((device) => {
      if (device.id === d.id) {
        return { ...device, open: !device.open }
      }
      return device
    })
    setDevices(updatedDevices)
  }

  const isNotEmpty = (tl) => {
    return !isEmpty(tl.typeLoss) && !isEmpty(tl.previousDamage)
  }

  const updateLossTypes = useCallback(
    debounce((data) => {
      dispatch(updateClaim({ section: TYPE_LOSS_PATH })).then((claim) => {})
    }, DELAY_TIMING),
    []
  )

  const onChangeValues = (data) => {
    updateLossTypes(data)
  }

  return (
    <div className="type-loss-form">
      {devices.map((device) => (
        <Device
          key={device.id}
          className="mb-3"
          onSelect={onToggleDropDownDevice}
          icon={getIcon(device.kind)}
          data={device}
          done={isNotEmpty(typeLoss[device.id])}
          open={device.open}
        >
          <LossFields device={device} onChangeValues={onChangeValues} />
        </Device>
      ))}
    </div>
  )
}
export default TypeLossForm
