import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import IconUpload from '../icons/icon-upload'
import { UPLOAD_FILE_TXT } from '../../i18n'

export function BtnUploadProof({
  className,
  id,
  more,
  onChangeImage,
  disabled,
  formats,
  ...props
}) {
  const { t } = useTranslation()

  const onChange = (event) => {
    onChangeImage(id, event)
  }
  const onClick = (event) => {
    event.target.value = ''
  }

  return (
    <label
      htmlFor={`upload-proof-btn-${id}`}
      className={c('upload-proof-btn', className, {
        'upload-proof-btn--more': more,
        disabled,
      })}
      {...props}
    >
      <input
        type="file"
        id={`upload-proof-btn-${id}`}
        className="d-none"
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      />
      <div className={c('upload-proof-btn__icon')}>
        <IconUpload />
      </div>
      <span className="upload-proof-btn__txt">{t(UPLOAD_FILE_TXT)}</span>
      <span className="upload-proof-btn__txt-more">Upload More Files</span>
      <span className="upload-proof-btn__format">{formats}</span>
    </label>
  )
}
export default BtnUploadProof

BtnUploadProof.propTypes = {
  className: PropTypes.string,
  more: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChangeImage: PropTypes.func,
  disabled: PropTypes.bool,
  formats: PropTypes.string,
}

BtnUploadProof.defaultProps = {
  className: '',
  more: false,
  onChangeImage: () => {},
  disabled: false,
  formats: 'JPEG, PNG, PDF or VIDEO',
}
