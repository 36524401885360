import React from 'react'

export function IconTrashRed() {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34615 1.59083H7.15876V1.19313C7.15876 0.535241 6.62351 0 5.96563 0H4.3748C3.71691 0 3.18167 0.535241 3.18167 1.19313V1.59083H0.994272C0.44603 1.59083 0 2.03686 0 2.58511V3.97709C0 4.19672 0.178074 4.37479 0.397709 4.37479H0.615057L0.958652 11.5903C0.989002 12.2275 1.51249 12.7267 2.15041 12.7267H8.19002C8.82796 12.7267 9.35145 12.2275 9.38177 11.5903L9.72537 4.37479H9.94272C10.1624 4.37479 10.3404 4.19672 10.3404 3.97709V2.58511C10.3404 2.03686 9.8944 1.59083 9.34615 1.59083ZM3.97709 1.19313C3.97709 0.973839 4.15551 0.795417 4.3748 0.795417H5.96563C6.18492 0.795417 6.36334 0.973839 6.36334 1.19313V1.59083H3.97709V1.19313ZM0.795417 2.58511C0.795417 2.47546 0.884628 2.38625 0.994272 2.38625H9.34615C9.4558 2.38625 9.54501 2.47546 9.54501 2.58511V3.57938C9.42244 3.57938 1.30332 3.57938 0.795417 3.57938V2.58511ZM8.58725 11.5525C8.57713 11.7649 8.40264 11.9313 8.19002 11.9313H2.15041C1.93776 11.9313 1.76327 11.7649 1.75317 11.5525L1.41137 4.37479H8.92906L8.58725 11.5525Z"
        fill="#F3103D"
      />
      <path
        d="M5.16529 11.1375C5.38492 11.1375 5.563 10.9594 5.563 10.7398V5.56958C5.563 5.34995 5.38492 5.17188 5.16529 5.17188C4.94565 5.17188 4.76758 5.34995 4.76758 5.56958V10.7398C4.76758 10.9594 4.94563 11.1375 5.16529 11.1375Z"
        fill="#F3103D"
      />
      <path
        d="M7.1565 11.1375C7.37613 11.1375 7.55421 10.9594 7.55421 10.7398V5.56958C7.55421 5.34995 7.37613 5.17188 7.1565 5.17188C6.93686 5.17188 6.75879 5.34995 6.75879 5.56958V10.7398C6.75879 10.9594 6.93684 11.1375 7.1565 11.1375Z"
        fill="#F3103D"
      />
      <path
        d="M3.17603 11.1375C3.39566 11.1375 3.57374 10.9594 3.57374 10.7398V5.56958C3.57374 5.34995 3.39566 5.17188 3.17603 5.17188C2.95639 5.17188 2.77832 5.34995 2.77832 5.56958V10.7398C2.77832 10.9594 2.95637 11.1375 3.17603 11.1375Z"
        fill="#F3103D"
      />
    </svg>
  )
}
export default IconTrashRed

IconTrashRed.propTypes = {}

IconTrashRed.defaultProps = {}
