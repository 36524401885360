import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import { selectClaimAcceptedTermsConditions } from '../../selectors/claim'
import { toggleAcceptedTermsConditions } from '../../reducers/claim'
import {
  RC_DISCLAIMER_TXT,
  RC_DISCLAIMER_CONTENT_TXT,
  RC_TERMS_TXT,
} from '../../i18n'

export function Disclaimer({ className, errors }) {
  const [agreement, setAgreement] = useState(
    useSelector(selectClaimAcceptedTermsConditions)
  )
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onChangeAgreement = () => {
    const value = !agreement
    setAgreement(value)
    dispatch(toggleAcceptedTermsConditions(value))
  }

  return (
    <div className={c('disclaimer', className)}>
      <div className="section-title">{t(RC_DISCLAIMER_TXT)}</div>
      <p>{t(RC_DISCLAIMER_CONTENT_TXT)}</p>
      <div className="mb-3">
        <div className="form-check">
          <input
            onChange={onChangeAgreement}
            checked={agreement}
            className="form-check-input"
            type="checkbox"
            name="agreement"
            id="agreement"
          />
          <label className="form-check-label" htmlFor="agreement">
            {t(RC_TERMS_TXT)}
          </label>
        </div>
        {!isEmpty(errors.acceptedTermsConditions) && (
          <div className="error-text">{errors.acceptedTermsConditions[0]}</div>
        )}
      </div>
    </div>
  )
}
export default Disclaimer

Disclaimer.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape({
    acceptedTermsConditions: PropTypes.arrayOf(PropTypes.string),
  }),
}
Disclaimer.defaultProps = {
  className: '',
  errors: {},
}
