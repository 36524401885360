import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getClaim } from '../reducers/claim'
import { setAppError } from '../reducers/app'

export function useRequestClaim(claimId) {
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData(params) {
      try {
        // Note: check getClaim condition, it prevent re call to the same claim
        await dispatch(getClaim({ claimId: params.claimId }))
      } catch (error) {
        dispatch(
          setAppError({ error: true, message: 'Error on Authentication' })
        )
      }
    }
    if (claimId) {
      fetchData({ claimId })
    }
  }, [claimId])
}

export default useRequestClaim
