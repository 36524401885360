import React from 'react'

export function Backpack() {
  return (
    <svg
      width="40"
      height="45"
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2476 18.8125C15.0377 18.8133 14.8366 18.8975 14.6887 19.0466C14.5408 19.1957 14.4582 19.3973 14.459 19.6073V25.9427C14.459 27.5545 15.1892 28.8597 16.2481 29.6833C17.3071 30.507 18.658 30.8879 19.9996 30.8879C21.3411 30.8879 22.6936 30.507 23.7526 29.6833C24.8115 28.8597 25.5401 27.5545 25.5401 25.9427V19.6073C25.5409 19.3973 25.4583 19.1957 25.3104 19.0466C25.1625 18.8975 24.9615 18.8133 24.7515 18.8125H15.2476ZM16.044 20.396H23.9567V25.9427C23.9567 27.102 23.5039 27.8704 22.7814 28.4323C22.059 28.9942 21.0332 29.3029 19.9996 29.3029C18.9659 29.3029 17.9401 28.9942 17.2177 28.4323C16.4952 27.8704 16.044 27.102 16.044 25.9427V20.396Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
      <path
        d="M19.999 10.3047C17.0677 10.3047 14.1262 11.1868 11.8807 13.0046C9.63521 14.8224 8.12306 17.6119 8.12306 21.1941V36.237C8.12306 36.237 8.11214 37.3904 8.7014 38.5689C9.29065 39.7474 10.6318 40.9889 12.875 40.9889H27.1231C29.3663 40.9889 30.7074 39.7474 31.2967 38.5689C31.8859 37.3904 31.875 36.237 31.875 36.237V21.1941C31.875 17.6119 30.3628 14.8224 28.1174 13.0046C25.8719 11.1868 22.9303 10.3047 19.999 10.3047ZM19.999 11.8881C22.6098 11.8881 25.212 12.6915 27.1231 14.2386C29.0342 15.7857 30.2915 18.0466 30.2915 21.1941V36.237C30.2915 36.237 30.2822 37.0614 29.8817 37.8622C29.4813 38.663 28.8385 39.4054 27.1231 39.4054H12.875C11.1596 39.4054 10.5167 38.663 10.1163 37.8622C9.71589 37.0614 9.70807 36.237 9.70807 36.237V21.1941C9.70807 18.0466 10.9639 15.7857 12.875 14.2386C14.7861 12.6915 17.3882 11.8881 19.999 11.8881Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
      <path
        d="M20 1C17.3452 1 14.7718 3.18815 14.4873 7.02303C12.78 7.45999 11.175 8.11076 9.77863 8.98999C6.93871 10.7781 4.95711 13.5924 4.95711 17.2367V29.9013H1.78864C1.35381 29.903 1.00174 30.2551 1 30.6899V37.0253C1 37.0253 0.993344 38.038 1.6139 39.0309C2.18224 39.9402 3.35218 40.8165 5.22618 40.9592C5.30495 41.2089 5.40295 41.472 5.53545 41.737C6.1247 42.9155 7.4658 44.1493 9.70904 44.1493H30.2925C32.5357 44.1493 33.8768 42.9155 34.4661 41.737C34.5986 41.472 34.6966 41.2089 34.7754 40.9592C36.6494 40.8165 37.8193 39.9402 38.3876 39.0309C39.0082 38.038 39 37.0253 39 37.0253V30.6899C38.9983 30.2551 38.6462 29.903 38.2114 29.9013H35.0444V17.2367C35.0444 13.5924 33.0628 10.7781 30.2229 8.98999C28.826 8.11044 27.2207 7.45845 25.5127 7.02148C25.2276 3.18754 22.6545 1 20 1ZM20 2.58346C21.7358 2.58346 23.5353 3.80223 23.8891 6.67974C22.6169 6.461 21.3089 6.34882 20 6.34882C18.691 6.34882 17.3832 6.46097 16.1109 6.67974C16.4647 3.80223 18.2642 2.58346 20 2.58346ZM20 7.93228C21.459 7.93228 22.9124 8.08911 24.2988 8.38073C24.5457 8.43935 24.705 8.46873 24.8911 8.50907C26.5472 8.91241 28.0857 9.52166 29.3755 10.3338C31.8798 11.9105 33.461 14.1512 33.461 17.2367V39.4051C33.461 39.4051 33.4516 40.228 33.0512 41.0288C32.6508 41.8296 32.0079 42.5659 30.2925 42.5659H9.70904C7.99362 42.5659 7.35077 41.8296 6.95036 41.0288C6.54994 40.228 6.54057 39.4051 6.54057 39.4051V30.6899V17.2367C6.54057 14.1512 8.12176 11.9105 10.626 10.3338C11.9163 9.5214 13.4552 8.9124 15.112 8.50907C15.3402 8.4618 15.4983 8.42892 15.7027 8.38073C17.0888 8.08923 18.5413 7.93228 20 7.93228ZM2.58346 31.4847H4.95711V39.0603C4.09693 38.8453 3.22182 38.6108 2.95768 38.1882C2.58857 37.5976 2.58346 37.0253 2.58346 37.0253V31.4847ZM35.0444 31.4847H37.4165V37.0253C37.4165 37.0253 37.4115 37.5976 37.0423 38.1882C36.7782 38.6108 35.9046 38.8453 35.0444 39.0603V31.4847Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Backpack
