import React from 'react'

export function IconCheckWhite() {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6744 1.35769L12.5351 1.50124L12.6744 1.35769L12.1578 0.856496C11.9871 0.690885 11.7157 0.690885 11.545 0.856495L5.35499 6.86224C5.33955 6.87721 5.31504 6.8773 5.2995 6.86245L1.87913 3.59387C1.70813 3.43045 1.43852 3.43147 1.26876 3.59618L0.752011 4.09754C0.573101 4.27112 0.573985 4.55853 0.753961 4.731L5.02293 8.82216C5.19388 8.98599 5.46382 8.98516 5.63376 8.82028L12.6744 1.98928C12.8525 1.81644 12.8525 1.53054 12.6744 1.35769Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
      />
    </svg>
  )
}
export default IconCheckWhite

IconCheckWhite.propTypes = {}

IconCheckWhite.defaultProps = {}
