import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'

// DEPREACATED
export function Videos({ className, label, instruction, ...props }) {
  return (
    <div className={c('images mt-2', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      <input type="file" />
    </div>
  )
}
export default Videos

Videos.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

Videos.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
