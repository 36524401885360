import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { selectEvidenceStatus, selectTimerId } from '../selectors/claim'
import {
  checkImagesStatus,
  checkEvidenceStatus,
  setTimerId,
} from '../reducers/claim'
import { CHECK_IMAGES_STATUS_TIMER } from '../constants'

export function useImageStatusChecker(claimId, claimDeviceId, deviceId) {
  const dispatch = useDispatch()
  // this timer is stored in claims state `timerId`
  // after checking the status this timerId is reset to null
  const timerId = useSelector(selectTimerId)
  // this selector pick only evidences that processing_status is `null` and return array of ids
  const { images, files, videos } = useSelector((state) =>
    selectEvidenceStatus(state, deviceId)
  )
  // if there is some id
  if (!isEmpty(images) || !isEmpty(files) || !isEmpty(videos)) {
    if (timerId === null) {
      const newTimerId = setTimeout(() => {
        dispatch(
          checkEvidenceStatus({
            claimId,
            claimDeviceId,
            images,
            files,
            videos,
          })
        )
      }, CHECK_IMAGES_STATUS_TIMER)
      // we store the timerId to avoid having multiple call to the API
      dispatch(setTimerId(newTimerId))
    }
  }
}

export default useImageStatusChecker
