import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

export const userStore = (state) => state.user

export const selectUserData = createSelector(userStore, (user) => user)

export const selectUserToken = createSelector(userStore, (user) => user.token)

export const selectUserAuthed = createSelector(userStore, (user) => user.authed)

export const selectUserAddress = createSelector(userStore, (user) => ({
  country: user.country,
  country_id: user.country_id,
  city: user.city,
  state: user.state,
  street: user.street,
  zip: user.zip,
}))

export const selectUserAddressString = createSelector(
  selectUserAddress,
  (address) =>
    `${address.street}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}`
)

export const selectFormattedUserAddress = createSelector(userStore, (user) => {
  let address = [user.street, user.city, user.state, user.zip, user.country]
  address = address.filter((a) => !isEmpty(a))
  return address.join(', ')
})

export const selectClaims = createSelector(userStore, (user) => user.claims)

export const selectOpenClaims = createSelector(
  selectClaims,
  (claims) => claims.open
)

export const selectClosedClaims = createSelector(
  selectClaims,
  (claims) => claims.closed
)

export const selectDraftClaims = createSelector(
  selectClaims,
  (claims) => claims.draft
)
