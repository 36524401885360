import React from 'react'

export function IconAlert() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-alert"
    >
      <circle cx="5" cy="5" r="5" fill="#F3103D" />
      <path
        d="M4.99602 5.71596C5.38888 5.71596 5.71031 5.39453 5.71031 5.00167V2.85882C5.71031 2.46596 5.38888 2.14453 4.99602 2.14453C4.60317 2.14453 4.28174 2.46596 4.28174 2.85882V5.00167C4.28174 5.39453 4.60317 5.71596 4.99602 5.71596Z"
        fill="white"
      />
      <path
        d="M5.35317 7.85882C5.5496 7.85882 5.71031 7.6981 5.71031 7.50167V6.78739C5.71031 6.59096 5.5496 6.43025 5.35317 6.43025H4.63888C4.44245 6.43025 4.28174 6.59096 4.28174 6.78739V7.50167C4.28174 7.6981 4.44245 7.85882 4.63888 7.85882H5.35317Z"
        fill="white"
      />
    </svg>
  )
}
export default IconAlert

IconAlert.propTypes = {}

IconAlert.defaultProps = {}
