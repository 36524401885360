import React from 'react'

export function IconArrowBreadcrumb() {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.37202 4.53513C5.62013 4.77511 5.62672 5.17078 5.38675 5.41889L1.4761 9.4621C1.23613 9.71021 0.840454 9.7168 0.592343 9.47683C0.344232 9.23685 0.337637 8.84118 0.577614 8.59306L4.05374 4.9991L0.459779 1.52298C0.211668 1.283 0.205073 0.887329 0.44505 0.639218C0.685026 0.391107 1.0807 0.384512 1.32881 0.624489L5.37202 4.53513ZM4.94792 5.60929L4.01042 5.62491L3.98958 4.37509L4.92708 4.35946L4.94792 5.60929Z"
        fill="#757D8A"
      />
    </svg>
  )
}
export default IconArrowBreadcrumb

IconArrowBreadcrumb.propTypes = {}

IconArrowBreadcrumb.defaultProps = {}
