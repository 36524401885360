import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { propTypes, defaultProps } from './props'

// DEPREACATED
export function InputText({ className, children, ...props }) {
  return (
    <div className={c('input-text', className)}>
      <input className="form-control" type="text" />
    </div>
  )
}
export default InputText

InputText.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
}

InputText.defaultProps = {
  ...defaultProps,
  className: '',
  children: null,
}
