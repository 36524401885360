import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { propTypes, defaultProps } from './props'

// DEPREACATED
export function Heading({ className, children, prefill, ...props }) {
  return <div className={c('heading', className)}>{prefill}</div>
}
export default Heading

Heading.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  prefill: PropTypes.string,
}

Heading.defaultProps = {
  ...defaultProps,
  className: '',
  prefill: '',
}
