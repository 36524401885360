import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { setAppError } from './app'
import { GET_PARTNER } from '../constants'

export const getPartner = createAsyncThunk(
  'partner/getPartner',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(GET_PARTNER)
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error load partner information' })
      )
    }
  }
)

export const initialState = {
  partnerId: null,
  partnerName: null,
  partnerPermissions: [],
  partnerPropertyId: null,
  partnerPropertyName: null,
  partnerPropertyPermissions: [],
  partnerPropertyAddress: null,
  partnerPropertyLatitude: null,
  partnerPropertyLongitude: null,
  theme: {},
}
export const partnerStore = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    addPartner: (state, action) => {
      const { payload } = action
      state = { ...payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartner.pending, (state, action) => {})
    builder.addCase(getPartner.fulfilled, (state, action) => {
      const { payload } = action
      if (!isEmpty(payload)) {
        state.partnerId = payload.partner_id
        state.partnerName = payload.partner_name
        state.partnerPermissions = payload.partner_permissions
        state.partnerPropertyId = payload.partner_propertyId
        state.partnerPropertyName = payload.partner_property_name
        state.partnerPropertyPermissions = payload.partner_property_permissions
        state.partnerPropertyAddress = payload.partner_property_address
        state.partnerPropertyLatitude = payload.partner_property_latitude
        state.partnerPropertyLongitude = payload.partner_property_longitude
        state.theme = {
          name: payload.theme.brand_name,
          logoUrl: payload.theme.image_url,
          color: payload.theme.color,
          active: payload.theme.active,
        }
        const singularNames = {
          Phones: 'phone',
          Laptops: 'laptop',
          Tablets: 'tablet',
          TVs: 'tv',
          Other: 'other',
        }
        state.repairDevices = payload.repair_devices.map(
          (d) => singularNames[d.name]
        )
      }
    })
    builder.addCase(getPartner.rejected, (state, action) => {})
  },
})

export const { addPartner } = partnerStore.actions

export default partnerStore.reducer
