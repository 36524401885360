import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import { selectClaimID } from '../../selectors/claim'
import { AFFECTED_ITEMS_PATH } from '../../constants'
import Message from '../message'

export function SelectAffectedItems({ devices, className, ...props }) {
  const claimID = useSelector(selectClaimID)
  if (isEmpty(devices)) {
    return (
      <div className={c('select-items', className)} {...props}>
        <Message error>
          Please select{' '}
          <Link to={`/${AFFECTED_ITEMS_PATH}/${claimID}`}>Affected Items</Link>
        </Message>
      </div>
    )
  }
  return null
}
export default SelectAffectedItems

SelectAffectedItems.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.shape({})),
}

SelectAffectedItems.defaultProps = {
  className: '',
  devices: [],
}
