import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconContainer from '../icon-container'
import IconAlert from '../icons/icon-alert'
import StatusLabel from '../status-label'

export function DeviceItem({
  className,
  icon,
  main,
  second,
  actionRequired,
  ...props
}) {
  return (
    <div className={c('device-item', className)} {...props}>
      <div className="d-flex align-items-center">
        {actionRequired ? (
          <IconContainer className="device-item-action-required">
            <IconAlert />
          </IconContainer>
        ) : null}
        <IconContainer className="me-3" md>
          {icon}
        </IconContainer>
        <div className="flex-grow-1">
          <div className="device-item__main">{main}</div>
          <StatusLabel
            className={c({
              inprogress: second === 'pending',
              success: second === 'approved',
              rejected: second === 'rejected',
              finished: second === 'finished',
            })}
          >
            {second}
          </StatusLabel>
        </div>
      </div>
    </div>
  )
}
export default DeviceItem

DeviceItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  main: PropTypes.string,
  second: PropTypes.string,
  actionRequired: PropTypes.bool,
}

DeviceItem.defaultProps = {
  className: '',
  icon: undefined,
  main: '',
  second: '',
  actionRequired: false,
}
