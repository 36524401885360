import React from 'react'

export function IconLossType() {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.88648 10.0573L0.85791 9.54297V16.2287H11.6579V9.54297L10.1151 10.0573L8.5722 9.54297L7.54362 10.0573L6.00077 9.54297L4.9722 10.0573L3.42934 9.54297L1.88648 10.0573Z"
        fill="white"
      />
      <path
        d="M1.88648 6.76306L0.85791 7.27734V0.59163H11.6579V7.27734L10.1151 6.76306L8.5722 7.27734L7.54362 6.76306L6.00077 7.27734L4.9722 6.76306L3.42934 7.27734L1.88648 6.76306Z"
        fill="white"
      />
      <path
        d="M11.4521 0.285156H0.652316C0.481761 0.285156 0.34375 0.423167 0.34375 0.593722V6.76503C0.34375 6.88587 0.414262 6.99555 0.523947 7.04558L1.87332 7.66271C1.95468 7.70007 2.0487 7.70007 2.13006 7.66271L3.35106 7.10434L4.57206 7.66271C4.65342 7.70007 4.74744 7.70007 4.8288 7.66271L6.0495 7.10434L7.27051 7.66271C7.35187 7.70007 7.44588 7.70007 7.52724 7.66271L8.74915 7.10434L9.97256 7.66271C10.0132 7.68139 10.0569 7.69073 10.1006 7.69073C10.1443 7.69073 10.188 7.68139 10.2287 7.66271L11.5802 7.04558C11.6902 6.99555 11.7607 6.88587 11.7607 6.76503V0.593722C11.7607 0.423167 11.6227 0.285156 11.4521 0.285156ZM11.1435 6.56676L10.1006 7.04286L8.87722 6.48449C8.83654 6.46581 8.79284 6.45647 8.74915 6.45647C8.70546 6.45647 8.66146 6.46581 8.62078 6.48449L7.39887 7.04286L6.17787 6.48449C6.09651 6.44713 6.00249 6.44713 5.92113 6.48449L4.70043 7.04286L3.47943 6.48449C3.39807 6.44713 3.30405 6.44713 3.22269 6.48449L2.00169 7.04286L0.960881 6.56676V0.902287H11.1435V6.56676Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M11.6191 9.38523C11.5305 9.32797 11.4187 9.32014 11.324 9.36413L10.1006 9.9225L8.87722 9.36413C8.79586 9.32677 8.70214 9.32677 8.62078 9.36413L7.39887 9.9225L6.17787 9.36413C6.09651 9.32677 6.00249 9.32677 5.92113 9.36413L4.70043 9.9225L3.47943 9.36413C3.39807 9.32677 3.30405 9.32677 3.22269 9.36413L2.00169 9.9225L0.780684 9.36413C0.685161 9.32074 0.573969 9.32858 0.485678 9.38492C0.397086 9.44188 0.34375 9.53951 0.34375 9.64467V16.4331C0.34375 16.6037 0.481761 16.7417 0.652316 16.7417H11.4521C11.6227 16.7417 11.7607 16.6037 11.7607 16.4331V9.64467C11.7607 9.53981 11.7073 9.44188 11.6191 9.38523ZM11.1435 16.1246H0.960881V10.125L1.87332 10.5423C1.95468 10.5797 2.0487 10.5797 2.13006 10.5423L3.35106 9.98398L4.57206 10.5423C4.65342 10.5797 4.74744 10.5797 4.8288 10.5423L6.0495 9.98398L7.27051 10.5423C7.35187 10.5797 7.44588 10.5797 7.52724 10.5423L8.74915 9.98398L9.97256 10.5423C10.0539 10.5797 10.1473 10.5797 10.2287 10.5423L11.1435 10.1247V16.1246Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default IconLossType

IconLossType.propTypes = {}

IconLossType.defaultProps = {}
