import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { INTERCOM_APP_ID } from '../../constants'

export function NoAuth() {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      })
    }
  }, [(!!window.Intercom).toString()])
  return (
    <div>
      <Outlet />
    </div>
  )
}
export default NoAuth

NoAuth.propTypes = {}

NoAuth.defaultProps = {}
