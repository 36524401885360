import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import {
  selectClaimID,
  selectClaimItemAffectedByID,
  selectClaimTypeLossObjectsByDeviceId,
  selectCustomerTasksCompletedByDeviceId,
  selectCustomerTasksUncompletedByDeviceId,
} from '../selectors/claim'
import InfoLabel from '../components/info-label'
import Collapsable from '../components/collapsable'
import ActionHeader from '../components/action-header'
import DeviceItemHeader from '../components/device-item-header'
import DynamicComponent from '../components/dynamic-component'
import { getDynamicComponentIcon } from '../components/icons'
import Finished from '../components/finished'
import useImageStatusChecker from '../hooks/images-status'
import WalkthroughModal from '../components/walkthrough/modal'
import { CLAIM_DETAIL_PATH } from '../constants'

export function DeviceDetail({ className }) {
  const claimId = useSelector(selectClaimID)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [walkThrough, showWalkThrough] = useState(!!searchParams.get('wt'))
  const location = useLocation()
  const navigate = useNavigate()

  let { claimDeviceId } = params
  claimDeviceId = parseInt(claimDeviceId, 10)

  // const claimDevice = useSelector(
  //   (state) => selectClaimItemAffectedByID(state, claimDeviceId),
  // )

  // if (isEmpty(claimDevice)) {
  //   navigate(`/${CLAIM_DETAIL_PATH}/${claimId}`)
  // }

  const completedDeviceTasks = useSelector((state) =>
    selectCustomerTasksCompletedByDeviceId(state, claimDeviceId)
  )
  const uncompletedDeviceTasks = useSelector((state) =>
    selectCustomerTasksUncompletedByDeviceId(state, claimDeviceId)
  )

  const deviceDetail = useSelector((state) =>
    selectClaimItemAffectedByID(state, claimDeviceId)
  )

  const lossTypes = useSelector((state) =>
    selectClaimTypeLossObjectsByDeviceId(state, claimDeviceId)
  )
  // this hook checks the status of the evidences
  useImageStatusChecker(claimId, deviceDetail?.claimDeviceId, claimDeviceId)

  const container = document.getElementById('modals')
  const onShowWalkthrough = () => {
    navigate(location.pathname)
    showWalkThrough(!walkThrough)
  }
  if (isEmpty(deviceDetail)) {
    return (
      <div className={c('mb-5 device-detail', className)}>
        <div className="container-fluid">
          Device not found, please{' '}
          <Link to={`/${CLAIM_DETAIL_PATH}/${claimId}`}>click here</Link>
        </div>
      </div>
    )
  }

  return (
    <div className={c('mb-5 device-detail', className)}>
      {walkThrough &&
        createPortal(
          <WalkthroughModal
            claimId={claimId}
            model={deviceDetail.model}
            onClose={onShowWalkthrough}
          />,
          container
        )}
      <div className="container-fluid">
        <div className="device-detail__heading">
          <DeviceItemHeader
            device={{ ...deviceDetail }}
            typeLost={lossTypes}
            claimID={claimId}
            status="In Progress"
            onInfo={onShowWalkthrough}
          />
        </div>
      </div>
      <div className="divider" />
      {uncompletedDeviceTasks.length > 0 ? (
        <div className="container-fluid">
          <div className="device-detail__box">
            <InfoLabel
              label="Current Task"
              count={uncompletedDeviceTasks.length}
            />
            {uncompletedDeviceTasks.map((deviceTask) =>
              deviceTask.field_groups.map((fg) => (
                <div key={deviceTask.id}>
                  <Collapsable
                    className="device-detail__card device-detail__action"
                    open
                    header={
                      <ActionHeader
                        alert={deviceTask.task_state_name === 'TODO'}
                        warning={deviceTask.task_state_name === 'DOING'}
                        success={deviceTask.task_state_name === 'DONE'}
                        icon={getDynamicComponentIcon(fg.type)}
                        title={fg.heading}
                        subtitle={fg.sub_heading}
                      />
                    }
                  >
                    <DynamicComponent
                      claimId={claimId}
                      claimDeviceId={deviceDetail.claimDeviceId}
                      deviceId={deviceDetail.deviceId}
                      fields={fg.fields}
                      taskGroupName={deviceTask.taskGroupName}
                    />
                  </Collapsable>
                </div>
              ))
            )}
          </div>
        </div>
      ) : null}
      {deviceDetail.deviceStatus === 'finished' && (
        <Finished model={deviceDetail.model} />
      )}
      <div className="container-fluid">
        <div
          className={c('device-detail__box', {
            'd-none': completedDeviceTasks.length <= 0,
          })}
        >
          <InfoLabel
            label="Completed Task"
            count={completedDeviceTasks.length}
          />
          {completedDeviceTasks.map((deviceTask) =>
            deviceTask.field_groups.map((fg) => (
              <div key={deviceTask.id} className="task-item">
                <Collapsable
                  className="device-detail__card device-detail__action"
                  header={
                    <ActionHeader
                      alert={deviceTask.task_state_name === 'TODO'}
                      warning={deviceTask.task_state_name === 'DOING'}
                      success={deviceTask.task_state_name === 'DONE'}
                      icon={getDynamicComponentIcon(fg.type)}
                      title={fg.heading}
                      subtitle={fg.sub_heading}
                    />
                  }
                >
                  <DynamicComponent
                    claimId={claimId}
                    claimDeviceId={deviceDetail.claimDeviceId}
                    deviceId={deviceDetail.deviceId}
                    fields={fg.fields}
                    disabled
                    completed
                    taskGroupName={deviceTask.taskGroupName}
                  />
                </Collapsable>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}
export default DeviceDetail

DeviceDetail.propTypes = {
  className: PropTypes.string,
}

DeviceDetail.defaultProps = {
  className: '',
}
