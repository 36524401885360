import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import c from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Label from '../label'
import IconContainer from '../icon-container'
import StatusLabel from '../status-label'
import { getIcon } from '../icons'
import { selectClaims } from '../../selectors/user'
import { CLAIM_DETAIL_PATH, AFFECTED_ITEMS_PATH } from '../../constants'
import ClaimsListMobile from '../claims-list-mobile'

export function ClaimsList({ className, ...props }) {
  const claims = useSelector(selectClaims)
  const navigate = useNavigate()

  const onSelectClaim = (status, id) => {
    const path = status === 'DRAFT' ? AFFECTED_ITEMS_PATH : CLAIM_DETAIL_PATH
    navigate(`${path}/${id}`)
  }
  const isMobile = useMediaQuery({ maxWidth: 992 })
  return (
    <div className={c('claims-list', className)} {...props}>
      <div className="claims-list__headers">
        <div className="ai-col1">
          <Label>CLAIM ID</Label>
        </div>
        <div className="ai-col2">
          <Label>AFFECTED ITEMS</Label>
        </div>
        <div className="ai-col3">
          <Label>DATE OF FILING</Label>
        </div>
        <div className="ai-col4">
          <Label>TYPE OF LOSS</Label>
        </div>
        <div className="ai-col5">
          <Label>CURRENT STATUS</Label>
        </div>
      </div>
      <div className="claims-list__items">
        {claims.map((item) => (
          <div
            key={item.id}
            className="claims-list__item"
            onClick={() => onSelectClaim(item.status, item.id)}
          >
            <div className="claims-list__item-col ai-col1">
              <div className="claims-list__claim">
                <div className="claim_id">
                  <IconContainer className="me-3">
                    {item.devices[0] && getIcon(item.devices[0].kind)}{' '}
                    {item.kind}
                  </IconContainer>
                  <div className="claim_id_model">
                    <div className="claim_id_model_id">Claim #{item.id}</div>
                    <div className="claim_model">
                      {item.devices.map((i) => i.model)}
                    </div>
                  </div>
                </div>
                <StatusLabel
                  draft={item.status === 'DRAFT'}
                  new_={item.status === 'NEW'}
                >
                  {item.status}
                </StatusLabel>
              </div>
            </div>
            <div className="claims-list__item-col ai-col2">
              <div className="claims-list__model">
                {item.devices.map((i) => i.model)}
              </div>
            </div>
            <div className="claims-list__item-col ai-col3">
              <div className="claims-list__date">
                <div>{moment(item.date_created).format('DD MMM YYYY')}</div>
              </div>
            </div>
            <div className="claims-list__item-col ai-col4">
              <div className="claims-list__loss-type">
                <div>
                  {item.devices[0]
                    ? item.devices[0].loss_types.map((lt) => lt.name).join(', ')
                    : ''}
                </div>
              </div>
            </div>
            <div className="claims-list__item-col ai-col5">
              <div className="claims-list__status">
                <StatusLabel className={item.status.toLowerCase()}>
                  {item.status}
                </StatusLabel>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isMobile && <ClaimsListMobile claims={claims} />}
    </div>
  )
}
export default ClaimsList

ClaimsList.propTypes = {
  className: PropTypes.string,
}

ClaimsList.defaultProps = {
  className: '',
}
