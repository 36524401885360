import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Spinner({ className, ...props }) {
  return (
    <div
      className={c(
        'spinner-border spinner-border-sm spinner-loading',
        className
      )}
      role="status"
      {...props}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}
export default Spinner

Spinner.propTypes = {
  className: PropTypes.string,
}

Spinner.defaultProps = {
  className: '',
}
