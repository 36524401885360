import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function IconContainer({ className, md, children }) {
  return (
    <div
      className={c('icon-container', className, { 'icon-container--md': md })} role='region'
    >
      {children}
    </div>
  )
}
export default IconContainer

IconContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  md: PropTypes.bool,
}

IconContainer.defaultProps = {
  className: '',
  children: undefined,
  md: false,
}
