import React from 'react'

export function Scooter() {
  return (
    <svg
      width="34"
      height="25"
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33312 8.44529L2.66644 7.11196H9.77754L10.6664 8.88973L11.5553 9.33418L13.3331 11.1119V15.1119L15.1109 16.0008H18.222L21.3331 15.5564L22.6664 15.1119L23.9997 13.7786L24.4442 11.5564L24.8886 8.88973V5.33419L23.5553 1.77864L25.333 0.445312H27.5553V4.4453H25.7775L26.2219 5.77863L27.1108 9.33418V12.4453L26.2219 14.6675H28.8886L31.1108 16.0008L32.4441 17.7786V19.5564H30.6664V20.8897L30.2219 22.223L29.333 23.1119H26.6664L25.7775 22.6675L24.8886 21.3341L24.8312 19.5564H8.44421L7.99976 21.3341L7.55532 22.6675L5.77755 23.5564H4.88866L3.55533 22.6675L2.222 21.3341V19.5564H0.888672V17.3342L1.33312 16.0008V8.44529Z"
        fill="white"
      />
      <path
        d="M5.29257 24.0042C4.12319 24.0036 3.02376 23.4487 2.32881 22.5083C1.63413 21.5681 1.42662 20.3538 1.76979 19.2363C1.87521 18.9031 2.22949 18.7175 2.56325 18.8199C2.89727 18.9225 3.08593 19.2749 2.9866 19.6097C2.763 20.341 2.89921 21.1351 3.3542 21.7498C3.80918 22.3643 4.52855 22.7272 5.29318 22.7272C6.05808 22.7272 6.7774 22.3643 7.23244 21.7498C7.68714 21.135 7.82364 20.341 7.60003 19.6097C7.54899 19.4477 7.56452 19.2721 7.64331 19.122C7.7221 18.9716 7.85776 18.859 8.02006 18.8093C8.18236 18.7594 8.35769 18.7766 8.50751 18.8568C8.65705 18.9369 8.7683 19.0734 8.81685 19.2363C9.15975 20.3538 8.95251 21.5678 8.25782 22.5083C7.56314 23.4485 6.46366 24.0037 5.29457 24.0042L5.29257 24.0042Z"
        fill="#060920"
      />
      <path
        d="M27.7978 24C26.6287 23.9994 25.5293 23.4443 24.8345 22.5041C24.1399 21.5636 23.9326 20.3496 24.2755 19.2321C24.3787 18.8956 24.7352 18.7064 25.0718 18.8093C25.4083 18.9125 25.5975 19.269 25.4943 19.6055C25.2696 20.3371 25.4052 21.1319 25.8599 21.7473C26.3149 22.3629 27.0348 22.726 27.8 22.726C28.5654 22.726 29.2853 22.3629 29.74 21.7473C30.195 21.1319 30.3307 20.3371 30.1059 19.6055C30.0063 19.2707 30.1953 18.9183 30.529 18.8157C30.863 18.7133 31.217 18.8989 31.3225 19.2321C31.6657 20.3496 31.4581 21.5639 30.7635 22.5041C30.0688 23.4446 28.969 23.9995 27.7999 24L27.7978 24Z"
        fill="#060920"
      />
      <path
        d="M10.7676 20.06C10.4161 20.06 10.1312 19.7751 10.1312 19.4236V18.8854C10.1312 18.4734 10.0735 18.0634 9.95945 17.6672C9.95945 17.6636 9.95751 17.6597 9.9564 17.6561C9.84987 17.2905 9.69617 16.9404 9.4992 16.6144C8.69686 15.2827 7.24204 14.4559 5.70172 14.4559C4.24434 14.4598 2.88193 15.1789 2.05629 16.3799C1.54527 17.1154 1.27199 17.9899 1.27285 18.8854V19.4233C1.27285 19.7745 0.987928 20.0595 0.636425 20.0595C0.284922 20.0595 2.61302e-06 19.7745 2.61302e-06 19.4233V18.8854C-0.00110704 17.7307 0.351228 16.6035 1.00987 15.6553C2.10879 14.0581 3.94422 13.1293 5.88184 13.1903C7.81968 13.2516 9.59303 14.2939 10.589 15.9571C10.8456 16.3816 11.0451 16.838 11.1824 17.3146L11.186 17.3276C11.3308 17.8342 11.4041 18.3586 11.4041 18.8854V19.4234C11.4041 19.592 11.3372 19.7541 11.2179 19.8733C11.0983 19.9929 10.9366 20.0601 10.7676 20.0601L10.7676 20.06Z"
        fill="#060920"
      />
      <path
        d="M31.8222 20.0535H0.636422C0.284919 20.0535 0 19.7686 0 19.4171C0 19.0656 0.284919 18.7807 0.636422 18.7807H31.818V18.6103C31.818 16.6614 29.909 15.0773 27.5581 15.0773L26.4886 15.0775C26.1371 15.0775 25.8521 14.7926 25.8521 14.4411C25.8521 14.0896 26.1371 13.8047 26.4886 13.8047H27.5581C30.609 13.8047 33.0908 15.9604 33.0908 18.6103V18.8789C33.0908 19.527 32.5221 20.0535 31.8225 20.0535L31.8222 20.0535Z"
        fill="#060920"
      />
      <path
        d="M10.57 18.1242C10.2185 18.1242 9.93359 17.8393 9.93359 17.488C9.93359 17.2306 10.0887 16.9986 10.3264 16.9002C10.5642 16.8014 10.838 16.856 11.02 17.038C11.202 17.22 11.2564 17.4936 11.1579 17.7313C11.0594 17.9694 10.8275 18.1242 10.57 18.1242Z"
        fill="#060920"
      />
      <path
        d="M10.6984 9.64705C10.3766 9.64677 10.1056 9.40624 10.0673 9.08664C10.0174 8.66495 9.83453 8.27017 9.54516 7.95945C9.22556 7.61017 8.775 7.40986 8.3017 7.40625H3.93441C3.06858 7.40625 2.3134 8.10538 2.17718 9.0328C2.17718 9.03891 2.17718 9.04473 2.17441 9.05028V9.05056C2.12086 9.37737 1.8254 9.60875 1.49526 9.58212C1.16484 9.5552 0.910433 9.27972 0.910156 8.94819C0.910156 8.9074 0.914041 8.86635 0.921532 8.82611C1.03223 8.09785 1.38651 7.42897 1.92664 6.92819C2.47234 6.42022 3.18891 6.13643 3.93443 6.13281H8.30315C9.12295 6.13281 9.8939 6.47128 10.4724 7.08742L10.4721 7.08714C10.9479 7.59651 11.2492 8.24431 11.3316 8.93621C11.3519 9.10405 11.3044 9.27301 11.2001 9.40562C11.0955 9.53851 10.9426 9.62423 10.7748 9.64421C10.7496 9.64643 10.724 9.64754 10.6985 9.64698L10.6984 9.64705Z"
        fill="#060920"
      />
      <path
        d="M1.54658 16.6314C1.19508 16.6314 0.91016 16.3465 0.91016 15.995V8.9513C0.909883 8.87473 0.923477 8.79871 0.949833 8.72657C1.04471 8.46357 1.2944 8.28851 1.57378 8.28906H9.85425C10.1822 8.28906 10.5093 8.32263 10.8302 8.38977C11.7374 8.57954 12.5628 9.04007 13.1546 9.68706C13.7721 10.3632 14.1122 11.2168 14.1122 12.0918V15.3511C14.1125 15.396 14.1078 15.4407 14.0986 15.4845C14.0986 15.4928 14.095 15.5009 14.0931 15.5087C14.064 15.626 14.0035 15.7331 13.9178 15.8186C13.8981 15.838 13.877 15.856 13.8551 15.8729C13.7383 15.9625 13.5954 16.0114 13.4483 16.0114H9.34823C8.99701 16.0114 8.71209 15.7264 8.71209 15.3749C8.71209 15.0237 8.99701 14.7388 9.34823 14.7388H12.8391V12.0929C12.8391 10.926 11.9055 9.91568 10.5689 9.63656C10.3334 9.58746 10.0937 9.56277 9.85313 9.56304H2.18274V15.995C2.18274 16.3465 1.89782 16.6314 1.54659 16.6314L1.54658 16.6314Z"
        fill="#060920"
      />
      <path
        d="M23.1082 20.0382C22.8352 20.0384 22.5922 19.8642 22.5048 19.6054C22.4174 19.3465 22.5048 19.0608 22.722 18.8954C22.9345 18.7334 23.1384 18.5609 23.3334 18.3783C24.6021 17.1884 25.5526 15.5543 26.0828 13.6565V13.6567C26.3266 12.7793 26.4742 11.8781 26.5231 10.9687C26.5367 10.7323 26.5433 10.4882 26.5433 10.241C26.5433 6.36639 24.409 2.86465 22.4257 1.25244L22.4107 1.23968C22.3674 1.20472 22.3255 1.17171 22.2867 1.14175C22.0076 0.928128 21.9546 0.528628 22.1682 0.249568C22.3818 -0.0295278 22.7813 -0.0825173 23.0604 0.131103C23.1145 0.170775 23.1697 0.216273 23.2291 0.264824L23.2449 0.277863C24.4972 1.29881 25.6385 2.80689 26.4589 4.5203C27.3458 6.37241 27.8147 8.35052 27.8147 10.2395C27.8147 10.5117 27.8072 10.7805 27.7925 11.0391C27.7387 12.0401 27.5761 13.0321 27.3073 13.9979C26.7147 16.1211 25.6413 17.9559 24.2026 19.3053H24.2029C23.9762 19.5181 23.739 19.719 23.4921 19.9074C23.3817 19.9917 23.2468 20.0375 23.1081 20.038L23.1082 20.0382Z"
        fill="#060920"
      />
      <path
        d="M22.6703 1.27285H18.986C18.6345 1.27285 18.3496 0.987925 18.3496 0.636422C18.3496 0.284919 18.6345 0 18.986 0H22.6703C23.0218 0 23.3067 0.284919 23.3067 0.636422C23.3067 0.987925 23.0218 1.27285 22.6703 1.27285Z"
        fill="#060920"
      />
      <path
        d="M17.7149 16.8341C17.1295 16.8344 16.5446 16.7936 15.9648 16.712L15.9448 16.709L15.9451 16.7087C15.0038 16.5794 14.0847 16.3225 13.2127 15.9452C12.8995 15.7987 12.7605 15.4289 12.8997 15.1123C13.039 14.7958 13.4058 14.6485 13.7254 14.7805C14.4966 15.1134 15.31 15.3392 16.1426 15.4516L16.1612 15.4544H16.1615C16.6761 15.526 17.1952 15.5615 17.7148 15.5612C19.6735 15.5612 21.5087 15.0629 22.7715 14.1915C23.8971 12.5724 24.5393 10.3086 24.5393 7.9599C24.5393 5.28627 23.7376 2.81108 22.3398 1.16756H22.3401C22.1157 0.897064 22.1531 0.495902 22.4236 0.271472C22.6941 0.047312 23.0953 0.0844852 23.3197 0.355257C24.9049 2.22484 25.8122 4.9967 25.8122 7.9596C25.8122 10.6487 25.0781 13.1559 23.7439 15.0186C23.7037 15.0749 23.6543 15.1243 23.598 15.1648C22.1231 16.2251 19.9784 16.8338 17.7148 16.8338L17.7149 16.8341Z"
        fill="#060920"
      />
      <path
        d="M16.0541 16.7254C15.8912 16.7254 15.7348 16.6632 15.6166 16.5511C15.3949 16.3411 15.3539 16.0035 15.5184 15.7466C15.6829 15.4894 16.0067 15.3854 16.2902 15.4986C16.5737 15.6115 16.7368 15.91 16.6791 16.2096C16.6214 16.5095 16.3593 16.7259 16.0541 16.7259L16.0541 16.7254Z"
        fill="#060920"
      />
      <path
        d="M27.4138 4.93904H25.9673C25.8086 4.93904 25.6505 4.92351 25.4949 4.89299C25.1531 4.82252 24.9317 4.48988 24.9988 4.14726C25.0662 3.80463 25.3967 3.58019 25.7399 3.644C25.8153 3.65899 25.8919 3.66648 25.9685 3.66648H26.7786L26.7788 1.30385H25.9673C25.6949 1.30413 25.4311 1.39818 25.22 1.57046C25.0091 1.74275 24.864 1.98245 24.8091 2.24934C24.7397 2.59391 24.4043 2.81696 24.0597 2.74732C23.7152 2.67796 23.4921 2.34255 23.5615 1.99797C23.6744 1.44311 23.9754 0.944282 24.414 0.585834C24.8524 0.227392 25.4011 0.031527 25.9673 0.03125H27.4139C27.5825 0.03125 27.7445 0.098111 27.8638 0.217406C27.9831 0.336979 28.0503 0.498718 28.0503 0.667395V4.30288C28.0503 4.47155 27.9831 4.63329 27.8638 4.75259C27.7445 4.87216 27.5825 4.93902 27.4139 4.93902L27.4138 4.93904Z"
        fill="#060920"
      />
    </svg>
  )
}
export default Scooter
