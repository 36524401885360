import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconChevron from '../icons/icon-chevron-down'

export function DropdownBtn({ className, up, ...props }) {
  return (
    <div
      className={c('dropdown-btn', className, { 'dropdown-btn--up': up })}
      {...props}
    >
      <IconChevron />
    </div>
  )
}
export default DropdownBtn

DropdownBtn.propTypes = {
  className: PropTypes.string,
  up: PropTypes.bool,
}

DropdownBtn.defaultProps = {
  className: '',
  up: false,
}
