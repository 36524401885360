import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { IconArrowRed } from '../icons/icon-arrow-red'

export function Amount({
  className,
  onToggleBreakDown,
  openBreakdown,
  totalPayoutAmount,
  limitApplied,
}) {
  return (
    <div className={c('payment-amount', className)}>
      <div className="payment-label">Payout Amount</div>
      <div className="payment-quantity">
        <div className="amount">
          ${totalPayoutAmount > 0 ? totalPayoutAmount : '0'}
        </div>
        <button
          type="button"
          className={c('breakdown', { open: openBreakdown })}
          onClick={onToggleBreakDown}
        >
          View Breakdown <IconArrowRed />
        </button>
      </div>
      {limitApplied && (
        <div className="error-box2">You have reached the maximum payment</div>
      )}
    </div>
  )
}
export default Amount

Amount.propTypes = {
  className: PropTypes.string,
  onToggleBreakDown: PropTypes.func,
  openBreakdown: PropTypes.bool,
  totalPayoutAmount: PropTypes.number,
  limitApplied: PropTypes.bool,
}

Amount.defaultProps = {
  className: '',
  onToggleBreakDown: () => {},
  openBreakdown: false,
  totalPayoutAmount: null,
  limitApplied: false,
}
