import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import c from 'classnames'
import PropTypes from 'prop-types'
import IconContainer from '../icon-container'
import IconArrowSmRight from '../icons/icon-arrow-sm-right'
import CheckStatus from '../check-status'

export function MenuItem({
  className,
  icon,
  label,
  done,
  active,
  to,
  disabled,
  ...props
}) {
  const location = useLocation()
  return (
    <Link
      to={disabled ? '#' : to}
      className={c('menu-item d-flex align-items-center', {
        active: location.pathname === to,
        disabled,
      })}
      {...props}
    >
      <span className="menu-item__active-dot" />
      <IconContainer className="me-2">{icon}</IconContainer>
      <span className="flex-grow-1">{label}</span>
      {done && (
        <span>
          <CheckStatus className="menu-item__check-status" sm />
        </span>
      )}
      <span className="menu-item__arrow">
        <IconArrowSmRight />
      </span>
    </Link>
  )
}
export default MenuItem

MenuItem.propTypes = {
  className: PropTypes.string,
  done: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  to: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}

MenuItem.defaultProps = {
  className: '',
  done: false,
  icon: undefined,
  label: '',
  to: '/',
  active: false,
  disabled: false,
}
