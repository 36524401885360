import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconAlert from '../icons/icon-alert'
import IconWarning from '../icons/icon-warning'
import IconSuccess from '../icons/icon-success'

export function ActionHeader({
  className,
  title,
  subtitle,
  icon,
  alert,
  warning,
  success,
  ...props
}) {
  return (
    <div className={c('action-header', className)} {...props}>
      {icon && (
        <div className="action-header__icon">
          <div className="action-header__circle">
            {icon}
            {alert ? (
              <div className="action-header__status">
                <IconAlert />
              </div>
            ) : null}
            {warning ? (
              <div className="action-header__status">
                <IconWarning />
              </div>
            ) : null}
            {success ? (
              <div className="action-header__status">
                <IconSuccess />
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div>
        <div className="action-header__title">{title}</div>
        <div className="action-header__subtitle">{subtitle}</div>
      </div>
    </div>
  )
}
export default ActionHeader

ActionHeader.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alert: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
}

ActionHeader.defaultProps = {
  className: '',
  icon: null,
  title: '',
  subtitle: '',
  alert: false,
  warning: false,
  success: false,
}
