import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Spinner from '../spinner'

export function ProcessingMessage({ className }) {
  return (
    <div className={c('image-preview-processing-message', className)}>
      <Spinner />
      &nbsp;&nbsp; Document is being processing
    </div>
  )
}
export default ProcessingMessage

ProcessingMessage.propTypes = {
  className: PropTypes.string,
}
ProcessingMessage.defaultProps = {
  className: '',
}
