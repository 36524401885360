import { createSlice } from '@reduxjs/toolkit'
import { claimFulfilledHandler } from '../../utils/task'
import {
  getClaim,
  createClaim,
  updateClaim,
  updateDynamicFieldImages,
  updateDynamicFieldFile,
  checkImagesStatus,
  deleteClaimImage,
  updateUserPayoutMethod,
  updateDeviceRepairCost,
  uploadDeviceRepairReceip,
  deleteClaimDeviceFile,
  updateDynamicFieldContinue,
  updateDynamicField,
  getDeviceStoreUrls,
  submitPayment,
  calculatePayment,
  generateShippingLabel,
  deleteEvidence,
  checkEvidenceStatus,
  getClaimLimits,
  getSignedURL,
  submitImage,
} from './thunks'

export const initialState = {
  changesToSave: false,
  reading: true,
  skipLoadingScreen: false,
  updating: false,
  finished: false,
  id: null,
  steepsCompleted: [],
  date: new Date().toString(),
  incident: '',
  affectedItems: [],
  typeLoss: {},
  proofLossImages: {},
  deviceFiles: {},
  deviceVideos: {},
  tasks: {},
  shippings: {},

  havePaypalAccount: true,
  paypalAccount: '',
  venmoAccount: '',
  skipPayoutMethod: false,

  acceptedTermsConditions: false,

  timerId: null,
  checking: false,
  allImagesProcessed: false,
  allWell: false,
  errors: null,
  checkingEvidenceStatus: false,

  // device detail
  deviceImages: {},

  rates: {},
  feedbacks: {},
  storeUrls: {},

  claimLimits: [],
}

export const claimStore = createSlice({
  name: 'claim',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action) => initialState,
    toggleChangesToSave: (state, action) => {
      const { payload } = action
      state.changesToSave = payload
    },
    toggleReadingClaim: (state, action) => {
      const { payload } = action
      state.reading = payload
    },
    setSkipLoadingScreen: (state, action) => {
      const { payload } = action
      state.skipLoadingScreen = payload
    },
    loadClaim: (state, action) => {
      state.value += action.payload
      state.changesToSave = true
    },
    setDate: (state, action) => {
      state.date = action.payload
      state.changesToSave = true
    },
    setIncident: (state, action) => {
      state.incident = action.payload
      state.changesToSave = true
    },
    updateAffectedItems: (state, action) => {
      const { payload } = action
      state.affectedItems = payload // this is updated on updateClaim.fullfilled
      state.changesToSave = true
      const typeLoss = {}
      const proofLossImages = {}
      const deviceFiles = {}
      payload.map((d) => {
        // TODO, kind of confusing. Refactor this or add explanation
        typeLoss[d.id] = {
          typeLoss: [],
          previousDamage: '',
          ...state.typeLoss[d.id],
        }
        proofLossImages[d.id] = state.proofLossImages[d.id]
          ? [...state.proofLossImages[d.id]]
          : []
        deviceFiles[d.id] = state.deviceFiles[d.id]
          ? [...state.deviceFiles[d.id]]
          : []
      })
      state.typeLoss = typeLoss
      state.proofLossImages = proofLossImages
      state.deviceFiles = deviceFiles
    },
    addTypeLoss: (state, action) => {
      const { payload } = action
      state.typeLoss[payload.id] = {
        ...state.typeLoss[payload.id],
        id: payload.id,
        typeLoss: payload.typeLoss,
      }
      state.changesToSave = true
    },
    addItemOperability: (state, action) => {
      const { payload } = action
      state.typeLoss[payload.id] = {
        ...state.typeLoss[payload.id],
        id: payload.id,
        itemOperability: payload.itemOperability,
      }
      state.changesToSave = true
    },
    addPreviousDamage: (state, action) => {
      const { payload } = action
      state.typeLoss[payload.id] = {
        ...state.typeLoss[payload.id],
        id: payload.id,
        previousDamage: payload.previousDamage,
      }
      state.changesToSave = true
    },
    addProofLossImages: (state, action) => {
      const { payload } = action
      const tmp = {
        ...payload,
      }
      state.proofLossImages[payload.claim_device_id].push(tmp)
      state.changesToSave = true
      state.allImagesProcessed = false
      state.allWell = false
      state.errors = null
    },
    addDeviceFiles: (state, action) => {
      const { payload } = action
      state.deviceFiles[payload.claim_device_id].push(payload)
    },
    addDeviceVideos: (state, action) => {
      const { payload } = action
      state.deviceVideos[payload.claim_device_id].push(payload)
    },
    removeProofLossImages: (state, action) => {
      const { payload } = action
    },
    setPaypalAccount: (state, action) => {
      const { payload } = action
      state.paypalAccount = payload
      state.venmoAccount = ''
      state.wireAccount = ''
      state.skipPayoutMethod = false
      state.changesToSave = true
    },
    setVenmoAccount: (state, action) => {
      const { payload } = action
      state.venmoAccount = payload
      state.paypalAccount = ''
      state.wireAccount = ''
      state.skipPayoutMethod = false
      state.changesToSave = true
    },
    setWireAccount: (state, action) => {
      const { payload } = action
      state.wireAccount = payload
      state.venmoAccount = ''
      state.paypalAccount = ''
      state.skipPayoutMethod = false
      state.changesToSave = true
    },
    setSkipPayoutMethod: (state, action) => {
      const { payload } = action
      state.skipPayoutMethod = payload
      state.venmoAccount = ''
      state.paypalAccount = ''
      state.changesToSave = true
    },
    toggleAcceptedTermsConditions: (state, action) => {
      const { payload } = action
      state.acceptedTermsConditions = payload
      state.changesToSave = true
    },
    setImagesStatus: (state, action) => {
      const { payload } = action
      state.allImagesProcessed = payload.allImagesProcessed
      state.allWell = payload.allWell
      state.errors = payload.errors
    },
    setTimerId: (state, action) => {
      const { payload } = action
      state.timerId = payload
    },
    setChecking: (state, action) => {
      const { payload } = action
      state.checking = payload
    },
    setNotRepairable: (state, action) => {
      const { payload } = action
      const { claimDeviceId, notRepairable } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.notRepairable = notRepairable
        }
        return {
          ...item,
        }
      })
    },
    setRepairCost: (state, action) => {
      const { payload } = action
      const { claimDeviceId, repairCost, repairCostUnit, type } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          if (type === 'REPAIR_COST_ESTIMATE') {
            item.repairCostEstimate = repairCost
          }
          if (type === 'REPAIR_COST_FINAL') {
            item.repairCost = repairCost
          }
          item.repairCostUnit = repairCostUnit
        }
        return {
          ...item,
        }
      })
    },
    setRepairReplace: (state, action) => {
      const { payload } = action
      const { claimDeviceId, repair, replace } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.repair = repair
          item.replace = replace
        }
        return {
          ...item,
        }
      })
    },
    setFieldState: (state, action) => {
      // PLEASE use `setFieldOptions` instead
      const { payload } = action
      const { fieldId, newState } = payload
      const { tasks } = state
      Object.values(tasks).map((task_state) => {
        task_state.task_groups.map((taskGroup) => {
          taskGroup.tasks.map((task) => {
            task.field_groups.map((fieldGroup) => {
              fieldGroup.fields.map((field) => {
                if (field.id === fieldId) {
                  field.field_state = newState
                }
              })
            })
          })
        })
      })
      state.tasks = tasks
    },
    setFieldOptions: (state, action) => {
      const { payload } = action
      const { fieldId, newFieldState, newRequiredState, newFillableState } =
        payload
      const { tasks } = state
      Object.values(tasks).map((deviceTask) => {
        deviceTask.task_groups.map((taskGroup) => {
          taskGroup.tasks.map((task) => {
            task.field_groups.map((fieldGroup) => {
              fieldGroup.fields.map((field) => {
                if (field.id === fieldId) {
                  if (newFieldState !== undefined)
                    field.field_state = newFieldState
                  if (newRequiredState !== undefined)
                    field.required = newRequiredState
                  if (newFillableState !== undefined)
                    field.fillable = newFillableState
                }
              })
            })
          })
        })
      })
      state.tasks = tasks
    },
    setRates: (state, action) => {
      const { payload } = action
      const { claimDeviceId, rate } = payload
      state.rates[claimDeviceId] = rate
    },
    setFeedbacks: (state, action) => {
      const { payload } = action
      const { claimDeviceId, feedback } = payload
      // console.log('setFeedbacks', payload)
      state.feedbacks[claimDeviceId] = feedback
    },
    setDeviceVerified: (state, action) => {
      const { payload } = action
      const { verified, claimDeviceId } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.verified = verified
        }
        return {
          ...item,
        }
      })
    },
    setMailedDevice: (state, action) => {
      const { payload } = action
      const { mailed, claimDeviceId } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.mailedDevice = mailed
        }
        return {
          ...item,
        }
      })
    },
    setMailedDateDevice: (state, action) => {
      const { payload } = action
      const { date, claimDeviceId } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.mailedDeviceDate = date
        }
        return {
          ...item,
        }
      })
    },
    setRepairShopAddress: (state, action) => {
      const { payload } = action
      const { address, recommendedRepairShop, claimDeviceId } = payload
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === claimDeviceId) {
          item.repairShopAddress = address
          item.recommendedRepairShop = recommendedRepairShop
        }
        return {
          ...item,
        }
      })
    },
    setStoreUrls: (state, action) => {
      const { payload } = action
      const { urls, claimDeviceId } = payload
      state.storeUrls[claimDeviceId] = urls
    },
  },
  extraReducers: (builder) => {
    // get claim
    builder.addCase(getClaim.pending, (state, action) => {
      state.reading = true
    })

    builder.addCase(getClaim.fulfilled, claimFulfilledHandler)
    builder.addCase(getClaim.rejected, (state, action) => {
      state.reading = false
    })

    // Create new claim
    builder.addCase(createClaim.pending, (state, action) => {
      state.updating = true
    })
    builder.addCase(createClaim.fulfilled, claimFulfilledHandler)
    builder.addCase(createClaim.rejected, (state, action) => {
      state.updating = false
    })

    // Update claim
    builder.addCase(updateClaim.pending, (state, action) => {
      state.updating = true
      state.changesToSave = false
    })
    builder.addCase(updateClaim.fulfilled, claimFulfilledHandler)

    builder.addCase(updateClaim.rejected, (state, action) => {
      state.updating = false
      state.changesToSave = true
    })

    // Images Status
    builder.addCase(checkImagesStatus.pending, (state, action) => {
      state.checking = true
    })
    builder.addCase(checkImagesStatus.fulfilled, (state, action) => {
      const { payload } = action
      state.checking = false
      state.allImagesProcessed = payload.all_complete
      state.allWell = payload.all_well
      state.errors = payload.errors
      if (payload.all_complete) {
        // this timer is created at hook `useImageStatusChecker`
        clearInterval(state.timerId)
        state.timerId = null
      }
      const images = [...payload.images]
      if (images) {
        images.map((image) => {
          return Object.values(state.proofLossImages).forEach((plimages) => {
            return plimages.map((plimage) => {
              if (image.id === plimage.id) {
                plimage.processing_status = !image.status
                plimage.public_url = image.public_url
              }
              return null
            })
          })
        })
      }
    })
    builder.addCase(checkImagesStatus.rejected, (state, action) => {
      state.checking = false
    })

    // delete image
    builder.addCase(deleteClaimImage.fulfilled, (state, action) => {
      const { payload } = action
      const images = state.proofLossImages[payload.deviceId]
      state.proofLossImages[payload.deviceId] = images.filter(
        (image) => image.id !== payload.id
      )
    })
    builder.addCase(deleteClaimImage.rejected, (state, action) => {
      state.changesToSave = true
    })

    // update payout method
    builder.addCase(updateUserPayoutMethod.fulfilled, (state, action) => {
      const { payload } = action
      state.paypalAccount = payload.paypal
    })

    // update device repair cost
    builder.addCase(updateDeviceRepairCost.fulfilled, (state, action) => {
      const { payload } = action
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === payload.claim_device_id) {
          return { ...item, repairCost: payload.repair_cost }
        }
        return item
      })
    })

    // upload device receip
    builder.addCase(uploadDeviceRepairReceip.fulfilled, (state, action) => {
      const { payload } = action
      state.deviceFiles[payload.claim_device_id].push({
        claim_device_id: payload.claim_device_id,
        claim_id: payload.claim_id,
        device_id: payload.device_id,
        name: payload.file_name,
        file_name: payload.file_name,
        file_size: payload.file_size,
        file_type: payload.file_type,
        date_created: payload.file_date_created,
        id: payload.id,
        processing_status: false,
        public_url: payload.public_url,
      })
    })

    // delete device file
    builder.addCase(deleteClaimDeviceFile.fulfilled, (state, action) => {
      const { payload } = action
      const files = state.deviceFiles[payload.deviceId]
      state.deviceFiles[payload.deviceId] = files.filter(
        (file) => file.id !== payload.id
      )
    })

    builder.addCase(updateDynamicFieldImages.fulfilled, (state, action) => {
      const { payload } = action
      const proofLossImages = {}
      payload.map((item) => {
        proofLossImages[item.claim_device_id] = [...item]
      })
      state.proofLossImages = proofLossImages
    })
    builder.addCase(updateDynamicFieldFile.fulfilled, (state, action) => {
      const { payload } = action
      const { handler, response } = payload
      state.deviceFiles[response.claim_device_id] = [
        ...state.deviceFiles[response.claim_device_id],
        { ...response },
      ]
    })

    // Continue
    builder.addCase(updateDynamicFieldContinue.fulfilled, claimFulfilledHandler)

    // Update field
    builder.addCase(updateDynamicField.fulfilled, (state, action) => {
      const { payload } = action
      const { handler, response, field } = payload

      // Update field
      Object.values(state.tasks).map((taskState) => {
        taskState.task_groups.map((taskGroup) => {
          taskGroup.tasks.map((task) => {
            task.field_groups.map((fieldGroup) => {
              fieldGroup.fields.map((f) => {
                if (f.id === field.id) {
                  f.field_state = field.field_state
                  f.field_state_id = field.field_state_id
                }
                // TODO, this is a temporal/fast solution to this bug
                // a proper new feature to wire fields should be implemented
                if (handler === 'repairable') {
                  if (
                    f.type === 'REPAIR_COST_ESTIMATE' ||
                    f.type === 'REPAIR_COST_FINAL' ||
                    f.type === 'SHOP_ADDRESS'
                  ) {
                    if (response.not_repairable === true) {
                      f.required = false
                    } else {
                      f.required = true
                    }
                  }
                }
              })
            })
          })
        })
      })

      if (handler === 'evidences') {
        response.map((item) => {
          item.file_type
          let stateContainer = ''
          if (/image/.test(item.file_type)) {
            stateContainer = 'proofLossImages'
          }
          if (/application/.test(item.file_type)) {
            stateContainer = 'deviceFiles'
          }
          if (/video/.test(item.file_type)) {
            stateContainer = 'deviceVideos'
          }

          if (item.to_delete === true) {
            const index = state[stateContainer][item.claim_device_id].findIndex(
              (i) => i.id === item.id
            )
            if (index > -1) {
              state[stateContainer][item.claim_device_id].splice(index, 1)
            }
          } else {
            state[stateContainer][item.claim_device_id].push(item)
          }
        })
      }
    })
    builder.addCase(getDeviceStoreUrls.fulfilled, (state, action) => {
      const { payload } = action
      const { claim_device_id, urls } = payload
      state.storeUrls[claim_device_id] = urls
    })
    builder.addCase(submitPayment.fulfilled, (state, action) => {
      const { payload } = action
      state.affectedItems = state.affectedItems.map((item) => {
        if (item.id === payload.claim_device_id) {
          item.paid = payload.paid
          item.payoutTransactionAmount = payload.amount
          item.payoutTransactionDateCreated = payload.payment_date
          item.payoutDetailReplacementCost = payload.replacement_cost
          // item.payoutDetailTaxes = payload.taxes // note: should be here
          item.payoutDetailTotalReplacementCost = payload.total_replacement_cost
          item.payoutDetailDeductible = payload.deductible
          item.payoutDetailDeductibleCredit = payload.deductible_credit
          item.payoutDetailTotalCostToAkkoForClaim =
            payload.total_cost_to_akko_for_claim
          item.payoutDetailTotalPayoutAmount = payload.total_payout_amount
          item.payoutDetailIsDeductibleWaived = payload.is_deductible_waived
          item.payoutDetailIsPartnerPayout = payload.is_partner_payout
        }
        return {
          ...item,
        }
      })
    })
    builder.addCase(submitPayment.rejected, (state, action) => {})
    builder.addCase(calculatePayment.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.affectedItems.findIndex(
        (i) => i.id === payload.claim_device_id
      )
      const tmp = {
        ...state.affectedItems[index],
        payoutDetailReplacementCost: payload.replacement_cost,
        payoutDetailTaxes: payload.taxes,
        payoutDetailDeductible: payload.deductible,
        payoutDetailDeductibleCredit: payload.total_credit_used,
        payoutDetailTotalPayoutAmount: payload.total_payout_amount,
        payoutDetailTotalReplaceCost: payload.total_replacement_cost,
        payoutDetailTaxesAmount: payload.taxes_amount,
        payoutDetailReplaceCostWithTaxes: payload.replace_cost_with_taxes,
        payoutDetailHasAppliedClaimLimit: payload.has_applied_claim_limit,
        payoutDetailClaimLimitValue: payload.claim_limit_value,
      }
      state.affectedItems[index] = tmp
    })
    builder.addCase(generateShippingLabel.fulfilled, (state, action) => {
      const { payload } = action
      state.shippings[payload.claim_device_id] = { ...payload }
    })
    builder.addCase(deleteEvidence.fulfilled, (state, action) => {
      const { payload } = action
      const { documentType, claimDeviceId, id } = payload
      if (documentType === 'image') {
        const currents = state.proofLossImages[claimDeviceId]
        const index = currents.findIndex((ci) => ci.id === id)
        currents.splice(index, 1)
        state.proofLossImages[claimDeviceId] = currents
      }
      if (documentType === 'video') {
        const currents = state.deviceVideos[claimDeviceId]
        const index = currents.findIndex((ci) => ci.id === id)
        currents.splice(index, 1)
        state.deviceVideos[claimDeviceId] = currents
      }
      if (documentType === 'file') {
        const currents = state.deviceFiles[claimDeviceId]
        const index = currents.findIndex((ci) => ci.id === id)
        currents.splice(index, 1)
        state.deviceFiles[claimDeviceId] = currents
      }
    })

    builder.addCase(checkEvidenceStatus.pending, (state, action) => {
      // check `checkEvidenceStatus` thunk, there is condition to avoid having multiple calls
      state.checkingEvidenceStatus = true
    })
    builder.addCase(checkEvidenceStatus.fulfilled, (state, action) => {
      const { payload } = action
      const { claim_device_id, images, files, videos } = payload

      // images
      const currentsImages = state.proofLossImages[claim_device_id]
      currentsImages.map((c) => {
        images.map((i) => {
          if (c.id === i.id) {
            c.processing_status = i.processing_status
            c.public_url = i.public_url
          }
        })
      })
      state.proofLossImages[claim_device_id] = currentsImages

      // files
      const currentsFiles = state.deviceFiles[claim_device_id]
      currentsFiles.map((c) => {
        files.map((i) => {
          if (c.id === i.id) {
            c.processing_status = i.processing_status
            c.public_url = i.public_url
          }
        })
      })
      state.deviceFiles[claim_device_id] = currentsFiles

      // videos
      const currentsVideos = state.deviceVideos[claim_device_id]
      currentsVideos.map((c) => {
        videos.map((i) => {
          if (c.id === i.id) {
            c.processing_status = i.processing_status
            c.public_url = i.public_url
          }
        })
      })
      state.deviceVideos[claim_device_id] = currentsVideos
      state.checkingEvidenceStatus = false
      // after each call, we set the timerId to null to let the hook do the call again
      state.timerId = null
    })
    builder.addCase(checkEvidenceStatus.rejected, (state, action) => {
      state.checkingEvidenceStatus = false
    })

    // Claim Limits
    builder.addCase(getClaimLimits.pending, (state, action) => {})
    builder.addCase(getClaimLimits.fulfilled, (state, action) => {
      const { payload } = action
      state.claimLimits = payload
    })
    builder.addCase(getClaimLimits.rejected, (state, action) => {
      state.claimLimits = []
    })

    builder.addCase(getSignedURL.pending, (state, action) => {})
    builder.addCase(getSignedURL.fulfilled, (state, action) => {})
    builder.addCase(getSignedURL.rejected, (state, action) => {})

    builder.addCase(submitImage.pending, (state, action) => {})
    builder.addCase(submitImage.fulfilled, (state, action) => {})
    builder.addCase(submitImage.rejected, (state, action) => {})
  },
})

// Action creators are generated for each case reducer function
export const {
  reset,
  toggleReadingClaim,
  setDate,
  setIncident,
  updateAffectedItems,
  addTypeLoss,
  addPreviousDamage,
  addItemOperability,
  setPaypalAccount,
  setWireAccount,
  addProofLossImages,
  addDeviceFiles,
  addDeviceVideos,
  toggleAcceptedTermsConditions,
  toggleChangesToSave,
  setImagesStatus,
  setTimerId,
  setChecking,
  setVenmoAccount,
  setSkipPayoutMethod,
  setNotRepairable,
  setRepairCost,
  setRepairReplace,
  setFieldState,
  setFieldOptions,
  setRates,
  setFeedbacks,
  setDeviceVerified,
  setMailedDevice,
  setMailedDateDevice,
  setRepairShopAddress,
  setSkipLoadingScreen,
} = claimStore.actions

export {
  getClaim,
  createClaim,
  submitImage,
  updateClaim,
  updateDynamicFieldImages,
  updateDynamicFieldFile,
  checkImagesStatus,
  deleteClaimImage,
  updateUserPayoutMethod,
  updateDeviceRepairCost,
  uploadDeviceRepairReceip,
  deleteClaimDeviceFile,
  updateDynamicFieldContinue,
  updateDynamicField,
  submitPayment,
  calculatePayment,
  generateShippingLabel,
  deleteEvidence,
  checkEvidenceStatus,
} from './thunks'

export default claimStore.reducer
