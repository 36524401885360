import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Label from '../label'
import { propTypes, defaultProps } from './props'

// DEPREACATED
export function Radio({ className, label, instruction, meta, ...props }) {
  const { options } = meta
  return (
    <div className={c('radio', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      {options.map((opt) => (
        <div key={opt} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="radioname"
            id={opt}
          />
          <label className="form-check-label" htmlFor={opt}>
            {opt}
          </label>
        </div>
      ))}
    </div>
  )
}
export default Radio

Radio.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  meta: PropTypes.shape({}),
}

Radio.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  meta: {},
}
