import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { propTypes, defaultProps } from './props'
import { selectFeedbackByDeviceId } from '../../selectors/claim'
import { updateDynamicField, setFeedbacks } from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'

export function Feedback({
  className,
  label,
  instruction,
  onChange,
  claimId,
  deviceId,
  claimDeviceId,
  disabled,
  id: fieldId,
}) {
  const feedbackData = useSelector((state) =>
    selectFeedbackByDeviceId(state, claimDeviceId)
  )
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [feedback, setFeedback] = useState(feedbackData)

  const update = useCallback(
    debounce(() => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'feedback',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        setLoading(false)
        onChange({ fieldId })
      })
    }, DELAY_TIMING),
    []
  )

  const storeFeedback = useCallback(
    debounce((f) => {
      dispatch(setFeedbacks({ claimDeviceId, feedback: f }))
    }, 500),
    []
  )

  const onFeedback = (e) => {
    const v = e.target.value
    setFeedback(v)
    storeFeedback(v)
    update()
  }

  return (
    <div className={c('amount-text', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div className="mb-1">
        <textarea
          onChange={onFeedback}
          className="form-control mb-2"
          rows="3"
          value={feedback}
          disabled={disabled}
        />
        {/* {loading && <Spinner /> } */}
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
    </div>
  )
}
export default Feedback

Feedback.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  disabled: PropTypes.bool,
}

Feedback.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  disabled: false,
}
