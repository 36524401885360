import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import c from 'classnames'
import IconClip from '../icons/icon-clip'
import Evidence from '../evidence'
import { selectClaimDeviceEvidences } from '../../selectors/claim'

export function EvidenceCount({ className, count, claimDeviceId, ...props }) {
  const [showEvidence, setShowEvidence] = useState(false)
  const container = document.getElementById('modals')
  const onShowEvidence = () => setShowEvidence(true)
  const onHideEvidence = () => setShowEvidence(false)

  const evidences = useSelector((state) =>
    selectClaimDeviceEvidences(state, {
      claimDeviceId,
      evidenceType: 'PROOF_OF_LOSS',
    })
  )

  return (
    <div className={c('evidence-counter', className)} {...props}>
      {evidences.length > 0 ? (
        <>
          <IconClip /> {evidences.length}{' '}
          <button type="button" onClick={onShowEvidence}>
            Uploads
          </button>
        </>
      ) : (
        <span className="soft">No Uploads</span>
      )}
      {showEvidence &&
        createPortal(
          <Evidence onClose={onHideEvidence} evidences={evidences} />,
          container
        )}
    </div>
  )
}
export default EvidenceCount

EvidenceCount.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  claimDeviceId: PropTypes.number,
}

EvidenceCount.defaultProps = {
  className: '',
  label: '',
  count: 0,
  claimDeviceId: 0,
}
