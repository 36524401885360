import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Breakdown({
  className,
  openBreakdown,
  cost,
  costWithTaxes,
  taxes,
  taxesAmount,
  finalCost,
  credit,
  deductible,
  totalPayoutAmount,
  limitApplied,
  claimLimitValue,
  repair,
  replace,
}) {
  return (
    <div className={c('payment-breakdown', className)}>
      {replace && (
        <div className="payment-breakdown__item">
          <div className="payment-breakdown-label">Replace Cost</div>
          <div className="payment-breakdown-amount">
            ${costWithTaxes}
            &nbsp;&nbsp;
            <span className="fst-italic">
              {`($${cost} replace cost + ${parseInt(taxes * 100, 10)}% taxes)`}
            </span>
          </div>
        </div>
      )}
      {repair && (
        <div className="payment-breakdown__item">
          <div className="payment-breakdown-label">Repair Cost</div>
          <div className="payment-breakdown-amount">${cost}</div>
        </div>
      )}
      {limitApplied && (
        <div className="payment-breakdown__item">
          <div className="payment-breakdown-label">
            {repair ? 'Repair' : 'Replace'}
            &nbsp; Cost Limited to
          </div>
          <div className="payment-breakdown-amount">
            <div>${claimLimitValue}</div>
          </div>
        </div>
      )}
      <div className="payment-breakdown__item">
        <div className="payment-breakdown-label">Deductible</div>
        <div className="payment-breakdown-amount">
          <div className="payment-symbol affects-amount">-</div>
          <div>${deductible}</div>
        </div>
      </div>
      <div className="payment-breakdown__item">
        <div className="payment-breakdown-label">Deductible Credit</div>
        <div className="payment-breakdown-amount">
          <div className="payment-symbol affects-amount">+</div>
          <div>${credit}</div>
        </div>
      </div>
      <div className="payment-breakdown__item total">
        <div className="payment-breakdown-label">Total Amount</div>
        <div className="payment-breakdown-amount">
          <div>
            <b>${totalPayoutAmount > 0 ? totalPayoutAmount : 0}</b>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Breakdown

Breakdown.propTypes = {
  className: PropTypes.string,
  openBreakdown: PropTypes.bool,
  cost: PropTypes.number.isRequired,
  costWithTaxes: PropTypes.number.isRequired,
  taxes: PropTypes.number.isRequired,
  taxesAmount: PropTypes.number.isRequired,
  finalCost: PropTypes.number.isRequired,
  credit: PropTypes.number.isRequired,
  deductible: PropTypes.number.isRequired,
  totalPayoutAmount: PropTypes.number.isRequired,
  limitApplied: PropTypes.bool.isRequired,
  claimLimitValue: PropTypes.number.isRequired,
  repair: PropTypes.bool,
  replace: PropTypes.bool,
}

Breakdown.defaultProps = {
  className: '',
  repair: false,
  replace: false,
  openBreakdown: false,
}
