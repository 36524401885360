import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { selectClaimTypeLoss } from '../../selectors/claim'
import {
  addTypeLoss,
  addPreviousDamage,
  addItemOperability,
} from '../../reducers/claim'
import { selectAppTraveled } from '../../selectors/app'
import {
  selectLossTypeIdsItemOperabilities,
  selectLossTypesListForSelect,
} from '../../selectors/losstypes'
import { selectItemOperabilitiesForSelector } from '../../selectors/item-operabilities'
import { DI_DATE_LOSS_TXT, DL_TEXTAREA_TXT } from '../../i18n'
import { lossTypesSchemaValidation } from '../../utils/validation'
import useValidation from '../../hooks/validations-schema'

export const verifyIfLossTypeIsAValidItemOperabilityOption = (
  values,
  options
) => {
  if (values.length <= 0) return false
  // every id in values should be present at least one time in the list of options
  return values.every((v) => options.some((lt) => lt === v.id))
}

export function LossFields({ device, onChangeValues }) {
  const typeLoss = useSelector(selectClaimTypeLoss)
  const [typeLossValue, setTypeLossValue] = useState(
    typeLoss[device.id].typeLoss
  )
  const [previousDamageValue, setPreviousDamageValue] = useState(
    typeLoss[device.id].previousDamage
  )
  const [itemOperabilityValue, setItemOperabilityValue] = useState(
    typeLoss[device.id].itemOperability
  )
  const traveled = useSelector(selectAppTraveled)
  const lossTypesList = useSelector(selectLossTypesListForSelect)
  const lossTypesItemOperabilitiesIds = useSelector(
    selectLossTypeIdsItemOperabilities
  )
  const [showItemOperability, setShowItemOperability] = useState(
    verifyIfLossTypeIsAValidItemOperabilityOption(
      typeLossValue,
      lossTypesItemOperabilitiesIds
    )
  )
  const itemOperabilities = useSelector(selectItemOperabilitiesForSelector)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toValidate = {
    typeLoss: typeLossValue,
    previousDamage: previousDamageValue,
    itemOperability: itemOperabilityValue,
  }

  const onChangeItemOperability = (values, saveChanges = true) => {
    dispatch(
      addItemOperability({
        id: device.id,
        itemOperability: values,
      })
    )
    setItemOperabilityValue(values)
    if (saveChanges) onChangeValues()
  }

  const onChangeLossType = (values) => {
    dispatch(
      addTypeLoss({
        id: device.id,
        typeLoss: values,
      })
    )
    setTypeLossValue(values)
    const isAvailable = verifyIfLossTypeIsAValidItemOperabilityOption(
      values,
      lossTypesItemOperabilitiesIds
    )
    if (!isAvailable) {
      onChangeItemOperability(null, false)
    }
    setShowItemOperability(isAvailable)
    onChangeValues()
  }

  const previousDamageDebounced = useCallback(
    debounce((data) => {
      dispatch(addPreviousDamage(data))
    }, 300),
    []
  )

  const onChangePreviousDamage = (event) => {
    previousDamageDebounced({
      id: device.id,
      previousDamage: event.target.value,
    })
    setPreviousDamageValue(event.target.value)
    onChangeValues()
  }
  const [errors] = useValidation(lossTypesSchemaValidation, toValidate)
  return (
    <form>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="mb-3">
            <Select
              className="multiselect loss-types-selector"
              placeholder={t(DI_DATE_LOSS_TXT)}
              options={lossTypesList}
              isMulti
              onChange={onChangeLossType}
              value={typeLossValue}
              isSearchable={false}
              classNamePrefix="multiselect"
            />
            {traveled && !isEmpty(errors.typeLoss) ? (
              <div className="error-text">{errors.typeLoss[0]}</div>
            ) : null}
          </div>
        </div>
        {showItemOperability && (
          <div className="col-lg-6 col-md-12">
            <div className="mb-3">
              <Select
                className="multiselect item-operability-selector"
                placeholder="Item Operability"
                options={itemOperabilities}
                onChange={onChangeItemOperability}
                value={itemOperabilityValue}
                isSearchable={false}
                classNamePrefix="multiselect"
              />
              {traveled && !isEmpty(errors.itemOperability) ? (
                <div className="error-text">{errors.itemOperability[0]}</div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="mb-3">
          <textarea
            className="form-control type-loss-form__description"
            rows="3"
            placeholder={t(DL_TEXTAREA_TXT)}
            onChange={onChangePreviousDamage}
            value={previousDamageValue}
          />
          {traveled && !isEmpty(errors.previousDamage) ? (
            <div className="error-text">{errors.previousDamage[0]}</div>
          ) : null}
        </div>
      </div>
    </form>
  )
}
export default LossFields

LossFields.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onChangeValues: PropTypes.func,
}

LossFields.defaultProps = {
  onChangeValues: () => {},
}
