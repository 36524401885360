import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Close from '../close'

export function Message({
  className,
  children,
  success,
  error,
  closable,
  fade,
  onClose,
  ...props
}) {
  // TODO, find a may to make it self close (unmount)
  return (
    <div
      className={c('message alert', className, {
        fade,
        'alert-secondary': success === false && error === false,
        'alert-success': success,
        'alert-warning': error,
      })}
      role="alert"
      {...props}
    >
      <span>{children}</span>
      {closable && <Close onClose={onClose} />}
    </div>
  )
}
export default Message

Message.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  success: PropTypes.bool,
  error: PropTypes.bool,
  closable: PropTypes.bool,
  fade: PropTypes.bool,
}

Message.defaultProps = {
  className: '',
  children: null,
  onClose: () => {},
  success: false,
  error: false,
  closable: false,
  fade: false,
}
