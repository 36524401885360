import React, { useState } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import {
  addProofLossImages,
  updateClaim,
  setTimerId,
  checkImagesStatus,
  submitImage,
  deleteClaimImage,
} from '../../reducers/claim'
import {
  selectClaimID,
  selectClaimUpdating,
  selectTimerId,
} from '../../selectors/claim'
import { PROOF_LOSS_PATH, CHECK_IMAGES_STATUS_TIMER } from '../../constants'
import ProofLossListView from './view'

export function ProofLossList({
  className,
  onAddImage,
  images,
  device,
  modifyable,
  ...props
}) {
  const dispatch = useDispatch()
  const claimId = useSelector(selectClaimID)
  const updating = useSelector(selectClaimUpdating)
  const timerId = useSelector(selectTimerId)

  // This solution is to keep the original name files
  const [tmpOriginalImageNames, setTmpOriginalImageNames] = useState({})

  const generateName = (fileName) => {
    const prefix = `CLAIM/${claimId}/`
    const extension = fileName.substr(
      fileName.lastIndexOf('.') + 1,
      fileName.length
    )
    const hash = uuidv4()
    return `${prefix}${hash}.${extension}`
  }

  const onChangeImage = (id, event) => {
    const file = event.nativeEvent.target.files[0]
    const filename = generateName(file.name)
    setTmpOriginalImageNames({
      ...tmpOriginalImageNames,
      [filename]: file.name,
    })
    const data = {
      id: null,
      claimId,
      device_id: id,
      name: file.name,
      size: file.size,
      type: file.type,
      processing_status: true,
      file,
      file_name: filename,
      filename,
    }
    dispatch(addProofLossImages(data))
    dispatch(updateClaim({ section: PROOF_LOSS_PATH }))
    dispatch(submitImage(data))

    // execute only one time
    // TODO, move to hooks
    if (timerId === null) {
      const newTimerId = setInterval(() => {
        dispatch(checkImagesStatus({ claimId }))
      }, CHECK_IMAGES_STATUS_TIMER)
      dispatch(setTimerId(newTimerId))
    }
  }

  const onDelete = (id) => {
    dispatch(deleteClaimImage({ deviceId: device.id, imageId: id }))
  }

  return (
    <ProofLossListView
      className={c(className, { modifyable })}
      images={images}
      tmpOriginalImageNames={tmpOriginalImageNames}
      onDelete={onDelete}
      onChangeImage={onChangeImage}
      device={device}
      modifyable={modifyable}
      {...props}
    />
  )
}
export default ProofLossList

ProofLossList.propTypes = {
  className: PropTypes.string,
  device: PropTypes.shape({
    id: PropTypes.number,
  }),
  modifyable: PropTypes.bool,
  onAddImage: PropTypes.func,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),
}

ProofLossList.defaultProps = {
  className: '',
  onAddImage: () => {},
  device: undefined,
  images: [],
  modifyable: true,
}
