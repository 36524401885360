import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Agreement({
  className,
  onChecked,
  checked,
  disabled,
  label,
  id,
}) {
  return (
    <div className={c('payment-agreement', className)}>
      <div className="form-check">
        <input
          onChange={onChecked}
          checked={checked}
          disabled={disabled}
          className="form-check-input"
          type="checkbox"
          name="agreement"
          id={id}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  )
}
export default Agreement

Agreement.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  onChecked: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

Agreement.defaultProps = {
  className: '',
  label: '',
  id: '',
  onChecked: () => {},
  checked: false,
  disabled: false,
}
