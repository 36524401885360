import React from 'react'

export function Imac() {
  return (
    <svg
      width="42"
      height="39"
      viewBox="0 0 42 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0001 37.5H10.0001L8.5 36V35L9.5 34H11.5L13 30H4L2 29V26.345V3.5L3.21606 2H39.0001L40.0001 3.5V28.5L38.5001 30H29.0001L30.5001 34H32.5001L33.5001 35V36.5L32.0001 37.5Z"
        fill="white"
        stroke="#060920"
      />
      <path
        d="M38.362 1H3.68148C2.20667 1 1 2.20667 1 3.68148V23.6138V28.1277V28.2617C1 29.7366 2.20667 30.9432 3.68148 30.9432H11.6812L10.8321 33.1331H10.0724C8.64223 33.1331 7.48025 34.2951 7.48025 35.7252C7.48025 37.1553 8.64223 38.3173 10.0724 38.3173H31.8818C33.3119 38.3173 34.4739 37.1553 34.4739 35.7252C34.4739 34.2951 33.3119 33.1331 31.8818 33.1331H31.1667L30.3176 30.9432H38.3173C39.7921 30.9432 40.9988 29.7366 40.9988 28.2617V28.1277V23.6585V3.68148C41.0435 2.20667 39.8368 1 38.362 1ZM39.2558 28.1277C39.2558 28.6193 38.8536 29.0215 38.362 29.0215H3.68148C3.18988 29.0215 2.78766 28.6193 2.78766 28.1277V25.4015H39.2558V28.1277ZM32.6862 35.7699C32.6862 36.2168 32.3287 36.5744 31.8818 36.5744H10.117C9.67013 36.5744 9.3126 36.2168 9.3126 35.7699C9.3126 35.323 9.67013 34.9655 10.117 34.9655H10.6087H30.6304H31.0773H31.9711C32.3287 34.9655 32.6862 35.323 32.6862 35.7699ZM29.2897 33.1778H12.7538L13.603 30.9879H28.4405L29.2897 33.1778ZM2.78766 23.6585V3.68148C2.78766 3.18988 3.18988 2.78766 3.68148 2.78766H38.362C38.8536 2.78766 39.2558 3.18988 39.2558 3.68148V23.6138H2.78766V23.6585Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.8"
      />
      <path
        d="M15.7191 16.8847C15.9054 16.8847 16.0846 16.8131 16.2279 16.6769L17.6613 15.2436C17.9408 14.9641 17.9408 14.5126 17.6613 14.2331C17.3818 13.9536 16.9303 13.9536 16.6508 14.2331L15.2174 15.6664C14.9379 15.9459 14.9379 16.3974 15.2174 16.6769C15.3536 16.8131 15.5328 16.8847 15.7191 16.8847Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M18.075 12.7926C17.946 12.9288 17.8672 13.1151 17.8672 13.3015C17.8672 13.4878 17.946 13.6741 18.075 13.8103C18.2112 13.9393 18.3975 14.0181 18.5839 14.0181C18.7702 14.0181 18.9565 13.9393 19.0927 13.8103C19.2217 13.6741 19.3005 13.4878 19.3005 13.3015C19.3005 13.1151 19.2217 12.9288 19.0927 12.7926C18.8275 12.5275 18.3474 12.5275 18.075 12.7926Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M19.5095 12.3701C19.6529 12.5063 19.832 12.578 20.0184 12.578C20.2047 12.578 20.3839 12.5063 20.5272 12.3701L22.6772 10.2201C22.9567 9.94062 22.9567 9.48912 22.6772 9.20962C22.3977 8.93013 21.9462 8.93013 21.6667 9.20962L19.5167 11.3596C19.23 11.632 19.23 12.0906 19.5095 12.3701Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M21.6596 12.792L15.2096 19.242C14.9301 19.5215 14.9301 19.973 15.2096 20.2525C15.353 20.3958 15.5321 20.4675 15.7185 20.4675C15.9048 20.4675 16.084 20.3958 16.2273 20.2597L22.6773 13.8097C22.9568 13.5302 22.9568 13.0787 22.6773 12.7992C22.3978 12.5197 21.9391 12.5125 21.6596 12.792Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M23.0926 11.3551C22.9636 11.4913 22.8848 11.6776 22.8848 11.864C22.8848 12.0503 22.9636 12.2366 23.0926 12.3728C23.2288 12.5018 23.4151 12.5806 23.6014 12.5806C23.7878 12.5806 23.9741 12.5018 24.1103 12.3728C24.2393 12.2366 24.3181 12.0503 24.3181 11.864C24.3181 11.6776 24.2393 11.4913 24.1103 11.3551C23.8379 11.09 23.3578 11.09 23.0926 11.3551Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M25.2427 9.20608L24.526 9.92274C24.2465 10.2022 24.2465 10.6537 24.526 10.9332C24.6694 11.0766 24.8485 11.1482 25.0349 11.1482C25.2212 11.1482 25.4004 11.0766 25.5437 10.9404L26.2604 10.2237C26.5399 9.94424 26.5399 9.49274 26.2604 9.21324C25.9809 8.93374 25.5222 8.92658 25.2427 9.20608Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.7"
      />
      <path
        d="M21.021 27.9014C21.5126 27.9014 21.8701 27.4992 21.8701 27.0523C21.8701 26.6053 21.4679 26.2031 21.021 26.2031C20.5741 26.2031 20.1719 26.6053 20.1719 27.0523C20.1719 27.5439 20.5294 27.9014 21.021 27.9014Z"
        fill="#060920"
        stroke="#E8E8E8"
        strokeWidth="0.5"
      />
    </svg>
  )
}
export default Imac
