import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import {
  selectClaimIncident,
  selectClaimDate,
  selectClaimID,
} from '../../selectors/claim'
import IconCalendar from '../icons/icon-calendar'
import IconChat from '../icons/icon-chat'
import { DESCRIBE_INCIDENT_PATH } from '../../constants'

export function ReviewBox({ className, editable, errors, ...props }) {
  const incident = useSelector(selectClaimIncident)
  const dateLoss = useSelector(selectClaimDate)
  const date = new Date(dateLoss)
  const claimId = useSelector(selectClaimID)

  return (
    <div className={`review-box ${className}`} {...props}>
      <div className="mb-4">
        <div>
          <div className="title">
            <IconCalendar />
            Date of Loss
          </div>
          <div className="description">
            {date.toLocaleString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </div>
          {!isEmpty(errors.date) && (
            <div className="error-box2">{errors.date}</div>
          )}
        </div>
      </div>
      <div>
        <div>
          <div className="title">
            <IconChat />
            Description of Incident
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: incident }}
          />
        </div>
      </div>
      {!isEmpty(errors.incident) && (
        <div className="error-box2">{errors.incident}</div>
      )}
      {editable && (
        <div className="text-end">
          <Link
            className="primary small unone"
            to={`/${DESCRIBE_INCIDENT_PATH}/${claimId}`}
          >
            Edit
          </Link>
        </div>
      )}
    </div>
  )
}
export default ReviewBox

ReviewBox.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  errors: PropTypes.shape({
    date: PropTypes.arrayOf(PropTypes.string),
    incident: PropTypes.arrayOf(PropTypes.string),
  }),
}

ReviewBox.defaultProps = {
  className: '',
  editable: false,
  errors: {},
}
