import React from 'react'

export function Surfboard() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 33.5L9.5 36.5L4.5 38.5L2 35.5L2.5 32.5L5 27L8.5 21.5L12.5 16L17.5 11L25 6.5L33 3L37 2H38.5L37 8L34.5 14L30.5 20L27 24L22 29L16 33.5Z"
        fill="white"
      />
      <path
        d="M6.67369 24.7418C6.3113 24.7418 6.0027 24.4653 5.96967 24.0973C5.93475 23.708 6.22212 23.364 6.6114 23.3291L28.888 21.3312C29.2739 21.2949 29.6212 21.5837 29.6561 21.9729C29.6911 22.3622 29.4037 22.7062 29.0144 22.7411L6.73786 24.739C6.71615 24.7409 6.69445 24.7418 6.67369 24.7418Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M11.2752 18.3713C10.9129 18.3713 10.6043 18.0948 10.5712 17.7267C10.5363 17.3374 10.8237 16.9935 11.213 16.9585L33.1083 14.9951C33.4919 14.9597 33.8415 15.2476 33.8764 15.6369C33.9114 16.0261 33.624 16.3701 33.2347 16.4051L11.3394 18.3685C11.3177 18.3704 11.2965 18.3713 11.2752 18.3713Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M4.40565 39.0027C4.2188 39.0027 4.03854 38.9291 3.90501 38.7956L1.20738 36.097C1.05261 35.9427 0.978526 35.7252 1.00542 35.5086C1.61035 30.6735 6.24261 21.6057 13.925 13.6289C23.6189 4.2917 35.7528 1 37.98 1C38.2169 1 38.5184 1.02926 38.7453 1.25622C38.96 1.47092 39.254 1.76489 38.5953 4.40259C38.1612 6.14187 37.4572 8.219 36.6135 10.2523C34.8869 14.4141 31.7118 20.5346 26.3915 26.0577C18.3963 33.7599 9.32858 38.3922 4.49342 38.9971C4.46416 39.0009 4.43491 39.0027 4.40565 39.0027ZM2.45875 35.3468L4.65621 37.5442C9.24459 36.8043 17.908 32.265 25.3912 25.0574C33.9729 16.1477 37.2311 5.09434 37.5614 2.44861C34.7794 2.80581 23.7581 6.12299 14.9258 14.6302C7.73652 22.0955 3.19816 30.7584 2.45875 35.3468Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M6.07717 34.6352C5.89597 34.6352 5.71478 34.5663 5.57652 34.428C5.30001 34.152 5.30001 33.7033 5.57652 33.4272L8.6323 30.3714C8.90881 30.0949 9.3566 30.0949 9.63311 30.3714C9.90962 30.6475 9.90962 31.0962 9.63311 31.3723L6.57734 34.428C6.43956 34.5658 6.25836 34.6352 6.07717 34.6352Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
export default Surfboard
