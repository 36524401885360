import React from 'react'

export function Laptop() {
  return (
    <svg
      width="47"
      height="32"
      viewBox="0 0 47 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.488 30.924H4.51239H3.68312L2.02458 29.6801L1.19531 27.6069L3.26848 27.1923V2.72885L5.46021 1.07031H42.6587L43.9026 2.72885C44.0408 10.8833 44.2343 27.1923 43.9026 27.1923C43.5709 27.1923 45.1465 27.4687 45.9758 27.6069L45.5612 29.6801L43.488 30.924Z"
        fill="white"
      />
      <path
        d="M22.9015 12.4051L19.0254 16.2812L19.939 17.1949L23.8152 13.3188L22.9015 12.4051Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M28.716 11.1161L24.8398 14.9922L25.7535 15.9058L29.6296 12.0297L28.716 11.1161Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M23.5472 16.2907L20.9629 18.875L21.8765 19.7887L24.4608 17.2044L23.5472 16.2907Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M26.7801 8.53433L24.1895 11.125L25.1031 12.0387L27.6938 9.44798L26.7801 8.53433Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M45.9644 26.6236H44.6225V2.99108C44.6225 1.47536 43.3894 0.242188 41.8736 0.242188H5.54101C4.0252 0.242188 2.79203 1.47536 2.79203 2.99108V26.6236H1.45023C1.08081 26.6236 0.78125 26.9231 0.78125 27.2926V28.0935C0.78125 30.0714 2.39037 31.6805 4.36829 31.6805H33.7413C34.1107 31.6805 34.4103 31.3811 34.4103 31.0117C34.4103 30.6422 34.1108 30.3427 33.7413 30.3427H4.36829C3.12804 30.3427 2.11911 29.3337 2.11911 28.0935V27.9615H18.0886V29.1521C18.0886 29.5216 18.388 29.821 18.7575 29.821H28.6572C29.0266 29.821 29.3262 29.5216 29.3262 29.1521V27.9615H45.2956V28.0935C45.2956 29.3338 44.2866 30.3427 43.0465 30.3427H38.4686C38.0992 30.3427 37.7996 30.6422 37.7996 31.0117C37.7996 31.3812 38.0991 31.6805 38.4686 31.6805H43.0465C45.0244 31.6805 46.6335 30.0713 46.6335 28.0935V27.2926C46.6334 26.923 46.3339 26.6236 45.9644 26.6236ZM27.9882 28.4831H19.4264V27.9614H27.9882V28.4831ZM43.2848 26.5671H4.12998V2.99108C4.12998 2.21302 4.76295 1.58005 5.5411 1.58005H41.8736C42.6517 1.58005 43.2848 2.21302 43.2848 2.99108V26.5671Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M40.2124 2.85156H7.20119C6.11024 2.85156 5.22266 3.73914 5.22266 4.83019V11.9476C5.22266 12.3171 5.52213 12.6165 5.89163 12.6165C6.26114 12.6165 6.56061 12.3171 6.56061 11.9476V4.83019C6.56061 4.4769 6.84799 4.18951 7.20119 4.18951H40.2123C40.5655 4.18951 40.8529 4.47699 40.8529 4.83019V24.0405H6.56061V16.7638C6.56061 16.3943 6.26114 16.0949 5.89163 16.0949C5.52213 16.0949 5.22266 16.3943 5.22266 16.7638V24.7094C5.22266 25.0789 5.52213 25.3784 5.89163 25.3784H41.522C41.8914 25.3784 42.1909 25.0789 42.1909 24.7094V4.8301C42.1909 3.73905 41.3033 2.85156 40.2124 2.85156Z"
        fill="#060920"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}
export default Laptop
