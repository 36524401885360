import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Label({ className, children, ...props }) {
  return (
    <span className={c('label', className)} {...props}>
      {children}
    </span>
  )
}
export default Label

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Label.defaultProps = {
  className: '',
  children: null,
}
