import React from 'react'

export function Nothing() {
  return (
    <div>
      <main style={{ padding: '2rem', textAlign: 'center' }}>
        <p>There&apos;s nothing here!</p>
      </main>
    </div>
  )
}
export default Nothing
