import React from 'react'

export function IconQR() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="1" width="17" height="16" rx="5" fill="white" />
      <path
        d="M3.53711 3.15554C3.53711 2.81072 3.81659 2.53125 4.1614 2.53125H5.25411C5.59894 2.53125 5.87854 2.81073 5.87854 3.15554V4.24826C5.87854 4.59308 5.59893 4.87268 5.25411 4.87268H4.1614C3.81658 4.87268 3.53711 4.59307 3.53711 4.24826V3.15554Z"
        fill="#060920"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66672 0C3.96546 0 3.38077 -3.58869e-08 2.91654 0.062456C2.42657 0.12831 1.98414 0.273212 1.62875 0.628748C1.27322 0.984144 1.12832 1.42657 1.06246 1.91654C1 2.38077 1 2.96546 1 3.66672V3.74786C1 4.44912 1 5.03394 1.06246 5.49805C1.12831 5.98801 1.27321 6.43044 1.62875 6.78584C1.98414 7.14137 2.42657 7.2864 2.91654 7.35226C3.38077 7.41472 3.96546 7.41459 4.66672 7.41459H4.74786C5.44912 7.41459 6.03394 7.41472 6.49805 7.35226C6.98801 7.28641 7.43044 7.14138 7.78584 6.78584C8.14137 6.43044 8.2864 5.98801 8.35226 5.49805C8.41472 5.03394 8.41459 4.44912 8.41459 3.74786V3.66672C8.41459 2.96546 8.41472 2.38077 8.35226 1.91654C8.28641 1.42657 8.14138 0.984144 7.78584 0.628748C7.43044 0.273218 6.98801 0.128317 6.49805 0.062456C6.03394 -3.58869e-08 5.44912 0 4.74786 0H4.66672ZM2.45648 1.45647C2.55826 1.35482 2.71231 1.27106 3.07254 1.22272C3.44951 1.17202 3.95489 1.17072 4.70736 1.17072C5.4597 1.17072 5.96511 1.17202 6.34205 1.22272C6.7023 1.27106 6.85646 1.35482 6.95811 1.45647C7.05977 1.55826 7.14352 1.7123 7.192 2.07253C7.24269 2.44951 7.24387 2.95489 7.24387 3.70736C7.24387 4.45969 7.24269 4.96511 7.192 5.34204C7.14352 5.70229 7.05977 5.85646 6.95811 5.95811C6.85646 6.05976 6.70228 6.14351 6.34205 6.19199C5.96508 6.24268 5.4597 6.24386 4.70736 6.24386C3.95489 6.24386 3.44951 6.24269 3.07254 6.19199C2.7123 6.14351 2.55826 6.05976 2.45648 5.95811C2.35482 5.85645 2.27107 5.70228 2.22273 5.34204C2.17203 4.96507 2.17072 4.45969 2.17072 3.70736C2.17072 2.95489 2.17203 2.44951 2.22273 2.07253C2.27107 1.71229 2.35482 1.55826 2.45648 1.45647Z"
        fill="#060920"
      />
      <path
        d="M4.16043 11.1172C3.8156 11.1172 3.53613 11.3968 3.53613 11.7416V12.8343C3.53613 13.1792 3.81562 13.4586 4.16043 13.4586H5.25314C5.59796 13.4586 5.87756 13.1791 5.87756 12.8343V11.7416C5.87756 11.3968 5.59795 11.1172 5.25314 11.1172H4.16043Z"
        fill="#060920"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66672 8.58594C3.96546 8.58594 3.38077 8.58581 2.91654 8.64826C2.42657 8.71412 1.98414 8.85915 1.62875 9.21469C1.27322 9.57008 1.12832 10.0124 1.06246 10.5025C1 10.9666 1 11.5514 1 12.2527V12.3338C1 13.0351 1 13.6198 1.06246 14.084C1.12831 14.574 1.27321 15.0164 1.62875 15.3718C1.98414 15.7273 2.42657 15.8722 2.91654 15.9381C3.38064 16.0005 3.96533 16.0005 4.66659 16.0005H4.74786C5.44912 16.0005 6.03394 16.0005 6.49805 15.9381C6.98801 15.8722 7.43044 15.7273 7.78583 15.3718C8.14136 15.0164 8.2864 14.574 8.35226 14.084C8.41472 13.6198 8.41459 13.0351 8.41459 12.3338V12.2527C8.41459 11.5514 8.41472 10.9666 8.35226 10.5025C8.28641 10.0124 8.14137 9.57008 7.78583 9.21469C7.43044 8.85916 6.98801 8.71412 6.49805 8.64826C6.03394 8.58581 5.44912 8.58594 4.74786 8.58594H4.66672ZM2.45648 10.0424C2.55826 9.94075 2.71231 9.857 3.07254 9.80853C3.44951 9.75796 3.95489 9.75665 4.70736 9.75665C5.4597 9.75665 5.96511 9.75796 6.34205 9.80853C6.7023 9.857 6.85646 9.94075 6.95811 10.0424C7.05977 10.1441 7.14352 10.2982 7.192 10.6585C7.24269 11.0354 7.24387 11.5408 7.24387 12.2932C7.24387 13.0456 7.24269 13.551 7.192 13.928C7.14352 14.2882 7.05977 14.4424 6.95811 14.544C6.85646 14.6457 6.70228 14.7293 6.34205 14.7778C5.96508 14.8285 5.4597 14.8298 4.70736 14.8298C3.95489 14.8298 3.44951 14.8285 3.07254 14.7778C2.7123 14.7293 2.55826 14.6457 2.45648 14.544C2.35482 14.4424 2.27107 14.2882 2.22273 13.928C2.17203 13.551 2.17072 13.0456 2.17072 12.2932C2.17072 11.5408 2.17203 11.0354 2.22273 10.6585C2.27107 10.2982 2.35482 10.1441 2.45648 10.0424Z"
        fill="#060920"
      />
      <path
        d="M12.493 8.58597H12.5127V9.75669C12.141 9.75669 11.8916 9.75695 11.6984 9.77014C11.5105 9.78295 11.4191 9.80607 11.3589 9.83103C11.1199 9.92994 10.9299 10.1199 10.8309 10.3589C10.806 10.4191 10.7829 10.5106 10.7701 10.6985C10.7569 10.8916 10.7567 11.141 10.7567 11.5127H9.58594V11.493C9.58594 11.1461 9.58594 10.8566 9.60214 10.6188C9.61899 10.3713 9.65545 10.1375 9.74926 9.91085C9.96707 9.38493 10.3849 8.96708 10.9108 8.74926C11.1375 8.65545 11.3713 8.61899 11.6188 8.60214C11.8566 8.58594 12.1461 8.58594 12.493 8.58594L12.493 8.58597Z"
        fill="#060920"
      />
      <path
        d="M14.8875 9.77011C14.6943 9.75691 14.4449 9.75665 14.0732 9.75665V8.58594H14.093C14.4397 8.58594 14.7294 8.58594 14.9672 8.60214C15.2147 8.61899 15.4485 8.65545 15.675 8.74926C16.2009 8.96707 16.6188 9.38493 16.8366 9.91085C16.9305 10.1375 16.9669 10.3713 16.9837 10.6188C17 10.8566 17 11.1461 17 11.493V11.5127H15.8293C15.8293 11.141 15.8289 10.8916 15.8157 10.6985C15.8029 10.5106 15.7799 10.4191 15.7551 10.3589C15.6561 10.1199 15.4661 9.92994 15.2271 9.83103C15.1669 9.80608 15.0753 9.78295 14.8876 9.77014L14.8875 9.77011Z"
        fill="#060920"
      />
      <path
        d="M9.58499 13.09V13.0703H10.7557C10.7557 13.4419 10.756 13.6913 10.7692 13.8846C10.782 14.0724 10.8051 14.1639 10.8299 14.2242C10.929 14.4631 11.1189 14.6531 11.3579 14.7522C11.4181 14.777 11.5096 14.8 11.6975 14.8128C11.8906 14.826 12.14 14.8264 12.5118 14.8264V15.9971H12.492C12.1451 15.9971 11.8556 15.9971 11.6178 15.9808C11.3703 15.9639 11.1366 15.9276 10.9099 15.8336C10.384 15.6158 9.96611 15.198 9.74829 14.6721C9.65447 14.4455 9.61802 14.2117 9.60116 13.9643C9.58496 13.7265 9.58496 13.4368 9.58496 13.09L9.58499 13.09Z"
        fill="#060920"
      />
      <path
        d="M15.8157 13.8846C15.8289 13.6914 15.8293 13.4419 15.8293 13.0703H17V13.09C17 13.4368 17 13.7265 16.9837 13.9643C16.9669 14.2118 16.9305 14.4455 16.8366 14.6721C16.6188 15.198 16.2009 15.6158 15.675 15.8337C15.4484 15.9276 15.2147 15.9639 14.9672 15.9808C14.7294 15.9971 14.4397 15.9971 14.093 15.9971H14.0732V14.8264C14.4448 14.8264 14.6943 14.826 14.8875 14.8128C15.0753 14.8 15.1669 14.777 15.2271 14.7522C15.4661 14.6532 15.656 14.4632 15.7551 14.2242C15.7799 14.164 15.8029 14.0724 15.8157 13.8846L15.8157 13.8846Z"
        fill="#060920"
      />
      <path
        d="M12.7465 2.53125C12.4017 2.53125 12.1221 2.81073 12.1221 3.15554V4.24826C12.1221 4.59308 12.4017 4.87268 12.7465 4.87268H13.8392C14.184 4.87268 14.4635 4.59307 14.4635 4.24826V3.15554C14.4635 2.81072 14.184 2.53125 13.8392 2.53125H12.7465Z"
        fill="#060920"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2517 0C12.5504 0 11.9656 -3.72529e-08 11.5015 0.062456C11.0114 0.12831 10.5691 0.273212 10.2137 0.628748C9.85818 0.984144 9.71315 1.42657 9.64729 1.91654C9.58483 2.38077 9.58496 2.96546 9.58496 3.66672V3.74786C9.58496 4.44912 9.58483 5.03394 9.64729 5.49805C9.71314 5.98801 9.85817 6.43044 10.2137 6.78584C10.5691 7.14137 11.0114 7.2864 11.5015 7.35226C11.9656 7.41472 12.5504 7.41459 13.2517 7.41459H13.3328C14.0341 7.41459 14.6188 7.41472 15.083 7.35226C15.573 7.28641 16.0154 7.14138 16.3708 6.78584C16.7263 6.43044 16.8712 5.98801 16.9371 5.49805C16.9995 5.03394 16.9995 4.44925 16.9995 3.748V3.66672C16.9995 2.9656 16.9995 2.38078 16.9371 1.91654C16.8712 1.42658 16.7263 0.984147 16.3708 0.628751C16.0154 0.273222 15.573 0.12832 15.083 0.0624593C14.6188 3.28198e-06 14.0341 3.31754e-06 13.3328 3.31754e-06L13.2517 0ZM11.0414 1.45647C11.1431 1.35482 11.2973 1.27106 11.6575 1.22272C12.0345 1.17202 12.5399 1.17072 13.2922 1.17072C14.0447 1.17072 14.55 1.17202 14.927 1.22272C15.2873 1.27106 15.4414 1.35482 15.5431 1.45647C15.6447 1.55826 15.7284 1.7123 15.7768 2.07253C15.8275 2.44951 15.8288 2.95489 15.8288 3.70736C15.8288 4.45969 15.8275 4.96511 15.7768 5.34204C15.7284 5.70229 15.6447 5.85645 15.5431 5.95811C15.4414 6.05976 15.2872 6.14351 14.927 6.19199C14.55 6.24268 14.0447 6.24386 13.2922 6.24386C12.5399 6.24386 12.0344 6.24269 11.6575 6.19199C11.2973 6.14351 11.1431 6.05976 11.0414 5.95811C10.9398 5.85645 10.856 5.70228 10.8076 5.34204C10.757 4.96507 10.7557 4.45969 10.7557 3.70736C10.7557 2.95489 10.757 2.44951 10.8076 2.07253C10.856 1.71229 10.9398 1.55826 11.0414 1.45647Z"
        fill="#060920"
      />
      <path
        d="M12.1221 11.7416C12.1221 11.3968 12.4017 11.1172 12.7465 11.1172H13.8392C14.184 11.1172 14.4635 11.3968 14.4635 11.7416V12.8343C14.4635 13.1792 14.184 13.4586 13.8392 13.4586H12.7465C12.4017 13.4586 12.1221 13.1791 12.1221 12.8343V11.7416Z"
        fill="#060920"
      />
    </svg>
  )
}
export default IconQR

IconQR.propTypes = {}

IconQR.defaultProps = {}
