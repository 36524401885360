import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconClose from '../icons/icon-close'

export function Close({ className, onClose, ...props }) {
  return (
    <button
      type="button"
      onClick={onClose}
      className={c('close', className)}
      {...props}
    >
      <IconClose />
    </button>
  )
}
export default Close

Close.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
}

Close.defaultProps = {
  className: '',
  onClose: () => {},
}
