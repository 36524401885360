import React from 'react'

export function IconArrowRed() {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.5L4 5L1 1.5"
        stroke="#F3103D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default IconArrowRed

IconArrowRed.propTypes = {}

IconArrowRed.defaultProps = {}
