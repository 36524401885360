import React from 'react'

export function AmazonFireStick() {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8V2.5H11V8.2716H13.5V27L13 29L11 31.5L8.5 32.5H6.5L4.5 31.5L2.5 29.5L1.5 27.5V8H4Z"
        fill="white"
      />
      <path
        d="M13 7.68334H11.5V2H3.5V7.68334H2C1.73482 7.68334 1.48035 7.79222 1.29286 7.98596C1.10536 8.17971 1 8.44266 1 8.71668V26.2834C1 28.6831 2.23886 30.9004 4.25 32.1003C6.26114 33.2999 8.73886 33.2999 10.75 32.1003C12.7611 30.9004 14 28.6831 14 26.2834V8.71668C14 8.44266 13.8946 8.17971 13.7071 7.98596C13.5196 7.79222 13.2652 7.68334 13 7.68334ZM4.5 3.03334H10.5V7.68334H4.5V3.03334ZM13 26.2834C13 28.3139 11.9518 30.19 10.25 31.2053C8.54823 32.2204 6.45177 32.2204 4.75 31.2053C3.04823 30.19 2 28.3138 2 26.2834V8.71668H13V26.2834ZM5 4.58334H6.5V5.61667H5V4.58334ZM8.5 4.58334H10V5.61667H8.5V4.58334ZM7.5 23.1834C6.70423 23.1834 5.94131 23.51 5.37857 24.0912C4.81607 24.6727 4.5 25.4611 4.5 26.2834C4.5 27.1057 4.81607 27.894 5.37857 28.4755C5.9413 29.0568 6.70423 29.3834 7.5 29.3834C8.29577 29.3834 9.05869 29.0568 9.62143 28.4755C10.1839 27.894 10.5 27.1057 10.5 26.2834C10.5 25.4611 10.1839 24.6727 9.62143 24.0912C9.0587 23.51 8.29577 23.1834 7.5 23.1834ZM7.5 28.35C6.96965 28.35 6.46091 28.1323 6.08571 27.7448C5.71071 27.3571 5.5 26.8314 5.5 26.2834C5.5 25.7354 5.71071 25.2097 6.08571 24.8219C6.46094 24.4344 6.96966 24.2167 7.5 24.2167C8.03034 24.2167 8.53909 24.4344 8.91429 24.8219C9.28929 25.2097 9.5 25.7354 9.5 26.2834C9.5 26.8314 9.28929 27.3571 8.91429 27.7448C8.53906 28.1323 8.03034 28.35 7.5 28.35ZM3 9.4918C3 9.59628 2.93906 9.69039 2.84576 9.73053C2.75223 9.77043 2.64465 9.74829 2.57322 9.67448C2.50179 9.60067 2.48036 9.48949 2.51898 9.39285C2.55782 9.29644 2.64889 9.23347 2.75001 9.23347C2.8163 9.23347 2.87991 9.26069 2.92679 9.30912C2.97367 9.35756 3 9.42329 3 9.4918ZM12 9.4918C12 9.38731 12.0609 9.2932 12.1542 9.25307C12.2478 9.21317 12.3554 9.23531 12.4268 9.30912C12.4982 9.38293 12.5196 9.4941 12.481 9.59075C12.4422 9.68716 12.3511 9.75013 12.25 9.75013C12.1118 9.75013 12 9.63458 12 9.4918Z"
        fill="#060920"
        stroke="#060920"
        strokeWidth="0.25"
      />
      <rect x="21" y="1" width="14" height="34" fill="white" />
      <path
        d="M21.5019 0C20.683 0 20 0.682086 20 1.50009V34.4999C20 35.3176 20.683 36 21.5019 36H34.9977C35.8166 36 36.4997 35.3179 36.4997 34.4999L36.5 1.50009C36.5 0.682384 35.817 0 34.9981 0H21.5019ZM21.5019 1.50009H34.9977V34.4999H21.5019V1.50009Z"
        fill="#060920"
      />
      <path
        d="M24.4726 28.4062C23.6537 28.4062 22.9707 29.0883 22.9707 29.9063V31.7053C22.9707 32.523 23.6537 33.2054 24.4726 33.2054H32.0271C32.846 33.2054 33.529 32.5233 33.529 31.7053V29.9063C33.529 29.0886 32.846 28.4062 32.0271 28.4062H24.4726ZM24.4726 29.9063H32.0271V31.7053H24.4726V29.9063Z"
        fill="#060920"
      />
      <path
        d="M28.2532 10.5C25.6843 10.5 23.5586 12.5377 23.5586 15.0608C23.5586 17.5839 25.6843 19.6217 28.2532 19.6217C30.8221 19.6217 32.9421 17.5839 32.9421 15.0608C32.9421 12.5378 30.8221 10.5 28.2532 10.5ZM28.2532 12.0001C30.0358 12.0001 31.4401 13.3746 31.4401 15.0608C31.4401 16.7474 30.036 18.1216 28.2532 18.1216C26.4705 18.1216 25.0606 16.7471 25.0606 15.0608C25.0606 13.3743 26.4703 12.0001 28.2532 12.0001Z"
        fill="#060920"
      />
      <path
        d="M26.9078 21.5299C26.9078 21.8444 26.7785 22.1461 26.5487 22.3687C26.3186 22.5912 26.0066 22.7161 25.6814 22.7161C25.3563 22.7161 25.0442 22.5912 24.8145 22.3687C24.5844 22.1461 24.4551 21.8445 24.4551 21.5299C24.4551 21.2154 24.5844 20.9134 24.8145 20.6909C25.0443 20.4687 25.3563 20.3438 25.6814 20.3438C26.0066 20.3438 26.3186 20.4687 26.5487 20.6909C26.7785 20.9135 26.9078 21.2154 26.9078 21.5299Z"
        fill="#060920"
      />
      <path
        d="M26.9078 25.655C26.9078 25.9698 26.7785 26.2715 26.5487 26.494C26.3186 26.7165 26.0066 26.8415 25.6814 26.8415C25.3563 26.8415 25.0442 26.7165 24.8145 26.494C24.5844 26.2714 24.4551 25.9698 24.4551 25.655C24.4551 25.3405 24.5844 25.0388 24.8145 24.8162C25.0443 24.5937 25.3563 24.4688 25.6814 24.4688C26.0066 24.4688 26.3186 24.5937 26.5487 24.8162C26.7785 25.0388 26.9078 25.3404 26.9078 25.655Z"
        fill="#060920"
      />
      <path
        d="M32.0485 21.5302C32.0485 21.8447 31.9191 22.1464 31.689 22.369C31.4593 22.5915 31.1473 22.7165 30.8221 22.7165C30.4969 22.7165 30.1849 22.5915 29.9548 22.369C29.725 22.1464 29.5957 21.8448 29.5957 21.5302C29.5957 21.2154 29.725 20.9137 29.9548 20.6912C30.1849 20.4687 30.4969 20.3438 30.8221 20.3438C31.1473 20.3438 31.4593 20.4687 31.689 20.6912C31.9191 20.9138 32.0485 21.2154 32.0485 21.5302Z"
        fill="#060920"
      />
      <path
        d="M32.0485 25.655C32.0485 25.9695 31.9191 26.2715 31.689 26.494C31.4593 26.7162 31.1473 26.8411 30.8221 26.8411C30.4969 26.8411 30.1849 26.7162 29.9548 26.494C29.725 26.2714 29.5957 25.9695 29.5957 25.655C29.5957 25.3405 29.725 25.0388 29.9548 24.8162C30.1849 24.5937 30.4969 24.4688 30.8221 24.4688C31.1473 24.4688 31.4593 24.5937 31.689 24.8162C31.9191 25.0388 32.0485 25.3404 32.0485 25.655Z"
        fill="#060920"
      />
      <path
        d="M26.9075 4.48311C26.9075 4.79794 26.7785 5.09959 26.5484 5.32211C26.3183 5.54465 26.0066 5.66958 25.6811 5.66958C25.3559 5.66958 25.0442 5.54465 24.8142 5.32211C24.5841 5.09956 24.4551 4.79791 24.4551 4.48311C24.4551 4.16859 24.5841 3.86694 24.8142 3.64435C25.0443 3.42176 25.356 3.29688 25.6811 3.29688C26.0066 3.29688 26.3183 3.4218 26.5484 3.64435C26.7785 3.8669 26.9075 4.16855 26.9075 4.48311Z"
        fill="#060920"
      />
      <path
        d="M26.9078 8.6003C26.9078 8.91513 26.7785 9.21678 26.5487 9.43929C26.3186 9.66153 26.0066 9.78677 25.6814 9.78677C25.3563 9.78677 25.0442 9.66153 24.8145 9.43929C24.5844 9.21675 24.4551 8.9151 24.4551 8.6003C24.4551 8.28577 24.5844 7.98412 24.8145 7.76154C25.0443 7.53899 25.3563 7.41406 25.6814 7.41406C26.0066 7.41406 26.3186 7.53899 26.5487 7.76154C26.7785 7.98408 26.9078 8.28573 26.9078 8.6003Z"
        fill="#060920"
      />
      <path
        d="M32.0485 4.48311C32.0485 4.79794 31.9191 5.09959 31.689 5.32211C31.4593 5.54434 31.1473 5.66958 30.8221 5.66958C30.4969 5.66958 30.1849 5.54434 29.9548 5.32211C29.725 5.09956 29.5957 4.79791 29.5957 4.48311C29.5957 4.16859 29.725 3.86694 29.9548 3.64435C30.1849 3.4218 30.4969 3.29688 30.8221 3.29688C31.1473 3.29688 31.4593 3.4218 31.689 3.64435C31.9191 3.8669 32.0485 4.16855 32.0485 4.48311Z"
        fill="#060920"
      />
      <path
        d="M32.0485 8.60054C32.0485 8.91506 31.9191 9.2167 31.689 9.43929C31.4593 9.66184 31.1473 9.78677 30.8221 9.78677C30.4969 9.78677 30.1849 9.66184 29.9548 9.43929C29.725 9.21675 29.5957 8.9151 29.5957 8.60054C29.5957 8.2857 29.725 7.98405 29.9548 7.76154C30.1849 7.5393 30.4969 7.41406 30.8221 7.41406C31.1473 7.41406 31.4593 7.5393 31.689 7.76154C31.9191 7.98408 32.0485 8.28573 32.0485 8.60054Z"
        fill="#060920"
      />
      <path
        d="M29.4781 15.0612C29.4781 15.3761 29.3488 15.6777 29.119 15.9002C28.889 16.1228 28.577 16.2477 28.2518 16.2477C27.9266 16.2477 27.6146 16.1228 27.3848 15.9002C27.1547 15.6777 27.0254 15.376 27.0254 15.0612C27.0254 14.7467 27.1547 14.4451 27.3848 14.2225C27.6146 13.9999 27.9266 13.875 28.2518 13.875C28.5769 13.875 28.889 13.9999 29.119 14.2225C29.3488 14.445 29.4781 14.7467 29.4781 15.0612Z"
        fill="#060920"
      />
    </svg>
  )
}
export default AmazonFireStick
