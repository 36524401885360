import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Device from '../device'
import ProofLossList from '../proof-loss-list'
import { getIcon } from '../icons'
import {
  selectClaimItemsAffected,
  selectClaimProofLossImages,
} from '../../selectors/claim'

export function ProofLossForm() {
  const [devices, setDevices] = useState(useSelector(selectClaimItemsAffected))
  const proofLossImages = useSelector(selectClaimProofLossImages)

  const onSelectDevice = (d) => {
    const updatedDevices = devices.map((device) => {
      if (device.id === d.id) {
        return { ...device, open: !device.open }
      }
      return device
    })
    setDevices(updatedDevices)
  }

  return (
    <div>
      {devices.map((device) => (
        <Device
          key={device.id}
          className="mb-3"
          onSelect={onSelectDevice}
          icon={getIcon(device.kind)}
          data={device}
          open={device.open}
        >
          <ProofLossList device={device} images={proofLossImages[device.id]} />
        </Device>
      ))}
    </div>
  )
}
export default ProofLossForm
