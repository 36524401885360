import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import { object, string, bool, array, number, lazy } from 'yup'

export const isValidEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export const isValidPhone = (phone) => {
  return /\d{10}/.test(phone)
}

const validPayoutAccount = (obj) => {
  if (obj.skipPayoutMethod) return true
  if (!isEmpty(obj.paypalAccount) && isValidEmail(obj.paypalAccount))
    return true
  if (!isEmpty(obj.venmoAccount) && isValidPhone(obj.venmoAccount)) return true
  return false
}

// claim steps
export const affectedItemsValidationSchema = object({
  affectedItems: array(
    object({
      id: string().required(),
      model: string().required(),
    })
  ).min(1, 'Select at least one device'),
})

export const lossTypesSchemaValidation = object({
  itemOperability: object({
    label: string().required(),
    value: string().required(),
  })
    .nullable()
    .required('Item Operability is required'),
  typeLoss: array(
    object({
      label: string().required(),
      value: string().required(),
    })
  ).min(1, 'Select Loss Type'),
  previousDamage: string().required('Previous Damage is required'),
})

export const incidentValidationSchema = object({
  date: string().required('Date is required'),
  incident: string().required('Inicident is required'),
})

export const payoutValidationSchema = object({
  skipPayoutMethod: bool(),
  paypalAccount: string()
    .email('Invalid Email')
    .when('skipPayoutMethod', {
      is: false,
      then: (schema) => schema.required('Paypal is Required'),
    }),
  venmoAccount: string()
    .min(10, 'Invalid phone number')
    .when('skipPayoutMethod', {
      is: false,
      then: (schema) => schema.required('Venmo is Required'),
    }),
})

export const payoutValidationSchemaV2 = object({
  skipPayoutMethod: bool(),
  refPaypalAccount: string(),
  paypalAccount: string()
    .email('Invalid Email')
    .when(['skipPayoutMethod', 'refVenmoAccount'], {
      is: (a, b) => a === false && isEmpty(b),
      then: (schema) => schema.required('Email is required'),
      otherwise: (schema) => schema,
    }),
  refVenmoAccount: string(),
  venmoAccount: string().when(['skipPayoutMethod', 'refPaypalAccount'], {
    is: (a, b) => a === false && isEmpty(b),
    then: (schema) =>
      schema.min(10, 'Invalid phone number').required('Venmo is required'),
    otherwise: (schema) => schema,
  }),
})

export const reviewValidationSchema = object({
  acceptedTermsConditions: bool().oneOf([true], 'Accept terms and conditions'),
})

export const addressValidationSchema = object({
  address: object({
    country: string().required('Country is required'),
    city: string().required('City is required'),
    state: string().required('State is required'),
    street: string().required('Street is required'),
    zip: string().required('Zip is required'),
  }),
})

// # claims steeps
export const claimValidationSchemaV2 = object({
  lossTypes: array(lossTypesSchemaValidation),
})
  .concat(affectedItemsValidationSchema)
  .concat(incidentValidationSchema)
  .concat(payoutValidationSchemaV2)
  .concat(reviewValidationSchema)

export const payout = object({
  skipPayoutMethod: bool().required('Payout Method is required'),
  paypalAccount: string().test(
    'oneOfRequired',
    'Payout Account is required',
    (value, testContext) => {
      return testContext && validPayoutAccount(testContext.parent)
    }
  ),
  venmoAccount: string().test(
    'oneOfRequired',
    'Payout Account is required',
    (value, testContext) => {
      return testContext && validPayoutAccount(testContext.parent)
    }
  ),
})

export const review = object({
  acceptedTermsConditions: bool().oneOf([true], 'Accept terms and conditions'),
})

export const claimSchema = object({
  id: string().required('Claim ID is required'),
  date: string().required('Date is required'),
  incident: string().required('Inicident is required'),
  affectedItems: array()
    .of(
      object({
        id: string().required(),
        model: string().required(),
      })
    )
    .required('Affected Items is required'),
  typeLoss: lazy((obj) =>
    object(
      mapValues(obj, () =>
        object({
          previousDamage: string().required(),
          typeLoss: array().required(),
        })
      )
    )
  ),
  acceptedTermsConditions: bool().oneOf([true], 'Accept terms and conditions'),
})

export const claimDataIsValid = (claimData) => {
  let isValid = null
  try {
    const validationResults = claimSchema.validateSync(claimData)
    isValid = true
  } catch (err) {
    isValid = false
  }
  return isValid
}

export const ConfirmAddressValidation = object({
  city: string().required('City is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  zip: string().required('Zip code is required'),
  street: string().required('Street is required'),
})

export const PaypalEmailValidation = object({
  paypal: string().email().required('Email is required'),
})

export const RepairCostValidation = object({
  repairCost: number().required('Repair Cost is required'),
  agreement: bool().oneOf([true], 'Should accept agreement'),
})

export const LoginValidation = object({
  phone: string().required('Phone number is required'),
  password: string().required('Password is required'),
})

export const ResetPasswordValidation = object({
  phone: string().required('Phone number is required').max(12),
})
