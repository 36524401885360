import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { propTypes, defaultProps } from './props'

// DEPREACATED
export function Paragraph({ className, children, ...props }) {
  return <div className={c('paragraph', className)}>{children}</div>
}
export default Paragraph

Paragraph.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
}

Paragraph.defaultProps = {
  ...defaultProps,
  className: '',
  children: null,
}
