import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

export function Toggle({ className, selected, options, onChange, ...props }) {
  const [isSelected, setIsSelected] = useState(selected)
  const onYes = () => {
    setIsSelected(true)
    onChange(true)
  }
  const onNo = () => {
    setIsSelected(false)
    onChange(false)
  }
  return (
    <div className={c('toggle', className)} {...props}>
      <button
        onClick={onYes}
        className={c('btn', {
          'btn-primary': isSelected,
          'btn-outline-primary': !isSelected,
        })}
        type="button"
      >
        {options[0]}
      </button>
      <button
        onClick={onNo}
        className={c('btn', {
          'btn-primary': !isSelected,
          'btn-outline-primary': isSelected,
        })}
        type="button"
      >
        {options[1]}
      </button>
    </div>
  )
}
export default Toggle

Toggle.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

Toggle.defaultProps = {
  className: '',
  selected: false,
  options: ['Yes', 'No'],
  onChange: () => {},
}
