import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import Label from '../label'
import { propTypes, defaultProps } from './props'
import { selectRepairStore } from '../repair-providers'
import { selectClaimItemAffectedByID } from '../../selectors/claim'
import { updateDynamicField } from '../../reducers/claim/thunks'
import { setRepairReplace } from '../../reducers/claim'
import { DELAY_TIMING } from '../../constants'
import Spinner from '../spinner'

export function RepairReplace({
  className,
  label,
  instruction,
  claimId,
  claimDeviceId,
  deviceId,
  id: fieldId,
  onChange,
}) {
  const dispatch = useDispatch()
  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )
  const [loading, setLoading] = useState(false)
  // TODO, reduce in to toggle variable instead of having two
  const [repair, setRepair] = useState(device ? device.repair : false)
  const [replace, setReplace] = useState(device ? device.replace : false)

  const update = useCallback(
    debounce((replace_, repair_) => {
      setLoading(true)
      const payload = {
        claim_id: claimId,
        claim_device_id: claimDeviceId,
        device_id: deviceId,
        field_id: fieldId,
        handler: 'repair_replace',
      }
      dispatch(updateDynamicField(payload)).then(() => {
        setLoading(false)
        onChange({ fieldId })
      })
    }, DELAY_TIMING),
    []
  )

  const onRepair = () => {
    const repairState = true
    const replaceState = false
    setRepair(repairState)
    setReplace(replaceState)
    dispatch(
      setRepairReplace({
        claimDeviceId,
        repair: repairState,
        replace: replaceState,
      })
    )
    update(repairState, replaceState)
  }
  const onReplace = () => {
    const repairState = false
    const replaceState = true
    setRepair(repairState)
    setReplace(replaceState)
    dispatch(
      setRepairReplace({
        claimDeviceId,
        repair: repairState,
        replace: replaceState,
      })
    )
    update(repairState, replaceState)
  }

  return (
    <div className={c('amount-text', className)}>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(instruction) && <div className="small mb-1">{instruction}</div>}
      <div className="mb-3">
        Mark if the device is replaceable or repairable
      </div>
      <div className="mb-2">
        <div
          className="btn-group"
          role="group"
          aria-label="Basic outlined example"
        >
          <button
            type="button"
            onClick={onRepair}
            className={c('btn', {
              'btn-primary': repair,
              'btn-outline-primary': !repair,
            })}
          >
            REPAIR
          </button>
          <button
            type="button"
            onClick={onReplace}
            className={c('btn', {
              'btn-primary': replace,
              'btn-outline-primary': !replace,
            })}
          >
            REPLACE
          </button>
        </div>
      </div>
      {/* {loading && <Spinner /> } */}
    </div>
  )
}
export default RepairReplace

RepairReplace.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

RepairReplace.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
