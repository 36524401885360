import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { PURCHASE_CONDITIONS } from '../constants'

export const getPurchaseConditions = createAsyncThunk(
  'purchaseConditions/getPurchaseConditions',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(`${PURCHASE_CONDITIONS}`)
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error on list currencies' })
      )
    }
  }
)

export const initialState = {
  list: [],
}
export const purchaseConditions = createSlice({
  name: 'purchaseConditions',
  initialState,
  reducers: {
    addPurchaseConditions: (state, action) => {
      const { payload } = action
      state.list = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseConditions.fulfilled, (state, action) => {
      const { payload } = action
      state.list = payload
    })
  },
})

export const { addPurchaseConditions } = purchaseConditions.actions

export default purchaseConditions.reducer
