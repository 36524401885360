import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toggleLoading, setAppError } from './app'
import { GET_COUNTRIES } from '../constants'

export const getCountries = createAsyncThunk(
  'devices/getCountries',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await axios.get(GET_COUNTRIES)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on list countries' }))
    }
  }
)

export const initialState = {
  countries: [],
}
export const devicesStore = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    addCountries: (state, action) => {
      state.countries = action.payload.countries
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state, action) => {})
    builder.addCase(getCountries.fulfilled, (state, action) => {
      const { payload } = action
      state.countries = payload
    })
    builder.addCase(getCountries.rejected, (state, action) => {})
  },
})

export const { addCountries } = devicesStore.actions

export default devicesStore.reducer
