import React from 'react'

export function Electronics() {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49805 10.7275V25.4858L2.11297 26.4082L13.1817 26.7156L13.4891 25.1783H18.1011L19.0235 24.2559L17.7936 19.9514H21.4832V25.4858L22.0981 26.4082H27.9399L28.5548 25.7932V14.1096L27.325 13.8021V3.04092L26.0951 1.19614L4.57268 0.888672C3.95776 1.29862 2.7279 2.18002 2.7279 2.42599V10.42L1.49805 10.7275Z"
        fill="white"
      />
      <path
        d="M27.7551 13.311V2.93925C27.7551 1.63948 26.6977 0.582031 25.3979 0.582031H4.65437C3.3546 0.582031 2.29715 1.63948 2.29715 2.93925V10.0109C1.51738 10.0109 0.882812 10.6455 0.882812 11.4252V25.5686C0.882812 26.3483 1.51738 26.9829 2.29715 26.9829H12.6689C13.4487 26.9829 14.0833 26.3483 14.0833 25.5686H18.0344C18.479 25.5686 18.8887 25.3659 19.1584 25.0127C19.428 24.6596 19.5157 24.2108 19.3988 23.7823L18.4719 20.3827H21.1549V25.5686C21.1549 26.3483 21.7895 26.9829 22.5693 26.9829H27.7551C28.5349 26.9829 29.1695 26.3483 29.1695 25.5686V14.7254C29.1695 13.9456 28.5349 13.311 27.7551 13.311ZM13.1404 25.5686C13.1404 25.8283 12.9287 26.04 12.6689 26.04H2.29715C2.03738 26.04 1.8257 25.8283 1.8257 25.5686V24.6257H13.1404V25.5686ZM13.1404 23.6828H1.8257V13.311H13.1404V23.6828ZM13.1404 12.3681H1.8257V11.4252C1.8257 11.1655 2.03738 10.9538 2.29715 10.9538H12.6689C12.9287 10.9538 13.1404 11.1655 13.1404 11.4252V12.3681ZM18.4894 24.0303C18.5285 24.1731 18.4993 24.3225 18.4092 24.4404C18.3192 24.5583 18.1829 24.6257 18.0344 24.6257H14.0833V23.6828H18.3946L18.4894 24.0303ZM14.0833 22.7399V20.3827H17.4946L18.1377 22.7399H14.0833ZM21.1549 19.4398H14.0833V16.6111H21.1549V19.4398ZM21.1549 14.7254V15.6682H14.0833V11.4252C14.0833 10.6455 13.4487 10.0109 12.6689 10.0109H3.24003V2.93925C3.24003 2.15948 3.8746 1.52492 4.65437 1.52492H25.3979C26.1777 1.52492 26.8123 2.15948 26.8123 2.93925V13.311H22.5693C21.7895 13.311 21.1549 13.9456 21.1549 14.7254ZM28.2266 25.5686C28.2266 25.8283 28.0149 26.04 27.7551 26.04H22.5693C22.3095 26.04 22.0978 25.8283 22.0978 25.5686V24.6257H28.2266V25.5686ZM28.2266 23.6828H22.0978V16.6111H28.2266V23.6828ZM28.2266 15.6682H22.0978V14.7254C22.0978 14.4656 22.3095 14.2539 22.5693 14.2539H27.7551C28.0149 14.2539 28.2266 14.4656 28.2266 14.7254V15.6682Z"
        fill="black"
      />
      <path
        d="M5.26374 18.165L3.37793 20.0508L4.04465 20.7175L5.93045 18.8317L5.26374 18.165Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M10.9211 15.8075L5.26367 21.4648L5.93039 22.1316L11.5878 16.4742L10.9211 15.8075Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M9.03553 14.3942L6.20703 17.2227L6.87375 17.8894L9.70225 15.0609L9.03553 14.3942Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M25.5345 17.6959L23.1777 20.0527L23.8445 20.7195L26.2013 18.3626L25.5345 17.6959Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M26.4769 19.5846L24.1201 21.9414L24.7868 22.6081L27.1436 20.2513L26.4769 19.5846Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M16.1058 5.90783L13.2773 8.73633L13.9441 9.40304L16.7726 6.57454L16.1058 5.90783Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M20.35 4.96837L17.5215 7.79688L18.1882 8.46359L21.0167 5.63509L20.35 4.96837Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M16.5782 8.73724L14.6924 10.623L15.3591 11.2898L17.2449 9.40396L16.5782 8.73724Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M18.9364 3.08023L17.0459 4.9707L17.7126 5.63742L19.6031 3.74694L18.9364 3.08023Z"
        fill="black"
        stroke="white"
      />
      <path
        d="M5.12551 2.46875H4.18262V3.41164H5.12551V2.46875Z"
        fill="black"
      />
      <path
        d="M7.01125 2.46875H6.06836V3.41164H7.01125V2.46875Z"
        fill="black"
      />
      <path d="M8.89699 2.46875H7.9541V3.41164H8.89699V2.46875Z" fill="black" />
    </svg>
  )
}
export default Electronics

Electronics.propTypes = {}

Electronics.defaultProps = {}
