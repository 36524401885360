import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Spinner from '../spinner'

const validationSchema = Yup.object().shape({
  cardNumber: Yup.boolean()
    .oneOf([true], 'Card number is required')
    .required('Card number is required'),
  expiryDate: Yup.boolean()
    .oneOf([true], 'Expiry date is required')
    .required('Expiry date is required'),
  cvc: Yup.boolean()
    .oneOf([true], 'CVC is required')
    .required('CVC is required'),
})

export function PaymentForm({
  onSubmitPayment,
  onStartTransaction,
  onFailStripeToken,
  loading,
}) {
  const [gettingToken, setGettingToken] = useState(false)
  const [stripeIsReady, setStripeIsReady] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const initialValues = {
    cardNumber: false,
    expiryDate: false,
    cvc: false,
  }

  const onSubmit = () => {
    onStartTransaction()
    setGettingToken(true)
    const card = elements.getElement('cardNumber')
    stripe
      .createToken(card)
      .then(({ token }) => {
        setGettingToken(false)
        onSubmitPayment({
          token,
        })
      })
      .catch(() => {
        setGettingToken(false)
        onFailStripeToken()
      })
  }

  useEffect(() => {
    if (!stripe || !elements) {
      setStripeIsReady(false)
    } else {
      setStripeIsReady(true)
    }
  }, [stripe, elements])

  if (!stripeIsReady) {
    return null
  }
  return (
    <div className="payment-service-fee_form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="payment-service-fee_form-cols">
              <div className="payment-service-fee_form-col">
                <div className="credit-card-input">
                  <CardNumberElement
                    onChange={(e) => {
                      setFieldValue('cardNumber', e.complete)
                    }}
                    className="form-control"
                  />
                </div>
                <ErrorMessage
                  component="div"
                  name="cardNumber"
                  className="payment-service-fee_input-error"
                />
              </div>
              <div className="payment-service-fee_form-col">
                <div className="payment-service-fee_form-cols">
                  <div className="payment-service-fee_form-col">
                    <CardExpiryElement
                      className="form-control"
                      onChange={(e) => {
                        setFieldValue('expiryDate', e.complete)
                      }}
                    />
                    <ErrorMessage
                      component="div"
                      name="expiryDate"
                      className="payment-service-fee_input-error"
                    />
                  </div>
                  <div className="payment-service-fee_form-col">
                    <CardCvcElement
                      className="form-control"
                      onChange={(e) => {
                        setFieldValue('cvc', e.complete)
                      }}
                    />
                    <ErrorMessage
                      component="div"
                      name="cvc"
                      className="payment-service-fee_input-error"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {gettingToken || loading ? (
                <Spinner />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Make Payment
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default PaymentForm

PaymentForm.propTypes = {
  onSubmitPayment: PropTypes.func,
  onStartTransaction: PropTypes.func,
  onFailStripeToken: PropTypes.func,
  loading: PropTypes.bool,
}

PaymentForm.defaultProps = {
  onSubmitPayment: () => {},
  onStartTransaction: () => {},
  onFailStripeToken: () => {},
  loading: false,
}
